import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class CategorySlider extends Component {
    constructor(props) {
        super(props); 
        this.state={
            clientXonMouseDown: null,
            clientYonMouseDown: null,
        }
    }



    
   handleOnMouseDown (e) {
    this.setState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY
    })
    e.preventDefault() // stops weird link dragging effect
  }

  render() {
    const settings = {
        autoplay: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    return (
            <div>
              <Slider {...settings}>
        {this.props.items!='' &&
           this.props.items.map(e =>  
            <div className="featured-box">
            <div className="featured-secQr">
            <div className="overlay">
                          </div> 
                      <img src="images/ilve.png" alt={e} />
            <a target="_blank" rel="noreferrer" onMouseDown={(events) => {this.handleOnMouseDown(events)}}>
            <span className="featured-caption">
              <div className="slider-logo-image">
                <h6>{e}</h6>
              </div>
            </span>
            </a>
          </div>
        </div>) 
        }
        
        </Slider>
      </div>
    );
  }
}