import React from "react";
import { Link } from "react-router-dom";
import { analyticsChangeEvent, analyticsClickEvent } from "../../action/firebaseAnalytics/firebaseAnalytics";

class ContractHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        {this.props.items &&
          this.props.items.map((response) => (
            <div className="col-lg-6 col-md-6 col-12 left-card--item">
              <div className="card">
              <Link className="wallet-contract-detail"  
                    //to="/wallet-contract-detail"
                    to={{
                      pathname: "/wallet-contract-detail",
                      state: { contract_id: response.contractId },
                    }}  onClick={(e) => analyticsClickEvent('history_contract','')}> 
                <div className="card-body">
                  <div className="inner-card--item">
                    <div className="sec--image">
                      <img
                        src={response.contractAccountCover}
                        className="img-fluid"
                        alt={response.contractAccountName}
                      />
                    </div>
                    <div className="card--content">
                      <h2>
                        {" "}
                        {"Amount Borrowed: £" + response.contractLoanAmount}
                      </h2>
                      <p>
                        Balance Outstanding:{" "}
                        <span className="rate-span">
                          £{response.contractBalanceOutStanding}
                        </span>
                      </p>
                      <span className="contractNumber">
                        Contract # {response.contractName}
                      </span>
                      <span className="contractCompletion">
                        Completion Date: {response.contractCompletionDate}
                      </span>
                    </div>
                  </div>
                </div>
                </Link>
                <div className="card-footer">
                  <Link
                    to={{
                      pathname: "/wallet-contract-detail",
                      state: { contract_id: response.contractId },
                    }}
                    className="text-right"
                    onClick={(e) => analyticsClickEvent('history_contract','')}
                  >
                    Go To Contract
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </React.Fragment>
    );
  }
}

export default ContractHistory;
