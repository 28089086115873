import React from 'react';
import {removeFavoritePartner} from '../../action/partner/removeFavoritePartner';
import {addViewPartner} from '../../action/partner/addViewPartners';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { Link } from 'react-router-dom';


class FavoritePartnerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            value:'',
            isFavAdd:'',
            isFavRemove:'',
            itemList:[]
        }
        this.handleFavoriteRemove = this.handleFavoriteRemove.bind(this)
    }
    componentDidMount(){
        this.setState({itemList:this.props.itemList});
    }

    
    favoritePartnerChange=(favList)=>{
        this.setState({itemList:favList});
    }

    async handleFavoriteRemove(value,partnerName){
        let  splitPartnerName = partnerName;
        if(partnerName!=undefined){
             splitPartnerName = partnerName.split(' ').join('_');
        }
        
        // analyticsClickEvent(splitPartnerName+'_partner_image_remove_favorite_icon');
        analyticsClickEvent('favorite_removed',splitPartnerName);
        const removeDataParam={email:localStorage.getItem('email'),account:value};
        await removeFavoritePartner (removeDataParam);
        let currentState = this.state.itemList;
        let removeFavUpdated = currentState.filter(function( obj ) {
            return obj.partnerId !== value;
        });
        await this.setState({itemList:removeFavUpdated});
        this.props.checkFavouriteExists();
     }
   
     addViewedPartner=async(partnerID,partnerWebsitLink)=>{
        if(partnerWebsitLink == '' || partnerWebsitLink == null || partnerWebsitLink == undefined){
            return false;
          }
        const obj={
            email:localStorage.getItem('email'),
            account:partnerID
        }
        await addViewPartner(obj)
     }

     checkURL=(url)=>{
        if(url!=null && url!=undefined && url!=''){
           let pattern = /^((http|https|ftp):\/\/)/;

           if(!pattern.test(url)) {
            return "http://" + url;
           }else{
            return url;
           }
        }else{
           return url 
        }
       
    }

    render() { 
        
        return (  
            <div className="gallery-partner">
            { this.state.itemList &&
            this.state.itemList.map(response =>  <div className="featured-box partner-item"   key={response.partnerId} >               
            <div id={response.partnerId} className="featured-itemQr">
            <div className="favorite-section">
                <a target="_blank" rel="noreferrer" href={this.checkURL(response.partnerWebSite)} onClick={()=>this.addViewedPartner(response.partnerId,response.partnerWebSite)}>
                    <div className="partner-img">
                    {response.partnerCoverImage==undefined &&
                      <img src="images/compu.png" alt={response.partnerName} />
                }
                {response.partnerCoverImage!=undefined &&
                      <img src={response.partnerCoverImage} alt={response.partnerName} />
                }
                        <div className="overlay">
                        </div>
                    </div>
                </a>
                <div className="fav-icon-section">
                {this.state.inputType &&(<span className="view-icon"  onClick={this.inputChangeHandler}> <i className="fa fa-eye"></i> </span>)}
                               
                    {response.partnerFavId===null &&(<a value={response.partnerId}  onClick={e => this.handleFavoriteAdd(response.partnerId)} className='fav-icon-btn'><img src="images/heart-white.svg" alt="Fav" /></a>) }
                    {response.partnerFavId!==null &&(<a value={response.partnerId} onClick={e => this.handleFavoriteRemove(response.partnerId,response.partnerName)} className='fav-icon-btn active'><img src="images/heart.svg" alt="Fav-active" /></a>) }
                    
                    
                </div>  
                <a target="_blank" rel="noreferrer" href={this.checkURL(response.partnerWebSite)} onClick={()=>this.addViewedPartner(response.partnerId,response.partnerWebSite)}>                      
                    <div className="overlay-logo-item">
                        <span className="featured-caption">
                            <div className="slider-logo-image">
                                {response.partnerLogo != null &&
                                   <img src={response.partnerLogo} alt={response.partnerName}/>
                                }
                                {response.partnerLogo == null &&
                                    <h6 >{response.partnerName}</h6>
                                }
                            </div>
                        </span>
                    </div>
                </a>
                <div className="qtwo-calculator">
                    <Link to={{
                        pathname: "/quote-calculator",
                        state: { id: response.partnerId, store_Name: response.partnerName }

                    }} onClick={() => {localStorage.setItem('prevRoute',window.location.pathname)}}>
                        <button className="qc-click-btn"
                        onClick={(_e) => { analyticsClickEvent('partner_quote_calculator', '') }}
                        >                            
                             <span className=''>
                                Get A Quote                                
                            </span>
                            <img src="images/right-arrow-shop.svg" width={25} alt="icon" />
                        </button></Link>

                </div>
            </div>
            </div>
        </div>) 
        } 
            </div>
  
        
        );
    }
}
 
export default FavoritePartnerList;