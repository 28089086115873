import React from 'react';
import Element from '../element';
import parse from 'html-react-parser';

export class FormType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() {
        
        return (
            <React.Fragment>
                {this.props.field_questions.length===0 &&
                    <div className="dynamic-input">
                    <div class="form-group"> 
                    {this.props.field_value!=='' &&
                    <h4 className="application-sub-title">{parse(this.props.field_value)}</h4>
                    }
                    <Element fieldItem={this.props.field_questions} stage={this.props.stage}  showValidation={this.props.showValidation}/>
                    </div>
                    </div>
                }
                {this.props.field_questions.length>1 &&
                <React.Fragment>
                    {this.props.field_value!=='' &&
                    <h4 className="application-sub-title">{parse(this.props.field_value)}</h4>
                    }
                    {/* <div className="col-md-6 col-12"></div>  */}
                    <Element fieldItem={this.props.field_questions} stage={this.props.stage}  showValidation={this.props.showValidation}/>
                    {/* <div className="col-md-6 col-12"></div>
                     */}
                </React.Fragment>
                }
                 </React.Fragment>
                
           
          );
    }
}
 
export default FormType;