import Cookies from 'universal-cookie';

export const handlingSession = (statusCode) => {
    if(statusCode===408){
        const cookie = new Cookies();
        // Remove cookie for Retailer Id once the user session is expired
        cookie.remove('retailerId', { path: '/' });
    }
    

    if(localStorage.getItem('fromWhere')!='skip'){ 
        if(statusCode===408){
            window.location.href = "/session-expired";
            localStorage.clear();
        } 
    }else{
        if(statusCode===408){
            localStorage.setItem('showPopup','true');
            window.location.href = "/application";
           
        }  
    }
};

export const sessionID = (val) =>{
    let DatatArray={};
    DatatArray['session_id']=localStorage.getItem('sessionId');
    DatatArray['Cache-control']='no-store';
    DatatArray['Pragma']='no-store';
    DatatArray['X-XSS-Protection']=`1 mode=block`; 
    DatatArray['X-Content-Type-Options']='nosniff';
    DatatArray['Referrer-Policy']='same-origin';
    DatatArray['Strict-Transport-Security']=`max-age=31536000; includeSubDomains; preload`
    DatatArray['X-DNS-Prefetch-Control']="on";
    DatatArray['X-Frame-Options']='sameorigin';
    DatatArray['Expect-Ct']='96400';
    DatatArray['csrf_token']=localStorage.getItem('csrfToken');
    // Cross-Origin-Embedder-Policy: unsafe-none | require-corp
    return DatatArray;
}
