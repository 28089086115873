import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from '../../utils/handlingSession';
export const userLogs = async (api_endpoint,formData,err, log_description='',status_code='') => {

     let url = config.Humm_Dev_Link+'user-logs/logs';
     let date =  new Date().toJSON().replace("T",' ').replace("Z",'+00');
     let user = (localStorage.getItem('fromWhere') === 'skip') ? 'skip': 'login';

     let logDescription=log_description;
     let statusCode=status_code;
     let methodName="";
     let errorMessage="";
     if(err.response){
         logDescription=err.response.data.message;
         statusCode=err.response.data.statusCode;
         methodName=err.response.config.method;
         errorMessage=err.message;
     }

     let critical=false;

     if(statusCode=="500" || statusCode=="503"){
        critical=true;
     }
     let path=window.location.pathname.split('/')[1];
 
     if(path=="application" || path=="humm-verfication"){
        critical=true;
     }

     let apiPointPath=api_endpoint.split('/').pop();

     if(apiPointPath=='verify_otp' ||  apiPointPath=='validate_phonenumber'){
        critical=false;
     }

     if(apiPointPath=='get_mitek_document_response'){
        formData='';
     }

     let obj = {
        "userId":sessionID().session_id, 
        "log_description":  logDescription,
        "logtypeLogTypeId": "", 
        "status_code": statusCode,
        "critical":   critical,
        "class_name": "",  
        "module_name": window.location.href,
        "method_name": methodName, 
        "line_number": "", 
        "device_id": "",
        "session_id": sessionID().session_id, 
        "login_type": user,
        "ip_address": "", 
        "created_date": date,
        "build_number": "",
        "device_type": "web",            
        "api_endpoint": api_endpoint,
        "error_message":errorMessage,
        "request_payload": formData       
    }
    return await axios.post(url,obj,{headers:sessionID()}).then(res => {
        return res
    })
    .catch(err => {
        return err.response
    })


};