import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

const headers = {
    'session_id': localStorage.getItem('sessionId'),
  }
export const addViewPartner = async (formData) => {
    let url = config.Humm_Dev_Link+'viewed-partner/add'

   return axios.post(url,formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })
};