import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loader from '../loader/loader';
import { QuoteCalculatorAPI } from '../../action/quoteCalculator/quoteCalculator'
import { QuoteTool } from '../../action/quoteCalculator/quoteTool';
import CurrencyFormat from "react-currency-format";
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';

class QuoteCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            isRedirect: false,
            customPath: "/quote-calculator-results",
            storeName: '',
            balance: '',
            searchResult: [],
            repaymentPlan: '',
            hasPartnerDetails:{},
            amount: '',
            hasDisplay: true,
            maximum: '',
            errors: {
                AmountError: ''
            },
            storeError: '',
            isErrorModal: false,
            NoRetailerMessage: '',
            isSelected: false,
            displayData: "",
            result: "",
            headerStoreId: '',
            isWebview: '',
            prevRoute: '',
            responseStore: {}


        }
        this.onHandleChange = this.onHandleChange.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
        this.filterResult = this.filterResult.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
    }

    closeErrorModel = () => {
        this.setState({ isErrorModal: false })
    }

    async componentDidMount() {
        const result = this.props.location.state;       
        this.getStoreId(this.props.location.search)
        if (result != undefined) {
            await this.setState({
                searchValue: result.store_Name,
                hasPartnerDetails: {
                    id: result.id,
                    store_name: result.store_Name
                }
            })
        }
        
        const obj = {
            "email": localStorage.getItem('email')
        }
        const response = await QuoteCalculatorAPI(obj);
        this.setState({responseStore: response.data.data.store});
        
        if (response) {
            if (response.data.statusCode === 200) {

                this.setState({ storeName: response.data.data.store, repaymentPlan: response.data.data.repayment_plan, balance: response.data.data.card_balance })

            }
        }


                
  
        if(this.state.headerStoreId !==null && this.state.headerStoreId !== undefined){
            this.setPartnerName();
        }
        
        if(this.props.location.state !== null && this.props.location.state !== '' && this.props.location.state !== undefined){
            if(this.props.location.state.partner_name !== undefined && this.props.location.state.partner_name !== null && this.props.location.state.partner_name !== '') {
            
            this.setState({searchValue: this.props.location.state.partner_name}) 
            this.setStoreId();
            }
        }
        
    }


    /* Get StoreId from current Route so amount input field will be displayed based on
     flag headerStoreId whether empty or not
    */
    getStoreId = (headerData) => {
        this.setState({prevRoute: headerData});
        // Get the search route values using URLSearchParams() method
        let searchParams = new URLSearchParams(headerData);
        let resultWebview = searchParams.get("isWebview");        
        let resultRetailerId = searchParams.get("store");
        /* splitValue.substring(splitValue.indexOf("%2") + 1, splitValue.lastIndexOf("%22")) */
        
        this.setState({headerStoreId: resultRetailerId});
        this.setState({isWebview: resultWebview})   
        
    
    }

    async onHandleChange(e) {
        let Value = e.target.value

        if (Value.trim() === '') {
            this.setState({
                storeError: "Retailer is required",
                isSelected: false,
                hasPartnerDetails:{}
            })

            console.log("Requried", this.state.storeError)
        }

        else if (this.state.searchResult.length == 0) {
            this.setState({ storeError: "Retailer is not in the list", hasDisplay: false, hasPartnerDetails:{} })
        }
        else {
            console.log(this.state.hasDisplay)
            this.setState({
                storeError: "", hasDisplay: true
            })
        }
        console.log("value", Value)
        await this.setState({ searchValue: e.target.value })
        this.filterResult();
    }

    filterResult() {
        let filterDetails;
        filterDetails = this.state.storeName.filter(
            (listitem) => {

                return listitem.store_name.toLowerCase().includes(this.state.searchValue?.toLocaleLowerCase());
            }
        );
        this.setState({ searchResult: filterDetails });
        // console.log(this.state.searchResult)
    }
    /* set the retailerIds in the list of states to display in the UI while the user can 
    searching the retailer Id during typing the retailer id in the store input field */    
    suggestionSelected = (values) => {        
        this.setState({ searchValue: values.store_name, hasPartnerDetails: values, hasDisplay: false })
        this.setState({ isSelected: true })
        this.setState({ storeError: "" })
        console.log("Search Result", values)
    };

    onKeyPressBlock = (event) => {
        if(event.key === '-'){
            event.preventDefault();
            return false;
        }
    }

    async handleAmountChange(event) {
        let { formattedValue, value } = event;
        if (Number(value) <= 1000000) {

            if (parseFloat(value) != 0) {
                await this.setState({ amount: value, ErrorMessage: "", displayData: formattedValue });
            }
            else {
                this.setState({ displayData: "" })
            }
        }
        else {
            console.log("false")
        }
                  
        if (value.length == 0 && value == "") {
            this.setState({ errors: { AmountError: "Quote Amount is required" } });
        }
        else {
            this.setState({ errors: { AmountError: "" } })
        }

    }

    async handleRedirect() {
        analyticsClickEvent('quote_landing_page_submit','')
        this.setState({ loading: true })
        let obj;
        if(this.state.hasPartnerDetails !== '' && this.state.hasPartnerDetails !== undefined) {
            obj = {
                "store_id": this.state.hasPartnerDetails.id,
                "amount": this.state.amount
            }
        }
        else {
            obj = {
                "store_id": this.state.headerStoreId,
                "amount": this.state.amount
            }
        }         
        console.log("Result Object", obj)
        const result = await QuoteTool(obj);
        this.setState({ result: result.data })
        if (result.data.statusCode == 400) {
            this.setState({
                loading: false,
                NoRetailerMessage: result.data.message
            })
            window.$("#commonModelRetail").modal("show")            
        }
        else {

            this.setState({ isRedirect: true })
        }


    }

    handleErrorModal(val) {
        this.setState({
            isErrorModal: val
        })
    }

    setStoreId() {
        for (const [i,value] of this.state.responseStore.entries()) {
            
            if(value.store_name === this.state.searchValue){
                
                this.setState({hasPartnerDetails : {
                    id: value.id,
                    store_name: value.store_name
                }})
                console.log('%d: %s', i, value.id);
                
            }
        }
        
    }
    

    setPartnerName() {
        console.log(this.state.hasPartnerDetails, "partnerddd...", this.state.headerStoreId)
        
        if(this.state.hasPartnerDetails){

            for (const [i,value] of this.state.responseStore.entries()) {                
                if(value.id === this.state.headerStoreId){
                    this.setState({hasPartnerDetails : {
                        id: value.id,
                        store_name: value.store_name
                    }})
                    this.setState({searchValue: value.store_name})
                    console.log('%d: %s', i, value.id);
                }
            }
        }

        
        
    }

    addClassnames = (displayState) => {
        if(displayState){
            return "search-body show-option"
        }
        else {
            return "search-body hide-option"
        }
    }
    

    onBlurChange = (event) => {

        let name = event.target.name;
        let value = event.target.value;
        
        if (name === 'purchase-amount') {
            if (value.length !== 0) {
              if(value.indexOf(".") >= 0){
                let decimal_pos = value.indexOf(".");
                let left_side = value.substring(0, decimal_pos);
                let right_side = value.substring(decimal_pos);
                let replaceLeftSide=left_side.replace(/\D/g, "").split(',').join('');
                right_side = right_side.substring(0, 3);
                if(right_side.length===2){
                  right_side=right_side+'0';
                }
      
              if(right_side.length===1){
                  right_side='.00';
              }
              value=replaceLeftSide+right_side;
              this.setState({displayData: value})
              
              }else{
                value = value.replace(/\D/g, "").split(',').join('')+'.00';
                this.setState({ displayData:value })
              
              }
      
              
            }
          }
    }

    render() {

        if (this.state.isRedirect) {
            const { repaymentPlan, balance, customPath, hasPartnerDetails, amount, prevRoute } = this.state;

            return (<Redirect

                push to={{
                    pathname: customPath,
                    state: { planDetails: repaymentPlan, balanceDetails: balance, partner: hasPartnerDetails, requestAmount: amount, result: this.state.result, routeHeader: prevRoute }
                }}
                HandleErrorModal={this.handleErrorModal}
            />)

        }

        return (
            <>
            <div className="wrapper">
                <Loader loaderStatus={this.state.loading} />


                {/* search nav */}
                <section className={localStorage.getItem('webView') ? `main mt-2` : `main`}>
                    { localStorage.getItem('webView') ? '' :
                    <div className="search-fav-section">
                        <div className="container">
                            <div className="d-flex align-items-center top-section-partner">
                                <div className="left-iteQerd">
                                    <div className="back-btn-item">
                                        <Link className="back-btnQr"
                                            to={{pathname: localStorage.getItem('prevRoute')}}
                                            onClick={() => {analyticsClickEvent('shop_back','');}}
                                        ><img src="images/back.svg" alt="<-" /> Back </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    }
                    <center>
                        <div className='top-nav-bar mt-4 mb-4 eligible-crit'>
                            <div className='my-2'>
                                <img src="images/loading-logo-img.png" alt="header icon" width="75" />
                            </div>
                            <h2 style={{color: "#ff6c00", fontSize: 37, fontWeight: 800}}>Get a quote</h2>
                            <h5 className='text-left'>To be eligible to apply for  <span style={{fontWeight: "600",display: "inline",color: "#212529",fontStyle: "italic"}}>
                                humm</span> you must meet the following criteria:</h5>
                            <ul>
                                <li>Be at least 18 years of age</li>
                                <li>Have a regular income of at least £1,000 per month</li>
                                <li>Have been a resident of the UK for at least 6 months</li>
                                <li>Have a UK credit or debit card in your own name</li>                            
                            </ul>
                        </div>

                        <div className="Q-header-center Quote-header">                            
                            
                            <p className='h5 font-weight-normal mx-3'>
                                <span className='d-block mt-1 mb-0'>humm allows you to spread the cost of your purchase with participating retail partners.</span>                           
                                <span className='d-block mt-2'>We provide fixed sum loans and you can start now by getting a quote today.</span>
                            </p>
                            
                        </div>
                    </center>
                </section>
                <section className="section py-1">
                    <center>
                        <div className="container">
                            <div className="Qc-section">
                                <h3 className="Qc-heading-text">Quote Calculator</h3>
                                <p className=''>
                                The below calculator should only be used as an illustrative example.</p>
                                <p>Please begin by selecting the retailer you wish to purchase from.</p>
                            </div>
                            <form>
                                <div className="Qc-form-body-section text-left">
                                    <div className="row">
                                    
                                        <div className="col-md-6 col-sm-12 col-12 my-0" style={{ position: 'relative' }}>
                                            
                                            <div className="form-group store-name">
                                                <label>Retailer</label>
                                                
                                                
                                                    <input type="text" className="form-control store-name" name="store"
                                                        value={this.state.searchValue}
                                                        placeholder="Start typing"
                                                        autoComplete="off"
                                                        onChange={(e) => this.onHandleChange(e)} />
                                                
                                            </div>
                                        
                                            {/* Display the retailers list by getting the list from the function() suggestionSelected  */}


                                            <div className="search-result-item">
                                                <div className={ this.addClassnames(this.state.hasDisplay) } >
                                                    <ul className="m-0 p-0">
                                                        {this.state.searchValue &&
                                                            this.state.searchResult.map(list =>
                                                                <li className="search-list" key={list.id}
                                                                    onClick={(_e) => this.suggestionSelected(list)}>{list.store_name}</li>
                                                            )

                                                        }


                                                    </ul>
                                                </div>
                                                <p className="error-message card-holder-error">{this.state.storeError}</p>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="form-group">
                                                <label>Quote Amount</label>
                                                <CurrencyFormat
                                                    className={"form-control amount-input"}
                                                    placeholder="Enter amount"                                                
                                                    name="purchase-amount"
                                                    value={this.state.displayData}
                                                    decimalScale={2}
                                                    isAllowed={(values) => {
                                                        const { value } = values;
                                                        return Boolean(value <= 1000000);
                                                    }}
                                                    thousandSeparator={true}
                                                    prefix={"£"}
                                                    autoComplete="off"
                                                    onValueChange={(values) => this.handleAmountChange(values)}
                                                    onKeyPress={(e) => this.onKeyPressBlock(e)}
                                                    onBlur={(event) => { this.onBlurChange(event)}}
                                                />
                                                <p className="error-message card-holder-error">{this.state.errors.AmountError}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                

                                <div className="qc-footer-section">                                
                                    {
                                            <button disabled={this.state.errors.AmountError != "" || this.state.storeError != "" || this.state.displayData === ""  || this.state.searchResult.length == 0 && Object.values(this.state.hasPartnerDetails).length == 0 || Object.values(this.state.hasPartnerDetails).length == 0} type="button" className="qc-confirm-btn btn" onClick={this.handleRedirect}>Confirm</button>

                                    }
                                    
                                </div>
                            </form>

                        </div>
                    </center>

                    <div className='Quote-footer mx-5 mt-4 mb-2'>
                        <p className='mx-auto mb-0'>
                        <span style={{fontWeight: "600",display: "inline",color: "#212529"}}>
                        humm</span> is a trading style of Humm Group Limited which is authorised and regulated by the Financial Conduct Authority. Financial Services Registration Number 954478. <span style={{fontWeight: "600",display: "inline",color: "#212529"}}>
                        humm</span> offers both regulated and unregulated products. 
                        Our interest free plans which are repayable within 12 months and in no more than 12 instalments are not regulated by the Financial Conduct Authority. Registered office: 3rd Floor, 2-4 Wellington Street, Belfast. 
                        Company Number: NI675430. Data Protection Register Number: ZB029507
                        </p>
                    </div>
                </section>

                <div
                    className="modal fade model-error"
                    id="commonModelRetail"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="commonModellLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header"></div>
                            <div className="modal-body">
                                <div className="logo">
                                    <img
                                        src="images/modal-logo.svg"
                                        alt="logo"
                                        className="img-fluid"
                                    />
                                </div>
                                <h5 className="modal-title " id="commonModellLabel">
                                No Plans Found
                                </h5>
                                <p className="text-center mt-4 mb-4">
                                    {this.state.NoRetailerMessage}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            );
    }
}


export default QuoteCalculator;

