import React from 'react';

export class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() { 
        
    
        return ( 
            <div className={`wrapper welcome-page loader-page ${this.props.loaderStatus?'':'custom-hide'}`} >
            
            <div className="logo">
                <img src="images/loading-logo-img.svg" className="img-fluid" alt="logo"/>
            </div>
            <div className="loading-dots">
                <div className="snippet" data-title=".dot-flashing">
                  <div className="stage">
                    <div className="dot-flashing"></div>
                  </div>
                </div>
              </div>
        </div>
         );
    }
}
 
export default Loader;