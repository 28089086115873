import React from "react";
import { getContractDetails } from "../../action/contract/getContractDetails";
import { getCardsDetails } from "../../action/cardDetails/cards";
import Loader from "../loader/loader";
import { Link } from "react-router-dom";
import { formatMonthDate } from "../../utils/const";
import { connect } from "react-redux";
import HeroSection from "./heroSection";
import ContractList from "./contractList";
import CardDetails from "./cardDetails";
import ContractHistory from "./contractHistory";
import Addcard from "../card/addCard";
import { analyticsClickEvent, analyticsScreenViewEvent, analyticsTypeEvent } from "../../action/firebaseAnalytics/firebaseAnalytics";


export class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.searchCall = React.createRef();
    this.state = {
      profilePicture: props.profilePicture,
      contractSummary:props.contractSummary,
      hasActiveContract: [],
      hasActiveCards: [],
      hasContractHistory: [],
      isLoading: false,
      inputValue: "",
      activeTab: 1,
      hasSearch: "",
      modal: false,
      cardHolderName:'',
      errors: {
        cardHolderName:''
    },
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  
    async componentDidMount() {
      // analyticsScreenViewEvent();
        this.initialLoad();
        if(localStorage.getItem('getScheduleContractDetails')!=null && localStorage.getItem('getScheduleContractDetails')!=undefined && localStorage.getItem('getScheduleContractDetails')!='' &&  localStorage.getItem('getScheduleContractDetails')!='false'){
          window.$("#registerModal").modal("show");

          setTimeout(()=>{
            window.$("#registerModal").modal("hide");
            localStorage.setItem('getScheduleContractDetails','')
          },8000)
          
        }
    }

    noScheduledContract=()=>{
      console.log('chrrrrr');
    }

  initialLoad=async()=>{
    this.setState({ isLoading: true });
    const contractDetails = await getContractDetails({
      email: localStorage.getItem("email"),
      contract: "",
    });
    // this.setState({ isLoading: false });
    // console.log(contractDetails);
if(contractDetails !== undefined){
    if (contractDetails.data.statusCode === 200) {
      let hasActiveContractArray = [];
      let hasContractHistoryArray = [];
      contractDetails.data.data.map((response) => {
        if (response.contract_status.includes('Active')) {
          let dataObj = {
            contractId: response.contract_id,
            contractName: response.contract_name,
            contractLoanAmount: response.contract_loan_amount,
            contractBalanceOutStanding: response.contract_balance__c,
            contractNextPayment: formatMonthDate(response.next_payment_date),
            contractAccountCover: response.account_cover,
            contractAccountName: response.account_name,
            contractCompletionDate: response.contract_completion_date,
          };
          hasActiveContractArray.push(dataObj);
        }
        if (response.contract_status.includes('Closed')) {
          let completedData = {
            contractId: response.contract_id,
            contractName: response.contract_name,
            contractLoanAmount: response.contract_loan_amount,
            contractBalanceOutStanding: response.contract_balance__c,
            contractNextPayment: formatMonthDate(response.next_payment_date),
            contractAccountCover: response.account_cover,
            contractAccountName: response.account_name,
            contractCompletionDate: response.contract_completion_date,
          };
          hasContractHistoryArray.push(completedData);
        }
      });

      this.setState({
        hasActiveContract: hasActiveContractArray,
        hasContractHistory: hasContractHistoryArray,
      });
    }
  }
  setTimeout(async() => {
    const contractCardDetails = await getCardsDetails({
      email: localStorage.getItem("email"),
    });

    if(contractCardDetails !== undefined){
    if (contractCardDetails.data.statusCode === 200) {
      let hasActiveCardsArray = [];
      contractCardDetails.data.data.map((response) => {
          let dataObj = {
            cardId: response.hummuk_customer_card_detail_id,
            cardNumber: response.hummuk_customer_card_detail_card_number__c,
            cardExpiryMonth: response.hummuk_customer_card_detail_exp_month,
            cardExpiryYear: response.hummuk_customer_card_detail_exp_year,
            PaymentMethodId: response.hummuk_customer_card_detail_payment_method_id__c,
            cardHoldName: response.hummuk_customer_card_detail_card_holder_name__c,
            cardStatus: response.hummuk_customer_card_detail_primary_card__c,
            cardType:response.hummuk_customer_card_detail_card_type__c.toLowerCase()
        
            // contractCompletionDate:response.contract_end_date
          };
        hasActiveCardsArray.push(dataObj);
      });

      this.setState({ hasActiveCards: hasActiveCardsArray });
    }
  }
  this.setState({ isLoading: false });
  }, 3000);

  }


  handleSelect = (event,value,firebaseEvent) => {
    event.preventDefault();
    analyticsClickEvent(firebaseEvent,'')
    this.setState({ activeTab: value,hasActiveCards:[] });
    this.initialLoad();
}


  handleSearchValue = (event) => {
    // analyticsTypeEvent('history_search')
    this.setState({ inputValue: event.target.value });

    const activeTabId = this.state.activeTab;
    let filterDetails;
    switch (activeTabId) {
      case 1:
        filterDetails = this.state.hasActiveContract.filter(
          (activeContract) => {
          
            return activeContract.contractAccountName
              .toLowerCase()
              .includes(this.state.inputValue.toLocaleLowerCase());
          }
        );

        break;
      case 2:
        filterDetails = this.state.hasContractHistory.filter(
          (contractHistory) => {
            return contractHistory.contractAccountName
              .toLowerCase()
              .includes(this.state.inputValue.toLocaleLowerCase());
          }
        );

        break;
    }

    this.setState({ hasSearch: filterDetails });
  };

  onShowAddCard = () => {
     analyticsClickEvent('payment_authority_ok','')
    setTimeout(() => {
      let cardHoldererrors = this.state.errors.cardHolderName;
      this.setState({ errors:{...this.state.errors,cardHolderName:''}});
      window.$("#addCard").modal("show");
      window.$(".add-card-form").trigger('reset');
      this.setState({cardHolderName:'',});
    }, 1000);

  };

  paymentfunctionCall=()=>{
    // window.location.reload(false)
    this.setState({ isLoading: true,hasActiveCards:[] });
    window.$("#addCard").modal("hide");
    setTimeout(async() => {
    this.initialLoad();
    this.initialLoad();
    }, 5000);
    
  }



  changeModalStatus(e) {
     analyticsClickEvent('add_card','')
    this.setState({ modal: false });
  }

  onlyAcceptInputs = (e) => {
      if (!/[a-zA-Z ]/i.test(e.clipboardData.getData('Text')) || /[0-9]/.test(e.clipboardData.getData('Text')) || /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(e.clipboardData.getData('Text'))) {
        e.preventDefault();
      }
    
  }
  handleKeyDown = event => {
    if (event.key === 'Enter') {
             event.preventDefault()
    }
}
  
  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
  //  analyticsTypeEvent(name)

    this.setState({ [name]: value });
    let errors = this.state.errors;
   

    switch (name) {
      case 'cardHolderName':
        if (value.trim() === "") {
          errors.cardHolderName = `Cardholder name is required`;
        // } else if (/[^a-zA-Z -]/.test(value)) {
        } else if (!/^[A-Za-z '-]+$/.test(value)) {
          errors.cardHolderName = "Invalid characters";
        } else {
          errors.cardHolderName = '';
        }
        break;
      default:
        errors.cardHolderName = '';
    }
    this.setState({ errors, [name]: value })
}

cardHolderCheckvalidation = () => {
  setTimeout(() => {
   
    if(this.state.cardHolderName == ''){
      this.setState({ errors:{...this.state.errors,cardHolderName:'Cardholder name is required'}});
    //  this.searchCall.current.cardHolderIsvalid(this.state.errors.cardHolderName);
      // return false;
    }
  }, 100);
  
}

// FCA-1250 - Hiding virtual card reference in the app as it is not required for now.

  

  render() {
    return (  
      <div className="wrapper">
        <Loader loaderStatus={this.state.isLoading} />
        <section className="main">
          <HeroSection profilePicture={this.state.profilePicture} contractSummary={this.state.contractSummary} />
        </section>
        <section className="contract--item">
          <div className="container">
            <div className="d-flex wallet-contract">
              <div className="nav-tabs">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="activeCont-tab"
                      data-toggle="tab"
                      href="#activeCont"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={(event) => this.handleSelect(event,1,'active_contracts_menu')}
                    >
                      Active Contracts
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contractHis-tab"
                      data-toggle="tab"
                      href="#contractHis"
                      role="tab"
                      aria-controls="contractHis"
                      aria-selected="false"
                      onClick={(event) => this.handleSelect(event,2,'history_menu')}
                    >
                      Contract History
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="myCard-tab"
                      data-toggle="tab"
                      href="#myCard"
                      role="tab"
                      aria-controls="myCard"
                      aria-selected="false"
                      onClick={(event) => this.handleSelect(event,3,'mycards_menu')}
                    >
                         {this.state.hasActiveCards?'My Cards':'My Card'}
                     
                    </a>
                  </li>
                </ul>
              </div>
              {this.state.activeTab <= 2 && (
                <div className="search--item">
                  <div className="search-bar">
                    <input
                      className="form-control"
                      type="text"
                      id="search"
                      value={this.state.inputValue}
                      name="search"
                      onChange={this.handleSearchValue}
                      placeholder="Search"
                      onBlur ={(e) => analyticsClickEvent('wallet_search',this.state.inputValue)}
                      onClick={(e) => analyticsClickEvent('wallet_search','')}
                    />
                  </div>
                </div>
              )}

              {this.state.activeTab === 3 &&  this.state.hasActiveCards.length!==0 &&(
                <div className="addCardBtn--item">
                  <div className="addCard-button">
                    <button
                      className="add-card-bth"
                      type="button"
                      data-toggle="modal"
                      data-target="#commonModel"
                      onClick={(e) => this.changeModalStatus(e)}
                    >
                      Add Card
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="tabs-section">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="activeCont"
                  role="tabpanel"
                  aria-labelledby="activeCont-tab"
                >
                  <div className="contract-list">
                    <div className="row">
                      {this.state.hasActiveContract.length === 0 && (
                        <div className="col-lg-6 col-md-6 col-12 left-card--item">
                          <div className="card">
                            <div className="card-body">
                              <div className="my-card--content">
                                <p>
                                  You do not have any active contract at the
                                  moment. Apply right now by clicking below.
                                </p>
                              </div>
                            </div>
                            <div className="card-footer">
                              {/* <button className="add-card-bth"  to="\application"type="button">Apply Now</button> */}
                              <Link
                                className="add-card-bth"
                                to="/application"
                                role="button"
                                onClick={(e) => analyticsClickEvent('wallet_apply_now','')}
                              >
                                Apply Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}

                      {this.state.hasActiveContract && (
                        <ContractList
                          //items={this.state.hasActiveContract}
                          items={
                            this.state.inputValue && this.state.activeTab === 1
                              ? this.state.hasSearch
                              : this.state.hasActiveContract
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="contractHis"
                  role="tabpanel"
                  aria-labelledby="contractHis-tab"
                >
                  <div className="contract-list">
                    <div className="row">
                      {this.state.hasContractHistory.length === 0 && (
                        <div className="col-lg-6 col-md-6 col-12 left-card--item">
                          <div className="card">
                            <div className="card-body">
                              <div className="my-card--content">
                                <p>
                                No Contract History Found.
                                </p>
                              </div>
                            </div>
                            <div className="card-footer">                              
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.hasContractHistory && (
                        <ContractHistory
                          //items={this.state.hasActiveContract}
                          items={
                            this.state.inputValue && this.state.activeTab === 2
                              ? this.state.hasSearch
                              : this.state.hasContractHistory
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="myCard"
                  role="tabpanel"
                  aria-labelledby="myCard-tab"
                >
                  <div className="my-card--item">
                    {this.state.hasActiveCards.length === 0 && (
                      <div className="row no-card-item">
                        <div className="col-lg-6 col-md-6 col-12 left-card--item">
                          <div className="card">
                            <div className="card-body">
                              <div className="my-card--content">
                                <p>
                                  You do not have any active cards at the
                                  moment. Please add one by clicking below.
                                </p>
                              </div>
                            </div>
                            <div className="card-footer">
                              <button
                                className="add-card-bth"
                                type="button"
                                data-toggle="modal"
                                data-target="#commonModel"
                              >
                                Add Card
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="card-active">
                      {this.state.hasActiveCards.length !== 0 && (
                             <CardDetails items={this.state.hasActiveCards}  paymentfunctionCalls={()=>{this.paymentfunctionCall()}}/>
                      )}
                      {/* FCA-1250 - Hiding virtual card reference in the app as it is not required for now. */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="modal fade"
          id="addCard"
          tabindex="-1"
          role="dialog"
          aria-labelledby="manualPayNextTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick = {(_e) => analyticsClickEvent('add_card_popup_close','')}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="logo">
                  <img
                    src="images/modal-logo.svg"
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="tittle-content">
                  <h2>Add Card</h2>
                </div>
                <form className="add-card-form">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      id="card-holder-name"
                      name="cardHolderName"
                      value={this.state.cardHolderName}
                      placeholder="Cardholder Name"
                      onPaste={(e) => { this.onlyAcceptInputs(e) }}
                      onChange={(e) => this.handleChange(e)}
                      onKeyDown={this.handleKeyDown}
                      onClick = {(e) => analyticsClickEvent('cardholder_name','')}
                    />
                
                     <p className="error-message card-holder-error">
                                        {this.state.errors.cardHolderName !== '' &&
                                            <span>{this.state.errors.cardHolderName}</span>
                                        }</p>
                  </div>
                 
                  {/* <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Credit Card Number"
                    />
                  </div>
                  <div className="d-flex">
                    <div className="monthyear">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="MM/YY"
                        />
                      </div>
                    </div>
                    <div className="cvv">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="CVV"
                        />
                      </div>
                    </div>
                  </div> */}
                </form>
                <Addcard paymentfunctionCalls={()=>{this.paymentfunctionCall()}}  cardHolderCheckvalidation={()=>{this.cardHolderCheckvalidation()}} isValidCardHolder = {this.state.errors.cardHolderName} cardHolderName={this.state.cardHolderName}/> 
              </div>
              {/* <div className="modal-footer">
                <button type="button" className="btn btn-primary">
                  Confirm
                </button>
              </div> */}
            </div>
          </div>
        </div>

        {/* model for notify */}
        <div
          className="modal fade"
          id="notify-virtual-card"
          tabindex="-1"
          role="dialog"
          aria-labelledby="notifyVirtualCard"
          aria-hidden="true"
        >
          
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={(_e) => analyticsClickEvent('notifyVirtualCard_popup_close','')}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="logo">
                  <img
                    src="images/modal-logo.svg"
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="tittle-content">
                  <p>
                    Kindly login using your mobile application to request a
                    virtual card
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* end model for notify */}

        {/* privacy policy */}
        <div
          className="modal fade model-error"
          id="commonModel"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="commonModellLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div class="modal-header"></div>
              <div className="modal-body">
                <div class="logo">
                  <img
                    src="images/modal-logo.svg"
                    alt="logo"
                    class="img-fluid"
                  />
                </div>
                <h5 className="modal-title " id="commonModellLabel">
                  Payment Authority
                </h5>
                <p className="text-center mt-4">
                  Please confirm you authorise humm to debit the card details
                  you provide for future payments due under this Loan Agreement
                  under a Continuous Payment Authority.
                  <br />
                  <br />
                  Please mark the new card you have added as the primary card for all future repayments.
                </p>
              </div>

              <div className="modal-footer mb-4">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.onShowAddCard();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* privacy policy */}
        <div className="modal fade" id="registerModal" tabIndex="-1" role="dialog" aria-labelledby="registerModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                                </div>

                                <p>{localStorage.getItem('getScheduleContractDetails')}</p>

                        </div>
                    </div>
                </div>
      </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  console.log('state-wallet',state);
  return {
    profilePicture: state.dataStore.profilePicture,
    contractSummary:state.dataStore.contractSummary
  };
};

export default connect(mapStateToProps,null)(Wallet);
