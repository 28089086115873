let currencyFormatter = require('currency-formatter');

export const currentAddressFields= ['Address_Line1__c','Postal_Code__c','County__c','FlatNumber__c','HouseName__c','HouseNumber__c','Street__c','Town__c','Address_Line2__c'];
export const PreviousAddressFields= ['Previous_Address_Line1__c','PreviousCounty__c','PreviousFlatNumber__c',
            'PreviousHouseName__c','PreviousHouseNumber__c','PreviousPostal_Code__c','PreviousStreet__c','PreviousTown__c','Previous_Address_Line2__c'];
export const autoCaptureHints = {
                MISNAP_HEAD_OUTSIDE: 'Place Face in Oval',
                MISNAP_HEAD_SKEWED: 'Look Straight Ahead',
                MISNAP_AXIS_ANGLE: 'Hold Phone Upright',
                MISNAP_HEAD_TOO_CLOSE: 'Move Farther Away',
                MISNAP_HEAD_TOO_FAR: 'Get Closer',
                MISNAP_STAY_STILL: 'Hold Still',
                MISNAP_SUCCESS: 'Success',
                MISNAP_STOP_SMILING: 'Stop Smiling',
                MISNAP_SMILE: 'Smile!',
                MISNAP_READY_POSE: 'Hold it There',
                NO_FACE_FOUND: 'Face not found',
                MITEK_ERROR_GLARE: 'Reduce Glare',
                MITEK_ERROR_FOUR_CORNER: 'Document Not Found',
                MITEK_ERROR_TOO_DARK: 'Too Dark. Use good lighting',
                MITEK_ERROR_FOCUS: 'Hold Steady',
                MITEK_ERROR_MRZ_MISSING: 'Can\'t read every element on the photo page of your passport',
                CV_NO_BARCODE_FOUND: 'Scanning for barcode',
                MITEK_ERROR_TOO_FAR: 'Document Too Far',
                MITEK_ERROR_TOO_CLOSE: 'Document Too Close',
                MITEK_ERROR_NOT_CENTERED: 'Document Not Centered',
                MITEK_ERROR_MIN_PADDING:  'Move further away',
                MITEK_ERROR_HORIZONTAL_FILL: 'Move closer',
                MITEK_ERROR_LOW_CONTRAST: 'Center document on a dark background',
                MITEK_ERROR_BUSY_BACKGROUND: 'Center document on a plain background',
                MITEK_ERROR_SKEW_ANGLE: 'Reduce angle'
              };
        
export const generalHints = {
                MITEK_ERROR_FOUR_CORNER: 'We can\'t find the 4 corners of your document.',
                MITEK_ERROR_TOO_DARK: 'There is not enough light on your document.',
                MITEK_ERROR_FOCUS: 'The image is too blurry.',
                MITEK_ERROR_GLARE: 'The image has glare.',
                MITEK_ERROR_MIN_PADDING: 'Move the camera further away from your document.',
                MITEK_ERROR_HORIZONTAL_FILL: 'Move the camera closer to your document.',
                MITEK_ERROR_SKEW_ANGLE: 'Document is skewed.  Hold camera directly over your document.',
                MITEK_ERROR_LOW_CONTRAST: 'Center document on a dark background.',
                MITEK_ERROR_BUSY_BACKGROUND: 'The background is too busy.  Please use a solid background.',
                MITEK_ERROR_MRZ_MISSING: 'No MRZ found',
                CV_NO_BARCODE_FOUND: 'We were unable to detect the barcode from the back of your license.',
                IMAGE_SMALLER_THAN_MIN_SIZE: 'The image you provided is too small.',
                CORRUPT_IMAGE: 'The image you provided is unreadable.',
                MISNAP_HEAD_SKEWED: 'Look Straight Ahead',
                MISNAP_HEAD_TOO_CLOSE: 'Move Farther Away',
                MISNAP_HEAD_TOO_FAR: 'Get Closer',
                NO_FACE_FOUND: 'No Face Detected',
              };
        
export const customMessages = {
                DL_FRONT: {
                    firstMessage: 'Center document on a dark background',
                    fourCornerMessage: 'Center document on a dark background'
                },
                PDF417_BARCODE: {
                    firstMessage: 'Center document on a dark background',
                    fourCornerMessage: 'Scanning for barcode'
                },
                PASSPORT: {
                    firstMessage: 'Center photo page on a dark background',
                    fourCornerMessage: 'Center photo page on a dark background'
                },
                SELFIE: {
                    firstMessage: 'Place face in oval',
                    fourCornerMessage: 'Face not found'
                },
                CHECK_FRONT: {
                    firstMessage: 'Center Check Front on a dark background',
                    fourCornerMessage: 'Scanning for Check Front'
                },
                CHECK_BACK: {
                    firstMessage: 'Center Check Back on a dark background',
                    fourCornerMessage: 'Scanning for Check Back'
                },
                DOCUMENT: {
                    firstMessage: 'Center document on a dark background',
                    fourCornerMessage: 'Center document on a dark background'
                }
              };

 export const formatMonthDate=(value)=>{

        if(value){
            let splitData = value.split(',');
            return splitData[0].split(' ');
        }
        return value;
 }

 export const formatAmount = (value)=>{
    if(value){
      return  currencyFormatter.format(value, { locale: 'en-ca',precision:"undefined",symbol:""})
      
    }
}