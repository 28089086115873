import axios from 'axios';
import config from '../config';
import {sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'


export const manualLogin = (formData) => {
  
    let url = config.Humm_Dev_Link+'login'
    return async (dispatch) => {
        let resp = [];
        await axios.post(url,  formData,{headers:sessionID()} )
            .then(response => {
                dispatch({
                    type: 'LOGIN_STORE',
                    value: response.data
                });
                resp = response;
                return resp;
            })
            .catch(async error => {
                await userLogs(url,formData,error);
                resp = error.response;
                return resp;
            })

            return resp;
    }
};

export const emailCheck = async (formData) => {
    let url = config.Humm_Dev_Link+'email-check'

   return axios.post(url, formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        return err.response
    })


};

export const twitterRequestToken = async  (formData) => {
    let url = config.Humm_Dev_Link+'twitter-login/RequestToken'
    return axios.post(url,formData).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        return err.response
    })
 
};

export const twitterLogin = async  (formData) => {
      let url = config.Humm_Dev_Link+'twitter-login/AccessToken'
      return axios.post(url,formData).then(res => {
          return res;
      })
      .catch(async err => {
        await userLogs(url,formData,err);
          return err.response
      })
  };

  export const getUserBlockStatus = async  (formData) => {
    let url = config.Humm_Dev_Link+'user/getuserblockstatus'
    return axios.post(url,formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        return err.response
    })
};

  
