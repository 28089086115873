import React from "react";
 import QRCode from "qrcode.react";
import { useHistory} from "react-router-dom";
import {handoffToken} from '../../action/application/handoffToken'
import {getStatus} from '../../action/application/getStatus'
import { Redirect, withRouter } from 'react-router';
import { analyticsClickEvent } from "../../action/firebaseAnalytics/firebaseAnalytics";
import Cookies from 'universal-cookie';
import parse from 'html-react-parser';
import $ from "jquery";
export class QrCode extends React.Component {
    constructor(props) {
        super(props);
        this.history=useHistory;
        this.state = {
            qrValue: '',
            generateqr: false,
            sendapi: false,
            sessionid:localStorage.getItem('sessionId'),
            isshowdesktop:true,
            token:'',
            tokenValue:'',
            redirectURL:'',
            qrScanned:false,
            countStatus:0,
            greater:false,
            qrIn:false,
            reGenerateQRCode:false,
            RedirectToHome:false,
            applicationStatusHeading:'',
            applicationStatusBody:''
        };
        this.loadData=this.loadData.bind(this);
        this.processloadData=this.processloadData.bind(this);
        this.loadDataCompletedData=this.loadDataCompletedData.bind(this);
        this.intervalID ='';
        this.checkIntervalID ='';
    }

     componentDidMount() {

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.generateToken();
        //this.processedDocument =setInterval(this.processloadData, 1000);
        if(this.state.greater===false){
          this.callevent()
        }
    }

    callevent=()=>{
        console.log('qroud',this.state.greater);
        if(this.state.qrIn===false){
            this.loadData();
            this.processloadData()
            localStorage.setItem('pageLanded','');
            this.intervalID =setInterval(this.loadData, 30000);
            this.setState({qrIn:true})
        }
    }
    
      async loadData() {
          if(this.state.qrScanned===true){
            this.setState({greater:true});
            clearInterval(this.intervalID);
          }
          if(this.state.greater===false){
         try {
          let obj = '';
          if (localStorage.getItem('fromWhere') === 'skip') {
            obj={
              "email":localStorage.getItem('flxPersonEmail__c'),
              "application_id":localStorage.getItem('applicationId')
          }
          }else{
            obj={
                "email":localStorage.getItem('email'),
                "application_id":localStorage.getItem('applicationId')
            }
          }
            const result=await getStatus(obj);
            if(result.data.data.hummuk_mitek_status_track_application_status){
             if(result.data.data.hummuk_mitek_status_track_application_status==="1"){
                this.checkIntervalID =setInterval(this.loadDataCompletedData, 30000);
                this.setState({qrScanned:true})
              }
            }
        } catch (e) {
            console.log(e);
        }
        }else{
          clearInterval(this.intervalID);
        }
      }
      
      async processloadData() {
        if(this.state.miteckCompleted===true){
          clearInterval(this.processedDocument);
        }
       try {
         if(localStorage.getItem('processedPageLanded')==='yes'){
          this.setState({miteckCompleted:true})
         }
      } catch (e) {
          console.log(e);
      }
    }

      componentWillUnmount(){
        clearInterval(this.intervalID);
        clearInterval(this.processedDocument);
        clearInterval(this.checkIntervalID); 
      }

      async loadDataCompletedData(){
        console.log('loadDataCompletedData');
        if(this.state.RedirectToHome===false){
          const obj={
              "email":localStorage.getItem('email'),
              "application_id":localStorage.getItem('applicationId')
          }
  
          const result=await getStatus(obj);      
          if(result.data.statusCode === 400 && result.data.message === "Invalid Session"){
            const cookie = new Cookies();
            // Remove cookie for Retailer Id once the user session is expired
            cookie.remove('retailerId', { path: '/' });

            localStorage.clear();
            this.props.history.push('/login')
            window.location.reload(true);
          }
        if(result.data.data !== undefined){
          if(result.data.data.hummuk_mitek_status_track_application_status){
            if(result.data.data.hummuk_mitek_status_track_application_status==="2"){
              // this.setState({RedirectToHome:true})
              if(result.data.data.application_current_application_status==="approved"){
                  analyticsClickEvent('application_approved', '');
                  this.setState({applicationStatusHeading:'APPROVED'})
                  this.setState({applicationStatusBody:"Congrats! You're up and humming! You have now completed your application."})
              }

              if(result.data.data.application_current_application_status==="canceled" || result.data.data.application_current_application_status==="pending"){
                  this.setState({applicationStatusHeading:'MESSAGE'})
                  let msg = "The ID verification process has not been successful so we are unable to proceed with the application at this time. Please contact us at contactuk@shophumm.com if you'd like further information";
                  if (this.extractEmails(msg) != null) {
                    let newtext = '';
                    let val = this.extractEmails(msg);
                    val.map((src) => {
                      let anchorTag = "<a target='_blank' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
                      newtext = msg.replace(src, anchorTag);
                      msg = newtext;
      
                    })
                  }
                  this.setState({applicationStatusBody:msg})
              }

              window.$('#mitekSucesssModal').modal('show');
          }
          else if(result.data.data.application_current_application_status==="REFER"){
            analyticsClickEvent('application_referred');
            this.setState({applicationStatusHeading:'REFERRED'})
            this.setState({applicationStatusBody:"Your application has been referred for further assessment. We will be in touch with you within 48 hours with further information"})

            window.$('#mitekSucesssModal').modal('show');
            }
          }
        }
              
        }else{
          clearInterval(this.checkIntervalID); 
        }
      
    }

    extractEmails = (text) => {
      return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    }

      isEnableDesktopCapture = async () => {
        clearInterval(this.intervalID);
        this.props.enableWebCapture()
    
      }

    generateToken=async()=>{
      let obj = '';
      if (localStorage.getItem('fromWhere') === 'skip') {
        obj={
          "email":localStorage.getItem('flxPersonEmail__c'),
          "application_id":localStorage.getItem('applicationId')
      }
      }else{
        obj={
            "email":localStorage.getItem('email'),
            "application_id":localStorage.getItem('applicationId')
        }
      }

        const result=await handoffToken(obj);
        let token = result.data.data.token;
        this.setState({tokenValue:token});
        let redirectURL=window.location.origin.toString()+'/mitek-humm/'+this.state.tokenValue;
        this.setState({redirectURL:redirectURL})
    }
    
    resize() {

        if(window.innerWidth <= 680){
            console.log(window.innerWidth);
            this.setState({isshowdesktop: false});
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    regenerateQRcode(){
         analyticsClickEvent('regenerateQRCode','')
        this.setState({reGenerateQRCode: true});
    }

    returnHome=()=>{
         analyticsClickEvent('return_to_home','')
      window.$('#mitekSucesssModal').modal('hide');
      this.setState({RedirectToHome:true})
    }

    render() {
        console.log(window.location.origin.toString()+'/humm-verfication/'+this.state.tokenValue);
        if(this.state.RedirectToHome){
            return <Redirect push to="/home" />;
        }
        if(this.state.qrScanned===true){
            return (
                <div>
                    
                  <h4 class="application-qr-sub-title">Please complete the Document Capture on your mobile phone</h4>
                  {this.state.reGenerateQRCode === false &&
                  <div class="text-center">
                  <button type="button" className="btn btn-primary" onClick={() => this.regenerateQRcode()}>Regenerate QR Code </button> 
                  </div>
                }  
                 {this.state.reGenerateQRCode === true &&      
                  <div className="QR-align">
                                 <QRCode size={200} value={`${window.location.origin.toString()+'/humm-verfication/'+this.state.tokenValue}`} />

                </div>  
                
        }   
              <div className="modal fade model-error" id="mitekSucesssModal" tabIndex="-1" role="dialog" aria-labelledby="mitekSucesssModalModel" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal"><img src="images/close-modal.svg"
                                    alt="x" /></button>
                            </div>
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                                </div>
                                {/* <h5 className="modal-title " id="mitekSucesssModal">{this.state.applicationStatusHeading}</h5> */}
                                <p className="text-center mt-4">{parse(this.state.applicationStatusBody)}</p>
                            </div>
                            <div className="modal-footer mb-4">
                                <button class="btn btn-warning btn-outline" onClick={(e) => { this.returnHome(e) }}>Return Home</button>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            );
        }else{
            return (
                 
                <div className="QR-align">
                                 <QRCode size={200} value={`${window.location.origin.toString()+'/humm-verfication/'+this.state.tokenValue}`} />
                                 {/* <h4 class="application-or-web text-center">OR</h4>
                                 <div class="next-btn  text-center" >
                                <button type="button" onClick={() => this.isEnableDesktopCapture()} class="btn btn-primary">
                                  Continue on web
                                </button>
                              </div> */}
                              <div className="modal fade model-error" id="mitekSucesssModal" tabIndex="-1" role="dialog" aria-labelledby="mitekSucesssModalModel" aria-hidden="true" data-backdrop="false">
                  <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal"><img src="images/close-modal.svg"
                                    alt="x" /></button>
                            </div>
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                                </div>
                                {/* <h5 className="modal-title " id="mitekSucesssModal">{this.state.applicationStatusHeading}</h5> */}
                                <p className="text-center mt-4">{parse(this.state.applicationStatusBody)}</p>
                            </div>
                            <div className="modal-footer mb-4">
                                <button class="btn btn-warning btn-outline" onClick={(e) => { this.returnHome(e) }}>Return Home</button>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                
            );
        }
        
    }
}




export default withRouter(QrCode);