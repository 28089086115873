import React from 'react';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';


class CategoryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            itemList:[],
            categoryClass:'category-img-Qed',
            Redirect:false
        }
        this.initialLoad =this.initialLoad.bind(this);
    }
    componentDidMount(){
        this.setState({itemList:this.props.itemList});
        this.initialLoad();
    }
    
    initialLoad(){
        this.props.clearCheckList();
    }
   
    categoryChange=(classes)=>{
        this.setState({categoryClass:classes});
    }


    categoryFilter=()=>{
        this.setState({itemList:this.props.itemList});
    }

    ClickEvent=(response)=>{
    //    analyticsClickEvent(response+'_partner')
       analyticsClickEvent('shop_partners',response)
        setTimeout(() => {
        this.setState({Redirect:true})
         this.props.redirectPage(this.state.Redirect,response);
        },100);
    
      
    }
    
    render() { 
        
        return (  
            <div className="category-gallery">
                <div className="category-unlist">   
            { this.state.itemList &&
            this.state.itemList.map(response =>
            <div className={this.state.categoryClass} onClick={()=>this.ClickEvent(response)} key={response}>                
                <div className="category-img-sec">
                    <img src="images/ilve.png" alt="partner" className="img-fluid"/>
                    <div className="overlay">
                    </div>
                        <div className="cate-text">
                            <div className="w-100">
                                <span>{response}</span>
                            </div>
                        </div>
                </div> 
            </div>   
             ) 
        } 
            </div>
        </div>
  
        
        );
    }
}
 
export default CategoryList;