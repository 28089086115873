import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import { miteckDocumentCapture } from "../../../action/application/mitekDoucmentCapture";
import { autoCaptureHints, customMessages } from "../../../utils/const";
import { Redirect } from 'react-router-dom';
import { updateMitek } from "../../../action/application/veifyToken";
import parse from 'html-react-parser';
import { analyticsClickEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import { frontEndLogs } from "../../../action/logs/frontEndLogs";


class Instructions extends Component {

    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            idStep: 1,
            passportStep: 1,
            veterianStep: 1,
            isSelectDocument: '',
            miTeck: window.mitekScienceSDK,
            statusCode: '',
            manualCaptureBase64image: '',
            manualErrorMessage: '',
            applicationSuccess: false,
            capturedData: [],
            buttonClose: false,
            redirect: false,
            selectCaptureType: '',
            selfieImageData: '',
            timeout: null,
            unSupportImageType: null,
            fileName: "",
            selectedFile: null,
            uploadFileErrorMessage: '',
            cameradeniedmsg: '',
            userLoginType: localStorage.getItem('userLoginType'),
            width: window.innerWidth,
            checkAutoCapture: false
        };


    }

    componentDidMount() {
        const existingScript = document.getElementById('miTeckScript');
        if (window.mitekScienceSDK === undefined) {
            existingScript.onload = (result) => {
                var miTeckValues = existingScript.ownerDocument.defaultView.mitekScienceSDK;
                this.setState({ miTeck: miTeckValues })
            };
        }
        localStorage.setItem('finalMessage', '');
    }


    idNextStep = async (idStep, captureType) => {
        window.scrollTo(0, 0);
        if (idStep === 'one') {
            analyticsClickEvent('driving_license_instructions_continue','')
            await frontEndLogs('driving_license_instructions_continue '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
            this.setState({ idStep: 2 });
        } else if (idStep === 'two') {
            analyticsClickEvent('driving_license_take_photo','')
            await frontEndLogs('driving_license_take_photo DL_FRONT '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
            this.documentCapture(captureType);
            // this.setState({ idStep: 3 });
        } else if (idStep === 'three') {
            analyticsClickEvent('driving_license_take_photo','')
            await frontEndLogs('driving_license_take_photo DL_BACK '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
            this.documentCapture(captureType);
            // this.setState({ idStep: 4 });
        } else if (idStep === 'four') {
            analyticsClickEvent('driving_license_selfie_continue','')
            await frontEndLogs('driving_license_selfie_continue '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
            this.documentCapture(captureType);
            // this.setState({ idStep: 5 });

        };

    }

    passportNextStep = async (passportstep, captureType) => {
        window.scrollTo(0, 0);
        if (passportstep === 'one') {
            analyticsClickEvent('passport_instructions_continue','')
            await frontEndLogs('passport_instructions_continue '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
            this.setState({ passportStep: 2 });
        } else if (passportstep === 'two') {
            analyticsClickEvent('passport_take_photo','')
            await frontEndLogs('passport_take_photo '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
            this.documentCapture(captureType);
            // this.setState({ passportStep: 3 });
        } else if (passportstep === 'three') {
            analyticsClickEvent('passport_selfie_continue','')
            await frontEndLogs('passport_selfie_continue '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
            this.documentCapture(captureType);
            // this.setState({ passportStep: 4 });
        } else if (passportstep === 'four') {
            this.setState({ passportStep: 5 });

        };

    }

    veterianNextStep = async(veterianstep, captureType) => {
        window.scrollTo(0, 0);
        if (veterianstep === 'one') {
            analyticsClickEvent('instructions_continue','')
            await frontEndLogs('veterian_instructions_continue '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
            this.setState({ veterianStep: 2 });
        } else if (veterianstep === 'two') {
            analyticsClickEvent('take_photo','')
            await frontEndLogs('veterian_take_photo '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
            this.documentCapture(captureType);
            // this.setState({ passportStep: 3 });
        } else if (veterianstep === 'three') {
            analyticsClickEvent('selfie_continue','')
            await frontEndLogs('veterian_selfie_continue '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
            this.documentCapture(captureType);
            // this.setState({ passportStep: 4 });
        } else if (veterianstep === 'four') {
            this.setState({ veterianStep: 5 });

        };

    }
    uploadfile = (passportstep, captureType, e) => {
        this.setState({ selectCaptureType: captureType });

        analyticsClickEvent('upload_file','')
        this.setState({ uploadFileErrorMessage: null, fileName: "" });
        if (e.target.files.length === 0) {
            return false;
        }
        this.setState({ fileName: e.target.files[0].name });

        this.setState({ selectedFile: e.target.files[0] }, () => {

            if (this.state.selectedFile !== null) {
                let filename = this.state.selectedFile.name;

                let fsize = this.state.selectedFile.size;
                const file = Math.round(fsize / 1024);
                if (!/\.(jpe?g|png|gif|bmp)$/i.test(filename)) {
                    // setTimeout(()=>{
                    this.setState({
                        selectedFile: null,
                        uploadFileErrorMessage:
                            "",
                        manualErrorMessage: "Only Support jpg,jpeg,png,gif,bmp file format",

                    });
                    window.$('#mitechManualModel').modal('show');
                    // },2000)
                }

                if (file >= 2048) {
                    // setTimeout(()=>{
                    this.setState({
                        selectedFile: null,
                        uploadFileErrorMessage:
                            "",
                        manualErrorMessage: "Image size too large.",
                    });
                    window.$('#mitechManualModel').modal('show');
                    // },2000)
                }


                setTimeout(() => {

                    if (this.state.uploadFileErrorMessage === null) {
                        this.uploadCapturedFile(captureType);
                    }
                }, 1000);
            }
        });

    }


    uploadCapturedFile(captureType) {
        this.setState({ selectCaptureType: captureType });
        let self = this;

        const miteck = this.state.miTeck;
        miteck.cmd('PROCESS_FRAME', {
            documentType: captureType,
            mitekSDKPath: "./mitekSDK/",
            options: {
                frame: self.state.selectedFile,
                qualityPercent: 80
            }
        });

        miteck.on('FRAME_PROCESSING_FEEDBACK', function (result) {
            if (result.key === "IMAGE_SMALLER_THAN_MIN_SIZE") {

                self.setState({ uploadFileErrorMessage: "", manualErrorMessage: "Image size too small." });
                window.$('#mitechManualModel').modal('show');

                return false;

            } else if (result.key === "CORRUPT_IMAGE") {

                self.setState({ uploadFileErrorMessage: "", manualErrorMessage: "Image is corrupted" });

                window.$('#mitechManualModel').modal('show');

                return false;
            }



        });

        miteck.on('FRAME_CAPTURE_RESULT', async function (result) {
            console.log("Direct Science Result", result);
            let imageResult = result.response.imageData.split(',');
            let extractData = result.response.imageData.replace(imageResult[0] + ',', '');
            const res = {
                data: extractData,
                customerReferenceId: localStorage.getItem('applicationId')
            }
            if (self.state.selectCaptureType === 'SELFIE') {
                self.updateResult(result);
                return false;
                // self.setState({ selfieImageData: extractData });
            } else {
                await frontEndLogs('Received uploadCaptureFile result from Miteck SDK '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
                self.setState({ capturedData: [...self.state.capturedData, res] })
                self.setState({ manualCaptureBase64image: extractData })

            }

            if (self.props.documentType === 'passport') {
                console.log("Direct Science Result", self.props.documentType);
                await frontEndLogs('Document UploadFile type passport '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
                const { passportStep } = self.state;
                self.setState({
                    passportStep: passportStep + 1,
                    uploadFileErrorMessage: null,
                    fileName: ""
                });
            } else if (self.props.documentType === 'ID') {
                console.log("Direct Science Result", self.props.documentType);
                await frontEndLogs('Document UploadFile type DL '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
                const { idStep } = self.state;
                self.setState({
                    idStep: idStep + 1,
                    uploadFileErrorMessage: null,
                    fileName: ""
                });
            } else if (self.props.documentType === 'VETERAN_CARD') {
                console.log("Direct Science Result", self.props.documentType);
                await frontEndLogs('Document UploadFile type HMC Card '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
                const { veterianStep } = self.state;
                self.setState({
                    veterianStep: veterianStep + 1,
                    uploadFileErrorMessage: null,
                    fileName: ""
                });
            }

            if (self.state.drivinglicense === true) {
                if (self.state.idCheckFront === false && self.state.idCheckBack === true) {
                    self.setState({ idCheckBack: false });
                    self.setState({ drivinglicense: false });
                }
                if (self.state.idCheckBack === false && self.state.idCheckFront === false) {
                    self.setState({ checkSelfie: true });
                }
                if (self.state.idCheckBack === false && self.state.idCheckFront === true) {
                    self.setState({ idCheckFront: false });
                    self.setState({ idCheckBack: true });
                }
            }

            if (self.state.checkPassport === true) {
                self.setState({ checkPassport: false, checkSelfie: true });
            }

        });
        miteck.on('SDK_ERROR', function (err) {
            console.log("error")
            err.map((src) => {
                if (src.code === 113 && src.type === 'CAMERA_PERMISSION_DENIED') {
                    self.setState({ cameradeniedmsg: "Your access permission for camera is 'Denied' for browsers, can you please change it to 'Allow' for auto-capture or you can use the manual capture option." });
                    if (self.state.selectCaptureType !== 'SELFIE') {
                        window.$('#mitechcameraDeniedModel').modal('show');
                    }

                }
            })
        });
    }

    async documentCapture(captureType) {
        let self = this;

        self.setState({ buttonClose: false, selectCaptureType: captureType });
        self.state.timeout = null;
        clearTimeout(self.state.timeout);
        if (window.$('#unable-to-capture').hasClass('show') === true) {
            window.$('#unable-to-capture').modal('hide');
            window.$('.modal-backdrop').removeClass('show');
            window.$('body').removeClass('modal-open');
            window.$('.modal-backdrop').remove();
            window.$('.navbar').css('z-index', 3333);
        }
        let cap = self.state.capturedData;
        var timer;
        var recentHint = null;
        // may be needed to address new browser standards (explicit video play)
        //  document.querySelector('div video[autoplay="true"]').play();

        const miteck = this.state.miTeck;
        // camera started
        miteck.on('CAMERA_DISPLAY_STARTED', function (result) {
            window.$('.navbar').css('z-index', 3);
            // show the first initial hint message
            var firstMessage = customMessages[captureType].firstMessage;
            miteck.cmd('SHOW_HINT', firstMessage);
        });


        miteck.on('FRAME_PROCESSING_FEEDBACK', function (result) {
            recentHint = result.key;
            if (captureType === 'SELFIE') {
                self.setState({ checkAutoCapture:true});
                // turn oval green if head is in guide
                if (result.key === 'MISNAP_SMILE'
                    || result.key === 'MISNAP_STOP_SMILING'
                    || result.key === 'MISNAP_READY_POSE') {
                    document.getElementsByClassName('integrator SELFIE')[0].classList.add('FACE_IN_GUIDE');
                } else {
                    document.getElementsByClassName('integrator SELFIE')[0].classList.remove('FACE_IN_GUIDE');
                }
                if (recentHint !== null) {
                    miteck.cmd('SHOW_HINT', autoCaptureHints[recentHint]);
                }

            } else {
                if (recentHint !== null) {
                    var hintMsg = autoCaptureHints[recentHint];

                    // use a custom message for four corners not found
                    if (recentHint === 'MITEK_ERROR_FOUR_CORNER') {
                        hintMsg = customMessages[captureType].fourCornerMessage;
                    }

                    miteck.cmd('SHOW_HINT', hintMsg);
                }
            }
        });


        miteck.on('FRAME_PROCESSING_STARTED', function (result) {
            console.log('started capture>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
            if (self.props.documentType === 'passport' || self.props.documentType === 'ID' || self.props.documentType === 'VETERAN_CARD') {
                self.setState({
                    timeout: setTimeout(function () {
                        let res = miteck.cmd('SDK_STOP');
                        if (res === undefined && self.state.buttonClose === false) {
                            window.$('#unable-to-capture').modal('show');
                            window.$('.navbar').css('z-index', 3);
                            self.state.timeout = null
                        }
                    }, 25000)
                })
            }
            var mitekDisplayContainer = document.querySelector('#mitekDisplayContainer');

            // add a button to allow the user to capture a frame
            var buttonEl = document.createElement('button');
            buttonEl.setAttribute('id', 'mitekCancelButtton');
            buttonEl.setAttribute('style', 'position: absolute; right: 15px; top: 15px; z-index: 100; background:transparent;border:0');
            buttonEl.innerHTML = '<img src="images/close.png" alt="front" />';
            buttonEl.onclick = function (e) {

                analyticsClickEvent('camera_close','')
                window.$('#unable-to-capture').modal('hide');
                window.$('.navbar').css('z-index', 3333);
                self.setState({ timeout: clearTimeout(self.state.timeout), buttonClose: true });
                self.state.timeout = null
                miteck.cmd('SDK_STOP');
                self.setState({})
                clearTimeout(self.state.timeout);


            };
            mitekDisplayContainer.appendChild(buttonEl);

        });

        miteck.on('FRAME_CAPTURE_RESULT', async function (result)  {
            window.$('.navbar').css('z-index', 3333);
            // document.querySelector('div video[autoplay="true"]').pause();
            if (self.state.timeout)
                clearTimeout(self.state.timeout);
            self.state.timeout = null
            console.log('updateResult inside auto capture');
            await frontEndLogs('Received Auto capture result from Miteck SDK '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
            self.updateResult(result);
        });




        miteck.on('SDK_ERROR', function (err) {
            window.$('.navbar').css('z-index', 3333);
            clearTimeout(timer);
            console.log(err);
            err.map((src) => {
                if (src.code === 113 && src.type === 'CAMERA_PERMISSION_DENIED') {
                    self.setState({ cameradeniedmsg: "Your access permission for camera is 'Denied' for browsers, can you please change it to 'Allow' for auto-capture or you can use the manual capture option." });
                    if (self.state.selectCaptureType !== 'SELFIE') {
                        window.$('#mitechcameraDeniedModel').modal('show');
                    }

                }
            })
            //   showSDKError(err);
        });

        miteck.cmd('CAPTURE_AND_PROCESS_FRAME', {
            mode: 'AUTO_CAPTURE',
            documentType: captureType,
            mitekSDKPath: "./mitekSDK/",
            options: {
                qualityPercent: 80,   // value between 50 to 100 recommended
                hintFrequencyMS: 2400, // how long the hint message displays (in MS) / note: 2400 recommended for screen readers
                hintAriaLive: 2, //screen reader setting for hint updates value (0=off / 1=polite / 2=assertive)
                guidePaddingLevel: 1,  // size of the overlay guide  (1=large, 2=medium, 3=small) / note: 1 is recommended
                hintMessageSize: 2,  // set the size of the hint message (1=small / 2=medium / 3=large)
                disableSmileDetection: false,  // disable smile detection
                faceDetectionLevel: 1, // determines how strict face detection is (1=lenient, 2=moderate, 3=strict)
                videoContainerId: null, // (optionally) embed the video in a custom HTML container
                videoRecordingEnabled: false, // (optionally) record the video session / returned as a dataURL
                audioRecordingEnabled: false, // (optionally) also record audio / only applies if videoRecordingEnabled = true)
                videoQuality: 70, // (optionally) value between 30 to 100 recommended (normalized bitrate of video / only applies if videoRecordingEnabled = true)
                videoRecordingMessage: 'Recording' // (optionally) override the default "Recording" message / Note: set to "" to disable icon and message
            }

        });
    };

    analyticsModalCloseEvent = () => {
        analyticsClickEvent('unable_to_capture_popup_close','')
    }

    async redirecttoapplication(value) {
        let text = value.replace(' ', '_');
        analyticsClickEvent(text,'')
        window.$('.modal-backdrop').removeClass('show');
        window.$('body').removeClass('modal-open');
        window.$('.modal-backdrop').remove();
        window.$('#mitechErrorModel').modal('hide');
        window.$('#mitechCompleteModel').modal('hide');
        window.$('#mitechReferModel').modal('hide');

        if (this.props.fromWhere === 'application') {
            if (this.state.statusCode === 417 || this.state.statusCode === 202) {
                this.setState({ redirect: true });
            } else {
                this.props.handleChnage();
            }
        } else {
            if (this.state.statusCode === 417) {
                const objs = {
                    email: localStorage.getItem('email'),
                    application_id: localStorage.getItem('applicationId')
                }

                const declineResult = await updateMitek(objs);
                console.log(declineResult);

                if (declineResult.data.statusCode == 200) {
                    this.props.handleChnage('decliened');
                    localStorage.setItem('applicationStatus', 'decliened')
                }
            } else if (this.state.statusCode === 202) {
                const obj = {
                    email: localStorage.getItem('email'),
                    application_id: localStorage.getItem('applicationId')
                }

                const successResult = await updateMitek(obj);
                if (successResult.data.statusCode == 200) {
                    localStorage.setItem('applicationStatus', 'success')
                    this.props.handleChnage('success');
                }
            } else if (this.state.statusCode == 200) {
                localStorage.setItem('applicationStatus', 'refer')
                this.props.handleChnage('refer');
            } else {
                this.props.handleChnage('retry');
            }

        }

    }

    updateResult = async (result) => {
        window.$('.navbar').css('z-index', 3333);
        let imageResult = result.response.imageData.split(',');
        let extractData = result.response.imageData.replace(imageResult[0] + ',', '');
        const captureType = this.state.selectCaptureType;
        if (captureType === 'SELFIE') {
            this.setState({ selfieImageData: extractData });
            this.setState({ checkSelfie: false, completeStatus: true });
            if (this.props.documentType === 'passport') {
                const { passportStep } = this.state;
                this.setState({
                    passportStep: passportStep + 1
                });
            } else if (this.props.documentType === 'ID') {
                const { idStep } = this.state;
                this.setState({
                    idStep: idStep + 1,
                });
            } else if (this.props.documentType === 'VETERAN_CARD') {
                const { veterianStep } = this.state;
                this.setState({
                    veterianStep: veterianStep + 1,
                });
            }
        }
        if (this.props.documentType === 'passport') {
            const { passportStep } = this.state;
            if (captureType === 'PASSPORT') {
                const res = {
                    data: '',
                    customerReferenceId: ''
                }
                res.data = extractData;
                res.customerReferenceId = localStorage.getItem('applicationId');
                this.setState({
                    capturedData: [...this.state.capturedData, res]
                });
                this.setState({
                    passportStep: passportStep + 1
                });
                if (extractData) {
                    setTimeout(() => {
                        this.setState({ checkPassport: false, checkSelfie: true, idCheckFront: false, idCheckBack: false });
                    }, 1000)
                }

            } else {
                this.setState({ selfieImageData: extractData });
                this.setState({ checkSelfie: false, completeStatus: true });

            }
        } else if (this.props.documentType === 'ID') {
            const { idStep } = this.state;
            if (captureType === 'DL_FRONT') {
                const res = {
                    data: '',
                    customerReferenceId: ''
                }
                res.data = extractData;
                res.customerReferenceId = localStorage.getItem('applicationId');
                this.setState({
                    capturedData: [...this.state.capturedData, res]
                });
                this.setState({
                    idStep: idStep + 1
                });
                if (extractData) {
                    if (this.state.idCheckFront === false && this.state.idCheckBack === true) {
                        await this.setState({ idCheckBack: false, drivinglicense: false });
                    }
                    if (this.state.idCheckBack === false && this.state.idCheckFront === false) {
                        await this.setState({ checkSelfie: true });
                    }
                    if (this.state.idCheckBack === false && this.state.idCheckFront === true) {
                        await this.setState({ idCheckFront: false, idCheckBack: true });
                    }
                }

            } else {
                await this.setState({ selfieImageData: extractData, checkSelfie: false, completeStatus: true });
            }
        } else if (this.props.documentType === 'VETERAN_CARD') {
            const { veterianStep } = this.state;
            if (captureType === 'DL_FRONT') {
                const res = {
                    data: '',
                    customerReferenceId: ''
                }
                res.data = extractData;
                res.customerReferenceId = localStorage.getItem('applicationId');
                this.setState({
                    capturedData: [...this.state.capturedData, res]
                });
                this.setState({
                    veterianStep: veterianStep + 1
                });


            } else {
                await this.setState({ selfieImageData: extractData, checkSelfie: false, completeStatus: true });
            }
        } else if (this.state.enableManualCapture === true) {
            if (captureType === 'SELFIE') {
                this.setState({ selfieImageData: extractData });
            }
            const res = {
                data: this.state.manualCaptureBase64image,
                customerReferenceId: localStorage.getItem('applicationId')
            }
            this.setState({ capturedData: [...this.state.capturedData, res] })
        }
        const obj = {
            "dossierMetadata": {
                "customerReferenceId": localStorage.getItem('applicationId')
            },
            "evidence": [
                {
                    "type": "IdDocument",
                    "images": this.state.capturedData
                },
                {
                    "type": "Biometric",
                    "biometricType": "Selfie",
                    "data": this.state.selfieImageData
                }
            ],
            "configuration": {
                "verifications": {
                    "faceComparison": true,
                    "faceLiveness": true,
                    "dataSignalAAMVA": false
                }
            }
        }
        this.setState({ statusCode: '' });
        if (this.state.selfieImageData !== '') {
            // Check for Images Missing
            if(this.props.documentType =='ID'){
                if(obj.evidence[0].images[1].data==null ||  obj.evidence[0].images[1].data==undefined || obj.evidence[0].images[1].data=='' || 
               obj.evidence[0].images[0].data==null ||  obj.evidence[0].images[0].data==undefined || obj.evidence[0].images[0].data=="" ||
               obj.evidence[1].data==null ||  obj.evidence[1].data==undefined || obj.evidence[1].data==''){
                let documentMissingType="DL_Front";
                if(obj.evidence[0].images[1].data==null ||  obj.evidence[0].images[1].data==undefined || obj.evidence[0].images[1].data==''){
                    documentMissingType="DL_Back";
                }
                if(obj.evidence[1].data==null ||  obj.evidence[1].data==undefined || obj.evidence[1].data==''){
                    documentMissingType="Selfie";
                }

                let errorDescription=documentMissingType +" is missing"
                this.setState({ serverMessage: "We were not able to verify your documents"});
                await this.convertMessage("We were not able to verify your documents");
                window.$('#mitechErrorModel').modal('show');
                await frontEndLogs(errorDescription,true,400,'get_mitek_document_response');
                return false;  
               }
            }else{
                if(obj.evidence[0].images[0].data==null ||  obj.evidence[0].images[0].data==undefined || obj.evidence[0].images[0].data=='' ||
               obj.evidence[1].data==null ||  obj.evidence[1].data==undefined || obj.evidence[1].data==''){

                let documentMissingType="PASSPORT";
               if(this.props.documentType === 'VETERAN_CARD'){
                     documentMissingType="VETERAN_CARD";
               }
                if(obj.evidence[1].data==null ||  obj.evidence[1].data==undefined || obj.evidence[1].data==''){
                    documentMissingType="Selfie";
                }
                let errorDescription=documentMissingType +" is missing"
                this.setState({ serverMessage: "We were not able to verify your documents"});
                await this.convertMessage("We were not able to verify your documents");
                window.$('#mitechErrorModel').modal('show');
                await frontEndLogs(errorDescription,true,400,'get_mitek_document_response');
                return false;  
               }
            }

            const checkResult = await miteckDocumentCapture(obj);
            console.log(checkResult);
            if (checkResult.status === 200 && checkResult.data.data.status_type === "Retry Application" && checkResult.data.data.mitek_retry_attempt <= 2) {
                this.setState({ serverMessage: checkResult.data.message, statusCode: 400 });
                await this.convertMessage(checkResult.data.message);
                window.$('#mitechErrorModel').modal('show');
                return false;
            } else if (checkResult.status === 200 && checkResult.data.data.status_type === "Application is Rejected") {
                this.setState({ serverMessage: '', statusCode: 417 });
                console.log(this.props.fromWhere);
                await this.convertMessage(checkResult.data.message);
                if (this.props.fromWhere === 'application') {
                    window.$('#mitechErrorModel').modal('show');

                }

            } else if (checkResult.status === 200 && checkResult.data.data.status_type === "Application is Approved") {
                this.setState({ serverMessage: checkResult.data.message, statusCode: 202 });
                await this.convertMessage(checkResult.data.message);
                if (this.props.fromWhere === 'application') {
                    window.$('#mitechCompleteModel').modal('show');
                }
                this.setState({ checkPassport: false, drivinglicense: false, applicationComplete: true })
            } else if (checkResult.status === 200 && checkResult.data.data.status_type === "Application is referred"){
                this.setState({ serverMessage: checkResult.data.message, statusCode: 200 });
                await this.convertMessage(checkResult.data.message);
                if (this.props.fromWhere === 'handoff') {
                    window.$('#mitechReferModel').modal('show');
                }
            } else if (checkResult.status === 500 || checkResult.status === 400) {
                this.setState({ serverMessage: checkResult.data.message, statusCode: checkResult.status });
                await this.convertMessage(checkResult.data.message);
                window.$('#mitechErrorModel').modal('show');
            } else {
                console.log('error');
            }

            if (this.state.statusCode === 202 || this.state.statusCode === 417) {
                if (this.props.fromWhere != 'application') {
                    this.redirecttoapplication('RETURN HOME');
                }
            }

        }

    }

    convertMessage = (msg) => {
        if (msg != '' && msg != null && msg != undefined) {
            let newtext = '';
            let val = this.extractEmails(msg);
            if (val != null) {
                val.map((src) => {
                    let anchorTag = "<a target='_blank' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
                    newtext = msg.replace(src, anchorTag);
                    msg = newtext;

                })
            }

            this.setState({ serverMessage: msg });
            localStorage.setItem('finalMessage', msg);

            return true;
        }
    }



    extractEmails = (text) => {
        return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    }

    manualCapture(e) {
        analyticsClickEvent('manual_capture_try_again','')
        window.$('.navbar').css('z-index', 3333);
        window.$('#unable-to-capture').modal('hide');
        window.$('#mitechManualModel').modal('hide');
        window.$('#mitechcameraDeniedModel').modal('hide');

        const miteck = this.state.miTeck;
        miteck.cmd('CAPTURE_AND_PROCESS_FRAME', {
            mode: 'MANUAL_CAPTURE',
            documentType: 'DL_FRONT',
            mitekSDKPath: "./mitekSDK/",
            options: {
                qualityPercent: 80
            }
        });

        let self = this;
        self.setState({checkAutoCapture: false});
        miteck.on('FRAME_PROCESSING_FEEDBACK', function (result) {
            if (result.key === "IMAGE_SMALLER_THAN_MIN_SIZE") {

                self.setState({ manualErrorMessage: "Image size too small." });
                window.$('#mitechManualModel').modal('show');

                return false;

            } else if (result.key === "CORRUPT_IMAGE") {

                self.setState({ manualErrorMessage: "Image is corrupted" });

                window.$('#mitechManualModel').modal('show');

                return false;
            }



        });





        miteck.on('FRAME_CAPTURE_RESULT', async function (result) {
            let imageResult = result.response.imageData.split(',');
            let extractData = result.response.imageData.replace(imageResult[0] + ',', '');
            const res = {
                data: extractData,
                customerReferenceId: localStorage.getItem('applicationId')
            }
            if (self.state.selectCaptureType === 'SELFIE'  && (!self.state.checkAutoCapture)) {
                console.log("manual capture selfie inside");
                await frontEndLogs('manual_capture_selfie_continue '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
                self.updateResult(result);
                return false;
                // self.setState({ selfieImageData: extractData });
            } else {
                console.log("manual capture selfie outside");
                await frontEndLogs('manual_capture_selfie_cancelled '+localStorage.getItem("applicationId"),true,200,'get_mitek_document_response');
                self.setState({ capturedData: [...self.state.capturedData, res] })
                self.setState({ manualCaptureBase64image: extractData })

            }

            if (self.props.documentType === 'passport') {
                const { passportStep } = self.state;
                self.setState({
                    passportStep: passportStep + 1
                });
            } else if (self.props.documentType === 'ID') {
                const { idStep } = self.state;
                self.setState({
                    idStep: idStep + 1
                });
            } else if (self.props.documentType === 'VETERAN_CARD') {
                const { veterianStep } = self.state;
                self.setState({
                    veterianStep: veterianStep + 1
                });
            }

            if (self.state.drivinglicense === true) {
                if (self.state.idCheckFront === false && self.state.idCheckBack === true) {
                    self.setState({ idCheckBack: false });
                    self.setState({ drivinglicense: false });
                }
                if (self.state.idCheckBack === false && self.state.idCheckFront === false) {
                    self.setState({ checkSelfie: true });
                }
                if (self.state.idCheckBack === false && self.state.idCheckFront === true) {
                    self.setState({ idCheckFront: false });
                    self.setState({ idCheckBack: true });
                }
            }

            if (self.state.checkPassport === true) {
                self.setState({ checkPassport: false, checkSelfie: true });
            }

        });
        miteck.on('SDK_ERROR', function (err) {
            console.log("error")
            err.map((src) => {
                if (src.code === 113 && src.type === 'CAMERA_PERMISSION_DENIED') {
                    self.setState({ cameradeniedmsg: "Your access permission for camera is 'Denied' for browsers, can you please change it to 'Allow' for auto-capture or you can use the manual capture option." });
                    if (self.state.selectCaptureType !== 'SELFIE') {
                        window.$('#mitechcameraDeniedModel').modal('show');
                    }

                }
            })
        });
    }

    manualhide() {
        window.$('#mitechManualModel').modal('hide');
    }
    retrycapture() {
        analyticsClickEvent('auto_capture_try_again','')
        window.$('#mitechErrorModel').modal('hide');
        window.$('#unable-to-capture').modal('hide');
        window.$('.navbar').css('z-index', 3333);
        if (this.props.documentType === 'passport') {
            if (this.state.passportStep === 3) {
                this.documentCapture('SELFIE');
            } else {
                this.documentCapture('PASSPORT');
            }

        } else if (this.props.documentType === 'ID') {
            if (this.state.idStep === 4) {
                this.documentCapture('SELFIE');
            } else {
                this.documentCapture('DL_FRONT');
            }

        } else if (this.props.documentType === 'VETERAN_CARD') {
            if (this.state.veterianStep === 3) {
                this.documentCapture('SELFIE');
            } else {
                this.documentCapture('DL_FRONT');
            }
        }
    }



    render() {
        const { width } = this.state;
        const isMobile = width <= 768;

        if (this.state.redirect) {
            return <Redirect push to={'/home'} />;
        }
        return (<div>




            {this.props.documentType === 'ID' &&
                <div className="">
                    <div className={`tab-pane fade ${this.state.idStep === 1 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepOne" role="tabpanel" aria-labelledby="stepOne-tab">
                        <div className="step-two-section">
                            <div className="form-row align-items-end m-0">
                                <div className="application-phone-screen">
                                    <div className="phone-section">
                                        <div class="mit-container">
                                            <div class="instructions-section">
                                                <h4 class="application-qr-sub-title">INSTRUCTIONS</h4>
                                                <div class="instruction-message">
                                                    <h6>Steps to capture the document correctly</h6>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Please place the document on a dark, plain background to ensure the best result
                                                        </p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>All four corners of the document must be visible in the frame</p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Avoid any glare, shadows or dark spots on the ID document</p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Hold the device steady while image is being captured</p>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="next-btn  text-center">
                                <button type="button" className="btn btn-primary" id="btn-one" onClick={() => this.idNextStep('one')}>Continue</button>
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${this.state.idStep === 2 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepTwo" role="tabpanel" aria-labelledby="stepTwo-tab">
                        <div className="step-two-section">
                            <div class="license-section">
                                <h4 class="application-qr-sub-title">{this.props.documentType === 'ID' ? "DRIVING LICENCE" : "VETERAN CARD"}</h4>

                                <div class="instruction-message">
                                    <h6>First, capture the front of your licence</h6>
                                </div>


                                <div class="license-image">
                                    <img src="images/license-front.svg" alt="front" />
                                </div>


                                <div class="instruction-message">
                                    <h6>Then, follow that by turning your licence over to capture the back</h6>
                                </div>


                                <div class="license-image">
                                    <img src="images/license-back.svg" alt="front" />
                                </div>






                            </div>
                            <div className="profile-error-show">
                                <p className="error-message code-error mitek-upload-file">
                                    {this.state.uploadFileErrorMessage ? (
                                        <span style={{ color: "Red" }}>
                                            {this.state.uploadFileErrorMessage}
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </p>
                            </div>
                            <div className="next-btn  text-center">

                                <div className="upload-btn-wrapper">
                                    <button className="btn profile-img-btn">
                                        Upload a File
                                    </button>
                                    <input
                                        type="file"
                                        required
                                        accept="image/*"
                                        onChange={(e) => { this.uploadfile('two', 'DL_FRONT', e) }}
                                    />
                                </div>
                                <br />
                                <div>
                                    <button type="button" className="btn btn-primary" onClick={() => this.idNextStep('two', 'DL_FRONT')}>Take Photo</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={`tab-pane fade ${this.state.idStep === 3 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepThree" role="tabpanel" aria-labelledby="stepThree-tab">
                        <div className="step-two-section step-three">

                            <div class="license-section">
                                <h4 class="application-qr-sub-title">{this.props.documentType === 'ID' ? "DRIVING LICENCE" : "VETERAN CARD"}</h4>




                                <div class="instruction-message">
                                    {/* <h6>Then, follow that by turning your licence over to capture the back</h6> */}
                                    <h6>Now let's take a photo of the back of the document</h6>
                                </div>




                                <div class="document-image">
                                    <img src="images/document-back.png" alt="front" />
                                </div>



                            </div>
                            <div className="profile-error-show">
                                <p className="error-message code-error mitek-upload-file">
                                    {this.state.uploadFileErrorMessage ? (
                                        <span style={{ color: "Red" }}>
                                            {this.state.uploadFileErrorMessage}
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </p>
                            </div>
                            <div className="next-btn  text-center mt-5">

                                <div className="upload-btn-wrapper">
                                    <button className="btn profile-img-btn">
                                        Upload a File
                                    </button>
                                    <input
                                        type="file"
                                        required
                                        accept="image/*"
                                        onChange={(e) => { this.uploadfile('three', 'DL_FRONT', e) }}
                                    />
                                </div>
                                <br />
                                <div>
                                    <button type="button" className="btn btn-primary" onClick={() => this.idNextStep('three', 'DL_FRONT')}>Take Photo</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={`tab-pane fade ${this.state.idStep === 4 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepFour" role="tabpanel" aria-labelledby="stepFour-tab">

                        <div className="step-two-section">

                            <div class="selfie-section">

                                <h4 class="application-qr-sub-title"></h4>
                                <span class="take-selfie-message">
                                    <h6>Ok let’s take a selfie</h6>
                                </span>
                                <span class="take-selfie-image">
                                    <img src="images/selfie.svg" alt="selfie" />
                                </span>
                                <span class="take-selfie-instruction">
                                    <p>Ok so nice and easy. Just centre your face within the oval and smile. Leave the rest
                                        up
                                        to us.</p>
                                </span>

                            </div>
                            <div className="next-btn  text-center mt-5">
                                <button type="button" className="btn btn-primary" onClick={() => this.idNextStep('four', 'SELFIE')}>Continue</button>
                            </div>

                        </div>
                    </div>
                    <div className={`tab-pane fade ${this.state.idStep === 5 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepFive" role="tabpanel" aria-labelledby="stepFive-tab">
                        <div className="step-five-item">
                            <div class="appfrom-section">
                                <div class="mit-container">
                                    <div class="complete-section">
                                        <div class="logo-area">
                                            <img src="images/humm-logo.svg" alt="Humm" />
                                        </div>
                                        <div class="complete-content">
                                            {/* <h4>Message</h4> */}
                                            <p>Thank you, your document verification is in process. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            }
            {this.props.documentType === 'passport' &&
                <div className="">

                    <div className={`tab-pane fade ${this.state.passportStep === 1 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepOne" role="tabpanel" aria-labelledby="stepOne-tab">
                        <div className="step-two-section">
                            <div className="form-row align-items-end m-0">
                                <div className="application-phone-screen">
                                    <div className="phone-section">
                                        <div class="mit-container">
                                            <div class="instructions-section">
                                                <h4 class="application-qr-sub-title">INSTRUCTIONS</h4>
                                                <div class="instruction-message">
                                                    <h6>Steps to capture the document correctly</h6>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Please place the document on a dark, plain background to ensure the best result
                                                        </p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>All four corners of the document must be visible in the frame</p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Avoid any glare, shadows or dark spots on the ID document</p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Hold the device steady while image is being captured</p>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="next-btn  text-center">
                                <button type="button" className="btn btn-primary" id="btn-one" onClick={() => this.passportNextStep('one')}>Continue</button>
                            </div>
                        </div>
                    </div>

                    <div className={`tab-pane fade ${this.state.passportStep === 2 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepTwo" role="tabpanel" aria-labelledby="stepTwo-tab">
                        <div className="step-two-section">

                            <div class="passport-section">
                                <h4 class="application-qr-sub-title">PASSPORT</h4>
                                <div class="instruction-message">
                                    <h6>Place the main page of your passport within the four corners of the frame</h6>
                                </div>
                                <div class="passport-image">
                                    <img src="images/passport-frame.svg" alt="Passport" />
                                </div>


                            </div>
                            <div className="profile-error-show">
                                <p className="error-message code-error mitek-upload-file">
                                    {this.state.uploadFileErrorMessage ? (
                                        <span style={{ color: "Red" }}>
                                            {this.state.uploadFileErrorMessage}
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </p>
                            </div>
                            <div className="next-btn  text-center">

                                {/* <button type="button" className="btn btn-primary" onClick={() => this.uploadfile('two', 'PASSPORT')}>Upload File</button> */}
                                <div className="upload-btn-wrapper">
                                    <button className="btn profile-img-btn">
                                        Upload a File
                                    </button>
                                    <input
                                        type="file"
                                        required
                                        accept="image/*"
                                        onChange={(e) => { this.uploadfile('two', 'PASSPORT', e) }}
                                    />
                                </div>
                                <br />
                                <div>
                                    <button type="button" className="btn btn-primary" onClick={() => this.passportNextStep('two', 'PASSPORT')}>Take Photo</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={`tab-pane fade ${this.state.passportStep === 3 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepFour" role="tabpanel" aria-labelledby="stepFour-tab">

                        <div className="step-two-section">
                            <div class="selfie-section">

                                <h4 class="application-qr-sub-title"></h4>
                                <span class="take-selfie-message">
                                    <h6>Ok let’s take a selfie</h6>
                                </span>
                                <span class="take-selfie-image">
                                    <img src="images/selfie.svg" alt="selfie" />
                                </span>
                                <span class="take-selfie-instruction">
                                    <p>Ok so nice and easy. Just centre your face within the oval and smile. Leave the rest
                                        up
                                        to us.</p>
                                </span>

                            </div>

                            <div className="next-btn  text-center mt-5">
                                <button type="button" className="btn btn-primary" onClick={() => this.passportNextStep('three', 'SELFIE')}>Continue</button>
                            </div>

                        </div>
                    </div>
                    <div className={`tab-pane fade ${this.state.passportStep === 4 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepFive" role="tabpanel" aria-labelledby="stepFive-tab">
                        <div className="step-five-item">
                            <div class="appfrom-section">
                                <div class="mit-container">
                                    <div class="complete-section">
                                        <div class="logo-area">
                                            <img src="images/humm-logo.svg" alt="Humm" />
                                        </div>
                                        <div class="complete-content">
                                            {/* <h4>Message</h4> */}
                                            <p>Thank you, your document verification is in process. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            }
            {this.props.documentType === 'VETERAN_CARD' &&
                <div className="">
                    <div className={`tab-pane fade ${this.state.veterianStep === 1 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepOne" role="tabpanel" aria-labelledby="stepOne-tab">
                        <div className="step-two-section">
                            <div className="form-row align-items-end m-0">
                                <div className="application-phone-screen">
                                    <div className="phone-section">
                                        <div class="mit-container">
                                            <div class="instructions-section">
                                                <h4 class="application-qr-sub-title">INSTRUCTIONS</h4>
                                                <div class="instruction-message">
                                                    <h6>Steps to capture the document correctly</h6>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Please place the document on a dark, plain background to ensure the best result
                                                        </p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>All four corners of the document must be visible in the frame</p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Avoid any glare, shadows or dark spots on the ID document</p>
                                                    </span>
                                                </div>
                                                <div class="suggetion-message">
                                                    <span class="icon-area">
                                                        <img src="images/progress-success.svg" alt="Success" />
                                                    </span>
                                                    <span class="message-area">
                                                        <p>Hold the device steady while image is being captured</p>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="next-btn  text-center">
                                <button type="button" className="btn btn-primary" id="btn-one" onClick={() => this.veterianNextStep('one')}>Continue</button>
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${this.state.veterianStep === 2 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepTwo" role="tabpanel" aria-labelledby="stepTwo-tab">
                        <div className="step-two-section">
                            <div class="license-section">
                                <h4 class="application-qr-sub-title">HM FORCES CARD</h4>




                                <div class="instruction-message">
                                    <h6>Please ensure the ID Card is  within the four corners of the frame</h6>
                                </div>


                                <div class="license-image">
                                    <img src="images/hm-force-card2.svg" alt="front" />
                                </div>






                            </div>
                            <div className="profile-error-show">
                                <p className="error-message code-error mitek-upload-file">
                                    {this.state.uploadFileErrorMessage ? (
                                        <span style={{ color: "Red" }}>
                                            {this.state.uploadFileErrorMessage}
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </p>
                            </div>
                            <div className="next-btn  text-center">
                                <div className="upload-btn-wrapper">
                                    <button className="btn profile-img-btn">
                                        Upload a File
                                    </button>
                                    <input
                                        type="file"
                                        required
                                        accept="image/*"
                                        onChange={(e) => { this.uploadfile('two', 'DL_FRONT', e) }}
                                    />
                                </div>
                                <br />
                                <div><button type="button" className="btn btn-primary" onClick={() => this.veterianNextStep('two', 'DL_FRONT')}>Take Photo</button></div>

                            </div>

                        </div>
                    </div>

                    <div className={`tab-pane fade ${this.state.veterianStep === 3 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepFour" role="tabpanel" aria-labelledby="stepFour-tab">

                        <div className="step-two-section">

                            <div class="selfie-section">

                                <h4 class="application-qr-sub-title"></h4>
                                <span class="take-selfie-message">
                                    <h6>Ok let’s take a selfie</h6>
                                </span>
                                <span class="take-selfie-image">
                                    <img src="images/selfie.svg" alt="selfie" />
                                </span>
                                <span class="take-selfie-instruction">
                                    <p>Ok so nice and easy. Just centre your face within the oval and smile. Leave the rest
                                        up
                                        to us.</p>
                                </span>

                            </div>
                            <div className="next-btn  text-center mt-5">
                                <button type="button" className="btn btn-primary" onClick={() => this.veterianNextStep('three', 'SELFIE')}>Continue</button>
                            </div>

                        </div>
                    </div>
                    <div className={`tab-pane fade ${this.state.veterianStep === 4 ? 'active show' : 'hide-tab-nav-bar'}`} id="stepFive" role="tabpanel" aria-labelledby="stepFive-tab">
                        <div className="step-five-item">
                            <div class="appfrom-section">
                                <div class="mit-container">
                                    <div class="complete-section">
                                        <div class="logo-area">
                                            <img src="images/humm-logo.svg" alt="Humm" />
                                        </div>
                                        <div class="complete-content">
                                            {/* <h4>Message</h4> */}
                                            <p>Thank you, your document verification is in process. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            }


            {/* model */}
            <div className="modal fade model-error" id="mitechErrorModel" tabIndex="-1" role="dialog" aria-labelledby="mitechErrorModelLabel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                            </div>
                            {/* <h5 className="modal-title " id="mitechErrorModelLabel">Message</h5> */}
                            {this.state.serverMessage != null && this.state.serverMessage != '' &&
                                <p className="text-center mt-4">{parse(this.state.serverMessage)}</p>
                            }
                        </div>
                        <div className="modal-footer mb-4">
                            <button class="btn btn-warning btn-outline" onClick={(e) => { this.redirecttoapplication(e.target.innerText) }}>
                                {this.state.statusCode === 417 ? 'RETURN HOME' : 'Try Again'}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade model-error" id="mitechCompleteModel" tabIndex="-1" role="dialog" aria-labelledby="mitechCompleteModel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                            </div>
                            {/* <h5 className="modal-title " id="mitechCompleteModelLabel">Message</h5> */}
                            {this.state.serverMessage != null && this.state.serverMessage != '' &&
                                <p className="text-center mt-4">{parse(this.state.serverMessage)}</p>
                            }

                        </div>
                        <div className="modal-footer mb-4">
                            <button class="btn btn-warning btn-outline" onClick={(e) => { this.redirecttoapplication(e.target.innerText) }}>
                                {this.state.statusCode === 202 ? 'RETURN HOME' : 'OK'}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade model-error" id="mitechReferModel" tabIndex="-1" role="dialog" aria-labelledby="mitechReferModel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                            </div>
                            {/* <h5 className="modal-title " id="mitechReferModelLabel">Message</h5> */}
                            {this.state.serverMessage != null && this.state.serverMessage != '' &&
                                <p className="text-center mt-4">{parse(this.state.serverMessage)}</p>
                            }

                        </div>
                        <div className="modal-footer mb-4">
                            <button class="btn btn-warning btn-outline" onClick={(e) => { this.redirecttoapplication(e.target.innerText) }}>
                                {this.state.statusCode == 200 ? 'RETURN HOME' : 'OK'}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade model-error" id="mitechManualModel" tabIndex="-1" role="dialog" aria-labelledby="mitechManualLabel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                            </div>
                            {/* <h5 className="modal-title " id="mitechErrorModelLabel">Message</h5> */}
                            <p className="text-center mt-4">{this.state.manualErrorMessage}</p>
                        </div>
                        <div className="modal-footer mb-4">
                            <button class="btn btn-warning btn-outline" onClick={(e) => { this.manualCapture(e) }}>Try Again</button>
                            {this.state.applicationSuccess === true &&
                                <button type="button" className="btn btn-primary" onClick={() => this.manualhide()}>Ok</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade model-error" id="mitechcameraDeniedModel" tabIndex="-1" role="dialog" aria-labelledby="mitechcameraDeniedModel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal"><img src="images/close-modal.svg"
                                alt="x" /></button>
                        </div>
                        <div className="modal-body">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                            </div>
                            {/* <h5 className="modal-title " id="mitechErrorModelLabel">Message</h5> */}
                            <p className="text-center mt-4">{this.state.cameradeniedmsg}</p>
                        </div>
                        <div className="modal-footer mb-4">
                            <button class="btn btn-warning btn-outline" onClick={(e) => { this.manualCapture(e) }}>Try Manual Capture</button>
                            {/* {this.state.applicationSuccess === true &&
                                <button type="button" className="btn btn-primary" onClick={() => this.manualhide()}>Ok</button>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal" id="unable-to-capture" data-backdrop="false">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" onClick={(e) => this.analyticsModalCloseEvent(e)} ><img src="images/close-modal.svg"
                                alt="x" /></button>
                        </div>
                        <div class="modal-body">
                            <div class="message-head-section">
                                <h6>{this.state.selectCaptureType === 'SELFIE' ? "Time's up, we couldn't take your photo..." : "We were unable to capture the document correctly"}</h6>
                            </div>
                            {this.state.selectCaptureType !== 'SELFIE' ?
                                <div class="suggetion-message">

                                    <span class="icon-area">
                                        <img src="images/progress-success.svg" alt="Success" />
                                    </span>

                                    <span class="message-area">
                                        <p>Please make sure the document is centered</p>
                                    </span>
                                </div> : <div class="suggetion-message">

                                    <span class="icon-area">
                                        {/* <img src="images/progress-success.svg" alt="Success" /> */}
                                    </span>

                                    <span class="message-area">
                                        <p class="selfie-content">How would you like to try again ?</p>
                                    </span>
                                </div>}
                            {this.state.selectCaptureType !== 'SELFIE' ?
                                <div class="suggetion-message">

                                    <span class="icon-area">
                                        <img src="images/progress-success.svg" alt="Success" />
                                    </span>
                                    <span class="message-area">
                                        <p>Placed on a dark background</p>
                                    </span>
                                </div> : ""}
                            <div class="modal-button-area">
                                <button class="btn btn-warning btn-outline" onClick={(e) => { this.retrycapture() }}>Try Again</button>
                                <button class="btn btn-warning btn-fill" onClick={(e) => { this.manualCapture(e) }}>Manual Capture</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>



        )

    }

}

export default Instructions;