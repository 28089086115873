import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'


const headers = {
    'session_id': localStorage.getItem('sessionId'),
  }
export const removeFavoritePartner = async (formData) => {
    let url = config.Humm_Dev_Link+'favorite-partner/remove'
    console.log('remove');
   return axios.delete(url,{headers: sessionID(),data: formData}).then(res => {
    
        return res.data;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        handlingSession(err.response.status);
        return err.response
    })
};