
import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

const headers = {
    'session_id': localStorage.getItem('sessionId'),
  }

export const getCategorieSearch = (formData) => {

    let url = config.Humm_Dev_Link+'industry/Industry-filter'

    return async (dispatch) => {
        let resp = [];
        await axios.post(url,formData,{headers:sessionID()})
            .then(response => {
                dispatch({
                    type: 'CATEGORY_SEARCH_LIST',
                    value: response.data
                });
                resp = response;
                return resp;
            })
            .catch(async error => {
                await userLogs(url,formData,error);
                if(error.response){
        handlingSession(error.response.status);
        }
                resp = error.response;
                return resp;
            })
            return resp;
    }
};