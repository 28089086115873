import React from 'react';
import { verifyToken } from '../../action/application/veifyToken'
import DocumentVerifyMobile from '../application/documentVerifyMobile'

class HummVerificationPage extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            redirect:'',
            checkPageLoad:''
        }
    }


    componentDidMount(){
        this.verificationData();
    }

    verificationData=async()=>{
        var jwt=this.props.match.params.doc;
        if(jwt!==localStorage.getItem('handOFF')){
            localStorage.setItem('applicationStatus','')
        }
        localStorage.setItem('handOFF',jwt);
        var responseData=JSON.parse(atob(jwt.split(".")[1]));
        const obj={
            "email":responseData.data.email,
            "application_id":responseData.data.application_id,
            "token":jwt
        }
        localStorage.setItem('email',responseData.data.email);
        localStorage.setItem('applicationId',responseData.data.application_id);
        localStorage.setItem('sessionId', responseData.data.session_id)
        if(localStorage.getItem('applicationStatus')===''){
            const result= await verifyToken(obj);
            console.log('application in')
        if(result.status===200){
            this.setState({redirect:'true'})
            localStorage.setItem('pageLanded','yes');
            localStorage.setItem('userLoginType',result.data.data.decoded.data.userlogintype);
        }else{
            this.setState({redirect:'false'})
            this.setState({checkPageLoad:true})
            localStorage.setItem('pageLanded','No');
            }
        }else{
            this.setState({redirect:'true'})
        }
    }

    render(){
        if (this.state.redirect === 'true') {
            return(
                <div>
                    <DocumentVerifyMobile/>
                </div>
            );
        }else if(this.state.redirect === 'false'){
            return(
                <div class="appfrom-section">
                <div class="mit-container">
                    <div class="complete-section">
                        <div class="logo-area">
                            <img src="images/humm-logo.svg" alt="Humm" />
                        </div>
                        <div class="complete-content">
                            <p>Token Expired</p>
                </div>
            </div>
        </div>
    </div>
    
            );
        }else{
            return(
                <div>
                    <p></p>
                </div>
    
            );
        }
       
    }


}

export default HummVerificationPage;