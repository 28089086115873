import React,{ Component } from "react";
import { Redirect } from 'react-router-dom';

class ApplyRedirect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect:false,
          };
    }

componentDidMount(){
    localStorage.setItem('fromWhere','skip');
    this.setState({redirect:true});
    localStorage.setItem('applicationImage', 'images/home-active-contract-bg.png');
}


    render(){
        if (this.state.redirect) {
            return <Redirect push to="/application" />;
        }
        return(
            <div className="layout-fixed">
          
            </div>
        )
    }

}

export default ApplyRedirect;