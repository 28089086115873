
import axios from 'axios';

export const getAutoAddress = async (formData) => {
    let url = 'https://api.autoaddress.ie/2.0/autocomplete?key='+process.env.REACT_APP_AUTOADDRESSKEY+'&addressProfileName=Demo5LineV3&vanityMode=true&geographicAddress=false&country=gb&address='+formData


   return axios.get(url).then(res => {
        return res;
    })
    .catch(err => {
        return err.response
    })
};




