import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { getAllUserNotification,updateNotificationStatus } from '../../action/notification/notification';
import parse from 'html-react-parser';

class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            timeout: null,
            dataStoreNotifications: props.notificationDatas
        }
    }

    componentWillReceiveProps(nextProps) {
      
        if(nextProps.notificationDatas!=this.state.notifications){
            this.setState({ notifications: nextProps.notificationDatas });
            if(nextProps.notificationDatas){
                let totalNotificationResult=nextProps.notificationDatas.filter(e => e.read_status === false);
                this.props.totalNotification(totalNotificationResult.length)
            }
        }
    }

    componentDidMount() {
        if (localStorage.getItem('fromWhere') != 'skip') {
            this.initialLoad();
            this.setState({
                timeout: setInterval(() => {
                    this.initialLoad();
                }, 1800000)
            });
        }

        console.log(this.props.dataStoreNotifications);
    }

    initialLoad = async () => {
        let notificationData = [];
        const objTemp={email:localStorage.getItem('email')};
        const notification = await getAllUserNotification(objTemp);
        console.log(notification);
        if (notification !== undefined) {
            if (notification.data.statusCode === 200) {
                let unReadNotificationCount = 0;
                notification.data.data.map((data) => {
                    if (data.read_status === false) {
                        unReadNotificationCount += 1;
                    }
                    
                        notificationData.push(data);
                    
                });


                this.setState({ notifications: notificationData });

                this.props.totalNotification(unReadNotificationCount,notificationData.length)
            }
        }
        clearInterval(this.state.timeout);
        this.state.timeout = null
    }

    updateNotification = async (e, notificationID) => {
       analyticsClickEvent('notification_readed','')
        const objTemp = { notification_id: notificationID }
        const notification = await updateNotificationStatus(objTemp);
        this.props.notCloseNotification(e)
        e.preventDefault();
        this.initialLoad();
    }

    convertToEmail=(msg)=>{
        if(msg!='' && msg!=null && msg!=undefined){
            let newtext = '';
            let val = this.extractEmails(msg);
            if(Array.isArray(val) && val.length!=0){
                val.map((src) => {
                    let anchorTag = "<a target='_blank' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
                    newtext = msg.replace(src, anchorTag);
                    msg = newtext;
                  })
                  return msg;
            }
            return msg;
           
        }
    }
       extractEmails(text) {
         return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
       }

    displayNotification = () => {
        return <span>
            {this.state.notifications.map((response, index) =>
                // <Link className="dropdown-item" id="dropdownItem" key={index} >

                    <div className="d-flex">
                        <div className="tick-mark-item">
                            <img src="images/tickmark.png" alt="tick" />
                        </div>
                        <div className="notification-content">
                            <h3>{response.title}</h3>
                            <h6 className="notification-sub-title">{response.sub_title}</h6>
                            {response.message!=null && response.message!='' && response.message!=undefined &&
                            <p>{parse(this.convertToEmail(response.message))}</p>
                            }
                        </div>
                        <div className="notification-time">
                            <a className="time-msg">{moment(response.created_date).fromNow()}</a>

                        <span className={response.read_status === false ?"active":''}></span>
                    </div>
                    <div className="notification-close">
                        <a onClick={(e) => { this.updateNotification(e, response.notification_id) }}><img src="images/close.png" className="default" alt="terms" /></a>
                    </div>
                </div>

                // </Link>
            )
            }
        </span>
    }

    render() {
        return (
            <div>
                {this.displayNotification()}

            </div>



        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    };
};
 
  export const mapStateToProps = (state) => {
    return {
        notificationDatas: state.dataStore.notificationDatas
    };
  };



export default connect(mapStateToProps,mapDispatchToProps,null,{forwardRef: true})(Notification);
  


