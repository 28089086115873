import React from 'react';
import { Link } from 'react-router-dom';
import { getFavoritePartner } from '../../action/partner/getFavoritePartner';
import FavoritePartnerList from './favoritePartnerList';
import {searchFavoritePartner} from '../../action/partner/searchFavoritePartner';
import Loader from '../loader/loader';
import { connect } from "react-redux";
import {getPartnerImage} from "../../action/partner/getPartnerImage"
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';

export class FavoritePartner extends React.Component {
    constructor(props) {
        super(props);
        this.searchFavCall=React.createRef();
        this.state = { 
            loading:false,
            value:'',
            searchValue:'',
            favoritePartnerDataList:null,
            showMessage:false
            
         }
         this.goBack = this.goBack.bind(this);
         this.handleSearch=this.handleSearch.bind(this);
    }

    goBack(){
        this.props.history.push("/shop");
    }

    componentDidMount(){
        this.initialLoad();
    }

    initialLoad=async()=>{
        let favoritePartnerData=[];
        this.setState({showMessage:false});
        this.setState({loading:true});
        const objTemp={email:localStorage.getItem('email')}
        const result = await this.props.getFavoritePartner(objTemp);
        this.setState({loading:false});
        if (Array.isArray(result.data.data) && result.data.data.length !== 0) {
        await Promise.all(result.data.data.map( async(response) => {
            // let backGroundImage=await this.getResponsePartnerImage(response.Cover_Image__c,response.access_token);
           const dataObj={
            partnerId: response.Id,
            partnerName: response.Name,
            partnerIndustry: response.Industry,
            partnerLogo: response.flxLogo__c,
            partnerCoverImage: response.Cover_Image__c,
            partnerWebSite: response.Website
           }
           favoritePartnerData.push(dataObj);
        
       }));
    }
        // result.data.data.map((response)=>{
        //     const dataObj={
        //         partnerId:response.id,
        //         partnerName:response.Name,
        //         partnerIndustry:response.Industry,
        //         partnerLogo:response.flxLogo__c,
        //         partnerCoverImage:response.Cover_Image__c,
        //         partnerWebSite:response.Website
        //     };
        //     favoritePartnerData.push(dataObj);
           
        //     });
            if(result.data.message==="No favorite partner found this user"){
                this.setState({showMessage:true});
            }
        this.setState({favoritePartnerDataList:favoritePartnerData});

    }

    handleSearch=async(event)=>{
   
        const target = event.target;
        const value = target.value;
        const name = target.name;
        await this.setState({ [name]: value});
      
        let inputValue =this.state.searchValue;

            const searchQuery={email:localStorage.getItem('email'),partner_name:inputValue};

            const responseSearch = await this.props.searchFavoritePartner(searchQuery);

            
            
            let favoritePartnerData=[];
            if (Array.isArray(responseSearch.data.data) && responseSearch.data.data.length !== 0) {
            await Promise.all(responseSearch.data.data.map( async(dataItem) => {
                // let backGroundImage=await this.getResponsePartnerImage(dataItem.account_Cover_Image__c,dataItem.access_token);
               const reposeSearchData={
                partnerId: dataItem.account_id,
                partnerName: dataItem.account_Name,
                partnerIndustry: dataItem.account_Industry,
                partnerLogo: dataItem.account_flxLogo__c,
                partnerCoverImage: dataItem.account_Cover_Image__c,
                partnerWebSite: dataItem.account_Website
               }
               favoritePartnerData.push(reposeSearchData);
            
           }));
          }
            // responseSearch.data.data.map((dataItem)=>{
            //     const reposeSearchData={
            //         partnerId:dataItem.account_id,
            //         partnerName:dataItem.account_Name,
            //         partnerIndustry:dataItem.account_Industry,
            //         partnerLogo:dataItem.account_flxLogo__c,
            //         partnerCoverImage:dataItem.account_Cover_Image__c,
            //         partnerWebSite:dataItem.account_Website
            //     };
            //     favoritePartnerData.push(reposeSearchData);
               
            //     });
        

            this.setState({favoritePartnerDataList:favoritePartnerData});
            if (this.searchFavCall && this.searchFavCall.current) {
            this.searchFavCall.current.favoritePartnerChange(this.state.favoritePartnerDataList);

             this.checkFavouriteListExist();
            }
            

    }

    checkFavouriteListExist=async()=>{

        this.setState({showMessage:false});
        const objTemp={email:localStorage.getItem('email')}
        const result = await this.props.getFavoritePartner(objTemp);
        if(result.data.message==="No favorite partner found this user"){
            this.setState({showMessage:true});
        }
    }

    getResponsePartnerImage=async(url,access)=>{

        if(access===undefined){
            return undefined;
        }
        
        const obj={
        image_url:url,
        access_token:access,
        }

        if(this.props.storePartnerImage.length!=0){
            const filteredImageValue = this.props.storePartnerImage.filter(itemInArray => itemInArray.image_url === url && itemInArray.access_token === access);  
            if(filteredImageValue.length!=0){
                return filteredImageValue[0].convertedImage;
            }
        }
        
        const result= await getPartnerImage(obj);
        if(result.status===200){
            const imageData={
                image_url:url,
                access_token:access,
                convertedImage:result.data.data
            }
          
            this.props.storePartnerImagefunction(imageData);
            return result.data.data;
        }else{
            return undefined;
        }
        
        
      }

        
    render() { 
   
        return (
            <div className="wrapper"> 
            <Loader loaderStatus={this.state.loading}/>
         
            {/* search nav */}
        <section className="main">
            <div className="search-fav-section">
                <div className="container">
                    <div className="d-flex align-items-center top-section-partner">
                        <div className="left-iteQerd">
                            <div className="back-btn-item">
                                <a className="back-btnQr" onClick={this.goBack}><img src="images/back.svg" alt="<-"/> Back </a>
                            </div>
                        </div>
                        <div className="right-iteQerd">
                            <div className="d-flex align-items-center">
                                <div>
                                    <form className="search-iconbanner">
                                        <div className="form-group m-0">
                                            <input className="form-control" placeholder="Search" type="text" name='searchValue' value={this.state.searchValue} onChange={this.handleSearch}   onBlur={(e) => analyticsClickEvent('favorite_partner_search',this.state.searchValue)} onClick={(e) => analyticsClickEvent('favorite_partner_search','')}/>
                                            <span className="search-icon"><img src="images/search-Icon-white.svg" alt="search"/></span>
                                        </div>
                                    </form>
                                </div>
                                {localStorage.getItem('fromWhere')!='skip' &&
                                <div className="fav-eye-item">
                                    <div className="icon-itemQer active">
                                        <Link to="/favorite-partner" onClick={(e)=>{analyticsClickEvent('favorite_partner_icon','')}}>
                                             <img src="images/active-fav.svg" alt="fav-icon" className="active"/>
                                        </Link>
                                    </div>
                                    <div className="icon-itemQer">
                                    <Link to="/viewed-partner" onClick={(e)=>{analyticsClickEvent('viewed_partner_icon','')}}>
                                         <img src="images/eye-icon.svg" alt="eye-icon" className="default"/>
                                    </Link>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
        <section className="section">
        <div className="container">
           
            <div className="image-gallery">
                <h3 className="partner-page-title">My Favourites</h3>
                <div className="featured-partners-list row">

                {this.state.favoritePartnerDataList && 
                          
                          <FavoritePartnerList itemList={this.state.favoritePartnerDataList} ref={this.searchFavCall} 
                           checkFavouriteExists={this.checkFavouriteListExist}
                          /> 
                          
                      } 
                     
                </div>
            </div>
        </div>
    </section>
    {this.state.showMessage===true &&
    <section className="section">
    <div className="container">
       <h3 className="partner-page-title">No favourite partners selected yet, click on the heart icon to add a partner as a favourite to get Humming!</h3>
    </div>
    </section>

    }
        <div className="floating-pageicon">
            <div className="calculator-icon">
                <Link to="/quote-calculator"
                onClick={(_e) =>
                    {
                        localStorage.setItem('prevRoute',window.location.pathname)
                        analyticsClickEvent("common_quote_calculator", "")
                    }}
                >
                    <span><img src="images/calculator.svg" alt="icon"/></span></Link>
                
            </div>
        </div>        
    </div>
);
    }
}

export const mapStateToProps = (state) => {
    return {
        favouritePartnerStore: state.dataStore.favouritePartnerStore,
        favouritePartnerSearchStore: state.dataStore.favouritePartnerSearchStore,
        storePartnerImage: state.dataStore.storePartnerImage,
    };
   };


  export const mapDispatchToProps = (dispatch) => {
    return {
        getFavoritePartner: (formData) => {
            return dispatch(getFavoritePartner(formData));
        },
        searchFavoritePartner: (formData) => {
            return dispatch(searchFavoritePartner(formData));
        },
        storePartnerImagefunction:(formData)=>{
            return dispatch({
                type: 'STOREPARTNERIMAGE',
                value: formData
            });
        }
    }
   }
export default connect(mapStateToProps, mapDispatchToProps)(FavoritePartner);
