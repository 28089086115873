import React from 'react'
import { connect } from 'react-redux';


class ApplicationLanding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    componentDidMount(){
        this.props.appvalidations([]);
        this.props.getAmountRequested('');
        this.props.removeSavedata([]);
    }

    continue = e => {
        e.preventDefault();
        this.props.nextStep();
        localStorage.setItem('ApplicationErrors','');
        console.log(this.props);
      };
    render() { 
        return ( 
            <section className="main">
            <div className="application-bg">                  
              <div className="appneed-section">
                  <div className="inner-content">
                        <h1 className="appneed-tittle">WHAT YOU NEED</h1>
                        <p className="appneed-para">The UK’s Latest Way To Pay</p>
                        <div className="icon-appneed-item">
                          <div className="icon--items">
                                <div className="icon-monthly">
                                    <div className="icon-circle">
                                        <img src="images/monthly-icon.svg" className="img-fluid" alt="monthly Income"/>
                                    </div>
                                    <div className="icon-para">
                                        <p>Income 1K Monthly</p>
                                    </div>
                                </div>
                                <div className="icon-monthly">
                                    <div className="icon-circle">
                                        <img src="images/18+-icon.svg" className="img-fluid" alt="Over18+"/>
                                    </div>
                                    <div className="icon-para">
                                    <p>Over<br/>18</p>
                                    </div>
                                </div>
                                <div className="icon-monthly">
                                    <div className="icon-circle">
                                        <img src="images/ID-icon.svg" className="img-fluid" alt="Id"/>
                                    </div>
                                    <div className="icon-para">
                                        <p>Government ID</p>
                                    </div>
                                </div>
                                <div className="icon-monthly">
                                    <div className="icon-circle">
                                        <img src="images/bank-statement-icon.svg" className="img-fluid" alt="Statement"/>
                                    </div>
                                    <div className="icon-para">
                                        <p>Bank Statement</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-appneed-content">
                            <button type="button" className="lets-go-btn" onClick={this.continue}>Lets Go</button>
                        </div>
                  </div>
              </div>
            </div>
        </section> 
         );
    }
}


export const mapStateToProps = (state) => {
    return {
        applicationValidation:state.dataStore.applicationValidation,
        amountRequested:state.dataStore.amountRequested,
        saveApplicationData:state.dataStore.saveApplicationData,
    };
   };
  
  
  export  const mapDispatchToProps = (dispatch) => {
    return {
        appvalidations:(validations)=>{
            return dispatch({
                type: 'APP_VALIDATIONS_Remove',
                value: validations
            });
        },
        getAmountRequested:(validations)=>{
            return dispatch({
                type: 'AMOUNTREQUESTED',
                value: validations
            });
          },
          removeSavedata:(validations)=>{
            return dispatch({
                type: 'REMOVEDATA',
                value: validations
            });
          },
        
    }
   }
  
  export default connect(mapStateToProps,mapDispatchToProps)(ApplicationLanding);