import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className='notfound' >
    <h1 style={{fontSize: 77}}>404</h1>
    <h4 className='mt-2 mb-4'>Sorry, we couldn't find that page.</h4>
    {!localStorage.getItem("email") && <Link to="/login"><button className='btn btn-primary'>GO TO LOGIN</button></Link>}
    {localStorage.getItem("email") && <Link to="/home"><button className='btn btn-primary'>GO TO HOME</button></Link>}
  </div>
);

export default NotFound;