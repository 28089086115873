import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {settings}  from '../../../utils/bannerSettings'
import { Link } from "react-router-dom";
import { analyticsClickEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";


export default class HomeBannerOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemValue: '',
        }
    }

  

    render() {        
        return (
            <div>
                <Slider {...settings}>
                    {/* <div className="hero-right d-flex">
                        <div className="card-image-section">
                            <img src="images/card-image.svg" alt="Card" className="img-fluid" />
                        </div>
                        <div className="card-details tittle-active">
                            <h6>Humm//TAPP is Here! </h6>
                            <p>humm//TAPP is the new digital card that makes it even easier to Buy Now, Pay Later everywhere Humm is accepted, always interest free. Simply add to your Apple/Google device via the Humm app.</p>
                            <div className="logos-sec">
                                <span><img src="images/logo-sm1.svg" alt="logo-sm" /></span>
                                <span><img src="images/logo-sm2.svg" alt="logo-sm" /></span>
                                <span><img src="images/logo-sm3.svg" alt="logo-sm" /></span>
                                <span><img src="images/logo-sm4.svg" alt="logo-sm" /></span>
                            </div>
                        </div>
                    </div> */}
                    <div class="hero-right d-flex">
                        <div class="card-image-section">
                            <img src="images/big-love.svg" alt="Card" class="img-fluid" />
                        </div>
                        <div class="card-details tittle-active">
                            <h6>Big Love with Humm</h6>
                            <p>Whether it's your first date or fiftieth anniversary, the season of romance is the time to spoil, and pay in loveable easy instalments.</p>
                            <div class="shop-button">
                                {/* <button class="shop-jewell-btn" type="button">Shop Jewellery and Watches</button> */}

                            <Link to="/shop" className="shop-jewell-btn" onClick={(e) => analyticsClickEvent('home_shop_banner_clicked','')}>Shop jewellery and Watches</Link>
                            </div>
                        </div>
                    </div>
                    {/* FCA-1250 - Hiding virtual card reference in the app as it is not required for now. */}
                    {/* <div class="hero-right offer-sec-bg d-flex">
                        <div class="card-image-section">
                            <img src="images/mobile-img-app.svg" alt="mobile app" class="img-fluid" />
                        </div>
                        <div class="card-details tittle-active">
                            <h6>Don’t Have Our App Yet?</h6>
                            <p>Take your Humm on the go and avail of so many more features, including Humm Tapp.</p>
                            <div class="d-flex applink-item">
                                <button class="applink-btn" type="button">
                                <a href="https://www.apple.com/uk/app-store/" target="_blank" rel="noreferrer" onClick={(e) => analyticsClickEvent('home_apple_store','')}><img src="images/apple-store.svg" class="img-fluid" alt="apple store" /></a>
                                </button>
                                <button class="applink-btn" type="button">
                                <a href="https://play.google.com/store" target="_blank" rel="noreferrer" onClick={(e) => analyticsClickEvent('home_play_store','')}><img src="images/play-store.svg" class="img-fluid" alt="google play" /></a>
                                </button>
                            </div>
                        </div>
                    </div> */}
                </Slider>
            </div>
        );
    }
}