import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, IdealBankElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React,{useMemo} from "react";
import { withRouter } from "react-router-dom";
import useResponsiveFontSize from "./useResponsiveFontSize";


const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            color: "#424770",
            letterSpacing: "0.025em",
            fontFamily: "Source Code Pro, monospace",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#9e2146"
          }
        }
      }),
      [fontSize]
    );
  
    return options;
  };

//   checkcardNumber = (data) => {
//     console.log(data)
//   }

const SetupIntent = () => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const handleSubmit = async event => {
        // const { confirmSetupIntent, loading } = useConfirmSetupIntent();
        event.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }
        const billingDetails = {
            email: 'Tomaitech@mailinator.com',
          };
        //   const clientSecret = await createSetupIntentOnBackend();
        // const { setupIntent, error } = await conf(clientSecret, {
        //     type: 'Card',
        //     billingDetails,
        //   });
    
        // const payload = await stripe.createPaymentMethod({
        //   type: "card",
        //   card: elements.getElement(CardNumberElement)
        // });
        console.log(elements.getElement(CardNumberElement));
        // console.log("[PaymentMethod]", payload);
      };
    return (
        <form onSubmit={handleSubmit}>
             <label>
               Bank
                <div class="StripeElement">
                <IdealBankElement
                    options={options}
                    onReady={() => {
                        console.log("CardNumberElement [ready]");
                    }}
                    onChange={(e,event) => {
                        console.log("CardNumberElement [change]",'e', e,'event',event);
                    }}
                    onBlur={() => {
                        console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                        console.log("CardNumberElement [focus]");
                    }}
                />
                </div>
            </label>
            <label>
                Card number
                <div class="StripeElement">
                <CardNumberElement
                    options={options}
                    onReady={() => {
                        console.log("CardNumberElement [ready]");
                    }}
                    onChange={(event) => {
                        // this.checkcardNumber(event)
                        console.log("CardNumberElement [change]",event);
                    }}
                    onBlur={() => {
                        console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                        console.log("CardNumberElement [focus]");
                    }}
                />
                </div>
            </label>
            <label>
                Expiration date
                <div class="StripeElement">
                <CardExpiryElement
                    options={options}
                    onReady={() => {
                        console.log("CardNumberElement [ready]");
                    }}
                    onChange={event => {
                        console.log("CardNumberElement [change]", event);
                    }}
                    onBlur={() => {
                        console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                        console.log("CardNumberElement [focus]");
                    }}
                />
                </div>
            </label>
            <label>
                CVC
                <div class="StripeElement">
                <CardCvcElement
                    options={options}
                    onReady={() => {
                        console.log("CardNumberElement [ready]");
                    }}
                    onChange={event => {
                        console.log("CardNumberElement [change]", event);
                    }}
                    onBlur={() => {
                        console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                        console.log("CardNumberElement [focus]");
                    }}
                />
                </div>
            </label>
            <button type="submit" disabled={!stripe}>
                Pay
            </button>
        </form>
    );
}

export default withRouter(SetupIntent);