import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {addViewPartner} from '../../../action/partner/addViewPartners';
import {addFavoritePartner} from '../../../action/partner/addFavoritePartner';
import {removeFavoritePartner} from '../../../action/partner/removeFavoritePartner';
import { analyticsClickEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import { event } from "jquery";
import { Link } from "react-router-dom";

export default class SimpleSlider extends Component {
    constructor(props) {
        super(props); 
        this.state={
            itemValue:'',
            clientXonMouseDown: null,
            clientYonMouseDown: null,
            value:'',
            isFavAdd:'',
            isFavRemove:'',
            itemList:[]
        }
        this.addViewedPartner =this.addViewedPartner.bind(this);
        this.handleFavoriteAdd = this.handleFavoriteAdd.bind(this)
        this.handleFavoriteRemove = this.handleFavoriteRemove.bind(this)
    }



    addViewedPartner=async(partnerID,partnerName,partnerWebsitLink)=>{
      if(partnerWebsitLink == '' || partnerWebsitLink == null || partnerWebsitLink == undefined){
        return false;
      }
      let splitPartnerName = partnerName.split(' ').join('_');
           analyticsClickEvent('home_partner',splitPartnerName)
      if(localStorage.getItem('fromWhere')!='skip'){
      const obj={
          email:localStorage.getItem('email'),
          account:partnerID
      }
      await addViewPartner(obj);
    }
   }

   handleOnMouseDown (e) {
    this.setState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY
    })
    e.preventDefault() // stops weird link dragging effect
  }

  handleFavoriteAdd=async(value,partnerName)=>{
    let splitPartnerName = partnerName.split(' ').join('_');
    // analyticsClickEvent(splitPartnerName+'_partner_image_favorite_icon');
    analyticsClickEvent('home_partner_favorite_heart_checked',partnerName);
    const addDataParam={email:localStorage.getItem('email'),account:value};
    const addFavoriteResponse = await addFavoritePartner(addDataParam);
    const tempData = addFavoriteResponse.data;
    let currentState = this.state.itemList;
    let addFavUpdated = currentState.map((item)=>{
        if(item.partnerId === tempData.account){
            item.partnerFavId = tempData.id
        
        }
        return item;
    })

     this.setState({itemList:addFavUpdated});
     this.props.favCall();
   
}

handleFavoriteRemove=async(value,partnerName)=>{
  let splitPartnerName = partnerName.split(' ').join('_');
    // analyticsClickEvent(splitPartnerName+'_partner_image_unfavorite_icon');
    analyticsClickEvent('home_partner_favorite_heart_unchecked',partnerName);
    const removeDataParam={email:localStorage.getItem('email'),account:value};

    removeFavoritePartner(removeDataParam);
    let currentState = this.state.itemList;
    let removeFavUpdated = currentState.map((item)=>{
        if(item.partnerId === value){
            item.partnerFavId = null
        
        }
        return item;
    })

     this.setState({itemList:removeFavUpdated});
     this.props.favCall();
   
 }
 checkURL=(url)=>{
  if(url!=null && url!=undefined && url!=''){
     let pattern = /^((http|https|ftp):\/\/)/;

     if(!pattern.test(url)) {
      return "http://" + url;
     }else{
      return url;
     }
  }else{
     return url 
  }
 
}

  render() {
   let partnerDispaly='';
    if (Array.isArray(this.props.items) && this.props.items !== 0) {
      partnerDispaly=this.props.items.sort((a,b)=>(a.partnerName > b.partnerName ? 1 : -1));
    }
    const settings = {
        autoplay: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    
   

    return (
            <div>
              <Slider {...settings}>
                {partnerDispaly!=='' &&
                partnerDispaly.map(e =>                    
                  <div className="featured-box">
                  <div className="featured-secQr">
                      {e.partnerCoverImage==undefined &&
                            <img src="images/compu.png" alt={e.partnerName} />
                      }
                      {e.partnerCoverImage!=undefined &&
                            <img src={e.partnerCoverImage} alt={e.partnerName} />
                      }
                      <div className="overlay">
                          </div> 
                          {localStorage.getItem('fromWhere')!='skip' &&
                          <div className="fav-icon-section">
                            {e.partnerFavId===undefined &&(<a value={e.partnerId}  onClick={(events) => this.handleFavoriteAdd(e.partnerId,e.partnerName)} className='fav-icon-btn'><img src="images/heart-white.svg" /></a>) }
                                {e.partnerFavId!==undefined &&(<a value={e.partnerId} onClick={(events) => this.handleFavoriteRemove(e.partnerId,e.partnerName)} className='fav-icon-btn active'><img src="images/heart.svg" /></a>) }
                      </div>
                    }
                  <a target="_blank" rel="noreferrer"  href={this.checkURL(e.partnerWebSite)}  key={e.partnerId}
                  onClick={()=>this.addViewedPartner(e.partnerId,e.partnerName,e.partnerWebSite)}  onMouseDown={(event) => {this.handleOnMouseDown(event)}}>
                  <span className="featured-caption">
                    <div className="slider-logo-image">
                      {e.partnerLogo!=null &&
                        <img src={e.partnerLogo} alt={e.partnerName} />
                      }
                      {e.partnerLogo==null &&
                      <h6>{e.partnerName}</h6>
                      }
                    </div>
                  </span>
                  </a>
                  <div className="qtwo-calculator">
                    <Link
                        to={{
                            pathname: "/quote-calculator",
                            state: { id: e.partnerId, store_Name: e.partnerName }
                        }}
                        onClick={() => {localStorage.setItem('prevRoute',window.location.pathname)}}
                    >
                        <button className="qc-click-btn"
                        onClick={(_e) => { analyticsClickEvent('home_partner_quote_calculator', '') }}
                        >
                             <span className=''>
                                Get A Quote                                
                            </span>
                            <img src="images/right-arrow-shop.svg" className="d-inline" alt="icon" />
                        </button>
                    </Link>
                  </div>
              </div>
          </div>) 
        }
        </Slider>
      </div>
    );
  }
}