import React from 'react'

export class Radio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
        this.onSiteChanged=this.onSiteChanged.bind(this);
    }
    onSiteChanged=(event)=>{
   
        const target = event.target;
        console.log(target);
    }
    render() {
        return ( 
            <div className="dynamic-input">
            <div className="form-group">
            <span>{this.props.field_label}</span>
                <div className="d-flex align-items-center ratio-section">
                    {this.props.field_options && 
                    this.props.field_options.map((src)=>
                        <div className="d-flex inner-check-radio">
                        <input type="radio" name="site_name"  className="form-control"
                                            value={src.id} 
                                            checked={src.id} 
                                            onChange={this.onSiteChanged} /><span>{src.flxDisplayText__c}</span> </div> 

                        )
                    }
                </div>
             </div>
            </div>

         );
    }
}
 
export default Radio;