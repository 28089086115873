
export const validateConfirmPasswordField = (confirmPassword,newPassword) =>{
    let confirmPasswordError='';
      if(confirmPassword===''){
        confirmPasswordError='Confirm Password is required';
      }
      if(newPassword!==confirmPassword && confirmPassword){
        confirmPasswordError='Passwords do not match';
      }
      return confirmPasswordError;
  }
    
    
    export const validatePasswordField =(value,confirmPassword)=>{
        let commonPasswordError='';
        if (value.trim() === "") {
            commonPasswordError = 'New Password is required';
        }else if (value.trim().length < 8) {
            commonPasswordError = `Password must contain at least 8 characters, including uppercase, lowercase, number and special characters.`;
        }else if(value.search(/[@$!%*?&]/)< 0){
          commonPasswordError="Password must contain at least one special character like @$!%*?&";
        }else if(value.search(/[a-z]/) < 0){
            commonPasswordError="Password must contain at least one lowercase letter.";
        }else if(value.search(/[A-Z]/) < 0){
            commonPasswordError="Password must contain at least one uppercase letter.";
        }else if(value.search(/[0-9]/)< 0){
          commonPasswordError="Your password must contain at least one digit."; 
        }else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value)) {
          commonPasswordError="Only special characters like @$!%*?& are permitted";
        }else if(value!==confirmPassword && (confirmPassword !== undefined && confirmPassword !== '')){
          commonPasswordError='Passwords do not match';
        }
        return commonPasswordError;
      
    }

    
    export const hasPasswordEmpty=(value)=>{
      let validationPasswordError='';
      if (value.trim() === "") {
        validationPasswordError= 'Password is required';
       } 
       return validationPasswordError
    }