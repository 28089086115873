import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

const headers = {
    'session_id': localStorage.getItem('sessionId'),
  }

export const phonenumbervalidation = async (formData) => {
    let url = config.Humm_Dev_Link+'application/validate_phonenumber'

   return axios.post(url, formData,{headers:sessionID('secured')}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};

export const verifyPhonenumberOtp = async (formData) => {
    let url = config.Humm_Dev_Link+'application/verify_otp'

   return axios.post(url, formData,{headers:sessionID('secured')}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};


export const applicationphonenumbervalidation = async (formData) => {
    let url = config.Humm_Dev_Link+'application/skip_for_now/validate_phonenumber';

   return axios.post(url, formData).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};


export const ApplicationverifyPhonenumberOtp = async (formData) => {
    let url = config.Humm_Dev_Link+'application/skip_for_now/verify_otp'

   return axios.post(url, formData).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })


};