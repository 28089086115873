
import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'


const headers = {
    'session_id': localStorage.getItem('sessionId'),
  }
export const getPartnerSearch = (formData) => {

    if(formData.Industry!=''){
        const data =formData.Industry.replaceAll(",~",'~>');
        if(data.includes('~')){
            formData.Industry=data.substring(1);
        }else{
            formData.Industry = data;
        }
    }

    if(formData.Location!=''){
        const LocationData =formData.Location.replaceAll(",~",'~>');
        if(LocationData.includes('~')){
            formData.Location=LocationData.substring(1);
        }else{
            formData.Location = LocationData;
        }
    }
    let url = config.Humm_Dev_Link+'partner/Partner-filter'
    return async (dispatch) => {
        let resp=[];
        await axios.post(url,  formData,{headers:sessionID()} )
            .then(response => {
                dispatch({
                    type: 'PARTNER_SEARCH_LIST',
                    value: response.data
                });
                
                resp=response;
                return resp;
            })
            .catch(async error => {
                await userLogs(url,formData,error);
                if(error.response){
        handlingSession(error.response.status);
        }
                resp = error.response;
                return resp;
            })
            return resp;
    }
};
