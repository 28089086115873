import React from "react";
import { Route, Redirect } from "react-router-dom";
import { authMiddleware } from './utils/authMiddleware.js';
import { useLocation } from 'react-router-dom'


const PrivateRoute: React.FC<{
    component: React.FC;
    path: string;
}> = (props) => {
        
    const condition = authMiddleware();
    const location = useLocation();
    //Removed the Skip Login Checks

    let routeLinks=[
        '/favorite-partner',
        '/wallet',
        '/wallet-contract-detail',
        '/viewed-partner',
        '/my-account',
        '/home',
        '/shop','/application','/quote-calculator','/quote-calculator-results'];
    
    
    if(!routeLinks.includes(location.pathname)){
        return (<Redirect to="/page-not-found" />);
    }    

    return condition ? (<Route path={props.path}  component={props.component} />) :
        (<Redirect to="/login" />);
};
export default PrivateRoute;