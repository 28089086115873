import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {getAutoAddress} from '../../../action/autoAddress/getAutoAddress';
import {getFindAddress,getFindAddressSecondCall} from '../../../action/autoAddress/getFindAddress'
import { analyticsClickEvent } from '../../../action/firebaseAnalytics/firebaseAnalytics';


export class AutoAddress extends React.Component {
 constructor(props) {
   super(props);
   this.state = {
     suggestions: [],
     text:'',
     fields:{},
     error:{},
     changeText:false,
     showValidation:this.props.showAppValidations,
   }

   this.hasAnotherCall =this.hasAnotherCall.bind(this);
 }

 componentDidMount(){
  this.handlingValidation(this.state.text);
 }

  onTextChange = async(e) => {
    this.setState({changeText:true})
    const value = e.target.value;
    this.setState({text:value});
    this.setState({showValidation:'yes'});
    this.handlingValidation(value);
    const autoResult=  await getAutoAddress(value);
    
    var suggestNameList=[];
  if(autoResult.data.options){
    if(autoResult.data.totalOptions!==autoResult.data.options.length){
      if(autoResult.data.options[0]){
        if(autoResult.data.options[0].links[0].href){
          const getFindAddressData=await getFindAddressSecondCall(autoResult.data.options[0].links[0].href);
          getFindAddressData.data.options.map((lists) => {
            const datalists = [
              lists.displayName,
              lists.links[0].rel,
              lists.links[0].href
            ];
            suggestNameList.push(datalists);
          });
        }
      }
    }else{
      autoResult.data.options.map((list) => {
        const datalist = [
          list.displayName,
          list.links[0].rel,
          list.links[0].href
        ];
        suggestNameList.push(datalist);
      });
    }
  }
    this.setState({suggestions:suggestNameList});

  }

  handlingValidation=(value)=>{
    let stages={};
      stages[this.props.field_api_name] = ''; 
      stages['stage'] = this.props.stage; 
      this.props.appvalidations(stages);
  }
 
  async hasAnotherCall(NexResult){
    let finalResult =[];
    NexResult.data.options.map((item) => {
          const lists = [
            item.displayName,
            item.links[0].rel,
            item.links[0].href
          ];
          finalResult.push(lists);
        });
        return finalResult[0];
  }
  
    async suggestionSelected(value){
      let selectAddress=value[0];
      if(value){
        if(value[0]!='' && value[0]!=null && value[0]!=undefined){
          if(value[0].includes('addresses')){
            let suggestNameList=[];
            const getFindAddressDatas=await getFindAddressSecondCall(value[2]);
            getFindAddressDatas.data.options.map((listAddress) => {
              const datalists = [
                listAddress.displayName,
                listAddress.links[0].rel,
                listAddress.links[0].href
              ];
              suggestNameList.push(datalists);
            });
            this.setState({suggestions:suggestNameList});
            this.renderSuggestions();
            return false;
          }
        }
      }
     
        let autoFindResult=  await getFindAddress(value);
        //find reformat address details stats code is 500
        if(autoFindResult && autoFindResult.data.result.code=== 500){
          let nextLink = await this.hasAnotherCall(autoFindResult);
          autoFindResult= await getFindAddress(nextLink);
          selectAddress=autoFindResult.data.input.address;
        }
          let inputValue={}
          let address = selectAddress?selectAddress:'';
          let fields=this.state.fields;
          fields[this.props.field_api_name]=address;
          this.setState(()=>({
          text:address,
          fields,
          suggestions:[]
        }))
        inputValue[this.props.field_api_name] = address.toString();
        this.props.saveApplicationDatas(inputValue)
        this.props.fieldChange(this.props.field_api_name,address);
       
     
        if(autoFindResult){
        if(autoFindResult.data.options && autoFindResult.data.result.code===100){

          let autoAddressResult =[];
          autoAddressResult['addressLineOneInfo']= autoFindResult.data.reformattedAddress[0]?autoFindResult.data.reformattedAddress[0]:null;
          autoAddressResult['addressLineTwoInfo']= autoFindResult.data.reformattedAddress[1]?autoFindResult.data.reformattedAddress[1]:null;
          autoAddressResult['addressLineThreeInfo']= autoFindResult.data.reformattedAddress[2]?autoFindResult.data.reformattedAddress[2]:null;
          autoAddressResult['townInfo']= autoFindResult.data.reformattedAddress[3]?autoFindResult.data.reformattedAddress[3]:null;
          autoAddressResult['countryInfo'] = autoFindResult.data.reformattedAddress[4]?autoFindResult.data.reformattedAddress[4]:null;
          autoAddressResult['postalCodeInfo']= autoFindResult.data.postcode?autoFindResult.data.postcode:null;
        
            if(this.props.field_api_name==='Current_Address_Country__c'){

              let currentAddressFieldsresults={};
              currentAddressFieldsresults['FlatNumber__c']=null;
              currentAddressFieldsresults['HouseName__c']=null;
              currentAddressFieldsresults['HouseNumber__c']=null;
              currentAddressFieldsresults['Address_Line1__c']=autoAddressResult['addressLineOneInfo'];
              currentAddressFieldsresults['Town__c']=autoAddressResult['townInfo'];
              currentAddressFieldsresults['County__c']=autoAddressResult['countryInfo'];
              currentAddressFieldsresults['Postal_Code__c']=autoAddressResult['postalCodeInfo'];
              currentAddressFieldsresults['Address_Line2__c']=autoAddressResult['addressLineTwoInfo']===null?'':autoAddressResult['addressLineTwoInfo'];
              this.props.addressChange(currentAddressFieldsresults);
              let stages={}
              let inputValues={}

              Object.keys(currentAddressFieldsresults).forEach((key)=>{
              if(currentAddressFieldsresults[key]){
                stages[key]='';
                stages['stage'] = this.props.stage; 
                this.props.appvalidations(stages);
                inputValues[key] = currentAddressFieldsresults[key];
                this.props.saveApplicationDatas(inputValues)
                stages={};
                inputValues={};
              }
              });
            }
          
            if(this.props.field_api_name==='Previous_Address_Country__c' ){
              let previousAddressFieldsresults={};
              previousAddressFieldsresults['PreviousFlatNumber__c']=null;
              previousAddressFieldsresults['PreviousHouseName__c']=null;
              previousAddressFieldsresults['PreviousHouseNumber__c']=null;
              previousAddressFieldsresults['Previous_Address_Line1__c']=autoAddressResult['addressLineOneInfo'];
              previousAddressFieldsresults['PreviousTown__c']=autoAddressResult['townInfo'];
              previousAddressFieldsresults['PreviousCounty__c']=autoAddressResult['countryInfo'];
              previousAddressFieldsresults['PreviousPostal_Code__c']=autoAddressResult['postalCodeInfo'];
              previousAddressFieldsresults['Previous_Address_Line2__c']=autoAddressResult['addressLineTwoInfo']===null?'':autoAddressResult['addressLineTwoInfo'];
              previousAddressFieldsresults['fromWhere']='PreviousCurrent_Address_Country__c';
              this.props.addressChange(previousAddressFieldsresults);
              let stages={}
              let inputValues={}
              Object.keys(previousAddressFieldsresults).forEach((src)=>{
              if(previousAddressFieldsresults[src]){
                if(src!=='fromWhere'){
                stages[src]='';
                stages['stage'] = this.props.stage; 
                this.props.appvalidations(stages);
                inputValues[src] = previousAddressFieldsresults[src];
                this.props.saveApplicationDatas(inputValues)
                stages={};
                inputValues={};
                }
              }
              });
            }
            
          }
      }else{
        return '';
      }
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.showCurrentAddress!=''){
        console.log('nextProps.showCurrentAddress',nextProps.showCurrentAddress)
        this.props.allowManually(nextProps.showCurrentAddress)
      }
      if(nextProps.prepopulateStatus===true && this.state.changeText===false){
        this.props.allowManually(this.props.field_api_name)
        const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
        this.setState({ text:allDetailData[this.props.field_api_name] })
      }
      if (nextProps.showAppValidations !== this.state.showValidation) {
        if(nextProps.showAppValidations!==''){
         this.setState({ showValidation:nextProps.showAppValidations })
        }
      } 
    }
      
 
 renderSuggestions = () => {
  const { suggestions } = this.state;
  if (suggestions.length === 0) {
    return null;
  }
  return (
    <ul className="custom-list">
      {suggestions.map(itemList => <li key={itemList} onClick={(e)=>this.suggestionSelected(itemList)}>
        {itemList[0]}</li>)}
    </ul>
  )
}

onFocus(e) {
  e.target.setAttribute('autoComplete', 'nope')
}
 
 render() {
  let disableValue=''
  if(this.props.editable===false && this.props.prepopulateStatus===true && this.props.prepopulateCondition===true){
    disableValue='nonEditable'
  }
  const allDetails = Object.assign({}, ...this.props.applicationValidation);
  
   const {text}=this.state
   return (
    <div className="dynamic-input">
      <div className="form-group"> 
      <span>{this.props.field_question}</span>
   <div className={`TypeAheadDropDown ${disableValue}`}>

                  <input type="text" name={this.props.field_api_name} value="" readOnly={true} style={{display: "none",opacity: 0,position: "absolute"}} autoComplete="off"/>
             
     <input className="form-control" 
    onChange={this.onTextChange}
     name={this.props.field_api_name}
     placeholder={this.props.fieldPlaceHolder} 
     value={this.state.text} type="text"   onFocus={this.onFocus} autoCorrect="off"  autoCapitalize="none" spellCheck="false" onClick={(e)=>analyticsClickEvent(this.props.field_label,'')}/>
   
     {this.state.showValidation==='yes' &&
      <Link className= {`manual-address  ${allDetails[this.props.field_api_name]!==''?"address-right":""}`} onClick={(e)=>{analyticsClickEvent('enter_manually','');e.preventDefault();this.props.allowManually(this.props.field_api_name)}}>Enter Address Manually</Link>
   }
    {this.state.showValidation==='' &&
     <Link className="manual-address" onClick={(e)=>{analyticsClickEvent('enter_manually','');e.preventDefault();this.props.allowManually(this.props.field_api_name)}}>Enter Address Manually</Link>
   }
     {text && 
     this.renderSuggestions()
     }
     {allDetails[this.props.field_api_name]!== '' && this.state.showValidation==='yes' && 
                                            <p className="error-message"><span className="app-error-message" style={{ color: 'Red' }}>{allDetails[this.props.field_api_name]}</span> </p>
                                        }
   </div>
   </div>
   </div>
   );
 }
 
}
export const mapStateToProps = (state) => {
  return {
      applicationValidation:state.dataStore.applicationValidation,
      showAppValidations:state.dataStore.showAppValidations,
      saveApplicationData:state.dataStore.saveApplicationData,
      prepopulateStatus:state.dataStore.prepopulateStatus,
      questionValueSave:state.dataStore.questionValueSave,
      showCurrentAddress: state.dataStore.showCurrentAddress,
  };
 };


export  const mapDispatchToProps = (dispatch) => {
  return {
      appvalidations:(validations)=>{
          return dispatch({
              type: 'APP_VALIDATIONS',
              value: validations
          });
      },
      saveApplicationDatas:(data)=>{
        return dispatch({
          type: 'SAVEDATA',
          value: data
      });
      },
      questionSave:(data)=>{
        return dispatch({
          type: 'QUESTIONSAVE',
          value: data
      });
      },
      
  }
 }

export default connect(mapStateToProps,mapDispatchToProps)(AutoAddress)


