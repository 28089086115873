import React from "react";
import { connect } from "react-redux";
import { DeAttachPayment } from '../../action/card/paymentIntent';
import { updatePrimaryCard } from "../../action/cardDetails/cards";
import { analyticsClickEvent } from "../../action/firebaseAnalytics/firebaseAnalytics";
import { getAllUserNotification } from "../../action/notification/notification";

class CardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: ''
    };
    this.removeCard = this.removeCard.bind(this)
    this.updatePrimarycards = this.updatePrimarycards.bind(this)
  }

  updatePrimarycards = async (paymentID) => {
   analyticsClickEvent('primary_card')
    // const objTemp = { Primary_card__c: true };
    const objTemp = { email: localStorage.getItem('email'), payment_method_id: paymentID }

    const result = await updatePrimaryCard(objTemp);
    console.log(result);
    if (result.data.statusCode === 200) {
      setTimeout(() => {
        // window.location.reload(false)
        this.props.paymentfunctionCalls();
      }, 100)
    }


  };


  removeCard = async (paymentID) => {
   analyticsClickEvent('remove_card');
    this.setState({ errorMessage: '' })

    console.log(paymentID);
    const obj = {
      "email": localStorage.getItem('email'),
      "paymentMethodId": paymentID
    }

    const Result = await DeAttachPayment(obj);


    if (Result.data.statusCode === 406) {
      this.setState({ errorMessage: Result.data.message });
      window.$("#commonModelPayment").modal("show");
    }

    if (Result.data.statusCode === 202) {
      this.setState({ errorMessage: Result.data.message });
      window.$("#commonModelPayment").modal("show");

    }

    setTimeout(() => {
      window.$("#commonModelPayment").modal("hide");
    }, 2000)


    setTimeout(() => {
      this.props.paymentfunctionCalls();
      if (Result.data.statusCode === 202) {
      window.location.reload(false)
      }
    }, 2000)
    let notificationData = [];
    const objTemp = { email: localStorage.getItem('email') };
    // const objTemp = { email: 'venkatesh.g@perfutil.com' }

    const notification = await getAllUserNotification(objTemp);
    console.log(notification);
    if (notification !== undefined) {
        if (notification.data.statusCode === 200) {
            notification.data.data.map((data) => {
                    notificationData.push(data);
                
            });
console.log(notificationData);
            this.props.notificationData(notificationData)
        }
    }



  }
  render() {
    return (
      <React.Fragment>
        {this.props.items &&
          this.props.items.map((response) => (
            <div className="card" key={response.cardId}>
              <div className="card-body">
                <div className="card--Qeitem">
                  <div className="card-img--erf">
                    <div className="card-Qesd-item">
                      <img
                        src="images/cardbackimg.svg"
                        className="img-fluid"
                        alt="visa card"
                      />
                      {response.cardType === "visa" &&
                        <span className="cardTypeDynamic"><img src="images/logo-visa.svg" alt="Type" /></span>
                      }
                      {response.cardType === "diners" &&
                        <span className="cardTypeDynamic"><img src="images/Hnet.com-image.png" alt="Type" /></span>
                      }
                      {response.cardType === "mastercard" &&
                        <span className="cardTypeDynamic"><img src="images/logo_mc_45_2x.png" alt="Type" /></span>
                      }
                      {response.cardType === "discover" &&
                        <span className="cardTypeDynamic"><img src="images/logo-ic-discover.webp" alt="Type" /></span>
                      }
                      {response.cardType === "amex" &&
                        <span className="cardTypeDynamic"><img src="images/logo-ic-amex.webp" alt="Type" /></span>
                      }
                      {response.cardType === "jcb" &&
                        <span className="cardTypeDynamic"><img src="images/logo-jcb.png" alt="Type" /></span>
                      }
                      {response.cardType === "unionpay" &&
                        <span className="cardTypeDynamic"><img src="images/logo-unionpay.png" alt="Type" /></span>
                      }
                      {response.cardType === "" ||response.cardType === null || response.cardType === undefined &&
                        <span className="cardTypeDynamic"><img src="images/logo-visa.svg" alt="Type" /></span>
                      }
                      <span className="cardNumberDynamic">{'**** **** **** ' + '' + response.cardNumber.replace(/^.{2}/g, '**')}</span>
                    </div>
                  </div>
                  <div className="card-detail-right">
                    <div className="card--iteQe">
                      <form className="details-card">
                        <div className="card-form">
                          <div className="form-group">
                            <label>Card Number</label>
                            <input
                              className="form-control"
                              type="text"
                              name="cardNumber"
                              value={'**** **** **** ' + '' + response.cardNumber.replace(/^.{2}/g, '**')}
                              placeholder={""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="d-flex mt-auto expiry-dates">
                          <div className="form-group">
                            <label>Expiry Date</label>
                            <div className="d-flex month-expiry">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Month"
                                value={response.cardExpiryMonth + '/' + response.cardExpiryYear}
                                readOnly
                              />
                              {/* <span>/</span> */}
                              {/* <input
                                className="form-control"
                                type="text"
                                placeholder="Year"
                                value={response.cardExpiryYear}
                                readOnly
                              /> */}
                            </div>
                          </div>
                          <div className="form-group csv-code">
                            <label>CVV</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="***"
                              readOnly
                            />
                          </div>
                        </div>
                      </form>
                      <div className="remove-btn-sectionk">
                        <div className="primary-card-option">
                          <label>Primary Card</label>
                          {/* <input type="radio" className="radio-primary-card" /> */}
                          <input
                            id={response.cardId}
                            type="radio"
                            name="custom-radio-btn"
                            className="radio-primary-card"
                            // checked = {response.cardStatus}
                            checked={response.cardStatus === true ? true : false}
                            onClick={() => this.updatePrimarycards(response.PaymentMethodId)}
                          />
                          <label class="custom-radio" for={response.cardId} ></label>
                        </div>
                        <div className="card-update">
                          <button className="update-card-btn" type="button" onClick={() => this.removeCard(response.PaymentMethodId)}>
                            Remove Card
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        <div
          className="modal fade model-error"
          id="commonModelPayment"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="commonModellLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div class="modal-header"></div>
              <div className="modal-body">
                <div class="logo">
                  <img
                    src="images/modal-logo.svg"
                    alt="logo"
                    class="img-fluid"
                  />
                </div>
                <h5 className="modal-title " id="commonModellLabel">
                  Message
                </h5>
                <p className="text-center mt-4">
                  {this.state.errorMessage}
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
      notificationDatas: state.dataStore.notificationDatas
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
      notificationData: (data) => {
          return dispatch({
            type: 'NOTIFICATIONDATA',
            value: data
          });
        },
  }

}

export default connect(mapStateToProps,mapDispatchToProps)(CardDetails);
