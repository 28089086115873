export const authMiddleware = () => {

    if(localStorage.getItem('fromWhere')==='skip' || localStorage.getItem('webView')){
        return true;
    }else{
        return (localStorage.getItem('token')==='' || localStorage.getItem('token')===null) ? false : true;
    }

    
    
};