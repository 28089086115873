import React from "react";
import 'firebase/auth';
import {twitterLogin } from "../../../action/auth/login";
import { Link,Redirect } from 'react-router-dom';
import {twittersocialLogin } from "../../../action/auth/socialLogin";
import { twitterRequestToken } from "../../../action/auth/login";
import { analyticsClickEvent, analyticsLoginSuccessEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import { getApplicationImage } from "../../../action/avatar/getAvatar";
import Cookies from 'universal-cookie';


export class Twitter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          loginMessage :'',
          redirect:false,
          customPath:'/welcome',
          userExists: false,
        };
        localStorage.setItem('contentFrom',this.props.fromWhere);
      }
     
  componentDidMount(){
        localStorage.clear();
        this.initialLoad()
  }

  getApplicationBannerImage_forTwitter = async() => {
    if(localStorage.getItem('fromWhere') !='skip'){
        const appImage = await getApplicationImage();
        if (appImage && appImage.status === 201 && Array.isArray(appImage.data.image) && appImage.data.image.length !== 0 && appImage.data.image[1] != undefined) {
                let appImg = appImage.data.image[1];
                localStorage.setItem('applicationImage', appImg);                                   
        }
        else {
          localStorage.setItem('applicationImage', 'images/home-active-contract-bg.png');
        }
     }
}

  initialLoad = async () => {
    
  if(window.location.href.includes("oauth_verifier") ) {
      let objects = window.location.href.split("?")[1].split("&")

    if(objects.length == 2){
      let oauth_tokens = objects[0]
      let oauth_verifiers = objects[1]
      let oauth_token = oauth_tokens.split("=")[1]
      let oauth_verifier = oauth_verifiers.split("=")[1]
      let objs = {
        "oauth_token": oauth_token,
        "oauth_verifier": oauth_verifier
      }
      let results = await twitterLogin(objs)
      localStorage.setItem('UserName',results.data.name);
      localStorage.setItem('email',results.data.email);
      localStorage.setItem('uuid',results.data.id);
      localStorage.setItem('provider', 4);
      localStorage.setItem('profilePicture',results.data.profile_image_url_https);
      if (results.status ===200 && results.data.email.length >1) {
            let obj = {
              "username":localStorage.getItem('UserName'),
              "email": localStorage.getItem('email'),
              "uu_id": localStorage.getItem('uuid'),
              "provider": localStorage.getItem('provider')
            }
            const result = await twittersocialLogin(obj) 
            let responseMessage = result.data.message;
            switch(responseMessage){
              case 'This email already using with Gmail account so must login with Gmail':
              case 'This email already using with Facebook account so must login with Facebook':
              case 'This email already using with Twitter account so must login with Twitter':
                localStorage.clear();
                this.setState({loginMessage:result.data.message})
                window.$('#exampleModal').modal('show');
                return false;
              case 'This email already using with Humm account so must login with Humm':
                this.setState({loginMessage: result.data.message});
                window.$('#exampleModal').modal('show');
                return false;
            }
            localStorage.setItem('token', result.data.data.access_token);
            localStorage.setItem('sessionId',result.data.data.session_id);
            localStorage.setItem('csrfToken',result.data.data.csrf_token);
            // Set the state whether the user already exists or not
            this.setState({userExists: result.data.data.user_exists});

            const statusCode=[200];
            if (statusCode.includes(results.status)) {
              analyticsLoginSuccessEvent('login','social_media_login')
              if(!this.state.userExists){
                this.getApplicationBannerImage_forTwitter();
              }
                this.setState({redirect: true});
            }
      }else if(results.status){
          if(results.status !==200){
            return false;
          }}
      if(results.data.email === '' || results.data.email === undefined ){
              this.setState({loginMessage:'Please try again with an account which has email id registerd with it'});
              window.$('#exampleModal').modal('show');
              return false;
            }

        }
      }
  }


  redirectCodeScreen=()=>{
    window.$('#exampleModal').modal('hide');
  }
    
  async handleTwitterUserLogin() {
    analyticsClickEvent('twitter_login','')
    let obj = {
            "callback":window.location.href.split('?')[0]
      }
    const userRequestToken = await twitterRequestToken(obj);
    window.location.replace(userRequestToken.data.redirect_url)
    }

    
  render() { 
    const cookies = new Cookies();
      if (this.state.redirect) {
        if(this.state.userExists && cookies.get('retailerId') == undefined){
          return <Redirect push to={this.state.customPath} />;
        }
        if(this.state.userExists && cookies.get('retailerId') != undefined){
          return <Redirect push to={'/application'} />;
        }
        if(!this.state.userExists){
          return <Redirect push to={'/application'} />;
        }
      }
    return ( 
      <div>
          <span className="col-md-4 col-sm-3 p-0">
          <Link to={this.props.fromWhere} 
            className="soQr--link" id="twitter"
            onClick={() => this.handleTwitterUserLogin() } >
            <img src="images/twitter.svg" alt="Twitter" />
            </Link>
        </span>

        {this.state.loginMessage &&
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                  
                    <div className="modal-body">
                    <div class="logo">
                                <img src="images/modal-logo.svg" alt="logo" class="img-fluid"/>
                            </div>
                            <h5 className="modal-title" id="exampleModalLabel">Social Login</h5>
                    {this.state.loginMessage &&
                    <p className="text-center mt-4">{this.state.loginMessage}</p>
                    }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={()=>this.redirectCodeScreen()} >Ok</button>
                    </div>
                    </div>
                </div>
              </div> }
        </div>

      );
  }
  }

export default Twitter;