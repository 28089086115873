import React, { Component } from 'react';
import Input from './InputType/input';
import CheckBox from './InputType/checkbox';
import Select from './InputType/select';
import TextArea from './InputType/textarea';
import Radio from './InputType/radio';
import RunningText from './InputType/runningText';
import FormType from './InputType/formType';
import InputDatePicker from './InputType/inputDatePicker';
import AutoAddress from './InputType/autoAddress';
import { currentAddressFields, PreviousAddressFields } from "../../utils/const";
import { connect } from 'react-redux';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';


export class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            fieldList: [],
            errors: {},
            showBlur: {},
            prepopulateUpdate: true,
            hideFieldList: ['Previous_Address_Line1__c','Address_Line1__c','flxPersonMobile__c', 'Postal_Code__c', 'FlatNumber__c', 'HouseName__c', 'HouseNumber__c', 'Street__c',
                'County__c', 'Town__c', 'PreviousFlatNumber__c',
                'PreviousHouseName__c', 'PreviousHouseNumber__c', 'PreviousPostal_Code__c', 'PreviousStreet__c', 'PreviousTown__c', 'Previous_Address_Country__c', 'Address_Line2__c', 'Previous_Address_Line2__c'],
            hasPreviousAddressFieldsActive: false,
            showValidation: this.props.showAppValidations,
            showInformations: '',
            amountFieldName: ''
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.BlurEvent = this.BlurEvent.bind(this);
        this.autoaddressfieldchange = this.autoaddressfieldchange.bind(this);
        this.allowManuallyHandleClick = this.allowManuallyHandleClick.bind(this);
        this.populateAddressBasedOnDate = this.populateAddressBasedOnDate.bind(this);
    }

    async handleBlur() {

    }
    static getDerivedStateFromProps(nextProps, prevState) {

        const allDetailDatas = Object.assign({}, ...nextProps.saveApplicationData);
        if (allDetailDatas != prevState.fields && prevState.prepopulateUpdate === true) {
            return {
                fields: allDetailDatas,
            };
        }

        return null;
    }

    BlurEvent = async (e, message, maximumValue) => {
        this.setState({ amountFieldName: '' })
        let showBlurField = this.state.showBlur;
        let popupText = message;
        if (window.$('body').hasClass('modal-open') === false) {
            if (e.target.name === 'Total_Monthly_Income__c') {
                const monthlyIncome = e.target.value.split(',').join('');
                let IntegerMonthlyIncome = Number(monthlyIncome);
                if (IntegerMonthlyIncome > maximumValue && showBlurField[e.target.name] === undefined) {
                    IntegerMonthlyIncome = (IntegerMonthlyIncome * 12).toString();
                    if (IntegerMonthlyIncome.length !== 0) {
                        // Given the currency code of UK for the NumberFormat() method to add commas after last 3 places in amount
                        IntegerMonthlyIncome = new Intl.NumberFormat('en-GB').format(IntegerMonthlyIncome);
                    }
                    const positionOf = popupText.indexOf('£');
                    let finalMessage = [popupText.slice(0, positionOf-1),' <b>£',IntegerMonthlyIncome,'</b>', popupText.slice(positionOf+1)].join('');
                    await this.setState({ showInformations: finalMessage, amountFieldName: e.target.name })
                    analyticsClickEvent(e.target.name + '_confirm','')
                    document.getElementById("showMessage").innerHTML = finalMessage.toString()
                    window.$('#MonthlyExpModal').appendTo("body").modal('show');
                }
            }
        }
        if (e.target.name === 'genesis__Loan_Amount__c' && e.target.value.length > 1) {
            let inputValues = {};
            let fields = this.state.fields;
            let amountValue = e.target.value;
            if (e.target.value.indexOf(".") >= 0) {
                let splitedValue = e.target.value.split('.');
                let leftValue = splitedValue['0'];
                let rightValue = splitedValue['1'];

                if (rightValue.length === 1) {
                    rightValue = rightValue + '0';
                    amountValue = leftValue + '.' + rightValue
                }

                if (rightValue.length === 0) {
                    rightValue = '00';
                    amountValue = leftValue + '.' + rightValue
                }

                fields['genesis__Loan_Amount__c'] = amountValue;
                this.setState({ fields: fields });
            } else {
                amountValue = amountValue.replace(/\D/g, "").split(',').join('') + '.00';
                fields['genesis__Loan_Amount__c'] = amountValue;
                this.setState({ fields: fields });
            }
        }

    }



    async handleChange(field, value, type = '') {
        if(field==='date_moved_in_current_address'){
            this.props.showAddress('');
        }
        let fields = this.state.fields;
        const allDetailDatas = Object.assign({}, ...this.props.saveApplicationData);
         //vinoth changes start
    if (field === 'genesis__Loan_Amount__c') {
        if (value.charAt(0) == 0) {
            if(value > 0){
              return false;  
            }
            fields['genesis__Loan_Amount__c'] = '';
            this.setState({ fields: fields });
            return false;
        }
    }
     //vinoth changes end
        if (field === 'Fractional_Residential_Expense__c') {
            if (allDetailDatas['Current_Residential_Status__c'] === 'Homeowner - mortgaged' || allDetailDatas['Current_Residential_Status__c'] === 'Tenant/Boarding') {
                if (value === '0') {
                    fields['Fractional_Residential_Expense__c'] = '';
                    this.setState({ fields: fields });
                    return false;
                }
            }
        }
        if (field === 'Current_Residential_Status__c') {
            if (allDetailDatas['Fractional_Residential_Expense__c'] !== undefined && allDetailDatas['Fractional_Residential_Expense__c'] !== '' && allDetailDatas['Fractional_Residential_Expense__c'].charAt(0) == 0) {
                // if (allDetailDatas['Current_Residential_Status__c'] === 'Homeowner - mortgaged' || allDetailDatas['Current_Residential_Status__c'] === 'Tenant/Boarding') {
                // if (value === '0') {
                fields['Total_Expenditure__c'] = allDetailDatas['Total_Expenditure__c'] - allDetailDatas['Fractional_Residential_Expense__c'];
            
                this.setState({ fields: fields });

                return false;
                // }
            }
        }

        
        if (field === 'Fractional_Residential_Expense__c' || field === 'monthly_expense_financial_commitments' || field === 'Fractional_Living_Expense__c' || field === 'Current_Residential_Status__c') {
            let Fractional_Residential_Expense__c = allDetailDatas['Fractional_Residential_Expense__c'];
            if (field === 'Fractional_Residential_Expense__c') {
                Fractional_Residential_Expense__c = value.replace(/\D/g, "").split(',').join('');
            }

            let monthly_expense_financial_commitments = allDetailDatas['monthly_expense_financial_commitments'];
            if (field === 'monthly_expense_financial_commitments') {
                monthly_expense_financial_commitments = value.replace(/\D/g, "").split(',').join('');
            }

            let Fractional_Living_Expense__c = allDetailDatas['Fractional_Living_Expense__c'];
            if (field === 'Fractional_Living_Expense__c') {
                Fractional_Living_Expense__c = value.replace(/\D/g, "").split(',').join('');
            }
            let finalValue = ''
            let final_Fractional_Residential_Expense__c = 0;
            if (Fractional_Residential_Expense__c != undefined && Fractional_Residential_Expense__c != '') {
                final_Fractional_Residential_Expense__c = parseInt(Fractional_Residential_Expense__c);
            }

            let final_monthly_expense_financial_commitments = 0;
            if (monthly_expense_financial_commitments != undefined && monthly_expense_financial_commitments != '') {
                final_monthly_expense_financial_commitments = parseInt(monthly_expense_financial_commitments);
            }

            let final_Fractional_Living_Expense__c = 0;
            if (Fractional_Living_Expense__c != undefined && Fractional_Living_Expense__c != '') {
                final_Fractional_Living_Expense__c = parseInt(Fractional_Living_Expense__c);
            }

            if(field==='Current_Residential_Status__c' && (value === 'Homeowner - no mortgage' || value === 'Living with parents/family' )){
                final_Fractional_Residential_Expense__c=0;
            }

            finalValue = final_Fractional_Residential_Expense__c + final_monthly_expense_financial_commitments + final_Fractional_Living_Expense__c;
            let StoreVales = {};
            StoreVales['Total_Expenditure__c'] = finalValue.toString();
            this.props.saveApplicationDatas(StoreVales);
            fields['Total_Expenditure__c'] = finalValue.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.setState({ fields: fields });

        }

        this.setState({ prepopulateUpdate: false })
        if (field === 'flxPersonConfirmEmail__c' || field === 'flxPersonEmail__c') {
            if (value.length > 50) {
                return false;
            }
        }
        if (field === 'sortcode_bankaccount') {
            if (!/^[-]*[0-9][- 0-9]*$/.test(value)) {
                return false;
            }
            if (value.length == 6) {
                value = value.replace(/(\d{2})(\d{2})(\d{2})/, "$1-$2-$3");
            } 
            if (value.length >= 2) {
                value = value.replace(/(\d{2})(\d{2})/, "$1-$2");
            }
            if (value.length !== 0) {
                value = value.replace(/(\d{2})(\d{2})(\d{2})/, "$1-$2-$3");
            }
            if (value.length >= 9) {
                return false;
            }

            fields['sortcode_bankaccount'] = value
            this.setState({fields: fields});
        }
        if (field === 'genesis__bank_account_number__c') {
            if (value.length === 9) {
                return false;
            }
        }

        if (field === 'Postal_Code__c' || field === 'PreviousPostal_Code__c') {
            value = value.toUpperCase()
            if (value.length >= 9) {
                return false;
            }
            if (!/^[a-zA-Z0-9 \s]*$/.test(value)) {
                return false;
            }
        }


        if (type === 'Currency' && field != 'genesis__Loan_Amount__c') {
            if (value.length !== 0) {
                value = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        }

        if (field === 'genesis__Loan_Amount__c') {
            if (value.length !== 0) {
                if (value.indexOf(".") >= 0) {

                    // get position of first decimal
                    // this prevents multiple decimals from
                    // being entered
                    let decimal_pos = value.indexOf(".");

                    // split number by decimal point
                    let left_side = value.substring(0, decimal_pos);
                    let right_side = value.substring(decimal_pos);

                    // add commas to left side of number
                    left_side = this.formatNumber(left_side);

                    // validate right side
                    right_side = this.formatNumber(right_side);


                    // Limit decimal to only 2 digits
                    right_side = right_side.substring(0, 2);
                    // join number by .
                    value = left_side + "." + right_side;

                } else {
                    value = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }
        }

        if (field === 'flxPersonMobile__c') {
            if (value.length === 12) {
                return false;
            }
        }
        if (field === 'genesis__bank_account_number__c' || field === 'flxNumber_of_Other_Financial_Dependents__c') {
            if (!/[0-9]+$/.test(value)) {
                return false;
            }
        }

        if (field === 'flxFirst_Name__c' || field === 'occupation') {
            if (!/^[A-Za-z ]+$/.test(value)) {
                return false;
            }
        }

        if (field === 'flxLast_Name__c') {
            if (!/[a-zA-Z '-]+$/.test(value)) {
                return false;
            }
        }

        if (field === 'flxPersonEmail__c') {
            if (!/^.{1,50}$/.test(value)) {
                let fields = this.state.fields;
                fields[field] = value
                this.setState({ fields: fields });

                return false;
            }
        }
        if (field === 'flxPersonConfirmEmail__c') {
            if (!/^.{1,50}$/.test(value)) {
                let fields = this.state.fields;
                fields[field] = value
                this.setState({ fields: fields });

                return false;
            }
        }


        fields[field] = value;
        this.setState({ fields: fields });

        if (field === 'date_moved_in_current_address') {
            let hasShowPreviousAddress = this.monthDiff(value);
            this.populateAddressBasedOnDate(hasShowPreviousAddress);

        }
    }
    //compare the given month from current month
    monthDiff(d1) {
        if (d1 === null) {
            return 0;
        }
        let today = new Date();
        let months;
        months = (today.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth() + 1;
        months += today.getMonth() + 1;
        return months <= 0 ? 0 : months;
    }

    //formattocurrency
    formatNumber = (n) => {
        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }



    populateAddressBasedOnDate(hasShowPreviousAddress) {
        const allDetailDatas = Object.assign({}, ...this.props.saveApplicationData);
        const hideFieldList = this.state.hideFieldList;
        let fields = this.state.fields;
        let errors = this.state.errors;
        let ValidationFields = ['PreviousStreet__c', 'PreviousTown__c', 'PreviousPostal_Code__c']
        if (hasShowPreviousAddress <= 5 && this.state.fields['flxBirthDate__c'] !== undefined && allDetailDatas['date_moved_in_current_address']!='') {
            let lessHideFieldList = this.state.hideFieldList.filter(function (ele) {
                return ele !== 'Previous_Address_Country__c';
            });
            this.setState({
                hideFieldList: lessHideFieldList
            });
        } else if (hasShowPreviousAddress > 5) {
            ValidationFields.forEach((src) => {
                let stages = {}
                errors[src] = '';
                stages[src] = '';
                stages['stage'] = this.props.stage;
                this.props.appvalidations(stages);
            });
            this.setState({
                errors
            });
            if (!hideFieldList.includes('Previous_Address_Country__c')) {
                hideFieldList.push("Previous_Address_Country__c");
                this.setState({
                    hideFieldList
                });

            }

            if (this.state.hasPreviousAddressFieldsActive) {
                PreviousAddressFields.forEach((previousAddressField) => {
                    hideFieldList.push(previousAddressField);
                    if (fields[previousAddressField]) {
                        fields[previousAddressField] = undefined;
                    }
                });


            }
            this.setState({
                hideFieldList,
                fields, hasPreviousAddressFieldsActive: false
            });
        }
    }


    autoaddressfieldchange(values) {
        let fields = this.state.fields;
        let hidefields = this.state.hideFieldList;
        Object.keys(values).forEach(key => {
            fields[key] = values[key];
            hidefields = this.arrayRemove(hidefields, key);
            if (values[key] === 'PreviousCurrent_Address_Country__c') {
                this.setState({ hasPreviousAddressFieldsActive: true })
            }

        });
        this.setState({
            hideFieldList: hidefields,
            fields: fields
        }, () => {
            this.render();
        });

    }

    arrayRemove(arr, value) {

        return arr.filter(function (ele) {
            return ele !== value;
        });
    }

    analyticsConfirmClickEvent = (data) => {
// analyticsClickEvent(this.state.amountFieldName +'_confirm','')
analyticsClickEvent('confirm','')


    }




    allowManuallyHandleClick(field) {
        let hideFieldList = this.state.hideFieldList
        switch (field) {
            case 'Current_Address_Country__c':
                hideFieldList = hideFieldList.filter(val => !currentAddressFields.includes(val));
                this.setState({
                    hideFieldList
                });
                break;

            case 'Previous_Address_Country__c':

                let preHideFieldList = this.state.hideFieldList.filter(val => !PreviousAddressFields.includes(val));
                this.setState({
                    hideFieldList: preHideFieldList,
                    hasPreviousAddressFieldsActive: true
                });
                break;

        }

        if(field==='both'){
                hideFieldList = hideFieldList.filter(val => !currentAddressFields.includes(val));
                this.setState({
                    hideFieldList
                });
                 hideFieldList = hideFieldList.filter(val => !PreviousAddressFields.includes(val));
                this.setState({
                    hideFieldList,
                    hasPreviousAddressFieldsActive: true
                });
        }
    }



    getDisplayFields(field) {
        let prepopulateCondition = field.pre_populated;
        if (this.props.prepopulateStatus === true && field.not_for_existing === true) {
            return false;
        }


        if (this.state.hideFieldList.indexOf(field.flxFieldApiName__c) === -1) {
            switch (field.flxType__c) {

                case 'Text':
                case 'Currency':
                case 'Email':
                case 'Number':
                case 'Phone':
                case 'ip called':
                    return (
                        <Input
                            key={field.id}
                            field_id={field.hummuk_flxCmsQuestion__c_id}
                            field_label={field.flxLabelPriorQuestion__c}
                            field_question={field.flxLabelPriorQuestion__c}
                            field_placeholder={field.flxPlaceHolder__c}
                            field_value={this.state.fields[field.flxFieldApiName__c] === undefined ? '' : this.state.fields[field.flxFieldApiName__c]}
                            field_required={field.hummuk_flxCmsQuestion__c_flxIsRequired__c}
                            field_type={field.flxType__c}
                            field_questions={field.Questions}
                            field_answers={field.answers}
                            field_api_name={field.flxFieldApiName__c}
                            field_api_validations={field.validations}
                            threshold={field.threshold_limit}
                            stage={this.props.stage}
                            showValidation={this.props.showValidation}
                            phoneNumber={this.props.phoneNumber}
                            fieldChange={this.handleChange}
                            fieldBlur={this.handleBlur}
                            priority={field.flxPriority__c}
                            prepopulateCondition={prepopulateCondition}
                            editable={field.editable}
                            initialLoad={this.state.prepopulateUpdate}
                            onBlurEvent={this.BlurEvent}
                        />
                    );

                case 'Auto Address':
                    return (
                        <AutoAddress key={field.id}
                            field_id={field.hummuk_flxCmsQuestion__c_id}
                            field_label={field.flxLabelPriorQuestion__c}
                            field_question={field.flxLabelPriorQuestion__c}
                            field_placeholder={field.flxPlaceHolder__c}
                            field_value={field.ans_Name}
                            field_required={field.hummuk_flxCmsQuestion__c_flxIsRequired__c}
                            field_type={field.flxType__c}
                            field_questions={field.Questions}
                            field_answers={field.answers}
                            field_api_name={field.flxFieldApiName__c}
                            fieldValidation={field.validations}
                            addressChange={this.autoaddressfieldchange}
                            allowManually={this.allowManuallyHandleClick}
                            fieldChange={this.handleChange}
                            priority={field.flxPriority__c}
                            stage={this.props.stage}
                            prepopulateCondition={prepopulateCondition}
                            editable={field.editable}
                            initialLoad={this.state.prepopulateUpdate}
                        />
                    );

                case 'Date':
                case 'Card expiry date':
                    return (<InputDatePicker
                        key={field.id}
                        field_id={field.hummuk_flxCmsQuestion__c_id}
                        field_label={field.flxLabelPriorQuestion__c}
                        field_question={field.flxLabelPriorQuestion__c}
                        field_placeholder={field.flxPlaceHolder__c}
                        field_value={field.ans_Name}
                        field_required={field.hummuk_flxCmsQuestion__c_flxIsRequired__c}
                        field_type={field.hummuk_flxCmsQuestion__c_flxType__c}
                        field_questions={field.Questions}
                        field_answers={field.answers}
                        field_api_name={field.flxFieldApiName__c}
                        field_api_validations={field.validations}
                        stage={this.props.stage}
                        showValidation={this.props.showValidation}
                        priority={field.flxPriority__c}
                        prepopulateCondition={prepopulateCondition}
                        editable={field.editable}
                        initialLoad={this.state.prepopulateUpdate}
                        fieldChange={this.handleChange} />);


                case 'Picklist':
                case 'select box called':
                    return (
                        <Select
                            key={field.id}
                            field_id={field.hummuk_flxCmsQuestion__c_id}
                            field_label={field.flxLabelPriorQuestion__c}
                            field_type={field.flxType__c}
                            field_options={field.answers}
                            field_value={''}
                            field_questions={field.Questions}
                            field_api_name={field.flxFieldApiName__c}
                            field_api_validations={field.validations}
                            stage={this.props.stage}
                            showValidation={this.props.showValidation}
                            priority={field.flxPriority__c}
                            prepopulateCondition={prepopulateCondition}
                            editable={field.editable}
                            initialLoad={this.state.prepopulateUpdate}
                            fieldChange={this.handleChange}
                        />
                    );

                case 'Checkbox':
                    return (
                        <CheckBox
                            key={field.id}
                            field_id={field.hummuk_flxCmsQuestion__c_id}
                            field_label={field.flxLabelPriorQuestion__c}
                            field_question={field.flxLabelPriorQuestion__c}
                            field_placeholder={field.flxPlaceHolder__c}
                            field_value={field.ans_Name}
                            field_required={field.hummuk_flxCmsQuestion__c_flxIsRequired__c}
                            field_type={field.hummuk_flxCmsQuestion__c_flxType__c}
                            field_questions={field.Questions}
                            field_answers={field.answers}
                            field_api_name={field.flxFieldApiName__c}
                            field_api_validations={field.validations}
                            stage={this.props.stage}
                            showValidation={this.props.showValidation}
                            priority={field.flxPriority__c}
                            prepopulateCondition={prepopulateCondition}
                            editable={field.editable}
                            initialLoad={this.state.prepopulateUpdate}
                            fieldChange={this.handleChange}
                        />
                    );

                case 'Text area':
                    return (
                        <TextArea
                            key={field.id}
                            field_id={field.hummuk_flxCmsQuestion__c_id}
                            field_label={field.flxLabelPriorQuestion__c}
                            field_type={field.flxType__c}
                            field_value={field.answers}
                            field_questions={field.Questions}
                            stage={this.props.stage}
                            showValidation={this.props.showValidation}
                            priority={field.flxPriority__c}
                            prepopulateCondition={prepopulateCondition}
                            editable={field.editable}
                            initialLoad={this.state.prepopulateUpdate}
                            fieldChange={this.handleChange}
                        />
                    );

                case 'Radio':
                case 'radio button called':
                    return (
                        <Radio
                            key={field.id}
                            field_id={field.hummuk_flxCmsQuestion__c_id}
                            field_label={field.flxLabelPriorQuestion__c}
                            field_type={field.flxType__c}
                            field_value={field.flxDefaultAnswer__c}
                            field_options={field.answers}
                            field_checked={field.flxDefaultAnswer__c}
                            field_questions={field.Questions}
                            field_header={field.flxHeader__c}
                            stage={this.props.stage}
                            showValidation={this.props.showValidation}
                            priority={field.flxPriority__c}
                            prepopulateCondition={prepopulateCondition}
                            editable={field.editable}
                            initialLoad={this.state.prepopulateUpdate}
                            fieldChange={this.handleChange}
                        />
                    );

                case 'Running Text':
                case 'Read Only':

                    return (<RunningText
                        key={field.id}
                        field_id={field.hummuk_flxCmsQuestion__c_id}
                        field_label={field.flxLabelPriorQuestion__c}
                        field_type={field.flxType__c}
                        field_value={field.answers}
                        field_questions={field.Questions}
                        stage={this.props.stage}
                        showValidation={this.props.showValidation}
                        priority={field.flxPriority__c}
                        prepopulateCondition={prepopulateCondition}
                        editable={field.editable}
                        initialLoad={this.state.prepopulateUpdate}
                        fieldChange={this.handleChange}
                    />);
                case 'Form':
                case '':
                    return (<FormType
                        key={field.id}
                        field_value={field.flxSectionBodyRich__c}
                        field_questions={field.Questions}
                        stage={this.props.stage}
                        showValidation={this.props.showValidation}
                        priority={field.flxPriority__c}
                        fieldChange={this.handleChange}
                        prepopulateCondition={prepopulateCondition}
                        editable={field.editable}
                        initialLoad={this.state.prepopulateUpdate}
                    />);


                default:
                    return (
                        <p></p>
                    );

            }
        }
    }
    render() {
        let field = '';
        if (this.props.fieldItem !== '') {

            field = this.props.fieldItem.sort((a, b) => {
                return a.flxSequence__c - b.flxSequence__c;
            });

        }
        return (
            <React.Fragment>
                {field !== '' &&
                    field.map((question) =>
                        this.getDisplayFields(question)
                    )

                }
                {this.state.showInformations != '' &&
                    <div className="modal fade model-error application-infomation-modal" id="MonthlyExpModal" tabIndex="-1" role="dialog" aria-labelledby="MonthlyExpModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body pb-0 mt-2">
                                    <div className="logo mb-4">
                                        <h5 className='font-weight-bold'>Note</h5>
                                    </div>
                                    <p className="mt-2 mb-3" id="showMessage"></p>
                                </div>
                                <div className="modal-footer mb-3">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => this.analyticsConfirmClickEvent()}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }



            </React.Fragment>

        );




    }
}

export const mapStateToProps = (state) => {
    return {
        applicationValidation: state.dataStore.applicationValidation,
        showAppValidations: state.dataStore.showAppValidations,
        saveApplicationData: state.dataStore.saveApplicationData,
        prepopulateStatus: state.dataStore.prepopulateStatus,
        showCurrentAddress: state.dataStore.showCurrentAddress,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        appvalidations: (validations) => {
            return dispatch({
                type: 'APP_VALIDATIONS',
                value: validations
            });
        },
        saveApplicationDatas: (data) => {
            return dispatch({
                type: 'SAVEDATA',
                value: data
            });
        },
        showAddress:(data) => {
            return dispatch({
              type: 'SHOWADDRESS',
              value: data
            });
          },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Element);