
import axios from 'axios';

export const getFindAddress = async (formData) => {

  
    let url =formData[2];


   return axios.get(url).then(res => {
        return res;
    })
    .catch(err => {
        return err.response
    })
};


export const getFindAddressSecondCall = async (formData) => {

  
    let url =formData;


   return axios.get(url).then(res => {
        return res;
    })
    .catch(err => {
        return err.response
    })
};





