import React,{ Component } from "react";
import {forgotPassword} from "../../../action/auth/forgotPassword";
import { Redirect } from 'react-router-dom';
import { analyticsClickEvent, analyticsScreenViewEvent, analyticsTypeEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";


class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
          email:'',
          disable:'',
          errors:{
            emailError:'',
          },
          redirect:false,
          serverError:'',
          domainMessage:''
        };
          this.handleChange = this.handleChange.bind(this);
          this.handleSubmit = this.handleSubmit.bind(this);
          this.removeFromHistory = this.removeFromHistory.bind(this);
      }

         
  handleChange(event) {
    
    const target = event.target;
    const value = target.value;
    const name = target.name;
    // analyticsTypeEvent(name.toLocaleLowerCase())
    this.setState({ [name]: value});
    let errors = this.state.errors;
    if(name==="email"){ 
      if (
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
          value
        )
      ) {
        errors.emailError='';
      }else if (value.trim() === "") {
         errors.emailError = "Email is required";
         this.setState({serverError:''})
      }else{
         errors.emailError= "Please enter a valid email";
         this.setState({serverError:''})
      }
    }

    this.setState({errors, [name]: value});
  }

    componentDidMount() {
    
        // analyticsScreenViewEvent();
        window.onpopstate = e => {
            this.removeFromHistory();
        }
    }

    removeFromHistory() {

        if (window.$('#forgotModal').hasClass('show') === false) {
            window.$('#forgotModal').modal('hide');
            window.$('.modal-backdrop').removeClass('show');
            window.$('body').removeClass('modal-open');
            window.$('.modal-backdrop').remove();
        }
    }


   handleSubmit=async(event)=> {
    analyticsClickEvent('send','')
    event.preventDefault();
   
    if (this.state.email === '') {
            this.setState({ errors:{...this.state.errors,emailError: 'Email is required' }});
            return false;
        }

        if (this.state.errors.emailError !== '') {
            return false;
        }
        this.setState({disable:'disabled'});
        const obj={'email':this.state.email}
        localStorage.setItem('forgotEmail',this.state.email)
        const result = await forgotPassword(obj);

        if(result.status===200){
            var domainMessage='Verification code send to registered e-mail address '+this.state.email;
            this.setState({domainMessage:domainMessage});
            window.$('#forgotModal').modal('show');
        }else{
            this.setState({serverError:result.data.message})
            this.setState({disable:''})
            }
    }

    redirectCodeScreen=(e)=>{
        analyticsClickEvent('forgot_password_popup_ok','')
        this.setState({redirect: true});
        this.setState({domainMessage: ''});
         window.$('#forgotModal').modal('hide');
    }

    backgroundImage=()=>{
            return <div className="col-md-6 col-sm-12 col-xs-12 section-left p-0">
            <div className="leftLogin-img-sec">
                <img src="images/humm-login-bg.jpg" alt="Background" />
            </div>
            </div>;
    }


    render(){
        if (this.state.redirect) {
            return <Redirect push to="/forgot-password-code" />;
        }
        return(
            <div className="layout-fixed">
            <div className="wrapper login-page scroll-sec-fix">
                {this.backgroundImage()}
                <div className="col-md-6 col-sm-12 col-xs-12 section-right p-0">
                    <div className="section-form">
                        <div className="form-header">
                            <span className="section-logo">
                                <img src="images/logo.svg" alt="logo"/>
                            </span>
                            <span className="section-heading">
                                <h4>Forgot your password? No problem, please enter your email below and we will send you a reset link</h4>
                            </span>
                        </div>
                        <div className="form-body">
                            <form id="login-form" className="log-form" name="loginForm">
                                <div className="error-user">
                                    <div className="form-group floating-label-group">
                                        <input type="email" className="form-control" id="email" name='email'  value={this.state.email} placeholder=" "   required onChange={this.handleChange} onClick={(e)=>analyticsClickEvent('forgot_password_email','')}/>
                                        <label className="floating-label">Email</label>
                                    </div>
                                    <p className="error-message"> 
                                        {this.state.errors.emailError !== '' &&
					<span style={{ color: 'Red' }}>{this.state.errors.emailError}</span>}
                                        {this.state.serverError && this.state.serverError!=='Verification code sent to registered email' &&
                                        <span style={{color:'Red'}}>{this.state.serverError}</span>}
                                    </p>
                                </div>
                                    <div className="form-group">
                                        <input type="submit" className="btn btn-warning form-control" required id="submit" value="Send" onClick={this.handleSubmit} />
                                    </div>  
                            </form>
                        </div>
                    </div>
                </div>
                {this.state.domainMessage &&
                <div className="modal fade model-error" id="forgotModal" tabIndex="-1" role="dialog" aria-labelledby="forgotModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid"/>
                                </div>
                                <h5 className="modal-title" id="forgotModalLabel">Email</h5>
                                <p className="mt-4">{this.state.domainMessage}</p>
                            </div>
                            <div className="modal-footer mb-4">
                                <button type="button" className="btn btn-primary"  onClick={(e)=>this.redirectCodeScreen(e)}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
    }
            </div>
        </div>
        )
    }

}

export default ForgotPassword;