// import axios from 'axios';

// export const getPartnerImage = async (formData) => {
//     let url = formData.url
//     let Auth={
//         'Authorization': 'Bearer '+formData.access, 
//         mode: 'cors',
//         redirect: 'follow',
//         cache: 'no-store',
//         'Content-Type': 'application/json',
//         'Referrer-Policy':'same-origin',
//         'X-Content-Type-Options':'nosniff',
//         'Strict-Transport-Security':`max-age=31536000; includeSubDomains; preload`,
//         'Expect-Ct':'96400',
//         "Access-Control-Allow-Methods": 'GET',
//     }
//     console.log('actions',url,Auth)

//    return axios.get(url, {headers:Auth}).then(res => {
//         return res;
//     })
//     .catch(err => {
//         return err.response
//     })



// };

import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

const headers = {
    'session_id': localStorage.getItem('sessionId'),
  }
export const getPartnerImage = async (formData) => {
    let url = config.Humm_Dev_Link+'partner/Partner_Image'

   return axios.post(url,formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })
};
