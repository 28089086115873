import React,{ Component } from "react";
import {forgotPasswordCode} from "../../../action/auth/forgotPasswordCode";
import { Redirect } from 'react-router-dom';
import {manualLogin} from "../../../action/auth/login";
import { connect } from "react-redux";
import {validatePasswordField} from '../../validation/passwordValidation';
import { analyticsClickEvent, analyticsScreenViewEvent, analyticsTypeEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";



export class ForgotPasswordCode extends Component {

    constructor(props) {
        super(props);
        this.state = {
          newPassword:'',
          confirmPassword:'',
          disable:'',
          code:'',
          errors:{
            newPasswordError:'',
            codeError:''
          },
          redirect:false,
          serverError:'',
          serverErrorColor:'',
          serverMessage:''
        };
          this.handleChange = this.handleChange.bind(this);
          this.handleSubmit = this.handleSubmit.bind(this);
      }
componentDidMount(){
//    analyticsScreenViewEvent();
}
         
  handleChange(event) {
    
    const target = event.target;
    const name = target.name;
    // analyticsTypeEvent(name.toLocaleLowerCase())
    let value = target.value
    if(name==="code"){
        value = target.value.replace(/\D/g, "");
    }
    
    this.setState({ [name]: value});

   
    let server=this.state;

    switch(name){
        case 'newPassword':
            server = {...server,errors:{...server.errors,newPasswordError: validatePasswordField(value)}};
            break;
            case 'code':
            if (value.trim() === "") {
                server = {...server,errors:{...server.errors,codeError:'Code is required'},serverError:''};
            }else if(value.trim().length > 5){
                server = {...server,errors:{...server.errors,codeError:'Code does not accept more than 5 digits'},serverError:''};
            }else{
                server = {...server,errors:{...server.errors,codeError:''},serverError:''};
            }
         
            break;
            default:
            server = {...server,errors:{codeError:'',newPasswordError:''}};
    }   
    server={...server,[name]:value};
    this.setState({...server});
  }




  initialFieldValidation(newPassword,verifyCode){

    let errors = this.state.errors
    if(newPassword.trim()===''){
        errors.newPasswordError='New Password is required';
        this.setState({errors});
    }
    if(verifyCode.trim()===''){
        errors.codeError='Code is required';
        this.setState({errors:errors,serverError:''});
    }
  }

   handleSubmit=async(event)=> {
    analyticsClickEvent('forgotPasswordCode_confirm','')
   event.preventDefault();
   
        const obj={
            'email':localStorage.getItem('forgotEmail'),
            'verify_code':this.state.code,
            'new_password':this.state.newPassword
           }


        this.initialFieldValidation(this.state.newPassword,this.state.code);
        if(this.state.errors.newPasswordError || this.state.errors.codeError){
            return false
        }

        this.setState({disable:'disabled'});
      
        const result = await forgotPasswordCode(obj);
        console.log('forgot',result);
        if(result.status===200){
            this.setState({serverMessage:result.data.message})
            const objs={
                'email':localStorage.getItem('forgotEmail'),
                'password':this.state.newPassword
               }

            const results = await this.props.manualLogin(objs);
            if(results.status===200){
                localStorage.setItem('UserName',results.data.data.user_details.username);
                localStorage.setItem('token',results.data.data.access_token);
                localStorage.setItem('sessionId',results.data.data.session_id);
                localStorage.setItem('email',localStorage.getItem('forgotEmail'));
                localStorage.setItem('csrfToken',results.data.data.csrf_token);
                this.setState({redirect: true});
            }
        }else{ 
                if(result.data.message == "Please use the password which recently not used"){
                    this.setState({errors:{ newPasswordError :result.data.message}})
                }
                else{
                this.setState({serverError:result.data.message,errors:{...this.state.errors,codeError:''}})
                }
                this.setState({serverErrorColor:'red'})
                this.setState({disable:''})
            }

        }
        backgroundImageDisplay=()=>{
            return <div className="col-md-6 col-sm-12 col-xs-12 section-left p-0">
            <div className="leftLogin-img-sec">
                <img src="images/humm-login-bg.jpg" alt="Background" />
            </div>
        </div>;
      }

    render(){
        if (this.state.redirect) {
            return <Redirect push to="/welcome" />;
        }
        return(
            <div className="layout-fixed">
            <div className="wrapper login-page scroll-sec-fix">
                    {this.backgroundImageDisplay()}
                    <div className="col-md-6 col-sm-12 col-xs-12 section-right p-0">
                        <div className="section-form">
                            <div className="form-header">
                                <span className="section-logo">
                                    <img src="images/logo.svg" alt="logo"/>
                                </span>
                                <span className="section-heading">
                                    <h4>Ok great. Please enter your new <br></br>password below</h4>
                                </span>
                            </div>
                            <div className="form-body">
                                <form id="login-form" className="log-form" name="loginForm">
                                    <div className="error-user">
                                        <div className="form-group floating-label-group">
                                            <input className="form-control"  min="0" id="code" name="code" placeholder=" "
                                                value={this.state.code} autoFocus required
                                                 onChange={this.handleChange} onClick={(e) => analyticsClickEvent('forgotPasswordCode_code','')}/> 
                                                <label className="floating-label">Code</label>                     
                                        </div> 
                                        <p className="error-message code-error">
                                            {this.state.commonError !== '' && this.state.errors.codeError === '' &&
                                            <span style={{ color: 'Red' }}>{this.state.commonError}</span>
                                            }
                                            {this.state.errors.codeError !== '' &&
                                            <span style={{ color: 'Red' }}>{this.state.errors.codeError}</span>
                                            }
                                            {this.state.serverError !== '' && 
                                             <span style={{ color: 'Red' }}>{this.state.serverError }</span>
                                            }
                                            
                                        </p>
                                     
                                    </div>
                                    <div className="error-user psw-error">
                                        <div className="form-group floating-label-group">
                                            <input type="password" className="form-control"id="newPassword" name="newPassword" placeholder=" "
                                                value={this.state.newPassword} 
                                                required onChange={this.handleChange} onClick={(e) => analyticsClickEvent('new_password','')}/>  
                                                <label className="floating-label">New Password</label>
                                        </div>
                                        <p className="error-message code-error">
                                            {this.state.errors.newPasswordError !== '' &&<span style={{ color: 'Red' }}>
                                            {this.state.errors.newPasswordError}</span>}
                                            {this.state.serverMessage!== '' && 
                                             <span style= {{ color: 'green' }}>{this.state.serverMessage }</span>
                                            }
                                           
                                            
                                        </p>
                                    </div>
                                    <div className="form-group">
                                        <input type="submit" className="btn btn-warning form-control" onClick={this.handleSubmit} id="submit" value="Confirm"/>
                                    </div>                    
                                </form>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        )
    }

}

export const mapStateToProps = (state) => {
    return {
        loginStore: state.dataStore.loginStore
    };
   };


 export  const mapDispatchToProps = (dispatch) => {
    return {
        manualLogin: (formData) => {
            return dispatch(manualLogin(formData));
        },
    }
   }
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordCode);
