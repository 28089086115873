import React from "react";
import {manualLogin,emailCheck} from "../../../action/auth/login";
import { Link,Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import SocialMedia from '../register/socialMedia';
import {hasPasswordEmpty} from '../../validation/passwordValidation';
import { analyticsClickEvent, analyticsScreenViewEvent, analyticsTypeEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import parse from 'html-react-parser';
import Cookies from 'universal-cookie';
import { getApplicationImage, getAvatar } from "../../../action/avatar/getAvatar";
export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          email:'',  
          password:'',
          disable:'',
          serverError:'',
          profilePicture:props.profilePicture,
          errors:{
            emailError:'',
            passwordError:'',
          },
          emailSuccess:false,
          inputType:false,
          isLogin:false,
          redirect:false,
          hasEmailMessage:'',
          customPath:'/welcome',
          isEnabled:true,
          loginMessage:'',
      forgotPasswordservermsg: ''
        
          
        };
        
          this.textInput = React.createRef(null);
          this.handleChange = this.handleChange.bind(this);
          this.inputChangeHandler = this.inputChangeHandler.bind(this);
          this.handleSubmit = this.handleSubmit.bind(this);
          this.handleBlur =this.handleBlur.bind(this);
          this.handleFromHistory =this.handleFromHistory.bind(this);
          
      }

      componentDidMount(){
        // analyticsScreenViewEvent()
           window.addEventListener("popstate", this.handleFromHistory);
           localStorage.clear();
     }

     analyticseveeent(e){
      let text = e.target.innerText.replace(' ','_');
      analyticsClickEvent(text,'')
     }

    handleFromHistory(){
      
      if(window.$('#loginModel').hasClass('show') === false){
        window.$('#loginModel').modal('hide');
        window.$('.modal-backdrop').removeClass('show');
        window.$('body').removeClass('modal-open');
        window.$('.modal-backdrop').remove();
      }
    }

    
   

   handleChange(event) {
    this.setState({isEnabled:false})
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value});
    const commonState= this.state;
  // analyticsTypeEvent(name.toLocaleLowerCase())
    if(commonState.serverError){
      
      this.setState({ serverError:null});
    }
     let errors = commonState.errors;
    switch(name){
      case 'email': 
      if (
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
          value
        )
      ) {
        errors.emailError=null;
      }else if (value.trim() === "") {
         errors.emailError = "Email is required";
      }else{
         errors.emailError= "Please enter a valid email";
      }
      break;
      case 'password':
        let result = hasPasswordEmpty(value);
        errors.passwordError=result?result:null;
      break;

      default:
        errors.passwordError=null;
        errors.emailError=null;

    }

    this.setState({errors, [name]: value})

    if(commonState.emailSuccess){
      this.setState({emailError:null});
    }
    if(errors.emailError){
      this.setState({
        emailSuccess:false
      })
      this.setState({isEnabled:true})
    }
setTimeout(() => {
  this.handleBlur(event.target.value);
}, 2000);
    
  }


  async handleBlur(email) {
    const emailValue= email;
    if (/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(emailValue)) {
      const obj={'email':emailValue}
      // setTimeout( async() => {
      const result = await emailCheck(obj);
      console.log('email',result);
      await this.setState({
      errors:{...this.state.errors,emailError:null}
      });
 
      if(result && result.status===400){

        switch (result.data.message) {
          case "This account does not exist with humm":
          case "User need to activate":
          case "User not activated":
            const Emailerrors = { ...this.state.errors, emailError: result.data.message };
            if (this.state.serverError == null || this.state.serverError == '') {
              await this.setState({
                errors: Emailerrors,
                emailSuccess: false
              });
            }
            break;
          case "User already exist":
            const errors = { ...this.state.errors, emailError: null };
            await this.setState({
              errors: errors,
              emailSuccess: true,
              isEnabled: false,
              customPath: "/welcome",
            });
            break;
          case "This is social media login. Click ok to reset the password":
            this.setState({ hasEmailMessage: 'This email has previously been used for a social media login, add a password by resetting' });
            this.setState({ customPath: "/forgot-password" });
            window.$("#loginModel").modal("show");
        }
   
        }
      // },1000);
    }
    }


  redirectForgetPasswordScreen=()=>{
    window.$('#loginModel').modal('hide');
    this.setState({redirect: true});
    this.setState({domainMessage: ''});
  }

  initialValidation(email,password){
    let errors= this.state.errors;
    if(email.trim()===''){
      errors.emailError='Email is required';
      this.setState({errors});
    }

      let data=hasPasswordEmpty(password)
      errors.passwordError=data?data:null;
      this.setState({errors});
  
  }

  getApplicationBannerImage_forLogin = async() => {
    if(localStorage.getItem('fromWhere') !='skip'){
        const ResultOfApplicationImage = await getApplicationImage();
        if (ResultOfApplicationImage && ResultOfApplicationImage.status === 201 && Array.isArray(ResultOfApplicationImage.data.image) && ResultOfApplicationImage.data.image.length !== 0 && ResultOfApplicationImage.data.image[1] != undefined) {
                let appImg = ResultOfApplicationImage.data.image[1];
                localStorage.setItem('applicationImage', appImg);                                   
        }
        else {
            localStorage.setItem('applicationImage', 'images/home-active-contract-bg.png');
        }
    }
  }

  getProfilePic_forLogin = async() => {
    const emailObj={
        email:localStorage.getItem('email')
    }

    const image_Result = await getAvatar(emailObj);
    if(image_Result && image_Result.data.statusCode===200){
      localStorage.setItem('UserName',image_Result.data.data.first_name+' '+image_Result.data.data.last_name);
       if(image_Result.data.data.image_path!==undefined){
          let userPicture ='data:image/jpeg;base64,'+image_Result.data.data.image_path
          this.props.setProfilePic(userPicture);
      }
       else{
          let userPicture= localStorage.getItem("profilePicture");
         if(userPicture!=null){
          this.props.setProfilePic(userPicture);
          }
          if(localStorage.getItem('fromWhere')==='skip'){
              let fallbackImage ='images/profile-avatar.png';

              this.props.setProfilePic(fallbackImage);
          }
       }

    }
  }

  async handleSubmit(event) {
    let text = event.target.defaultValue.replace(' ','');
    analyticsClickEvent(text.toLocaleLowerCase(),'')
    event.preventDefault();
    const obj={
               'email':this.state.email,
               'password':this.state.password
              }
    this.initialValidation(obj.email,obj.password);
  
    if(this.state.errors.passwordError || this.state.errors.emailError){
      return false;
    }
  
    this.setState({disable:'disabled'});
    const result = await this.props.manualLogin(obj);
    console.log('maual', result);
    if (result !== undefined) {
      if (result.status === 200) {
        analyticsClickEvent('login', 'manual_login');
        localStorage.setItem('token', result.data.data.access_token);
        localStorage.setItem('email', this.state.email);
        localStorage.setItem('UserName', result.data.data.user_details.username);
        localStorage.setItem('sessionId', result.data.data.session_id);
        localStorage.setItem('csrfToken', result.data.data.csrf_token);
        this.setState({ customPath: '/welcome' })
        const cookies = new Cookies();
        if(cookies.get('retailerId') != undefined && cookies.get('retailerId') !== "undefined" && cookies.get('retailerId') !== null){
          this.getApplicationBannerImage_forLogin();
          this.getProfilePic_forLogin();
          this.setState({ customPath: '/application' })
        }
        this.setState({ redirect: true });
      } else {
        setTimeout(() => {
          const Emailerrors = { ...this.state.errors, emailError: '' };
          this.setState({
            errors: Emailerrors
          });

        }, 1000);
        this.setState({ serverError: result.data.message })



        this.setState({ disable: '' })
      }
    }
 
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (prevState.profilePicture !== nextProps.profilePicture) {
        return {
          profilePicture: nextProps.profilePicture
        };
      }
      
      return null;
  }

    inputChangeHandler(event){
      analyticsClickEvent('login_password_view_icon','')
        this.setState({
          inputType: !this.state.inputType
          })   
    }

    backgroundLogin=()=>{
        return <div className="col-md-6 col-sm-12 col-xs-12 section-left p-0">
        <div className="leftLogin-img-sec">
            <img src="images/humm-login-bg.jpg" alt="Background" />
        </div>
    </div>;
  }

  skipForNow=()=>{
      localStorage.setItem('fromWhere','skip');
      this.setState({redirect:true});
      analyticsClickEvent('skip_for_now','')
    
  }

  redirectCodeScreen=()=>{
    window.$('#exampleModal').modal('hide');
  }
  
  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.customPath} />;
    }
    return (
      <div className="layout-fixed">
        <div className="wrapper login-page scroll-sec-fix">
          {this.backgroundLogin()}
          <div className="col-md-6 col-sm-12 col-xs-12 section-right p-0">
            <div className="section-form">
              <div className="form-header">
                <span className="section-logo">
                  <img src="images/logo.svg" className="img-fluid" alt="logo" />
                </span>
                <span className="section-heading">
                  <h4>Welcome</h4>
                </span>
              </div>
              <div className="form-body">
                <form id="login-form" className="log-form" name="loginForm" >
                  <div className="error-user">
                    <div className="form-group floating-label-group">
                      <input type="email" className="form-control" id="email" name='email' autoComplete="on" required value={this.state.email} autoFocus placeholder=" " onChange={(e) => this.handleChange(e)} onClick={(e) => analyticsClickEvent('login_email', '')} />
                      <label className="floating-label">Email</label>
                    </div>
                    {/* email message */}
                    <div><p className="error-message">
                      {this.state.errors.emailError && <span style={{ color: 'Red' }}>{this.state.errors.emailError}</span>}


                    </p>
                    </div>
                  </div>
                  <div className="error-user  error-message-Qr">
                    <div className="form-group floating-label-group">
                      <input type={this.state.inputType ? "text" : "password"} className="form-control" id="pwd" autoComplete="on" name='password' value={this.state.password} placeholder=" " required onChange={(e) => this.handleChange(e)} onClick={(e) => analyticsClickEvent('login_password', '')} />
                      <label className="floating-label">Password</label>
                      <div className="input-group-append">
                        {this.state.inputType && (<span className="view-icon" onClick={this.inputChangeHandler}><img src="images/eye-icon-password.svg" alt="logo" /> </span>)}
                        {!this.state.inputType && (<span className="view-icon" onClick={this.inputChangeHandler}> <img src="images/eye-icon-password.svg" alt="logo" /> </span>)}
                      </div>
                    </div>
                    {/* password message */}
                    <div>
                      <p className="error-message">
                        {this.state.errors.passwordError && <span style={{ color: 'Red' }}>{this.state.errors.passwordError}</span>}
                        {this.state.serverError && <span style={{ color: 'Red' }}>{this.state.serverError}</span>}
                      </p>
                    </div>
                  </div>
                  <div className="section-forgot-psw">
                    <Link to='/forgot-password' onClick={(e) => this.analyticseveeent(e)}>
                      <p>Forgot Password</p>
                    </Link>
                  </div>
                  <div className="form-group">
                    <input type="submit" className={`btn btn-warning form-control ${this.state.disable} `} id="submit" value="Log In" onClick={(e) => this.handleSubmit(e)} />
                  </div>
                  <div className="social-accounts">
                    <div className="social-acc-content">
                      <h6>Or log in with ease via social media</h6>
                    </div>
                    <SocialMedia fromWhere={'/login'} />
                  </div>
                  <div className="form-group m-0">
                    <p className="register-link">Don't have an account?  <Link to='/register' onClick={(e) => this.analyticseveeent(e)}>
                      Register Here
                    </Link></p>
                  </div>
                  {/* <div className="form-group m-0">
                    <p className="register-link"><a href="javascript:void(0)" onClick={(e) => { this.skipForNow() }}>
                      Skip for now
                    </a></p>
                  </div> */}
                </form>
              </div>
            </div>
         </div>

        {/* model */}
        <div className="modal fade model-error" id="loginModel" tabIndex="-1" role="dialog" aria-labelledby="loginModelLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="logo">
                  <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                </div>
                <h5 className="modal-title " id="loginModelLabel">Message</h5>
                <p className="text-center mt-4">{this.state.hasEmailMessage}</p>
              </div>
              <div className="modal-footer mb-4">
                <button type="button" className="btn btn-primary" onClick={() => this.redirectForgetPasswordScreen()}>Ok</button>
              </div>
            </div>
          </div>
        </div>
        {/* invalid password message */}
        <div className="modal fade model-error" id="serverErrorShowModel" tabIndex="-1" role="dialog" data-keyboard="false" aria-labelledby="serverErrorShowModellLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div class="modal-header">
              </div>
              <div className="modal-body">
                <div class="logo">
                  <img src="images/modal-logo.svg" alt="logo" class="img-fluid" />
                </div>
                {/* <h5 className="modal-title " id="serverErrorShowModelLabel">Message</h5> */}

                <p className="text-center mt-4">{parse(this.state.forgotPasswordservermsg)}</p>
              </div>

              <div className="modal-footer mb-4">
                {/* <button type="button" className="btn btn-primary" data-dismiss="modal">Ok</button> */}
              </div>


            </div>
          </div>
        </div>
      </div>
      </div>

    );
  }
}

  export const mapStateToProps = (state) => {
     return {
         loginStore: state.dataStore.loginStore,
         profilePicture:state.dataStore.profilePicture
     };
    };


  export const mapDispatchToProps = (dispatch) => {
     return {
         manualLogin: (formData) => {
             return dispatch(manualLogin(formData));
         },
         setProfilePic:(userPic)=>{
          return dispatch({
              type: 'SET_PROFILE_PIC',
              value: userPic
          });
      }
     }
    }
export default connect(mapStateToProps, mapDispatchToProps)(Login);