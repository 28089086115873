import React, { useMemo } from "react";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./paymentForm";
import setupIntent from "./setupIntent";
import { connect } from "react-redux";
import { getAllUserNotification } from "../../action/notification/notification";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPEAPIKEY);

export class Addcard extends React.Component {
    constructor(props) {
        super(props);
        this.searchCall = React.createRef();
        this.useMemo = useMemo;
        this.useStripe = useStripe;
        this.useElements = useElements;
        this.state = {
            stripe: true,
            elements: true,
            useOption: '',
            cardHolderIsValid:true
        }

        // this.SplitForm = this.SplitForm.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
        // this.useOptions = this.useOptions.bind(this);

    }

   



    callCardProcessSucess=async (data)=>{
        this.props.paymentfunctionCalls();
        if(data === 200){
            this.setState({addCardSatus:true})
                let notificationData = [];
                const objTemp = { email: localStorage.getItem('email') };
        
                const notification = await getAllUserNotification(objTemp);
                console.log(notification);
                if (notification !== undefined) {
                    if (notification.data.statusCode === 200) {
                        notification.data.data.map((data) => {
                            
                                notificationData.push(data);
                            
                        });
        console.log(notificationData);
                        this.props.notificationData(notificationData)
            
        }
    }
}
    }

    
    cardHolderCheckValidation = () => {
        this.props.cardHolderCheckvalidation();
    }

    cardHolderIsvalid = () => {
        console.log(this.props);
        this.setState({cardHolderIsValid: this.props.isValidCardHolder});
    }


    render() {        
        return (
            // <h1>Add CArd</h1>
            <Elements stripe={stripePromise} options={{
                fonts: [
                  {
                    src: "url(https://db.onlinewebfonts.com/t/0376a58122a881d16a294512d3c947b1.ttf)",
                    family: "ProximaNova-Regular",
                    style: "normal",
                    weight:'500'
                  },
                ],
              }}
               
                >
            
                <div className="DemoWrapper">
                    <div className="DemoPickerWrapper">
                        <div className="Demo">
                            {/* <setupIntent></setupIntent> */}
                            <PaymentForm callCardProcessSucess={this.callCardProcessSucess} cardHolderCheckValidation={this.cardHolderCheckValidation} isCheckcardHolderValid={this.props.isValidCardHolder} cardHolderName={this.props.cardHolderName}></PaymentForm>
                        </div>
                    </div>
                </div>
            </Elements>

        );
    }
}


export const mapStateToProps = (state) => {
    return {
        notificationDatas: state.dataStore.notificationDatas
    };
  };

export const mapDispatchToProps = (dispatch) => {
    return {
        notificationData: (data) => {
            return dispatch({
              type: 'NOTIFICATIONDATA',
              value: data
            });
          },
    }

}





export default connect(mapStateToProps,mapDispatchToProps)(Addcard);