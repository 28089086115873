import axios from 'axios';
import config from '../config';
import {sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

export const forgotPassword = async (formData) => {
    let url = config.Humm_Dev_Link+'user/forgot-password'

   return axios.post(url, formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err)
        return err.response
    })


};