import React from 'react'

export class RunningText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (  
            <div className="col-md-12 col-12">
            <div className="form-group"> 
            <h5> {this.props.field_label}</h5>
            </div>
            </div>
        );
    }
}
 
export default RunningText;