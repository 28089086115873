import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

const headers = {
    'session_id': localStorage.getItem('sessionId'),
  }
export const getIndustriesLocation = async () => {
    let url = config.Humm_Dev_Link+'partner/Location-list'

   return axios.get(url,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,'',err);
        if(err.response){
        handlingSession(err.response.status);
        }
        return err.response
    })
};
