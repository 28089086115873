import React from 'react';
import { Link } from 'react-router-dom';
import Loader from '../loader/loader';
import { Modal, Button } from 'react-bootstrap';
import { applicationSave } from "../../action/applicationSave/applicationSave";
import { formatAmount } from '../../utils/const';
import CurrencyFormat from 'react-currency-format';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';


class QuoteCalculatorResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
           partnerName:'',
           partnerId:'',
           repaymentPlan:'',
           hasRepaymentPlanLength:'',
           balance:'',
           hasAmount:'',
           hasPlanValue:'',
           hasAPR:'',
           EMISchedule:'',
           EMISchedules:"",
           hasLimit:2,
           EMIIndexSize:'',
           LT_limit:'',
           isBigThings:false,
           isFlinks:false,
           isConfirmAmount:false ,
           isDecline:false,
           isStep:'',
           partnerImage:'',
           hasHideSeeMore:true,
           resetLimit:2,
           loading:false,
           lt_balance:"",
           isErrorModal:false,
           NoRetailerMessage:'',
           defaultModalMessage:'',
          isDefaultModal:false,
          bt_balance:"",
          ErrorMessage:"",
          amount:"",
          displayData: "",
          EMISchedulesDetails:{
            isUpFrontPayment:'',
            setup_fee:'',
            upfront:'',
            isRegulatedPlan: false, 
            offerName:'',  
            bearing_instalment_amount: '', 
            bearing_installmentPeriod: '',
            interestFree_installmentAmount: '',
            interestFree_installmentPeriod: '',
            hasInterestFreeInstalments: false,
            Total_upfront:'',
            frequency:'',
            instalment_amount:'',
            Monthly_fee:'',
            Total_amount_payable:'',
            Interest_rate:'',
            Apr:'',
            total_interest:'',
            total_fees:'',
            installmentPeriod:''
          },
          frequency:"",
          SelectedPlan:"",
          Selectplans:"",
          showplan:false,
          Selectedfrequency:'',
          res:"",
          tile_Lt_Limit:'',
          tile_Bt_Limit:'',
          isLastPaymentStatus:false

         }
        this.quoteToolAPI=this.quoteToolAPI.bind(this)
        this.handlePlanChange = this.handlePlanChange.bind(this);
        this.scheduleRender =this.scheduleRender.bind(this);
        this.goBack = this.goBack.bind(this);

        this.handleOnChange =this.handleOnChange.bind(this)
        this.handleOnSubmit =this.handleOnSubmit.bind(this);
        this.handleFlinksUkSubmit =this.handleFlinksUkSubmit.bind(this);
        this.SelectPlan=this.SelectPlan.bind(this)
    }



  // application flinks status
    frequentCallSetInterval = () => {
      // start timer after button is clicked
      this.interval = setInterval(() => {
        this.getFlinksApplicationStatus();
      }, 5000);
    };

    componentWillUnmount() {
      clearInterval(this.interval);
    }
  

  
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
    openBigThings =()=>
  {
    this.setState({isBigThings:true});
    // console.log("Limit tile amount---->",this.state.LT_limit)
  }
  closeBigThings =()=>
  {
    this.setState({isBigThings:false})
    this.setState({displayData:""})
    this.setState({ ErrorMessage: '' });
  }
  ;
  openFlinksModel =()=>this.setState({isFlinks:true});
  closeFlinksModel =()=>this.setState({isFlinks:false});
  openConfirmAmountModel =()=>this.setState({isConfirmAmount:true});
  closeConfirmAmountModel =()=>this.setState({isConfirmAmount:false});
  openDeclineModel=()=>this.setState({isDecline:true});
  closeDeclineModel=()=>this.setState({isDecline:false});
  openDefaultModal=()=>this.setState({isDefaultModal:true})
  closeDefaultModal=()=>this.setState({isDefaultModal:false})
  closeErrorModel=()=>{
    this.setState({isErrorModal:false})
  }
 
  OnHandleRedirect=(step)=>{
    console.log(step)
  }

  SelectPlan = async (plan) => {
      await this.setState({Selectplans:plan})
      console.log(plan)
  }

  async handleOnChange(event) {
    let { formattedValue, value } = event;
    if (value <= 30000) {
      console.log(`PostCheck Value: ${value},DisplayValue: ${formattedValue}`);
      if (parseFloat(value) != 0) {
        await this.setState({
          amount: value,
          ErrorMessage: "",
          displayData: formattedValue,
        });
      } else {
        this.setState({displayData:""})
      }
      if (
        this.state.amount <=
        Number(
          this.getLimit()
        )
      ) {
        console.log(
          typeof Number(
            this.getBalance()
          )
        );
        this.setState({
          ErrorMessage: `Requested amount should be more than £${formatAmount(
            this.getLimit()
          )} .`,
        });
      }
    } else {
      return false;
    }
    if (value.length == 0 && value == "") {
      this.setState({ ErrorMessage: "Amount is required." });
    }
  }

  getBalance = () => {
    if(this.state.bt_balance >= 1){
      return this.state.bt_balance
    }
    else {
      return this.state.lt_balance
    }
  }

  getLimit = () => {
    if( this.state.tile_Bt_Limit >= 1){
      return this.state.tile_Bt_Limit
    }
    else {
      return this.state.tile_Lt_Limit
    }
  }


async handleResponse(applicationSaveResponse){
  console.log('in app',applicationSaveResponse)
  if(applicationSaveResponse.data.statusCode===202){
    console.log('inside',applicationSaveResponse.data.data.lT_Limit);
    console.log('else in ');
   
    this.setState({application_id:applicationSaveResponse.data.data.application_id})
    this.setState({confirmAmountLT:applicationSaveResponse.data.data.lT_Limit,confirmAmountBT:applicationSaveResponse.data.data.bT_Limit});
    this.closeBigThings()
    this.setState({loading:false})
    this.openConfirmAmountModel()
  } 
  else if(applicationSaveResponse.data.statusCode === 417) {  
    this.closeBigThings()
    this.setState({loading:false})
    this.openDeclineModel()
  }else if(applicationSaveResponse.data.statusCode === 201){

    if(applicationSaveResponse.data.data.nextStep==="RETRY_ID"){
      this.closeBigThings()
      this.setState({loading:false})
      this.openDeclineModel()
    }

   if(applicationSaveResponse.data.data.nextStep==="BSO"){
    console.log('in BSO',applicationSaveResponse);
    const ltLimit= applicationSaveResponse.data.data.lT_Limit;
    const btLimit= applicationSaveResponse.data.data.bT_Limit;
    this.setState({application_id:applicationSaveResponse.data.data.application_id,confirmAmountLT:ltLimit,confirmAmountBT:btLimit});
    this.closeBigThings()
    await this.openFlinksModel()
    setTimeout(() => {
      this.setState({loading:false})
    }, 1000);
    
  }

  }
  else{
    console.log("Message---->",applicationSaveResponse.data.message)
            if(applicationSaveResponse.data.message){
                this.setState({defaultModalMessage:applicationSaveResponse.data.message})
            }
            else{
                this.setState({defaultModalMessage:"Please try again later"})
            }
    this.closeBigThings()
    this.openDefaultModal()
    this.setState({loading:false})
    }
  

}


BTadditionalDetailsFormat=()=>{
  return {
    "genesis__Loan_Amount__c": this.state.amount,
    "additionalDetails" : {
      "productCode" : "BT",
      "applicationType": "limitIncrease",
      "secureBankToken" :  localStorage.getItem('sessionId')
      } 
    }
  }

async handleOnSubmit (){
  this.setState({loading:true})
  const BigThingsDetails= this.BTadditionalDetailsFormat();
  const response = await applicationSave(BigThingsDetails);
  if(response){
      console.log('finding',response);
      console.log("application_id:",response.data.data.application_id)
      this.handleResponse(response)
   }
}

setLoginId=(value)=>{
  if(value){
    this.setState({bsoToken:value.loginId});
    this.setState({isStep:value.step})
  }
}

setAccountId=(value)=>{
console.log('flinks-account',value);
}

  async handleFlinksUkSubmit(){
    this.setState({loading:true})
    this.closeFlinksModel()
    const bsoValue ={
      "application_id":this.state.application_id,
      "bsoToken":this.state.bsoToken
    };
    console.log("BSO post data --->",bsoValue)
    
  }




    async componentDidMount(){
        const result = this.props.location.state;
        console.log(this.props.location.state.partner.store_name, "...route..");
        console.log("Result",result)
        await this.setState({partnerName:result.partner.store_name,partnerId:result.partner.id,balance:result.balanceDetails,hasAmount:result.requestAmount,res:result.result});
        this.setState({loading:true})
        this.quoteToolAPI()  
      }
      
      onChangeOptionHandle(e){
        this.setState({hasOptionSelect:e.target.value});
      }
      
      async quoteToolAPI(){      
          console.log(this.state.res.data.pca)
                     
          if(this.state.res){
            const res = this.state.res            
              const overAllIndex= res.data.pca.length
              this.setState({
               EMISchedulesDetails:{
               isUpFrontPayment:res.data.pca[0].isUpFrontPayment,
               setup_fee:res.data.pca[0].setUpFee,
               upfront:res.data.pca[0].upfrontInstallment,
               isRegulatedPlan: res.data.pca[0].isRegulated, 
               offerName: res.data.pca[0].planName, 
               bearing_instalment_amount: res.data.pca[0].remainingInstalmentAmount, 
               bearing_installmentPeriod: res.data.pca[0].remainingInstalmentTimes, 
               interestFree_installmentAmount: res.data.pca[0].interestFreeInstalmentAmount, 
               interestFree_installmentPeriod: res.data.pca[0].interestFreeInstalmentTimes, 
               hasInterestFreeInstalments: res.data.pca[0].hasInterestFreeInstalments,
               Total_upfront:res.data.pca[0].totalUpfrontInstallment,
               frequency:res.data.pca[0].frequency,
               instalment_amount:res.data.pca[0].installmentAmount,
               Monthly_fee:res.data.pca[0].monthlyFee,
               Total_amount_payable:res.data.pca[0].totalAmountPayable,
               Interest_rate:res.data.pca[0].interestRate,
               Apr:res.data.pca[0].apr,
               total_interest:res.data.pca[0].totalInterest,
               total_fees:res.data.pca[0].totalFees,
               installmentPeriod:res.data.pca[0].installmentPeriod,
             },
               EMIIndexSize:overAllIndex,
               EMISchedules:res.data.pca,
               frequency:res.data.pca,
              })
            this.setState({
              SelectedPlan:{  
                isUpFrontPayment:res.data.pca[0].isUpFrontPayment,
                setUpFee:res.data.pca[0].setUpFee,
                upfrontInstallment:res.data.pca[0].upfrontInstallment,
                isRegulated: res.data.pca[0].isRegulated, 
                planName: res.data.pca[0].planName, 
                remainingInstalmentAmount: res.data.pca[0].remainingInstalmentAmount, 
                remainingInstalmentTimes: res.data.pca[0].remainingInstalmentTimes, 
                interestFreeInstalmentAmount: res.data.pca[0].interestFreeInstalmentAmount, 
                interestFreeInstalmentTimes: res.data.pca[0].interestFreeInstalmentTimes, 
                hasInterestFreeInstalments: res.data.pca[0].hasInterestFreeInstalments,
                totalUpfrontInstallment:res.data.pca[0].totalUpfrontInstallment,
                frequency:res.data.pca[0].frequency,
                installmentAmount:res.data.pca[0].installmentAmount,
                monthlyFee:res.data.pca[0].monthlyFee,
                totalAmountPayable:res.data.pca[0].totalAmountPayable,
                interestRate:res.data.pca[0].interestRate,
                apr:res.data.pca[0].apr,
                totalInterest:res.data.pca[0].totalInterest,
                totalFees:res.data.pca[0].totalFees,
                installmentPeriod:res.data.pca[0].installmentPeriod,                
              }
            })
           this.setState({loading:false})
          }
          else {
            return false
          }   
  }

     async handlePlanChange(e){
        await this.setState({hasPlanValue:e.target.value});
        console.log("default value",this.state.hasPlanValue)
            this.selectEMIPlans();
            this.setState({hasLimit:2,hasHideSeeMore:true})
        }
    

    selectEMIPlans=async(src)=>{
      console.log('emi',this.state.EMISchedules);

       let schedulePaymentDetails = this.state.EMISchedules.filter(e =>{
         
         return  e.plan_id.includes(src.plan_id)
       }
        );
      await this.setState({
        SelectedPlan:schedulePaymentDetails[0],Selectedfrequency:src.planName 
      })
      console.log('final -->',this.state.SelectedPlan);     
    }

handleIncreaseLimit = () =>{ 
  return false

}


scheduleRender(){

    return (
        <>


        <ul className="pay-client-listitem">
            {console.log(this.state.EMISchedule, "...Emi_schedule")}            
                                            
        </ul>
        {this.state.EMISchedule && this.state.hasHideSeeMore && this.state.EMIIndexSize>3 &&
        <div style={{textAlign:'center',maxWidth:'322px'}}>
        <button className="seemore-btn" 
         onClick={(_e)=>{this.handleSeeMore(this.state.EMIIndexSize)}}
        >See More</button>
        </div>  }


        {this.state.EMISchedule && !this.state.hasHideSeeMore &&
        <div style={{textAlign:'center',maxWidth:'322px'}}>
        <button className="seemore-btn" 
         onClick={this.handleSeeLess}
        >See Less </button>
        </div>  }
        </>
        )
    
}

handleSeeLess =()=>{
    this.setState({hasLimit:2,hasHideSeeMore:true});

}

handleSeeMore = (value)=>{
    console.log('see more',value)
    this.setState({hasLimit:value,hasHideSeeMore:false})
}
goBack(){
  analyticsClickEvent('quote_back','');
  this.props.history.push({
    pathname: "/quote-calculator",
    state: {
      partner_name: this.state.partnerName
    },
  });
}

checkHeaderRoute() {
    let temporaryHeaderdata = this.props.location.state.routeHeader;
    let resultWebview = temporaryHeaderdata.slice(temporaryHeaderdata.indexOf('ew') +3); 
    let resultRetailerId = temporaryHeaderdata.slice(temporaryHeaderdata.indexOf('re') +3, temporaryHeaderdata.indexOf('&'));  
    console.log("storeId: ",resultRetailerId, "...webview:", resultWebview)
    if(resultWebview !== null && resultWebview !== undefined && resultWebview !== '' && resultWebview === 'true'){
        localStorage.setItem('webView', true);
    }
}

  getClassNames(stateValue) {
    if(stateValue){
        return "main mt-2"
    }
    else {
      return "main"
    }
  }

  getEachStateValues = (checkStateValue) => {
        return formatAmount(checkStateValue)
  }

  formatAprValues = (checkAprValue) => {
    if(checkAprValue > 0){
      return formatAmount(checkAprValue);
    }
    else {
      return checkAprValue + ".00";
    }

  }

  formatUpfrontValues = (checkUpfrontValue) => {
    if(typeof checkUpfrontValue === "number"){
      return formatAmount(checkUpfrontValue.toString())
    }
    else {
      return formatAmount(checkUpfrontValue)
    }
  }

  getReturnToShopLink(){
    if (localStorage.getItem('sessionId') === null) {
      if(localStorage.getItem('mobile')) {
        return <Link class="return-to-shop-text" to="/login" onClick={ evt => evt.preventDefault()}>
          <span class="limit-text-para">Return to shop</span>
        </Link>
      }
      else if (localStorage.getItem('fromWhere') === 'skip') {
        return <Link class="return-to-shop-text" to="/shop" 
            onClick={ () => analyticsClickEvent('return_to_shop','') }>
                <span class="limit-text-para">Return to shop</span>
            </Link>
      }
      else {
        return <Link class="return-to-shop-text" to="/login" >
          <span class="limit-text-para">Return to shop</span>
        </Link>
      }
    }
  }

  getCalculateAgainLink() {
    if(localStorage.getItem('sessionId') === null){
      if(localStorage.getItem('mobile')) {
        return (<Link class="quote-apply-now" to="/login" onClick={ evt => evt.preventDefault()}>
          Apply Now
        </Link>);
      }
      else if(localStorage.getItem('fromWhere') === 'skip'){
        return (
          <Link class="quote-apply-now" to="/application" 
          onClick={ () => analyticsClickEvent('quote-tool-apply-now','')}>
            Apply Now
          </Link>
        );
      }
      else {
        return (
          <Link class="quote-apply-now" to="/login" >Apply Now</Link>
        );
      }
    }
  }
   

    render() { 
        this.checkHeaderRoute();
        return ( 
            <div className="wrapper"> 
            <Loader loaderStatus={this.state.loading}/>
         
            {/* search nav */}
            <section className={ this.getClassNames(localStorage.getItem('webView')) }>
                { localStorage.getItem('webView') ? ' ' :
                <div className="search-fav-section">
                    <div className="container">
                        <div className="d-flex align-items-center top-section-partner">
                            <div className="left-iteQerd">
                                <div className="back-btn-item">
                                    <a className="back-btnQr" onClick={this.goBack}><img src="images/back.svg" alt="<-"/> Back </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                }
            </section> 
            <section className="section py-3">
                <div className="container">
                    <div className="tab-content" id="myTabContent">
                     
                        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">                        
                            <div className="Qc-section">
                                {localStorage.getItem('webView') ? ' ' : <h3 className="Qc-heading-text">Quote Calculator</h3>}
                                <p>Please note, late fees apply.</p>
                            </div>
                            <div className="qc-price-schedule">
                                <div className="store-price-item">
                                    <div className="price-div-section"> 
                                        <p>Retailer</p>
                                        <h3>{this.state.partnerName}</h3>
                                    </div>
                                    <div className="price-div-section">
                                        <p>Quote Amount</p>
                                        <h3>£{formatAmount(this.state.hasAmount)}</h3>
                                    </div>
                                    <div className="price-div-section payment-method-section" style={{padding:0,margin:0}}>
                                        <p>Offer Name</p>                                         
                                        
                                          {console.log("size",this.state.EMIIndexSize)}
                                          {this.state.EMIIndexSize>1 && this.state.frequency!=""&&
                                          
                                          <div className="drop-down">
                                          <div className="selected-item" onClick={()=>{ this.setState(prevState => ({
                                          showplan: !prevState.showplan
                                          }));}}>
                                           <button className="select--btn-item">
                                             <span>{this.state.Selectedfrequency?this.state.Selectedfrequency:this.state.frequency[0].planName}</span>
                                           </button>
                                           <span><img src={`${this.state.showplan?"images/up-arrow.png":"images/down-arrow.png"}`} alt="down-arrow"/></span>
                                          <div className={`options ${this.state.showplan?"show":""}`}>
                                          <ul> 
                                             
                                            {this.state.frequency.map((src)=>(
                                              
                                              <li key={src.plan_id} 
                                              onClick={()=>{this.selectEMIPlans(src)}} 
                                              >{src.planName}</li>
                                            )
                                            )}
                                          </ul>
                                          </div>
                                          </div>
                                          </div>
                                     
                                          }

                                        {this.state.EMIIndexSize==1 &&
                                          <h3>{this.state.frequency[0].planName}</h3>
                                        }

                                    </div>

                                    <div className="price-div-section">
                                            {/* <div className="form-group">
                                                <label>Term</label>
                                                <input type="text" className="form-control"  name="keyword"
                                                 value={this.state.searchValue} 
                                                 placeholder="12 Months" 
                                                 autoComplete="off"
                                                 onChange={(e)=>this.onHandleChange(e)}/>
                                            </div> */}

                                   

                                             <div className="search-result-item">
                                                <div className={`search-body ${this.state.hasDisplay?'show-option':'hide-option'}`} >
                                                    <ul className="m-0 p-0">
                                                        {this.state.searchValue &&
                                                         this.state.searchResult.map(list =>
                                                        <li className="search-list" key={list.id} 
                                                        onClick={(_e)=>this.suggestionSelected(list)}>{list.store_name}</li>
                                                         )
                                                         
                                                        }
                                                       
                                                        
                                                    </ul>
                                                </div>
                                                        <p className="error-message card-holder-error">{this.state.storeError}</p>
                                            </div>
                                            </div>
                                             
                                    {/* <div className="price-div-section">
                                        <p>APR</p> */}
                                        {/* <h3>{this.state.hasAPR}%</h3> */}
                                    {/* </div> */}
                                    {/* <div className="price-div-section">                                    
                                        <div className="form-group">
                                            <label>Plan</label>
                                            {this.state.hasRepaymentPlanLength>1 && this.state.hasRepaymentPlanLength!=""&&
                                            <select class="form-control"  name="selectedPlan" value={this.state.hasPlanValue} 
                                             onChange={(e)=>{this.handlePlanChange(e)}}>      
                                                 {/* <option value=''>Select</option> */}                                                

                                            {/* </select>
                                          }
                                            {this.state.hasRepaymentPlanLength==1 && 
                                             <h3>{this.state.repaymentPlan.[0].frequency_plan}</h3>
                                              } */}

                                          
                                        {/* </div>
                                    </div> */}
                                </div>

                                    {/* Single plan logic */}
                                
                                {this.state.EMISchedules.length==1 && 
                                <div className="store-payment-details">
                                  { (this.state.EMISchedulesDetails.isUpFrontPayment|| !this.state.EMISchedulesDetails.isUpFrontPayment) &&
                                  <div className="payments-col">
                                    <div className="row">
                                      <label className='header-title'>Upfront Payment</label>
                                    </div>
                                    <div className="row">
                                      <div className="payments-section-title">Setup Fee</div>
                                      <div className="payments-section-value">£{formatAmount(this.state.EMISchedulesDetails.setup_fee)}</div>
                                    </div>
                                    <div className="row">
                                      <div className="payments-section-title">Upfront Instalment</div>
                                      <div className="payments-section-value">£{this.formatUpfrontValues(this.state.EMISchedulesDetails.upfront)}</div>
                                    </div>
                                    <div className="row row-total row-bold">
                                      <div className="payments-section-title">Total Upfront Payment</div>
                                      <div className="payments-section-value">£{this.formatUpfrontValues(this.state.EMISchedulesDetails.Total_upfront)}</div>
                                    </div>
                                  </div>
                                  }

                                  <div className="payments-col">
                                    <div className="row">
                                      <label className='header-title'>Instalment Payments</label>
                                    </div>
                                    <div className="row">
                                      <div className="payments-section-title">Frequency</div>
                                      <div className="payments-section-value">{this.state.EMISchedulesDetails.frequency}</div>
                                    </div>
                                    
                                    {/* Displaying Interest Free Instalment Amount and interest Bearing Amount */}
                                    <div className="row">
                                      <div className="payments-section-title">Interest Free Instalments</div>
                                      <div className="payments-section-value">
                                      £{this.state.EMISchedulesDetails.interestFree_installmentAmount!=="0"&&
                                        this.formatUpfrontValues(this.state.EMISchedulesDetails.interestFree_installmentAmount) + " x " + this.state.EMISchedulesDetails.interestFree_installmentPeriod
                                      }                                      
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="payments-section-title">Interest Bearing Instalments</div>
                                      <div className="payments-section-value">
                                      £{this.state.EMISchedulesDetails.bearing_instalment_amount!=="0"&&
                                        this.formatUpfrontValues(this.state.EMISchedulesDetails.bearing_instalment_amount) + " x " + this.state.EMISchedulesDetails.bearing_installmentPeriod
                                      }
                                      </div>
                                    </div>
                                    {/* Displaying monthly Account Fee */}
                                    <div className="row">
                                      <div className="payments-section-title">Account Fee</div>
                                      <div className="payments-section-value">
                                      £{this.state.EMISchedulesDetails.Monthly_fee!=="0"&&
                                        formatAmount(this.state.EMISchedulesDetails.Monthly_fee) + " x " + this.state.EMISchedulesDetails.installmentPeriod
                                      }
                                      {this.state.EMISchedulesDetails.Monthly_fee === "0" && formatAmount(this.state.EMISchedulesDetails.Monthly_fee)}
                                       </div>
                                    </div>
                                    <div className="row row-total row-bold">
                                      <div className="payments-section-title">Total Amount Payable*</div>
                                      <div className="payments-section-value">£{formatAmount(this.state.EMISchedulesDetails.Total_amount_payable)}</div>
                                    </div>
                                  </div>
                                  

                                  <div className="payments-col">
                                    <div className="row">
                                      <label className='header-title'>Instalment Payments</label>
                                    </div>
                                    <div className="row">
                                      <div className="payments-section-title">Interest Rate</div>
                                      <div className="payments-section-value">{this.formatAprValues(this.state.EMISchedulesDetails.Interest_rate)}%</div>
                                    </div>
                                    <div className="row">
                                      <div className="payments-section-title">APR</div>
                                      <div className="payments-section-value">{this.formatAprValues(this.state.EMISchedulesDetails.Apr)}%</div>
                                    </div>
                                    <div className="row row-total row-bold">
                                      <div className="payments-section-title">* includes total interest</div>
                                      <div className="payments-section-value">£{formatAmount(this.state.EMISchedulesDetails.total_interest)}</div>
                                    </div>
                                    <div className="row row-bold">
                                      <div className="payments-section-title">* includes total fees</div>
                                      <div className="payments-section-value">£{formatAmount(this.state.EMISchedulesDetails.total_fees)}</div>
                                    </div>
                                  </div>
                                </div>  
                                }

                                {/* multiple plan logic */}                                
                                {this.state.EMISchedules.length>1 && this.state.EMISchedules!="" && 
                                <div className="store-payment-details">
                                  
                                {console.log("isUpfront",this.state.EMISchedulesDetails.isUpFrontPayment)}
                                {console.log("isUpfront",this.state.SelectedPlan.isUpFrontPayment)}
                                {console.log("final",this.state.SelectedPlan.isUpFrontPayment==undefined ?
                                 this.state.EMISchedulesDetails.isUpFrontPayment:
                                 this.state.SelectedPlan.isUpFrontPayment)}
                               
                               {console.log(this.state.SelectedPlan)}
                                { (this.state.SelectedPlan.isUpFrontPayment || !this.state.SelectedPlan.isUpFrontPayment) && 
                                <div className="payments-col">
                                  <div className="row">
                                    <label className='header-title'>Upfront Payment</label>
                                  </div>
                                  <div className="row">
                                    <div className="payments-section-title">Setup Fee</div>
                                    <div className="payments-section-value">£{
                                    this.getEachStateValues(this.state.SelectedPlan.setUpFee)
                                    }</div>
                                  </div>
                                  <div className="row">
                                    <div className="payments-section-title">Upfront Instalment</div>
                                   
                                    <div className="payments-section-value">£{                                    
                                    this.formatUpfrontValues(this.state.SelectedPlan.upfrontInstallment)                                    
                                    }</div>
                                  </div>
                                  <div className="row row-total row-bold">
                                    <div className="payments-section-title">Total Upfront Payment</div>
                                    <div className="payments-section-value">£{
                                    this.formatUpfrontValues(this.state.SelectedPlan.totalUpfrontInstallment)
                                    }</div>
                                  </div>
                                </div>
                                }
                                

                                <div className="payments-col">
                                  <div className="row">
                                    <label className='header-title'>Instalment Payments</label>
                                  </div>
                                  <div className="row">
                                    <div className="payments-section-title">Frequency</div>
                                    <div className="payments-section-value">{this.state.SelectedPlan.frequency}</div>
                                  </div>
                                  
                                  {/* Displaying Interest Free Instalment Amount and interest Bearing Amount */}
                                  <div className="row">
                                    <div className="payments-section-title">Interest Free Instalments</div>
                                    <div className="payments-section-value">£{                                    
                                    this.state.SelectedPlan.interestFreeInstalmentAmount!=="0" && 
                                    this.formatUpfrontValues(this.state.SelectedPlan.interestFreeInstalmentAmount) +" x "+this.state.SelectedPlan.interestFreeInstalmentTimes                                    
                                    }                                    
     

                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="payments-section-title">Interest Bearing Instalments</div>
                                    <div className="payments-section-value">£{                                    
                                    this.state.SelectedPlan.remainingInstalmentAmount!=="0" && 
                                    this.formatUpfrontValues(this.state.SelectedPlan.remainingInstalmentAmount) +" x "+this.state.SelectedPlan.remainingInstalmentTimes                                    
                                    }
     

                                    </div>
                                  </div>
                                  {/* Displaying monthly Account Fee */}                                  
                                  
                                  <div className="row">
                                    <div className="payments-section-title">Account Fee</div>
                                    <div className="payments-section-value">£
                                    {
                                    this.state.SelectedPlan.monthlyFee!=="0" && 
                                    formatAmount(this.state.SelectedPlan.monthlyFee) +" x "+this.state.SelectedPlan.installmentPeriod                                    
                                    }

                                    {
                                    this.state.SelectedPlan.monthlyFee==="0" && 
                                    formatAmount(this.state.SelectedPlan.monthlyFee)                                    
                                    }

                                    </div>
                                  </div>
                                  <div className="row row-total row-bold">
                                    <div className="payments-section-title">Total Amount Payable*</div>
                                    <div className="payments-section-value">£{
                                    this.getEachStateValues(this.state.SelectedPlan.totalAmountPayable)                                    
                                    }</div>
                                  </div>
                                </div>
                                

                                <div className="payments-col">
                                  <div className="row">
                                    <label className='header-title'>Instalment Payments</label>
                                  </div>
                                  <div className="row">
                                    <div className="payments-section-title">Interest Rate</div>
                                    <div className="payments-section-value">{this.formatAprValues(this.state.SelectedPlan.interestRate)}%</div>
                                  </div>
                                  <div className="row">
                                    <div className="payments-section-title">APR</div>                                    
                                    <div className="payments-section-value">{this.formatAprValues(this.state.SelectedPlan.apr)}%</div>
                                  </div>
                                  <div className="row row-total row-bold">
                                    <div className="payments-section-title">* includes total interest</div>
                                    <div className="payments-section-value">£{formatAmount(this.state.SelectedPlan.totalInterest)}</div>
                                  </div>
                                  <div className="row row-bold">
                                    <div className="payments-section-title">* includes total fees</div>
                                    <div className="payments-section-value">£{
                                    this.getEachStateValues(this.state.SelectedPlan.totalFees)                                    
                                    }</div>
                                  </div>
                                </div>
                              </div>  
                                }
                            
                                

                                <div className="store-payment-limit">                                
                                  { 
                                  !this.handleIncreaseLimit() && 
                                      <>
                                        <div className="limit-section">
                                        {localStorage.getItem('sessionId') !== null &&
                                        <Link class="quote-apply-now" to="/application"
                                        onClick={ () => analyticsClickEvent('quote-tool-apply-now','')}
                                        >Apply Now</Link> 
                                        }
                                        {this.getCalculateAgainLink()}                                                                    
                                        </div>
                                        <div className='row'>
                                        <div className='col-md-6 col-6'>
                                          <Link className="calculate-again-text" 
                                          to={{
                                            pathname: '/quote-calculator',
                                            search: '?store='+this.state.partnerId
                                          }}
                                          onClick={() => {                                            
                                            analyticsClickEvent('calculate_again','')
                                          }}
                                           > 
                                          <span className='limit-text-para'>Calculate again </span>
                                          </Link>
                                        </div>
                                        <div className='col-md-6 col-6'>
                                        {localStorage.getItem('sessionId') != null &&
                                        <Link class="return-to-shop-text" to="/shop" 
                                        onClick={ () => analyticsClickEvent('return_to_shop','') }>
                                            <span class="limit-text-para">Return to shop</span>
                                        </Link>
                                        }
                                        {this.getReturnToShopLink()}
                                        </div>
                                        </div>    
                                      </>                              
                                 }                                                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='Quote-footer mx-5 mt-4 mb-2'>
                    <p className='mx-auto mb-0'>
                      <span style={{fontWeight: "600",display: "inline",color: "#212529"}}>
                      humm</span> is a trading style of Humm Group Limited which is authorised and regulated by the Financial Conduct Authority. Financial Services Registration Number 954478. <span style={{fontWeight: "600",display: "inline",color: "#212529"}}>
                      humm</span> offers both regulated and unregulated products. 
                    Our interest free plans which are repayable within 12 months and in no more than 12 instalments are not regulated by the Financial Conduct Authority. Registered office: 3rd Floor, 2-4 Wellington Street, Belfast. 
                    Company Number: NI675430. Data Protection Register Number: ZB029507
                    </p>
                </div>
            </section>
            {/* <ul class="nav nav-tabs" id="myTab" role="tablist" style={{maxWidth:"280px",margin:'0 auto'}}>
                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Prev</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Next</a>
                </li>
            </ul> */}
                    {/* Modal Pop up of Big things */}
        <Modal show={this.state.isBigThings} onHide={this.closeBigThings} className="funding-modal-popup">
            <Modal.Header >
              <button onClick={this.closeBigThings} type="button" className="btn-close modal-close-btn" aria-label="Close">X</button>
            </Modal.Header>
            <Modal.Body>
              <div className="funding-modal-section">
                <h3 className="power-title-text">Increase your limit and <br></br> spending power </h3>                
                <div className="inner-funding-section text-areat-increse">                    
                    <p>You may be able to increase your limit up to £30,000 but that increase is subject to our lending criteria.</p>
                    <p>we may request an electronic bank statement from you.</p>
                </div>
                <h4 className="current-limit-text">Your current limit</h4>
                <h2 className="Current-price-text">£{formatAmount(this.state.tile_Bt_Limit >=1 ?this.state.tile_Bt_Limit:this.state.tile_Lt_Limit)}</h2>
                <div className="request-limit-section">
                    <form>
                      <div className="form-group">
                        <label>New Limit Requested</label>
                        
                       <CurrencyFormat
                      className={"form-control amount-input"}
                      placeholder={`Enter amount between £${formatAmount(
                        this.state.tile_Bt_Limit >= 1
                          ? this.state.tile_Bt_Limit
                          : this.state.tile_Lt_Limit
                      )} and £30,000`}
                      value={this.state.displayData}
                      decimalScale={2}
                      isAllowed={(values) => {
                        const { value } = values;
                        return Boolean(value <= 30000);
                      }}
                      thousandSeparator={true}
                      prefix={"£"}
                      onValueChange={(values) => this.handleOnChange(values)}
                    />   
                      {this.state.ErrorMessage != '' &&
                      <p className="error-message code-error my-account-error"><span className="app-error-message" style={{ color: 'Red' }}>{this.state.ErrorMessage}</span> </p>
                    }
                        <p>Note: We may approve you for a lower amount</p>
                      </div>
                    </form>
                  </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-funding-footer">
              {!this.state.isValue || this.state.ErrorMessage!=""?(
                  <Button variant="primary"
                  className="confirmation-btn"
                  disabled
                  // onClick={this.handleOnSubmit}
                  >
                    Confirm
                  </Button>
              ):(
                <Button variant="primary"
                className="confirmation-btn"
                onClick={this.handleOnSubmit}>
                  Confirm
                </Button>
              )}
            </Modal.Footer>
          </Modal>
          {/* Big things ended */}


          {/* FLinks Modal Integration */}
          <Modal show={this.state.isFlinks} onHide={this.closeFlinksModel} className="funding-modal-popup  flinks-integration-popup">
            <Modal.Header >
              <button onClick={this.closeFlinksModel} type="button" className="btn-close modal-close-btn" aria-label="Close">X</button>
            </Modal.Header>
            <Modal.Body>
              <div className="funding-modal-section" style={{minHeight:'600px'}}>
               
                   {/* <FlinksIFrame
          setLoginId={this.setLoginId}
          setAccountId={this.setAccountId}
        /> */}
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-funding-footer">
              {this.state.isStep == '' ?(
                  <Button variant="primary" disabled className="confirmation-btn"  onClick={this.handleFlinksUkSubmit}>
                  Confirm
                </Button>
              ):(
                <Button variant="primary" className="confirmation-btn"  onClick={this.handleFlinksUkSubmit}>
                Confirm
              </Button>
              )}
            </Modal.Footer>
          </Modal>
          {/* FLink Integration Ended */}

          <Modal   show={this.state.isConfirmAmount} onHide={this.closeConfirmAmountModel} className="funding-modal-popup">
          <Modal.Header >
              <button onClick={this.closeConfirmAmountModel} type="button" className="btn-close modal-close-btn" aria-label="Close">X</button>
            </Modal.Header>
            <Modal.Body>
              <div className="funding-modal-section">
                <h5 className="congratulations--text">Congratulations!</h5>
                <h3 className="power-title-text">Your limit has been <br></br>increased </h3>
                <div className="inner-funding-section">
                  <p>Shop with more confidence with your new increased limit. Pay over a longer time with manageable instalments that suit your budget.</p>
                </div>
                <h4 className="current-limit-text">Your current limit</h4>
                <h2 className="Current-price-text">£{formatAmount(this.state.confirmAmountBT > 1 ?this.state.confirmAmountBT:this.state.confirmAmountLT)}</h2>
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-funding-footer">
              <div className="bottom-footer-section">
               <Link to="/shop"> <Button variant="primary" className="show-now-btn"  onClick={this.closeModal}>
                  Shop Now
                </Button></Link>
                <Link style={{textDecoration:"none"}} to ="/home"><Button variant="primary" className="btn-borer-line-btn"  onClick={this.closeModal}>
                  Proceed to Home Screen
                </Button></Link>
              </div>
            </Modal.Footer>
          </Modal>

          {/*Sorry modal popup */}
          <Modal show={this.state.isDecline} onHide={this.closeDeclineModel} className="funding-modal-popup">
            <Modal.Header >
              <button onClick={this.closeDeclineModel} type="button" className="btn-close modal-close-btn" aria-label="Close">X</button>
            </Modal.Header>
            <Modal.Body>
              <div className="funding-modal-section">
                <h5 className="congratulations--text">Sorry!</h5>
                <h3 className="power-title-text">We can't increase your <br></br>limit at this time </h3>
                <div className="inner-funding-section">
                <p>Unfortunately we can't increase your limit at this time. We're committed to responsible lending and want to ensure your repayments are comfortably within your budget.</p>
                <p>You may try a limit increase again in future. Please contact us if you have any questions.</p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-funding-footer">
              <div className="bottom-footer-section">
                <Button variant="primary" className="btn-borer-line-btn"  onClick={this.closeDeclineModel}>
                  Proceed to Home Screen
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          
          <Modal   show={this.state.isDefaultModal} onHide={this.state.isDefaultModal} className="funding-modal-popup">
            <Modal.Header >
              <button onClick={this.closeDefaultModal} type="button" className="btn-close modal-close-btn" aria-label="Close">X</button>
            </Modal.Header>
            <Modal.Body>
              <div className="funding-modal-section">
                <h5 className="power-title-text">Sorry !</h5>
                {/* <h3 className="power-title-text">Your limit has been <br></br>increased </h3> */}
                <div className="inner-funding-section">
                  <p>{this.state.defaultModalMessage}</p>
                </div>
                {/* <h4 className="current-limit-text">Your current limit</h4>
                <h2 className="Current-price-text">${this.state.confirmAmountBT?this.state.confirmAmountBT:this.state.confirmAmountLT}</h2> */}
              </div>
            </Modal.Body>
          </Modal>

         
        
    </div> );
    }
}
 

export default QuoteCalculatorResults

