import React from 'react';
import ApplicationLanding from './applicationLanding';
import { questions } from '../../action/application/questions';
import Element from '../application/element';
import { connect } from "react-redux";
import { applicationphonenumbervalidation, phonenumbervalidation, verifyPhonenumberOtp, ApplicationverifyPhonenumberOtp } from '../../action/phonenumvalidate/phoneNumberValidation';
import Loader from '../loader/loader';
import { applicationSave } from '../../action/applicationSave/applicationSave';
import { prepopulate } from '../../action/application/prepopulatedQuestion';
import DocumentVerify from './documentVerify';
import QrCode from '../miteck/qrCode';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import parse from 'html-react-parser';
import { analyticsChangeEvent, analyticsClickEvent, analyticsScreenViewEvent, analyticsTypeEvent, firebaseLoadingEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { getAvatar } from "../../action/avatar/getAvatar";
import Cookies from 'universal-cookie';
const threatMertix = window.threatmetrix;

export class Application extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.miteck = React.createRef();
    this.qrRef = React.createRef();
    this.state = {
      hasErrorMessage: 's',
      step: 1,
      stepOne: '',
      stepTwo: '',
      stepThree: '',
      stepFour: '',
      stepOnedata: '',
      stepTwodata: '',
      stepThreedata: '',
      displayErrorsStepOne: '',
      displayErrorsStepTwo: '',
      displayErrorsStepThree: '',
      phoneNo: '',
      displayPhoneError: '',
      hasOtpSendMessage: '',
      hasOtpSendToUserMessage: '',
      otpNumber: '',
      loading: false,
      isResendEnableBtn: true,
      hasOtpResendMessage: '',
      documentSteps: true,
      passportInstruction: false,
      drivinglicenseInstruction: false,
      selfieInstruction: false,
      width: window.innerWidth,
      showQR: false,
      unableToProvide: false,
      otpVerify: false,
      otpVerifyStatus: '',
      isEnableMobileBtn: true,
      isEnableCheckBox: true,
      serverErrorMessage: '',
      isPopupDisabled: true,
      isEnableOkButton: true,
      isEnableDesktopCapture: false,
      isContinuePrevAmount: false,
      privacyPolicyCheck: false,
      approvedApplicationMessage: '',
      hideFieldList: ['Postal_Code__c', 'Street__c',
        'Town__c', 'PreviousPostal_Code__c', 'PreviousStreet__c', 'PreviousTown__c','Previous_Address_Line1__c','Address_Line1__c'],
      validateFieldList: ['PreviousPostal_Code__c', 'PreviousStreet__c', 'PreviousTown__c','Previous_Address_Line1__c'],
      occupationValidation: ['occupation', 'time_in_occupation']
    }
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeOtpInput = this.handleChangeOtpInput.bind(this);
    this.resendOTP = this.resendOTP.bind(this);
    this.removeFromHistory = this.removeFromHistory.bind(this);
    this.isDateval = this.isDateval.bind(this);

  }

  componentDidMount() {
    if(localStorage.getItem('fromWhere')=='skip'){
      localStorage.setItem('sessionId', '')
    }

    this.props.removeQuestionSave([]);
    this.props.appValidationsRemove([]);
    this.props.showappValidation('');
    this.props.getAmountRequested('');
    this.props.removeSavedata([]);
    this.props.showAddress('');
    window.addEventListener("popstate", this.removeFromHistory);
    window.addEventListener('resize', this.handleWindowSizeChange);

    localStorage.setItem('ApplicationErrors', '');

    localStorage.setItem('flxPersonEmail__c', '')
    localStorage.setItem('flxPersonConfirmEmail__c', '')
    this.openModal();

  }

  openModal=()=>{
    if(localStorage.getItem('fromWhere')=='skip' && localStorage.getItem('showPopup')=='true'){ 
      setTimeout(() => {
        window.$("#sessionExpiredModal").modal("show");
      localStorage.setItem('showPopup','false');
    }, 1000);
    }
    localStorage.setItem('showPopup','false');
  }

  isDateval=(dateStr)=>{
    return !isNaN(new Date(dateStr).getDate());
  }

  componentWillUnmount() {
    this.props.appValidationsRemove([]);
    this.props.showappValidation('');
    window.removeEventListener('resize', this.handleWindowSizeChange);

  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  analyticsClickEvent = (data) => {
    if (data === 'serverErrorShowModel') {
      analyticsClickEvent('serverError_popup_ok','')
    } else if (data === 'fieldErrorShowModel') {
      analyticsClickEvent('fieldError_popup_ok','')
    }
  }

  removeFromHistory() {

    if (window.$('#forgotModal').hasClass('show') === false) {
      window.$('#forgotModal').modal('hide');
      window.$('.modal-backdrop').removeClass('show');
      window.$('body').removeClass('modal-open');
      window.$('.modal-backdrop').remove();
    }
  }


  handleChangeInput(event) {
    let name = event.target.name;
    // analyticsTypeEvent(name.toLocaleLowerCase())
    this.props.removeSavedata([]);
    if (event.target.value.length >= 12) {
      return false
    }
    if (event.target.value.length != 0) {
      if (!Number(event.target.value) && event.target.value != 0) {
        event.preventDefault();
        return;
      }
    }

    this.setState({ phoneNo: event.target.value });
    this.handlingValidations(event.target.value);
  }

  handlingValidations = (value) => {
    let mystring = value.substring(0, 2);

    if (value === '') {
      this.setState({ displayPhoneError: 'Required Field' });
    } else if (value.length !== 11) {
      this.setState({ displayPhoneError: 'Phone number must be 11 digits' });
    } else if (mystring !== '07') {
      this.setState({ displayPhoneError: 'This is not a valid UK Phone Number' });
    } else {
      this.setState({ displayPhoneError: '' });
    }

  }


  PrepopulateHideData = async () => {
    if (localStorage.getItem('fromWhere') == 'skip') {
      this.initialLoad();
      return false;
    }
    
    let self = this.props;
    let prepopulateData = [];
    this.props.applicationValidation.map((appValidationData) => {
      let appValidationStageDatas = {};
      appValidationStageDatas[Object.keys(appValidationData)[0]] = appValidationData.stage
      prepopulateData.push(appValidationStageDatas)
    });

    const obj = {
      application_email: localStorage.getItem('email'),
      application_mobile: this.state.phoneNo
    }

    const prepopulateResult = await prepopulate(obj);

    this.setState({ loading: true })
    if (prepopulateResult.status === 200 && this.state.isContinuePrevAmount===true) {
      this.props.prepopulateStatusCall(true);
      this.initialLoad();
    } else {
      this.props.prepopulateStatusCall(false);
      this.initialLoad();
      return false;
    }

    if (prepopulateResult.data.data !== '') {
      const prepopulateDataFilter = Object.assign({}, ...prepopulateData);
      Object.keys(prepopulateResult.data.data).forEach(function (key) {
        if (prepopulateResult.data.data[key] !== '' && prepopulateResult.data.data[key] !== null && prepopulateResult.data.data[key] !== undefined) {
     

          if(key==='flxBirthDate__c' || key==='time_in_occupation' ||  key==='genesis__bank_hours__c'){
            if(/^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/.test(prepopulateResult.data.data[key]) || /^((0[1-9])|(1[0-2]))\/(\d{4})$/.test(prepopulateResult.data.data[key])){
              console.log('check true')
            }else{
              return false;
            }
          }
          

          if (key === 'flxBirthDate__c') {
            self.saveDate(prepopulateResult.data.data[key])
          }
          if(key==="sortcode_bankaccount"){
            prepopulateResult.data.data[key] = prepopulateResult.data.data[key].replace(/(\d{2})(\d{2})(\d{2})/, "$1-$2-$3")
          }
          let prepopulateValues = {}
          prepopulateValues[key] = prepopulateResult.data.data[key];
          self.saveApplicationDatas(prepopulateValues);
          if (prepopulateDataFilter[key] != undefined) {
            let errorPrepopulate = {};
            errorPrepopulate[key] = '';
            errorPrepopulate['stage'] = prepopulateDataFilter[key];
            self.appvalidations(errorPrepopulate);
          }
        }
      });
    }
    
  }




  initialLoad = async () => {
    const result = await this.props.questions();
    if (result !== undefined) {
      const details = result.data.data.filter((drink, index) => index === 0);
      this.setState({ stepOnedata: details });
      const step2 = result.data.data.filter((drink, index) => index === 1);
      this.setState({ stepTwodata: step2 });
      const step3 = result.data.data.filter((drink, index) => index === 2);
      this.setState({
        stepThreedata: step3, passportInstruction: false,
        drivinglicenseInstruction: false,
        selfieInstruction: false,
        loading: false
      });
    }
  }

  monthDiff = (d1) => {
    if (d1 === null) {
      return 0;
    }
    let today = new Date();
    let months;
    months = (today.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += today.getMonth() + 1;
    return months <= 0 ? 0 : months;
  }


  getLabelName=(fieldName)=>{
    let LabelNames='';
    this.props.questionValueSave.map((src) => {
      if(src['field_api_name']===fieldName){
        LabelNames=src['field_question'];
      }
    });
    return LabelNames;
  }


  addApplicationProcessSave = (completedSteps) => {
    this.props.showAddress('');
    let errorDataShow='';
    const accountValue = this.props.amountRequested;
    const savedData = Object.assign({}, ...this.props.saveApplicationData);
    const validationData = Object.assign({}, ...this.props.applicationValidation);
    let applicationSaveProcess = [];
    let monthDiffValue = 6;
    if (savedData['date_moved_in_current_address'] && savedData['date_moved_in_current_address'] != '' && savedData['flxBirthDate__c'] != undefined) {
      let monthdiffData = savedData['date_moved_in_current_address'].split('/');
      let date_moved_in_current_address = monthdiffData[0] + '/' + '01' + '/' + monthdiffData[1];
      monthDiffValue = this.monthDiff(new Date(date_moved_in_current_address));
    }

    if (completedSteps === 'two') {
      this.props.questionValueSave.map((src) => {
        if (src.stage == '1') {
          if (accountValue > this.props.threashold) {
          
            let clearStpeOneErrorData = {}
            if (src.priority === 1) {
              clearStpeOneErrorData[src.field_api_name] = '';
              clearStpeOneErrorData['stage'] = src.stage;
              this.props.appvalidations(clearStpeOneErrorData);
            }
            if (src.priority === 3 || src.priority === 2) {
              if (monthDiffValue <=5) {
                //if(this.state.validateFieldList.includes(src.field_api_name)!=true){
                let errorData = {};
                if (savedData[src.field_api_name] != '' && savedData[src.field_api_name] != null && savedData[src.field_api_name] != undefined) {
                  if (validationData[src.field_api_name] === 'This is Required') {
                    errorData[src.field_api_name] = '';
                    errorData['stage'] = src.stage;
                    this.props.appvalidations(errorData);
                  }
                  let pushData = {};
                  pushData[src.field_api_name] = savedData[src.field_api_name]
                  applicationSaveProcess.push(pushData)
                } else {
                  if (src.field_api_name === 'flxPersonConfirmEmail__c') {
                    if (localStorage.getItem('flxPersonConfirmEmail__c') != '' && localStorage.getItem('flxPersonConfirmEmail__c') != null) {
                      return;
                    }
                  }
                  if (src.field_api_name === 'flx_Agree__c' || src.field_api_name === 'flx_DocumentAgree__c') {
                    if (savedData[src.field_api_name] === true) {
                      return;
                    }
                  }
                  if (src.validation === 'yes') {
                    errorData[src.field_api_name] = 'This is Required';
                    errorData['stage'] = src.stage;
                    this.props.appvalidations(errorData);
                  }
                }
                //}
              } else {
                let errorData = {};
                if (this.state.validateFieldList.includes(src.field_api_name) === true) {
                  errorData[src.field_api_name] = '';
                  errorData['stage'] = src.stage;
                  this.props.appvalidations(errorData);
                } else {
                  if (savedData[src.field_api_name] != '' && savedData[src.field_api_name] != null && savedData[src.field_api_name] != undefined) {
                    if (validationData[src.field_api_name] === 'This is Required') {
                      errorData[src.field_api_name] = '';
                      errorData['stage'] = src.stage;
                      this.props.appvalidations(errorData);
                    }
                    let pushData = {};
                    pushData[src.field_api_name] = savedData[src.field_api_name]
                    applicationSaveProcess.push(pushData)
                  } else {
                    if (src.field_api_name === 'flxPersonConfirmEmail__c') {
                      if (localStorage.getItem('flxPersonConfirmEmail__c') != '' && localStorage.getItem('flxPersonConfirmEmail__c') != null) {
                        return;
                      }
                    }

                    if (src.field_api_name === 'flx_Agree__c' || src.field_api_name === 'flx_DocumentAgree__c') {
                      if (savedData[src.field_api_name] === true) {
                        return;
                      }
                    }

                    if (src.validation === 'yes') {
                      errorData[src.field_api_name] = 'This is Required';
                      errorData['stage'] = src.stage;
                      this.props.appvalidations(errorData);
                    }
                  }
                }

              }
            }
          } else {
           
            let clearErrorData = {}
            if (src.priority === 3) {
              clearErrorData[src.field_api_name] = '';
              clearErrorData['stage'] = src.stage;
              this.props.appvalidations(clearErrorData);
            }
            if (src.priority === 1 || src.priority === 2) {
              if (monthDiffValue <=5) {
                //if(this.state.validateFieldList.includes(src.field_api_name)!=true){
                let errorData = {};
                if (savedData[src.field_api_name] != '' && savedData[src.field_api_name] != null && savedData[src.field_api_name] != undefined) {
                  let pushData = {};
                  pushData[src.field_api_name] = savedData[src.field_api_name]
                  applicationSaveProcess.push(pushData)
                  if (validationData[src.field_api_name] === 'This is Required') {
                    errorData[src.field_api_name] = '';
                    errorData['stage'] = src.stage;
                    this.props.appvalidations(errorData);
                  }
                } else {
                  if (src.field_api_name === 'flxPersonConfirmEmail__c') {
                    if (localStorage.getItem('flxPersonConfirmEmail__c') != '' && localStorage.getItem('flxPersonConfirmEmail__c') != null) {
                      return;
                    }
                  }
                  if (src.field_api_name === 'flx_Agree__c' || src.field_api_name === 'flx_DocumentAgree__c') {
                    if (savedData[src.field_api_name] === true) {
                      return;
                    }
                  }
                  if (src.validation === 'yes') {
                    errorData[src.field_api_name] = 'This is Required';
                    errorData['stage'] = src.stage;
                    this.props.appvalidations(errorData);
                  }
                }
                //}
              } else {
                let errorData = {};
                if (this.state.validateFieldList.includes(src.field_api_name) === true) {
                  errorData[src.field_api_name] = '';
                  errorData['stage'] = src.stage;
                  this.props.appvalidations(errorData);
                } else {
                  if (savedData[src.field_api_name] != '' && savedData[src.field_api_name] != null && savedData[src.field_api_name] != undefined) {
                    if (validationData[src.field_api_name] === 'This is Required') {
                      errorData[src.field_api_name] = '';
                      errorData['stage'] = src.stage;
                      this.props.appvalidations(errorData);
                    }
                    let pushData = {};
                    pushData[src.field_api_name] = savedData[src.field_api_name]
                    applicationSaveProcess.push(pushData)
                  } else {

                    if (src.field_api_name === 'flxPersonConfirmEmail__c') {
                      if (localStorage.getItem('flxPersonConfirmEmail__c') != '' && localStorage.getItem('flxPersonConfirmEmail__c') != null) {
                        return;
                      }
                    }
                    if (src.field_api_name === 'flx_Agree__c' || src.field_api_name === 'flx_DocumentAgree__c') {
                      if (savedData[src.field_api_name] === true) {
                        return;
                      }
                    }
                    if (src.validation === 'yes') {
                      errorData[src.field_api_name] = 'This is Required';
                      errorData['stage'] = src.stage;
                      this.props.appvalidations(errorData);
                    }
                  }
                }

              }
            }
          }
        }
      })
    }

    if (completedSteps === 'three') {
      this.props.questionValueSave.map((src) => {
        if (src.stage == '2') {
          let errorStepTwoData = {};
          if(src.field_api_name == 'sortcode_bankaccount'){
            if(savedData[src.field_api_name] !== undefined && savedData[src.field_api_name] !== '' && savedData[src.field_api_name] !== null){
              let sortCode= savedData[src.field_api_name];
              if (sortCode.length <= 8) {
                sortCode = sortCode.replace(/\D/g, "").split('-').join('');
                sortCode = sortCode.slice(0, 6)
                savedData[src.field_api_name] = sortCode
              }
              if (sortCode.length > 6 || sortCode.length<6 ) {        
                errorStepTwoData[src.field_api_name] = 'Must be 6 digit numeric value';
                errorStepTwoData['stage'] = src.stage;
                this.props.appvalidations(errorStepTwoData)
              } 
            }
          }
          if (this.props.employmentStatus) {
            if (this.state.occupationValidation.includes(src.field_api_name) === true) {

              if (savedData[src.field_api_name] != '' && savedData[src.field_api_name] != null && savedData[src.field_api_name] != undefined) {
                let pushData = {};
                pushData[src.field_api_name] = savedData[src.field_api_name]
                applicationSaveProcess.push(pushData)
                if (validationData[src.field_api_name] === 'This is Required') {
                  errorStepTwoData[src.field_api_name] = '';
                  errorStepTwoData['stage'] = src.stage;
                  this.props.appvalidations(errorStepTwoData);
                }
              } else {
                errorStepTwoData[src.field_api_name] = 'This is Required';
                errorStepTwoData['stage'] = src.stage;
                this.props.appvalidations(errorStepTwoData);
              }
            } else {
              let val = '';
              val = 'thridData';

              if (savedData[src.field_api_name] != '' && savedData[src.field_api_name] != null && savedData[src.field_api_name] != undefined) {
                let pushData = {};
                pushData[src.field_api_name] = savedData[src.field_api_name]
                applicationSaveProcess.push(pushData)
                if (validationData[src.field_api_name] === 'This is Required') {
                  errorStepTwoData[src.field_api_name] = '';
                  errorStepTwoData['stage'] = src.stage;
                  this.props.appvalidations(errorStepTwoData);
                }
              } else {
                errorStepTwoData[src.field_api_name] = 'This is Required';
                errorStepTwoData['stage'] = src.stage;
                this.props.appvalidations(errorStepTwoData);
              }
            }
          } else {
            if (this.state.occupationValidation.includes(src.field_api_name) != true) {
              if (savedData[src.field_api_name] != '' && savedData[src.field_api_name] != null && savedData[src.field_api_name] != undefined) {
                let pushData = {};
                pushData[src.field_api_name] = savedData[src.field_api_name]
                applicationSaveProcess.push(pushData)
                if (validationData[src.field_api_name] === 'This is Required') {
                  errorStepTwoData[src.field_api_name] = '';
                  errorStepTwoData['stage'] = src.stage;
                  this.props.appvalidations(errorStepTwoData);
                }
              } else {
                errorStepTwoData[src.field_api_name] = 'This is Required';
                errorStepTwoData['stage'] = src.stage;
                this.props.appvalidations(errorStepTwoData);
              }
            } else {
              errorStepTwoData[src.field_api_name] = '';
              errorStepTwoData['stage'] = src.stage;
              this.props.appvalidations(errorStepTwoData);
            }
          }

        }
      })
    }

    if (completedSteps === 'two') {
      if(monthDiffValue<=5){
        let errorDatas={};
        this.props.showAddress('both');
        if(savedData['Town__c']===undefined){
          errorDatas['Town__c'] = 'This is Required';
          errorDatas['stage'] = '1';
          this.props.appvalidations(errorDatas);
          errorDataShow='error'
          errorDatas={};
        } 
        if(savedData['Postal_Code__c']===undefined){
          errorDatas['Postal_Code__c'] = 'This is Required';
          errorDatas['stage'] = '1';
          this.props.appvalidations(errorDatas);
          errorDataShow='error'
          errorDatas={};
        }
        if(savedData['Address_Line1__c']===undefined){
          errorDatas['Address_Line1__c'] = 'This is Required';
          errorDatas['stage'] = '1';
          this.props.appvalidations(errorDatas);
          errorDataShow='error'
        }
        let errorDatasPrev={};
        this.props.showAddress('both');
        if(savedData['PreviousTown__c']===undefined){
          errorDatasPrev['PreviousTown__c'] = 'This is Required';
          errorDatasPrev['stage'] = '1';
          this.props.appvalidations(errorDatasPrev);
          errorDataShow='error'
          errorDatasPrev={};
        } 
        if(savedData['PreviousPostal_Code__c']===undefined){
          errorDatasPrev['PreviousPostal_Code__c'] = 'This is Required';
          errorDatasPrev['stage'] ='1';
          this.props.appvalidations(errorDatasPrev);
          errorDataShow='error'
          errorDatasPrev={};
        }
        if(savedData['Previous_Address_Line1__c']===undefined){
          errorDatasPrev['Previous_Address_Line1__c'] = 'This is Required';
          errorDatasPrev['stage'] ='1';
          this.props.appvalidations(errorDatasPrev);
          errorDataShow='error'
        }
      }else{
        let errorDatas={};
        this.props.showAddress('Current_Address_Country__c');
        if(savedData['Town__c']===undefined){
          errorDatas['Town__c'] = 'This is Required';
          errorDatas['stage'] = '1';
          this.props.appvalidations(errorDatas);
          errorDataShow='error'
          errorDatas={};
        } 
        if(savedData['Postal_Code__c']===undefined){
          errorDatas['Postal_Code__c'] = 'This is Required';
          errorDatas['stage'] = '1';
          this.props.appvalidations(errorDatas);
          errorDataShow='error'
          errorDatas={};
        }
        if(savedData['Address_Line1__c']===undefined){
          errorDatas['Address_Line1__c'] = 'This is Required';
          errorDatas['stage'] = '1';
          this.props.appvalidations(errorDatas);
          errorDataShow='error'
        }
        }
      }
    if(errorDataShow===''){
      return applicationSaveProcess;
    }else{
      return errorDataShow;
    }
  }

  isEnableDesktopCapture = async () => {
    setTimeout(() => {
      this.setState({ isEnableDesktopCapture: true })
    }, 500);

  }

  updateLocalUserName=async()=>{
    if(localStorage.getItem('fromWhere')!='skip'){
      const obj={
        email:localStorage.getItem('email')
      }
      const userDataResult = await getAvatar(obj);
      // Check whether the response's data is empty or not from API "user/getUser"
      if(Object.keys(userDataResult.data.data).length !== 0){
         localStorage.setItem('UserName',userDataResult.data.data.first_name+' '+userDataResult.data.data.last_name);         
      } 
    }
  }

  stepTwofunc=async(completedSteps)=>{

    const applicationProcessBasedResult = await this.addApplicationProcessSave(completedSteps);

    let showError=false;

    if(applicationProcessBasedResult==='error'){
      showError=true;
    }
    Promise.all(
      this.props.applicationValidation.map(async (src) => {
        let fieldLabels = Object.keys(src)['0'];
        if (src[fieldLabels] !== '' && src['stage'] === '1' && completedSteps === 'two') {
          window.$('#fieldErrorShowModel').modal('show')
          this.props.showappValidation('yes');
          showError=true;
          return false;
        }
      })
    )

    let stepone = 0
    let errorCount=0;
    let LabelCount=0;
    let LabelNames=[]; 

    this.props.applicationValidation.map((steps) => {
      let fieldLabels = Object.keys(steps)['0'];
      if (steps['stage'] === '1') {
        if(steps[fieldLabels]!='' && steps[fieldLabels]!=undefined  && completedSteps === 'two'){
          if(LabelCount<=2){
            let label_name=this.getLabelName(fieldLabels);
            if(label_name!=undefined){
            LabelCount++;
            LabelNames.push(label_name);
            }
          }
          errorCount++;
        }
        stepone++;
        this.setState({ displayErrorsStepOne: 'erros' });
        this.props.showappValidation('yes');
      }
    })

    this.setState({errorCount:errorCount})
    this.setState({LabelNames:LabelNames})
    this.setState({requiredFieldCount:LabelCount})

    if(showError){
      window.$('#fieldErrorShowModel').modal('show')
      return false;
    }else{
      window.$('#userNoteShowModel').modal('show')
    }



  }

  nextStep = async (completedSteps, e) => {
    const cookie = new Cookies();
    window.$('#userNoteShowModel').modal('hide')
    const applicationProcessBasedResult = await this.addApplicationProcessSave(completedSteps);
   
    this.setState({ unableToProvide: false });
    const { step } = this.state;
    if (this.props.applicationValidation.length === '0') {
      this.props.hideappValidation('');
      this.setState({
        step: step + 1
      });
      return false;
    }
    if (completedSteps === 'one') {
      analyticsClickEvent('phone_number_verify_next','')
      this.props.hideappValidation('');

      if (this.state.displayPhoneError !== '' || this.state.phoneNo === '') {
        if (this.state.phoneNo === '') {
          this.setState({ displayPhoneError: 'Required Field' });
        }
        return false;
      }
      this.props.getPhoneNumber(this.state.phoneNo)
      const obj = {
        'email': localStorage.getItem('email'),
        'application_phone_number': this.state.phoneNo,
        'application_flag': this.state.isContinuePrevAmount,
        'Retailer_Account__c': cookie.get('retailerId')
      }
      if(this.state.isContinuePrevAmount === true){
        analyticsClickEvent('new_application','')
      }
      if (localStorage.getItem('fromWhere') !== 'skip') {
        this.setState({ loading: true });
        const result = await phonenumbervalidation(obj);       
        if (result !== undefined) {
          if (result.status === 200 && result.data.message === "Please enter the verification code we've sent by SMS") {
            this.setState({ displayPhoneError: '' });
            this.setState({ otpNumber: '' });
            this.setState({ otpErrorMessage: '' });
            this.setState({ hasOtpResendMessage: '' });
            this.setState({ loading: false })
            this.setState({ hasOtpSendToUserMessage: result.data.message });
            window.$('#otpModel').modal('show');
            this.props.showappValidation('');
            return false;
          } else if (result.status === 200 && result.data.message === 'Application phone number verified') {
            analyticsClickEvent('step1_next','')
            firebaseLoadingEvent('step2_loaded','')
            this.props.removeSavedata([]);
            this.PrepopulateHideData();
            localStorage.setItem('applicationId', result.data.data.application_id);
            localStorage.setItem('flxPersonConfirmEmail__c', '');
            localStorage.setItem('flxPersonEmail__c', '');
            this.props.showappValidation('');
            this.setState({
                step: step + 1
              });
              this.setState({ stepOne: 'completed' })
            
            window.scrollTo(0, 0);
            setTimeout(() => {
              this.setState({
                loading: false
              });
            }, 2000)
            return false;
          } else if (result.status === 409) {
            this.setState({
              loading: false
            });
            this.setState({ approvedApplicationMessage: result.data.message, isContinuePrevAmount: true })

            window.$("#userBalanceShowModel").modal("show");
            return false;
          } else if (result.data.message === 'Sorry, something has gone wrong with the processing of your application. Please try again.') {
            this.setState({ loading: false })
            this.setState({ hasErrorMessage: result.data.message });
            window.$('#commonModel').modal('show');
            return false;
          }        
          else if (result.status === 400) {
            let msg = result.data.message;
            if (this.extractEmails(msg) != null) {
              let newtext = '';
              let val = this.extractEmails(msg);
              val.map((src) => {
                let anchorTag = "<a target='_blank' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
                newtext = msg.replace(src, anchorTag);
                msg = newtext;

              })
            }
            this.setState({ loading: false, serverErrorMessage: msg });
            window.$('#serverErrorShowModel').modal('show');
            return false;
          } else {
            this.setState({ loading: false })
            this.setState({ displayPhoneError: result.data.message });
            this.props.showappValidation('');
            return false;
          }
        }
      }
      if (localStorage.getItem('fromWhere') == 'skip') {
        const appobj = {
          "application_phone_number": this.state.phoneNo,
          "user_type": "6"
        };
        this.setState({ loading: true });
        const result = await applicationphonenumbervalidation(appobj);
        this.setState({ loading: false });
        if(result != undefined){
        if (result.status === 200 && result.data.message === "Please enter the verification code we've sent by SMS") {
          this.setState({ displayPhoneError: '' });
          this.setState({ otpNumber: '' }); const { step } = this.state;
          this.setState({ loading: false });
          this.setState({ hasOtpSendToUserMessage: result.data.message });
          if (this.state.otpVerify === true) {

            window.$('#otpModel').modal('hide');
            this.setState({ loading: false });
            this.setState({
              step: step + 1
            });
            return false;
          }
          this.setState({ otpErrorMessage: '' });
          this.setState({ hasOtpResendMessage: '' });
          this.setState({ loading: false })
          this.setState({ hasOtpSendMessage: result.data.message });
          window.$('#otpModel').modal('show');
          this.props.showappValidation('');
          return false;
        } else if (result.status === 200 && result.data.message === 'Application phone number verified') {
          firebaseLoadingEvent('step2_loaded')
              analyticsClickEvent('step1_next','')
          this.setState({ loading: true });
          localStorage.setItem('applicationId', result.data.data.application_id);
          localStorage.setItem("sessionId", result.data.data.session_id)
          localStorage.setItem('csrfToken',result.data.data.csrf_token);
          this.props.removeSavedata([]);
          this.PrepopulateHideData();

          // this.initialLoad();
          this.props.showappValidation('');
          this.setState({
            step: step + 1
          });
          this.setState({ stepOne: 'completed' });
          setTimeout(() => {
            this.setState({ loading: false });
          }, 3000)
          window.scrollTo(0, 0);

          return false;
        } else if (result.status === 400) {
          let errorMsg = result.data.message;
            if (this.extractEmails(errorMsg) != null) {
              let newErrorMsg = '';
              let extractErrorMsg = this.extractEmails(errorMsg);
              extractErrorMsg.map((src) => {
                let anchorTag = "<a target='_blank' style='color: #ff6200' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
                newErrorMsg = errorMsg.replace(src, anchorTag);
                errorMsg = newErrorMsg;

              })
            }
          this.setState({ serverErrorMessage: errorMsg, loading: false });
          window.$('#serverErrorShowModel').modal('show');
          return false;
        } else {
          this.setState({ loading: false })
          let newErrorMessage = result.data.message;
          if(newErrorMessage.indexOf("Try logging in") !== -1){
            let errorMessage = '';
            const loginLink = "<a target='_self' style='color: #ff6200' rel='noopener noreferrer' href='/login'> Try logging in</a>";
            errorMessage = newErrorMessage.replace("Try logging in", loginLink);
            newErrorMessage = errorMessage;
          }
          this.setState({ displayPhoneError: newErrorMessage });
          this.props.showappValidation('');
          return false;
        }
      }
      }
    }
    let i = 0;
    let j = 0;
    let stepone = 0;
    let steptwo = 0;
    let errorCount=0;
    let nameCount=0;
    let LabelNames=[];

    this.props.applicationValidation.map((steps) => {
      let vals = Object.keys(steps)['0'];
      if (steps['stage'] === '1') {
        if(steps[vals]!='' && steps[vals]!=undefined  && completedSteps === 'two'){
          if(nameCount<=2){
            let label_name=this.getLabelName(vals);
            if(label_name!=undefined){
            nameCount++;
            LabelNames.push(label_name);
            }
          }
          errorCount++;
        }
        stepone++;
        this.setState({ displayErrorsStepOne: 'erros' });
        this.props.showappValidation('yes');
      }
      if (steps['stage'] === '2') {
        steptwo++;
        if(steps[vals]!='' && steps[vals]!=undefined &&  completedSteps !== 'two'){
          if(nameCount<=2){
            let label_name=this.getLabelName(vals);
            if(label_name!=undefined){
            nameCount++;
            LabelNames.push(label_name);
            }
          }
          errorCount++;
        }
        this.setState({ displayErrorsStepTwo: 'erros' });
        this.props.showappValidation('yes');
      }
      if (steps['stage'] === '3') {
        steptwo++;
        this.setState({ displayErrorsStepThree: 'erros' });
        this.props.showappValidation('yes');
      }
    });
    this.setState({errorCount:errorCount})
    this.setState({LabelNames:LabelNames})
    this.setState({requiredFieldCount:nameCount})
    let dataArray = [];
    let dataArrayStage = [];
    if(applicationProcessBasedResult==='error'){
      window.$('#fieldErrorShowModel').modal('show')
      return false;
    }
    this.props.applicationValidation.map(async (src) => {
      let val = Object.keys(src)['0'];
      if (src[val] === '' && src['stage'] === '1') {
        i++;
        this.props.saveApplicationData.filter(async function (itm) {
          if (Object.keys(itm).toString() === val) {
            dataArray.push(itm)
            return itm
          } else {
            dataArrayStage.push(itm)
          }
        });

        if (stepone === i && completedSteps !== 'three') {
          analyticsClickEvent('step2_next','')
          if (this.props.saveApplicationData) {
            // window.$('#fieldErrorShowModel').modal('show')
            const allDetails = Object.assign({}, ...applicationProcessBasedResult);
            const TMXSessionId = uuidv4();
            window.threatmetrix.profile(process.env.REACT_APP_TMXDOMAIN, process.env.REACT_APP_TMXKEY, TMXSessionId);
            allDetails.application_id = localStorage.getItem('applicationId');
            allDetails.session_id = TMXSessionId;      

            allDetails.Application_Channel = 'Web';
            this.setState({ loading: true })
            if(this.props.amountRequested > this.props.threashold){
              analyticsChangeEvent('big_thing',this.props.amountRequested)
            }else{
              analyticsChangeEvent('little_thing',this.props.amountRequested)
            }
            
            const applicationSaveResponse = await applicationSave(allDetails);
            if (applicationSaveResponse.data.message === 'Sorry, something has gone wrong with the processing of your application. Please try again.'){

              this.setState({ loading: false })
              this.setState({ hasErrorMessage: applicationSaveResponse.data.message });
              window.$('#commonModel').modal('show');
              // Call the function updateLocalUserName() to update the username when submitted application is fault response
              this.updateLocalUserName();
              return false;
            }
            if (applicationSaveResponse === undefined || applicationSaveResponse.data.statusCode === 503) {

              this.setState({ loading: false });
              this.setState({ serverErrorMessage: "Something went wrong" })
              window.$('#serverErrorShowModel').modal('show');
              return false;
            }
            let msg = applicationSaveResponse.data.message;
            // Go to step 4 when statuscode equals to 206     
            if(applicationSaveResponse.data.statusCode == 206) {
              
              analyticsClickEvent('step3_next','')
              firebaseLoadingEvent('step4_loaded');
              this.setState({
                step: step + 2
              });
              if (completedSteps === 'two') {
                this.setState({ loading: false });
                this.props.showappValidation('');
                this.setState({ showQR: true });
                this.setState({ stepTwo: 'completed' })
                this.setState({ stepThree: 'completed' })
                if (this.miteck && this.miteck.current) {
                  this.miteck.current.updatechanges(this.state.documentSteps);
                }
                if (this.qrRef && this.qrRef.current) {
                  this.qrRef.current.callevent();
                }
              }

              this.setState({ loading: false });
              window.scrollTo(0, 0);
              
            }

            if (applicationSaveResponse.data.statusCode === 201 && applicationSaveResponse.data.message === 'Application requested additional user details') {
              
              analyticsClickEvent('step2_next','')
              firebaseLoadingEvent('step3_loaded')
              
              this.setState({
                step: step + 1
              });
              if (completedSteps === 'two') {
                // this.props.showappValidation('');
                // this.props.appValidationsRemove([]);
                this.setState({
                  step: 1
                });
                this.setState({
                  step: step + 1
                });
                this.setState({ stepTwo: 'completed' })
                setTimeout(() => {
                  this.setState({ loading: false })
                }, 1000);

              }
              this.updateLocalUserName();
              window.scrollTo(0, 0);
            } else {
              
              this.setState({ loading: false })
              if (applicationSaveResponse.data.statusCode === 202) {

                this.setState({ hasErrorMessage: msg });
                window.$('#commonModel').modal('show');
                this.props.showappValidation('');
                this.props.appValidationsRemove([]);
                window.$('#commonModel').modal({ backdrop: 'static', keyboard: false });
                localStorage.setItem('flxPersonConfirmEmail__c', '');
                localStorage.setItem('flxPersonEmail__c', '');
                this.updateLocalUserName();
                return false;
              } else if (applicationSaveResponse.data.statusCode === 400) {

                this.setState({ hasErrorMessage: msg, unableToProvide: true });
                window.$('#commonModel').modal('show');
              } else if (applicationSaveResponse.data.statusCode === 201) {

                this.setState({ hasErrorMessage: msg });
                window.$('#commonModel').modal('show');
              } else if (applicationSaveResponse.data.statusCode === 201 && msg === 'Do not Need More Detail') {

                this.setState({ hasErrorMessage: 'Application submited sucessfully' });
                window.$('#commonModel').modal('show');
                setTimeout(() => {
                  this.setState({ loading: false })
                }, 1000);
                this.updateLocalUserName();
              } else if (applicationSaveResponse.data.statusCode === 200) {
                analyticsClickEvent('application_approved','');
                this.setState({ hasErrorMessage: msg });
                window.$('#commonModel').modal('show');

                setTimeout(() => {
                  this.setState({ loading: false })
                }, 1000);
                // Call the function updateLocalUserName() to update the username when submitted application is referred
                this.updateLocalUserName();
              } else if (applicationSaveResponse.data.statusCode === 417) {

                analyticsClickEvent('application_declined', '');
                if (this.extractEmails(msg) != null) {
                  let newtext = '';
                  let val = this.extractEmails(msg);
                  val.map((src) => {
                    let anchorTag = "<a onClick='analyticsClickEvent('email_link','')' target='_blank' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
                    newtext = msg.replace(src, anchorTag);
                    msg = newtext;

                  })
                }
                this.setState({ hasErrorMessage: msg });
                window.$('#commonModel').modal('show');
                this.updateLocalUserName();
              } else if (applicationSaveResponse.data.statusCode === 409) {

                this.setState({ hasErrorMessage: msg, unableToProvide: true });
                window.$('#commonModel').modal('show');
              } else if (applicationSaveResponse.data.statusCode === 500) {

                setTimeout(() => {
                  this.setState({ loading: false });
                  this.setState({ serverErrorMessage: "Something went wrong" })
                  window.$('#serverErrorShowModel').modal('show');
                }, 1000);
              }
              return false;
            }
          } else {

            return false;
          }
        } else {

          this.props.applicationValidation.map(async (src) => {
            let valuesOfStageOne = Object.keys(src)['0'];
            if (src[valuesOfStageOne] !== '' && src['stage'] === '1' && completedSteps === 'two') {
              window.$('#fieldErrorShowModel').modal('show')
            }
          })
        }

      }
      let validateCondition = false;
      if (src[val] !== '' && src['stage'] === '2') {
        // j = j - 1;
        let jsub = j - 1;
        j = jsub;
        validateCondition = true;
        // return false;
      }

      if (src[val] === '' && src['stage'] === '2' && completedSteps != 'two') {
        j++;
        if (steptwo === j) {
          if (this.props.saveApplicationData) {
            analyticsClickEvent('step3_next','')
            // window.$('#fieldErrorShowModel').modal('show')
            const allDetailsStage = Object.assign({}, ...applicationProcessBasedResult);
            const TMXSessionId = uuidv4();
            window.threatmetrix.profile(process.env.REACT_APP_TMXDOMAIN, process.env.REACT_APP_TMXKEY, TMXSessionId);
            allDetailsStage.application_id = localStorage.getItem('applicationId');
            allDetailsStage.session_id = TMXSessionId;
            this.setState({ loading: true })
            let applicationSaveResponses = await applicationSave(allDetailsStage);
            if (applicationSaveResponses.data.message === 'Sorry, something has gone wrong with the processing of your application. Please try again.'){
              this.setState({ loading: false })
              this.setState({ hasErrorMessage: applicationSaveResponses.data.message });
              window.$('#commonModel').modal('show');
              return false;
            }
            if (applicationSaveResponses === undefined || applicationSaveResponses.data.statusCode === 503) {
              this.setState({ loading: false });
              this.setState({ serverErrorMessage: "Something went wrong" })
              window.$('#serverErrorShowModel').modal('show');
              return false;
            }
            let msg = applicationSaveResponses.data.message;
            if (applicationSaveResponses.data.statusCode === 206 && applicationSaveResponses.data.message === 'Capture requested additional details') {
              analyticsClickEvent('step3_next','')
              firebaseLoadingEvent('step4_loaded');
              this.setState({
                step: step + 1
              });
              if (completedSteps === 'three') {
                this.setState({ loading: false });
                this.props.showappValidation('');
                this.setState({ showQR: true });
                this.setState({ stepThree: 'completed' })
                if (this.miteck && this.miteck.current) {
                  this.miteck.current.updatechanges(this.state.documentSteps);
                }
                if (this.qrRef && this.qrRef.current) {
                  this.qrRef.current.callevent();
                }
              }

              this.setState({ loading: false });
              window.scrollTo(0, 0);
            } else if (applicationSaveResponses.data.statusCode === 202) {
              this.setState({ hasErrorMessage: msg });
              window.$('#commonModel').modal('show');
              this.setState({ loading: false })
              return false;
            } else if (applicationSaveResponses.data.statusCode === 400) {
              this.setState({ hasErrorMessage: msg, unableToProvide: true });
              window.$('#commonModel').modal('show');
              this.setState({ loading: false })
            } else if (applicationSaveResponses.data.statusCode === 201) {
              this.setState({ hasErrorMessage: msg });
              window.$('#commonModel').modal('show');
              this.setState({ loading: false })
            } else if (applicationSaveResponses.data.statusCode === 201 && msg === 'Do not Need More Detail') {
              this.setState({ hasErrorMessage: 'Application submited sucessfully' });
              window.$('#commonModel').modal('show');
              setTimeout(() => {
                this.setState({ loading: false });
              }, 1000);
            } else if (applicationSaveResponses.data.statusCode === 200) {
              this.setState({ hasErrorMessage: msg });
              window.$('#commonModel').modal('show');
              setTimeout(() => {
                this.setState({ loading: false });
              }, 1000);
            } else if (applicationSaveResponses.data.statusCode === 417) {
              analyticsClickEvent('application_declined','');
              if (this.extractEmails(msg) != null) {
                let newtext = '';
                let val = this.extractEmails(msg);
                val.map((src) => {
                  let anchorTag = "<a onClick='analyticsClickEvent('email_link','')' target='_blank' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
                  newtext = msg.replace(src, anchorTag);
                  msg = newtext;

                })
              }
              this.setState({ loading: false })
              this.setState({ hasErrorMessage: msg });
              window.$('#commonModel').modal('show');
            } else if (applicationSaveResponses.data.statusCode === 500) {
              setTimeout(() => {
                this.setState({ loading: false });
              }, 1000);
              this.setState({ serverErrorMessage: "Something went wrong" })
              window.$('#serverErrorShowModel').modal('show');
            }
          } else {

            return false;
          }
        }
      } else {
        this.props.applicationValidation.map(async (src) => {
          let valuesOfStageTwo = Object.keys(src)['0'];
          if (src[valuesOfStageTwo] !== '' && src['stage'] === '2' && completedSteps === 'three') {
            window.$('#fieldErrorShowModel').modal('show')
          }
        })


      }
    })
  };

  extractEmails(text) {
    return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
  }


  tabonchange = (step) => {
    window.scrollTo(0, 0);
    if (step === '1') {
      this.setState({ step: 1 });
      analyticsClickEvent('progress_step1','')
    } else if (step === '2') {
      this.setState({ step: 2 });
      analyticsClickEvent('progress_step2','')
    } else if (step === '3') {
      this.setState({ step: 3 });
      analyticsClickEvent('progress_step3','')
    } else if (step === '4') {
      this.setState({ step: 4 });
      analyticsClickEvent('progress_step4','')
      if (this.miteck && this.miteck.current) {
        this.miteck.current.updatechanges(this.state.documentSteps);
      }
      if (this.qrRef && this.qrRef.current) {
        this.qrRef.current.callevent();
      }
    }
  };
  /* Go back to prev step */
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  /*  Handle fields change */
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  changeMobileCheckbox = (e) => {
    analyticsClickEvent('checkbox','')
    if (e.target.checked) {
      // analyticsClickEvent('accepted_privacy_policy','')
      this.setState({ isEnableMobileBtn: false })
    } else {
      // analyticsClickEvent('notaccepted_privacy_policy','')
      this.setState({ isEnableMobileBtn: true })
    }


  }


  gotoStepTWo = () => {
    analyticsClickEvent('otp_verify_next','')
    firebaseLoadingEvent('step2_loaded')
    // analyticsClickEvent('step1_next','')
    this.setState({ loading: true });
    const { step } = this.state;


    if(this.state.otpNumber.length!=6){
      this.setState({ otpErrorMessage: 'Verification code is incorrect', loading: false });
      this.setState({ loading: false });
      return false;
    } 
    if (this.state.otpNumber === '') {
      this.setState({ otpErrorMessage: 'Verification code is required', loading: false });
      this.setState({ loading: false });
      return false;
    }
    
    /* Check whether the phone number is verified, otpVerifyStatus is 403 and the length of the otp is correct 
    and hide the otp verification popup and show the popup with error message after two seconds.
    */
    if(this.state.otpVerify === true && this.state.otpNumber.length === 6 && this.state.otpVerifyStatus == 403 ) {
      window.$('#otpModel').modal('hide');
      this.setState({phoneNo: '',hasOtpSendMessage: ''})
      setTimeout(() => {
        this.setState({ loading: false });        
        window.$('#serverErrorShowModel').modal('show');
      }, 2000);
      return false;
    }

    /* Check whether the phone number is verified, otpVerifyStatus is 200 and the length of the otp is correct
    and hide the otp verification popup and redirect the user to the step 2 of the application journey
    */
    if (this.state.otpVerify === true && this.state.otpNumber.length === 6 && this.state.otpVerifyStatus == 200) {
      
      window.$('#otpModel').modal('hide');
      this.setState({
        step: step + 1
      });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 2000);

      window.scrollTo(0, 0);
      return false;
    } else {
      this.setState({ loading: false });
    }

  }

  handleChangeOtpInput = async (event) => {
    const cookie = new Cookies();
    let value = event.target.value;
    this.setState({ otpNumber: value });
    if (value !== '') {
      this.setState({ otpErrorMessage: '' });
    } else {
      this.setState({ otpErrorMessage: value });
      this.setState({ otpErrorMessage: 'Verification code is required' });
      return false;
    }

    if (value.length===6) {
      this.setState({ otpErrorMessage: '' });
    }else{
      return false;
    }

    const { step } = this.state;
    this.props.hideappValidation('');

    if (localStorage.getItem('fromWhere') != 'skip') {
      const obj = {
        'application_phone_number': this.state.phoneNo,
        'verify_code': value,
        'email': localStorage.getItem('email'),
        'Retailer_Account__c': cookie.get('retailerId')
      }
      const result = await verifyPhonenumberOtp(obj);
      if (result !== undefined) {
        if (result.status === 200) {
          // window.$('#otpModel').modal('hide');
          this.props.showappValidation('');
          this.props.removeSavedata([]);
          this.PrepopulateHideData();
          localStorage.setItem('applicationId', result.data.data.application_id);
          localStorage.setItem('flxPersonConfirmEmail__c', '');
          localStorage.setItem('flxPersonEmail__c', '');
          this.setState({ hasOtpSendMessage: result.data.message });
          this.setState({ stepOne: 'completed', loading: false, otpVerify: true, otpVerifyStatus: result.status });

        }
        // check whether the status code is 403 and assign the response from the variable result to respective state variables
         else if(result.status === 403) {
          let errorMessage = result.data.message
          if (this.extractEmails(errorMessage) != null) {
            let newErrorMessage = '';
            let extractErrorMessage = this.extractEmails(errorMessage);
            extractErrorMessage.map((src) => {
              let anchorTag = "<a target='_blank' style='color: #ff6200' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
              newErrorMessage = errorMessage.replace(src, anchorTag);
              errorMessage = newErrorMessage;
            })
          }
          this.setState({serverErrorMessage: errorMessage});
          this.setState({ 
            hasOtpSendMessage: result.data.data.message,
             loading: false, otpVerify: true, otpVerifyStatus: result.status });
        } else if (result.status === 503) {
          this.setState({
            step: step,
            otpVerify: false,
            otpVerifyStatus: result.status,
            hasOtpSendMessage: result.data.data.message
          });
          this.setState({ loading: false })
          this.setState({ otpErrorMessage: result.statusText })
        } else {
          this.setState({
            step: step,
            otpVerify: false,
            otpVerifyStatus: result.status,
            hasOtpSendMessage: result.data.data.message
          });
          this.setState({ loading: false })
          this.setState({ otpErrorMessage: result.data.message })
        }
      }
    }
    if (localStorage.getItem('fromWhere') === 'skip') {

      const appobj = {
        "application_phone_number": this.state.phoneNo,
        "user_type": "6",
        "verify_code": value
      };

      const result = await ApplicationverifyPhonenumberOtp(appobj);
      if (result.status === 200 && result.data.message === "Please enter the verification code we've sent by SMS") {
        this.setState({ hasOtpResendMessage: 'Verification code resent to your phone number' });
        return false;
      } else if (result.status === 200 && result.data.message === 'Application phone number verified') {
        localStorage.setItem('sessionId', result.data.data.session_id);
        localStorage.setItem('applicationId', result.data.data.application_id);
        localStorage.setItem('csrfToken',result.data.data.csrf_token);
        this.initialLoad();
        // window.$('#otpModel').modal('hide');
        this.props.showappValidation('');
        this.props.removeSavedata([]);
        
        // this.setState({
        //   step: step + 1
        // });
        window.scrollTo(0, 0);
        this.setState({ stepOne: 'completed' })
        this.setState({ loading: false })
        this.setState({ hasOtpSendMessage: result.data.message, otpVerify: true, otpVerifyStatus: result.status });

      } else if (result.status === 503) {
        this.setState({
          step: step,
          otpVerify: false,
          otpVerifyStatus: result.status,
          hasOtpSendMessage: result.data.data.message
        });
        this.setState({ loading: false })
        this.setState({ otpErrorMessage: result.statusText })
      } else {
        this.setState({
          step: step,
          otpVerify: false,
          otpVerifyStatus: result.status,
          hasOtpSendMessage: result.data.data.message
        });
        this.setState({ loading: false })
        this.setState({ otpErrorMessage: result.data.message })
      }
    }
  }


  resendOTP = async () => {
    analyticsClickEvent('resend_code','')
    const obj = {
      'email': localStorage.getItem('email'),
      'application_phone_number': this.state.phoneNo
    }
    if (localStorage.getItem('fromWhere') !== 'skip') {
      const result = await phonenumbervalidation(obj);
      if (result.status === 200 && result.data.message === "Please enter the verification code we've sent by SMS") {
        this.setState({ hasOtpResendMessage: 'Verification code resent to your phone number' });
        return false;
      } else if (result.status === 200 && result.data.message === 'Application phone number verified') {
        localStorage.setItem('applicationId', result.data.data.application_id);
        return false;
      }
    }
    if (localStorage.getItem('fromWhere') == 'skip') {

      const appobj = {
        "application_phone_number": this.state.phoneNo,
        "user_type": 6,
        "verify_code": this.state.otpNumber
      }
      const result = await applicationphonenumbervalidation(appobj);
      if (result.status === 200 && result.data.message === "Please enter the verification code we've sent by SMS") {
        this.setState({ hasOtpResendMessage: 'Verification code resent to your phone number' });
        return false;
      } else if (result.status === 200 && result.data.message === 'Application phone number verified') {
        localStorage.setItem('applicationId', result.data.data.application_id);
        localStorage.setItem('sessionId', result.data.data.sesssion_id);
        return false;
      }
    }
  }

  paginationSlider = () => {
    return <div className="top-nav-bar">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a className={`nav-link  ${this.state.stepOne === 'completed' ? 'hide-nav-bar' : ''} ${this.state.step === 1 ? 'active' : this.state.stepOne}`} id="stepOne-tab" data-toggle="tab" onClick={() => this.tabonchange('1')}

            role="tab" aria-controls="stepOne" aria-selected="true">
            <div className="progress-item">
              <span className="default"></span>
              <label>Step One</label>
            </div>
          </a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${this.state.stepOne === 'completed' ? '' : 'hide-nav-bar'}  ${this.state.step === 2 ? 'active' : this.state.stepTwo}`} id="stepTwo-tab" data-toggle="tab" onClick={() => this.tabonchange('2')}

            role="tab" aria-controls="stepTwo" aria-selected="false">
            <div className="progress-item">
              <span className="default"></span>
              <label>Step Two</label>
            </div>
          </a>
        </li>
        <li className="nav-item">
          <a className={`nav-link  ${this.state.stepTwo === 'completed' ? '' : 'hide-nav-bar'} ${this.state.step === 3 ? 'active' : this.state.stepThree}`} id="stepThree-tab" data-toggle="tab" onClick={() => this.tabonchange('3')}

            role="tab" aria-controls="stepThree" aria-selected="false">
            <div className="progress-item">
              <span className="default"></span>
              <label>Step Three</label>
            </div>
          </a>
        </li>
        <li className="nav-item">
          {/* <a className={`nav-link ${this.state.stepThree == 'completed' ? '' : ''} ${this.state.step == 4 ? 'active' : this.state.stepFour}`} id="stepFour-tab" data-toggle="tab" onClick={() => this.tabonchange('4')} */}
          <a className={`nav-link ${this.state.stepThree === 'completed' ? '' : 'hide-nav-bar'} ${this.state.step === 4 ? 'active' : this.state.stepFour}`} id="stepFour-tab" data-toggle="tab" onClick={() => this.tabonchange('4')}

            role="tab" aria-controls="stepThree" aria-selected="false">
            <div className="progress-item">
              <span className="default"></span>
              <label>Step Four</label>
            </div>
          </a>
        </li>
      </ul>
    </div>
  }

  onKeyPressData = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let invalidChars = [
      "e",
      "-",
      "+",
      ",",
      ".",
      "''"
    ];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
    if (/(([0-9]+)(\.|,)([0-9]){2})/g.test(event.key)) {
      event.preventDefault();
      return false;
    }
    if (name === 'otp_verify_code') {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
        return false;
      }
      if (this.state.otpNumber.length === 6) {
        event.preventDefault();
        return false;
      }
      if (this.state.otpNumber !== '') {
        this.setState({ otpErrorMessage: '' });
      } else {
        this.setState({ otpErrorMessage: value });
        this.setState({ otpErrorMessage: 'Verification code is required' });
        return false;
      }

      if (value.length > 6) {
        return false;
      }
    }
  }


  redirectCodeScreen = () => {
   
    if(this.state.isContinuePrevAmount === false){
      analyticsClickEvent('cancel','')
    }else{
      analyticsClickEvent('popup_ok','');
    }
   
    window.$('#commonModel').modal('hide');
    window.$('#userBalanceShowModel').modal('hide')
    window.$('.modal-backdrop').removeClass('show');
    window.$('body').removeClass('modal-open');
    window.$('.modal-backdrop').remove();
    if (this.state.unableToProvide === false) {
      this.props.appValidationsRemove([]);
      this.props.getAmountRequested('');
      this.props.showappValidation('');
      this.props.removeSavedata([]);
      this.props.history.push("/home");
    }

  }

  onlyAcceptInputs = (e) => {
    if (!/[0-9]/.test(e.clipboardData.getData('Text'))) {
      e.preventDefault();
    } else if (/[a-zA-Z]/.test(e.clipboardData.getData('Text'))) {
      e.preventDefault();
    }
    if (e.target.name === 'otp_verify_code') {
      if (e.clipboardData.getData('Text').length > 6) {

        e.preventDefault();
        return false
      }
    }
  }


  




  render() {
    const { width } = this.state;
    const isMobile = width <= 768;
    const { step } = this.state;


    if (step !== 0) {


      return (
        <div className="wrapper">
          <Loader loaderStatus={this.state.loading} />
          <section className="main">
            <div className="container">

              { !this.state.privacyPolicyCheck && 
                <div className="top-nav-bar mt-4">
                  <div className="d-flex justify-content-center">
                    <h2>Important Information</h2>
                  </div>
                  <div className="mt-4">
                    <p className="mb-0">
                      <span className="font-weight-bold">Before you apply&nbsp;</span>
                      for 
                      <span className="font-weight-bold font-italic">&nbsp;humm:</span>
                    </p>

                    <ul>
                      <li className='mb-2 mt-2'>Please ensure <span>you meet all our </span> 
                        <a href="https://www.shophumm.com/uk/faqs/am-i-eligible-for-humm/" target='_blank' rel="noopener noreferrer" className='apply-important-info-link' >
                        eligibility criteria</a>
                        &nbsp;before proceeding</li>
                      <li className='mb-2'>Please <span className='font-weight-bold'>read our</span>&nbsp;
                       <a href="https://www.shophumm.com/uk/privacy-policy/" target='_blank' rel="noopener noreferrer"
                        className='apply-important-info-link'>
                         Privacy Policy</a>
                       &nbsp;which sets out the basis on which we will process your personal data when you use our services</li>
                      <li className='mb-2'>Please note that we <span className='font-weight-bold'>will perform a hard credit check</span> if you proceed 
                      to submit an application. You’ll be reminded of this before you actually submit your application 
                      </li>
                    </ul>

                    <p className='mt-3'>
                      The personal information we  collect from you will also be <span className="font-weight-bold">shared with fraud prevention agencies</span> who will use it 
                      to prevent fraud and money-laundering and to verify your identity. If fraud is detected, you could be refused 
                      certain services, finance, or employment. Further details of how your information will be used by us and these fraud 
                      prevention agencies, and your data protection rights, can be found by reviewing our&nbsp;
                      <a href="https://www.shophumm.com/uk/privacy-policy/" target='_blank' rel="noopener noreferrer"
                      className='apply-important-info-link'>
                      Privacy Policy</a> or from&nbsp;
                      <a href="https://www.cifas.org.uk/fpn" target='_blank' rel="noopener noreferrer"
                      className='apply-important-info-link'>
                      Cifas.</a> 
                    </p>

                    <p>
                    If you are eligible for a loan you will be required to enter into a loan agreement with us.  
                    An example loan agreement setting out the terms and conditions that apply when you use&nbsp;
                    <span className="font-weight-bold font-italic">humm</span>&nbsp;is available&nbsp;
                    <a href="https://www.shophumm.com/uk/example-loan-agreement/" target='_blank' rel="noopener noreferrer"
                    className='apply-important-info-link'>
                    here.</a>
                    </p>

                    <p>
                    To get a <span className='font-weight-bold'>quote</span> before making an application,&nbsp;
                    <Link to="/quote-calculator" 
                    className='apply-important-info-link' 
                    onClick={() => {localStorage.setItem('prevRoute',window.location.pathname)}}
                    >click here
                    </Link>.
                    </p>

                    <p>By clicking "<span className='font-weight-bold'>Apply Now</span>"
                     you agree that you have read and accepted all of the important information above. </p>
                  </div>
                  <div className="modal-footer d-flex justify-content-center mt-2 mb-3"
                  style={{borderTop: "none"}}>
                    <button type="button" className="btn btn-primary" onClick={ () => 
                    { analyticsClickEvent('application_information_apply', '')
                     this.setState({privacyPolicyCheck: true})} }>Apply Now</button>
                  </div>
                </div>
              }

              {this.state.privacyPolicyCheck &&
              <div className="appfrom-section">
                {this.paginationSlider()}



                <div className="below-form-section">

                  <div className={`tab-pane fade ${this.state.step === 1 ? 'active show' : ''}`} id="stepOne" role="tabpanel" aria-labelledby="stepOne-tab">
                    <div className="step-two-section">
                      <div className="form-row align-items-end m-0">
                        <div className="application-phone-screen">
                          <div className="phone-section">
                            <h4 className="application-sub-title">Let’s start by getting your <br></br>mobile number</h4>
                            <form>
                              <div className="form-group">
                                <label>Phone Number</label>
                                <input type="text" onWheel={(event) => { event.target.blur() }} id='phoneNumber' value={this.state.phoneNo} name="phoneNo"
                                  onKeyPress={(event) => { this.onKeyPressData(event) }} onClick = {(e) => analyticsClickEvent('mobile_number','')} onPaste={(e) => { this.onlyAcceptInputs(e) }}
                                  className="form-control" onChange={(e) => this.handleChangeInput(e)} placeholder="Phone No" />
                                {this.state.displayPhoneError != '' &&
                                  <p className="error-message"><span className="app-error-message" style={{ color: 'Red' }}>{parse(this.state.displayPhoneError)}</span> </p>
                                }<br />                                
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>                      
                      <div className="next-btn  text-center mt-0">
                        <button type="button" className="btn btn-primary mt-1" id="btn-one" onClick={() => this.nextStep('one')}>Next</button>
                      </div>
                      <div className="support-section text-center mt-3"  style={{maxWidth: "325px", margin:"0 auto", width: "100%"}}>
                        <p className="mb-0" style={{textAlign: "left", fontWeight:"600",color:"#212529"}}>Need help?</p>
                        <p className='mt-1' style={{textAlign: "left"}}>                          
                          Click&nbsp;
                          <a href="https://www.shophumm.com/uk/contact-us/customer-contact/" target='_blank' rel="noopener noreferrer"
                          style={{WebkitTextFillColor: "#ff6200",display: 'inline', cursor: "pointer", textDecoration: "underline", textDecorationColor: "#ff6200"}}
                          >here</a>
                          &nbsp;to contact us</p>
                      </div>

                      <div className='footer-text text-center mt-4' style={{maxWidth: "325px", margin:"0 auto", width: "100%"}}>
                        <p style={{textAlign: "left"}}> <span style={{fontWeight: "600",display: "inline",fontSize:"16px",color: "#212529"}}>
                          humm</span> is a trading style of Humm Group Limited which is authorised and regulated by the Financial Conduct Authority. Financial Services Registration Number 954478. 
                        <span style={{fontWeight: "600",display: "inline",fontSize:"16px",color: "#212529"}}>
                          &nbsp;humm</span> offers both regulated and unregulated products. Our interest free plans which are repayable within 12 months and in no more than 12 instalments are not regulated by the Financial Conduct Authority. 
                          Registered office: 3rd Floor, 2-4 Wellington Street, Belfast. Company Number: NI675430. Data Protection Register Number: ZB029507</p>
                      </div>

                    </div>
                  </div>

                  <div className={`tab-pane fade ${this.state.step === 2 ? 'active show' : ''}`} id="stepTwo" role="tabpanel" aria-labelledby="stepTwo-tab">
                    <div className="step-two-section">

                      <form autoComplete="nope">
                        <div className="form-row align-items-end m-0">
                          {this.state.stepOnedata ? this.state.stepOnedata.map((data, i) =>
                            <Element fieldItem={data.sections} ref={this.child} stage={'1'} mobileno={this.state.phoneNo} change={this.handleChange} showValidation={this.state.displayErrorsStepOne} />
                          ) : <span></span>}
                        </div>
                        <div className="next-btn  text-center">
                          <button type="button" className="btn btn-primary" onClick={() =>this.stepTwofunc('two')}>Next</button>
                        </div>
                        <div className='footer-text text-center mt-4' style={{margin:"0 auto", width: "100%"}}>
                          <p style={{textAlign: "left"}}> <span style={{fontWeight: "600",display: "inline",fontSize:"16px",color: "#212529"}}>
                            humm</span> is a trading style of Humm Group Limited which is authorised and regulated by the Financial Conduct Authority. Financial Services Registration Number 954478. 
                          <span style={{fontWeight: "600",display: "inline",fontSize:"16px",color: "#212529"}}>
                            &nbsp;humm</span> offers both regulated and unregulated products. Our interest free plans which are repayable within 12 months and in no more than 12 instalments are not regulated by the Financial Conduct Authority. 
                          Registered office: 3rd Floor, 2-4 Wellington Street, Belfast. Company Number: NI675430. Data Protection Register Number: ZB029507</p>
                       </div>
                      </form>
                    </div>
                  </div>
                  <div className={`tab-pane fade ${this.state.step === 3 ? 'active show' : ''}`} id="stepThree" role="tabpanel" aria-labelledby="stepThree-tab">
                    <div className="step-two-section step-three">
                      <form autoComplete="nope">
                        <div className="form-row align-items-end m-0">
                          {this.state.stepTwodata ? this.state.stepTwodata.map((data, i) =>
                            <Element fieldItem={data.sections} stage={'2'} change={this.handleChange} showValidation={this.state.displayErrorsStepTwo} />
                          ) : <span></span>}
                        </div>
                        <div className="next-btn  text-center mt-5">
                          <button type="button" className="btn btn-primary" onClick={() => this.nextStep('three')}>Next</button>
                        </div>
                        <div className='footer-text text-center mt-4' style={{margin:"0 auto", width: "100%"}}>
                          <p style={{textAlign: "left"}}><span style={{fontWeight: "600",display: "inline",fontSize:"16px",color: "#212529"}}>
                            humm</span> is a trading style of Humm Group Limited which is authorised and regulated by the Financial Conduct Authority. Financial Services Registration Number 954478. 
                            <span style={{fontWeight: "600",display: "inline",fontSize:"16px",color: "#212529"}}>
                              &nbsp;humm</span> offers both regulated and unregulated products. Our interest free plans which are repayable within 12 months and in no more than 12 instalments are not regulated by the Financial Conduct Authority. 
                            Registered office: 3rd Floor, 2-4 Wellington Street, Belfast. Company Number: NI675430. Data Protection Register Number: ZB029507</p>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className={`tab-pane fade ${this.state.step === 4 ? 'active show' : ''}`} id="stepFour" role="tabpanel" aria-labelledby="stepFour-tab">
                    <div className="step-two-section">
                      {this.state.isEnableDesktopCapture === false &&
                        <span>
                          {isMobile === true && (
                            <DocumentVerify
                              ref={this.miteck}
                              handleViewChange={this.tabonchange}
                            />
                          )}
                          {isMobile === false && this.state.showQR === true && ( 
                            <div>
                              <h4 class="application-qr-sub-title">
                                Start confirming your identity <br />
                                Scan the QR Code to continue capturing on your mobile
                              </h4>
                              <QrCode ref={this.qrRef} enableWebCapture={this.isEnableDesktopCapture} />

                            </div>

                           )}
                        </span>

                      }
                      {this.state.isEnableDesktopCapture === true &&
                        <DocumentVerify ref={this.miteck} handleViewChange={this.tabonchange} />
                      }
                      <div className='footer-text text-center mt-4' style={{maxWidth: "325px",margin:"0 auto", width: "100%"}}>
                          <p style={{textAlign: "left"}}><span style={{fontWeight: "600",display: "inline",fontSize:"16px",color: "#212529"}}>
                            humm</span> is a trading style of Humm Group Limited which is authorised and regulated by the Financial Conduct Authority. Financial Services Registration Number 954478. 
                          <span style={{fontWeight: "600",display: "inline",fontSize:"16px",color: "#212529"}}>
                            &nbsp;humm</span> offers both regulated and unregulated products. Our interest free plans which are repayable within 12 months and in no more than 12 instalments are not regulated by the Financial Conduct Authority. 
                            Registered office: 3rd Floor, 2-4 Wellington Street, Belfast. Company Number: NI675430. Data Protection Register Number: ZB029507</p>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane fade ${this.state.step === 5 ? 'active show' : ''}`} id="stepFive" role="tabpanel" aria-labelledby="stepFive-tab">
                    <div className="step-five-item">
                      <p>The final step we will require some documents to verify and approve your application.</p>
                      <p>Please check your mobile phone now for a link we have sent you via text message. Once that is complete we will notify you as soon as possible with a decision.</p>
                      <p>Thanks! </p>
                    </div>
                  </div>

                </div>
                {/* section close */}
              </div>
              }
            </div>
          </section>
          <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">�</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p className="mt-4">You will Be Lose Data</p>
                </div>
                <div className="modal-footer mb-4">
                  <button type="button" className="btn btn-primary">Ok</button>
                </div>
              </div>
            </div>
          </div>
          {/* otp model */}

          <div className="modal fade model-error" id="otpModel" aria-labelledby="otpModelLabel" tabIndex="-1" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button> */}
                </div>
                <div className="modal-body">
                  <div className="logo">
                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                  </div>
                  <h5 className="modal-title " id="otpModelLabel">Verification Code</h5>
                  {/* <p className="text-center mt-4">Please enter the verification code we've sent by SMS</p> */}
                  <p className="text-center mt-4">{this.state.hasOtpSendToUserMessage}</p>

                  <div className="form-group modal-error-message">
                    <input
                      type="number"
                      disabled={this.state.otpVerifyStatus === 200 ? true : false}
                      className="form-control"
                      id="otp_verify_code"
                      name="otp_verify_code"
                      onKeyPress={(event) => { this.onKeyPressData(event) }}
                      value={this.state.otpNumber}
                      onChange={this.handleChangeOtpInput}
                      autoComplete="nope"
                      placeholder="Enter verification code"
                      onPaste={(e) => { this.onlyAcceptInputs(e) }}
                      onClick={(e)=>analyticsClickEvent('verification_code','')}
                    />
                    {/* {this.state.otpErrorMessage != '' || this.state.hasOtpSendMessage != '' || */}
                    <p className="error-message code-error my-account-error">
                      {this.state.otpVerifyStatus === 200 ? <span className="app-error-message" style={{ color: 'green' }}>{this.state.hasOtpSendMessage}</span> : <span className="app-error-message" style={{ color: 'Red' }}>{this.state.otpErrorMessage}</span>}
                      {/* If the otpVerifyStatus is 403, display the message to the user */}
                      {this.state.otpVerifyStatus == 403 && 
                        <span className="app-error-message" style={{ color: 'green' }}>{this.state.hasOtpSendMessage}</span>
                      }
                    </p>
                    {/* } */}
                  </div>
                </div>
                <p className="text-center modal-error-msg">DIDN'T RECEIVE CODE? <a onClick={() => this.resendOTP()}>RESEND CODE</a>
                  <p className="text-center modal-error-msg">{this.state.hasOtpResendMessage !== '' &&
                    <span style={{ color: 'green' }}>{this.state.hasOtpResendMessage}</span>
                  }</p></p>


                <div className="modal-footer mb-4">
                  <button
                    type="button"
                    data-toggle="modal"
                    disabled={this.state.isEnabled}
                    className="btn btn-primary" id="verify-otp"
                    onClick={this.gotoStepTWo}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          
          {/* session Expired */}

          <div className="modal fade model-error" id="sessionExpiredModal" aria-labelledby="sessionExpiredModalLabel" tabIndex="-1" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button> */}
                </div>
                <div className="modal-body">
                  <div className="logo">
                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                  </div>
                  <h5 className="modal-title " id="sessionExpiredModalLabel">Message</h5>
                  {/* <p className="text-center mt-4">Please enter the verification code we've sent by SMS</p> */}
                  <p className="text-center mt-4">Your session has expired. Please verify again to proceed</p>
                </div>

                <div className="modal-footer mb-4">
                  <button
                    type="button"
                    data-toggle="modal"
                    className="btn btn-primary" id="session-expired-button"
                    data-dismiss="modal"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* stage save message modal */}
          <div className="modal fade model-error" id="commonModel" tabIndex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="commonModellLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div class="modal-header">
                </div>
                <div className="modal-body">
                  <div class="logo">
                    <img src="images/modal-logo.svg" alt="logo" class="img-fluid" />
                  </div>
                  {/* <h5 className="modal-title " id="commonModellLabel">Message</h5> */}

                  <p className="text-center mt-4" >{parse(this.state.hasErrorMessage)}</p>
                </div>

                <div className="modal-footer mb-4">
                  <button type="button" className="btn btn-primary" onClick={() => this.redirectCodeScreen()}>Ok</button>
                </div>


              </div>
            </div>
          </div>

          {/* field error show modal */}

          <div className="modal fade model-error" id="fieldErrorShowModel" tabIndex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="fieldErrorShowModellLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div class="modal-header">
                </div>
                <div className="modal-body">
                  <div class="logo">
                    <img src="images/modal-logo.svg" alt="logo" class="img-fluid" />
                  </div>
                  {/* <h5 className="modal-title " id="fieldErrorShowModellLabel">Message</h5> */}
                  <div>
                  <p className="text-center mt-4">There are <b>{this.state.errorCount}</b> required fields that are either blank or incorrect. Please check again.</p>
                  {this.state.errorCount<=3 &&
                  <div>
                  {this.state.requiredFieldCount!=0 &&
                  <h5 className="text-center mt-4 modal-title">Missing Fields</h5>
                  }
                  {this.state.LabelNames!=undefined && this.state.LabelNames.map((response, index) =>
                   <li className="text-center mt-4">{response}</li>)
                   }
                   </div>
                  }
                   </div>
                </div>

                <div className="modal-footer mb-4">
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => this.analyticsClickEvent('fieldErrorShowModel')}>Ok</button>
                </div>


              </div>
            </div>
          </div>
          {/* userNoteShowModel show modal */}
          <div className="modal fade model-error" id="userNoteShowModel" tabIndex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="userNoteShowModelLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div class="modal-header">
                </div>
                <div className="modal-body">
                  <div className="text-center">                    
                    <h5 style={{fontWeight: "700"}}>Note</h5>
                  </div>

                  <p className='text-center mt-2' style={{color: '#212529', fontFamily: "Proxima Nova, Fallback, sans-serif"}}>
                  Please review the data you have input in particular:
                  </p>
                  <ul>
                    <li>
                    Make sure the income is your net monthly income
                    </li>
                    <li>Make sure you have included your monthly mortgage or rent payment</li>
                    <li>Make sure you have included all current loan and credit 
                      card payments plus other commitments such as child maintenance</li>
                    <li>Make sure you have included monthly living costs for you and any dependants</li>
                  </ul>

                  <p style={{color: "#212529", fontFamily: "Proxima Nova, Fallback, sans-serif"}}>
                    If you need to make changes select <a href="javascript:void(0)" onClick={() => window.$('#userNoteShowModel').modal('hide')}
                    style={{ WebkitTextFillColor: '#ff6200', display: 'inline', cursor: 'pointer', textDecoration: "underline", textDecorationColor: "#ff6200" }}>Amend</a>, 
                    otherwise select <span style={{fontWeight: "600"}}>Continue.</span>
                  </p>
                </div>

                <div className="modal-footer mb-4">
                  <button type="button" className="btn btn-primary" onClick={() => this.nextStep('two')}>Continue</button>
                </div>


              </div>
            </div>
          </div>
          {/* userBalanceShowModel show modal */}
          <div
            className="modal fade model-error"
            id="userBalanceShowModel"
            tabIndex="-1"
            role="dialog"
            data-keyboard="false"
            data-backdrop="static"
            aria-labelledby="userBalanceShowModellLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div class="modal-header"></div>
                <div className="modal-body">
                  <div class="logo">
                    <img
                      src="images/modal-logo.svg"
                      alt="logo"
                      class="img-fluid"
                    />
                  </div>
                  {/* <h5 className="modal-title " id="userBalanceShowModellLabel">
                    Message
                  </h5> */}
                  <p className="text-center mt-4" id="prevamtmsg">
                    {parse(this.state.approvedApplicationMessage)}
                  </p>
                </div>

                <div className="modal-footer mb-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() => this.nextStep('one')}
                  >
                    New Application
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={(e) => this.redirectCodeScreen(e)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* show server error message */}
          <div className="modal fade model-error" id="serverErrorShowModel" tabIndex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="serverErrorShowModellLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div class="modal-header">
                </div>
                <div className="modal-body">
                  <div class="logo">
                    <img src="images/modal-logo.svg" alt="logo" class="img-fluid" />
                  </div>
                  {/* <h5 className="modal-title " id="serverErrorShowModelLabel">Message</h5> */}
                  {/* Added the margin left and right spacing to message displayed in popup */}
                  <p className="text-center mt-4 mx-3">{parse(this.state.serverErrorMessage)}</p>
                </div>

                <div className="modal-footer mb-4">
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => analyticsClickEvent('ok','')}>Ok</button>
                </div>


              </div>
            </div>
          </div>
        </div>

      );
    } else {
      return (
        <ApplicationLanding
          nextStep={this.nextStep}
        />
      );
    }
  }
}

export const mapStateToProps = (state) => {
  return {
    getApplicationQuestions: state.dataStore.getApplicationQuestions,
    applicationValidation: state.dataStore.applicationValidation,
    showAppValidations: state.dataStore.showAppValidations,
    phoneNumbers: state.dataStore.phoneNumbers,
    saveDates: state.dataStore.saveDates,
    saveApplicationData: state.dataStore.saveApplicationData,
    amountRequested: state.dataStore.amountRequested,
    employmentStatus: state.dataStore.employmentStatus,
    threashold: state.dataStore.threashold,
    prepopulateStatus: state.dataStore.prepopulateStatus,
    questionValueSave: state.dataStore.questionValueSave,
    showCurrentAddress: state.dataStore.showCurrentAddress,
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    questions: () => {
      return dispatch(questions());
    },
    showappValidation: (validations) => {
      return dispatch({
        type: 'SHOW_APP_VALIDATIONS',
        value: validations
      });
    },
    hideappValidation: (validations) => {
      return dispatch({
        type: 'HIDE_APP_VALIDATIONS',
        value: validations
      });
    },
    getPhoneNumber: (validations) => {
      return dispatch({
        type: 'PHONENO',
        value: validations

      });
    },
    appvalidations: (validations) => {
      return dispatch({
        type: 'APP_VALIDATIONS',
        value: validations
      });
    },
    saveApplicationDatas: (data) => {
      return dispatch({
        type: 'SAVEDATA',
        value: data
      });
    },
    appValidationsRemove: (validations) => {
      return dispatch({
        type: 'APP_VALIDATIONS_Remove',
        value: validations
      });
    },
    getAmountRequested: (validations) => {
      return dispatch({
        type: 'AMOUNTREQUESTED',
        value: validations
      });
    },
    prePopulate: (validations) => {
      return dispatch({
        type: 'PREPOPOLATE',
        value: validations
      });
    },

    removeSavedata: (validations) => {
      return dispatch({
        type: 'REMOVEDATA',
        value: validations
      });
    },
    prepopulateStatusCall: (validations) => {
      return dispatch({
        type: 'PREPOPULATEE',
        value: validations
      });
    },
    questionSave: (data) => {
      return dispatch({
        type: 'QUESTIONSAVE',
        value: data
      });
    },
    saveDate: (data) => {
      return dispatch({
        type: 'SAVEDATE',
        value: data
      });
    },
    removeQuestionSave: (data) => {
      return dispatch({
        type: 'REMOVEQUESTIONSAVE',
        value: data
      });
    },
    showAddress:(data) => {
      return dispatch({
        type: 'SHOWADDRESS',
        value: data
      });
    },
    
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Application);