import React from 'react';
import { Link } from 'react-router-dom';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import {addFavoritePartner} from '../../action/partner/addFavoritePartner';
import { addViewPartner } from '../../action/partner/addViewPartners';
import {removeFavoritePartner} from '../../action/partner/removeFavoritePartner';



class ViewedPartnerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            value:'',
            isFavAdd:'',
            isFavRemove:'',
            itemList:[]
        }
        
        this.handleFavoriteAdd = this.handleFavoriteAdd.bind(this)
        this.handleFavoriteRemove = this.handleFavoriteRemove.bind(this)
        this.getupdater = this.getupdater.bind(this);
        
    }


     getupdater = async(partnerID,partnerName,partnerWebsitLink)=>{
        if(partnerWebsitLink == '' || partnerWebsitLink == null || partnerWebsitLink == undefined){
            return false;
          }
        let splitPartnerName = partnerName.split(' ').join('_');
analyticsClickEvent('viewed_partner',splitPartnerName)
        const obj={
            email:localStorage.getItem('email'),
            account:partnerID
        }
        await addViewPartner(obj);
        this.props.updaterecentviewpartner();
       
        }

    componentDidMount(){
        this.setState({itemList:this.props.itemList});
    }

    
    viewedPartnerChange=(viewList)=>{
        this.setState({itemList:viewList});
    }

    
    
    async handleFavoriteAdd(value,partnerName){
        let splitPartnerName = partnerName.split(' ').join('_');
        // analyticsClickEvent(splitPartnerName+'_partner_image_favorite_icon');
        analyticsClickEvent('viewed_heart_checked',splitPartnerName);
        const addDataParam={email:localStorage.getItem('email'),account:value};
        const addFavoriteResponse = await addFavoritePartner(addDataParam);
        const tempData = addFavoriteResponse.data;
        let currentState = this.state.itemList;
        let addFavUpdated = currentState.map((item)=>{
            if(item.partnerId === tempData.account){
                item.partnerFavId = tempData.id
            
            }
            return item;
        })

         this.setState({itemList:addFavUpdated});
         this.props.handleViewChange();
       
    }

    async handleFavoriteRemove(value,partnerName){
        let splitPartnerName = partnerName.split(' ').join('_');
        // analyticsClickEvent(splitPartnerName+'_partner_image_unfavorite_icon');
        analyticsClickEvent('viewed_heart_unchecked',splitPartnerName);
        const removeDataParam={email:localStorage.getItem('email'),account:value};

        removeFavoritePartner(removeDataParam);
        let currentState = this.state.itemList;
        let removeFavUpdated = currentState.map((item)=>{
            if(item.partnerId === value){
                item.partnerFavId = null
            
            }
            return item;
        })

         this.setState({itemList:removeFavUpdated});
         this.props.handleViewChange();
       
     }

     checkURL=(url)=>{
        if(url!=null && url!=undefined && url!=''){
           let pattern = /^((http|https|ftp):\/\/)/;

           if(!pattern.test(url)) {
            return "http://" + url;
           }else{
            return url;
           }
        }else{
           return url 
        }
       
    }



    
    render() { 
        return (  
            <div className="gallery-partner">
            { this.state.itemList &&
            this.state.itemList.map(response =>  <div className="featured-box partner-item">
            <div id={response.partnerId} class="featured-itemQr">
            <div className="recent-view-section">
                <a target="_blank" rel="noreferrer" href={this.checkURL(response.partnerWebSite)} onClick={e => this.getupdater(response.partnerId,response.partnerName,response.partnerWebSite)}>
                    <div class="partner-img">
                        {response.partnerCoverImage==undefined &&
                      <img src="images/compu.png" alt={response.partnerName} />
                }
                {response.partnerCoverImage!=undefined &&
                      <img src={response.partnerCoverImage} alt={response.partnerName} />
                }
                        <div class="overlay">
                        </div>
                    </div>
                </a>
                <div className="fav-icon-section">
                {response.partnerFavId===undefined &&(<a value={response.partnerId}  onClick={e => this.handleFavoriteAdd(response.partnerId,response.partnerName)} className='fav-icon-btn'><img src="images/heart-white.svg" alt="Fav" /></a>) }
                    {response.partnerFavId!==undefined &&(<a value={response.partnerId} onClick={e => this.handleFavoriteRemove(response.partnerId,response.partnerName)} className='fav-icon-btn active'><img src="images/heart.svg" alt="Fav-active" /></a>) }
                </div>   
                <a target="_blank" rel="noreferrer" href={this.checkURL(response.partnerWebSite)} onClick={e => this.getupdater(response.partnerId,response.partnerName,response.partnerWebSite)}>                        
                    <div className="overlay-logo-item">
                        <span class="featured-caption">
                            <div class="slider-logo-image">
                                {response.partnerLogo != null &&
                                   <img src={response.partnerLogo} alt={response.partnerName}/>
                                }
                                {response.partnerLogo == null &&
                                    <h6 >{response.partnerName}</h6>
                                }
                            </div>
                        </span>
                    </div>
                </a>
                <div className="qtwo-calculator">
                    <Link
                        to={{
                            pathname: "/quote-calculator",
                            state: { id: response.partnerId, store_Name: response.partnerName }
                        }} onClick={() => {localStorage.setItem('prevRoute',window.location.pathname)}}
                    >
                        <button className="qc-click-btn"
                        onClick={(_e) => { analyticsClickEvent('partner_quote_calculator', '') }}
                        >
                             <span className=''>
                                Get A Quote                                
                            </span>
                            <img src="images/right-arrow-shop.svg" width={25} alt="icon" />
                        </button>
                    </Link>
                </div>
            </div>
            </div>
        </div>) 
        } 
            </div>
  
        
        );
    }
}
 
export default ViewedPartnerList;