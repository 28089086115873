import axios from 'axios';
import config from '../config';
import {sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

export const manualRegisterVerifyEmail = async (formData) => {
    let url = config.Humm_Dev_Link+'user/ResendActivationCode';

   return axios.post(url, formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        return err.response
    })


};

export const validateEmailCode = async (formData) => {
    let url = config.Humm_Dev_Link+'user/UserActivation';

   return axios.post(url, formData,{headers:sessionID()}).then(res => {
        return res;
    })
    .catch(async err => {
        await userLogs(url,formData,err);
        return err.response
    })


};


export const manualRegister = (formData) => {
    let url = config.Humm_Dev_Link+'user/create';

    return async (dispatch) => {
        let resp = [];
        await axios.post(url,  formData,{headers:sessionID()} )
            .then(response => {
                dispatch({
                    type: 'REGISTER_STORE',
                    value: response.data
                });
                resp = response;
                return resp;
               
            })
            .catch(async error => {
                await userLogs(url,formData,error);
                resp = error.response;
                return resp;
            });
            return resp;
    }
};



