import React from "react";
import { manualRegister, manualRegisterVerifyEmail, validateEmailCode } from "../../../action/auth/register";
import { emailCheck } from "../../../action/auth/login";
import { Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import SocialMedia from '../register/socialMedia';
import { hasPasswordEmpty } from '../../validation/passwordValidation';
import { analyticsClickEvent, analyticsLoginSuccessEvent, analyticsScreenViewEvent, analyticsTypeEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import $ from 'jquery';
import { getApplicationImage } from "../../../action/avatar/getAvatar";



export class Register extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            email: '',
            password: '',
            disable: '',
            errors: {
                userName: null,
                email: null,
                password: '',
                serverError: '',
            },
            isPasswordEmpty: false,
            isUppercase: false,
            isLowercase: false,
            isMinCharacter: false,
            isSpecialCharacter: false,
            isNumber: false,
            commonError: '',
            serverError: '',
            homePage: '',
            redirect: false,
            inputType: false,
            emailSuccess: false,
            isEnabled: true,
            code: '',
            codeError: '',
            codeSuccess: '',
            disableRegistration: '',
            disablePopupRegistration: '',
            hasEmailMessage: '',
            hasReadPrivacyPolicy: false,
            disableRegisterButton: true,
            isPasswordCheck: false,
            disableverifyokbtn:false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.handleOnKeyUp = this.handleOnKeyUp.bind(this);
        this.validateUserPassword = this.validateUserPassword.bind(this);
        this.redirectCodeScreen = this.redirectCodeScreen.bind(this);
        this.removeBlurModelPopup = this.removeBlurModelPopup.bind(this);
        this.changeRegisterBtnStatus = this.changeRegisterBtnStatus.bind(this);

    }

    componentDidMount() {
        // analyticsScreenViewEvent();
        window.addEventListener('popstate', this.removeBlurModelPopup);


    }

    analyticseveeent(e) {
        let text = e.target.innerText.replace(' ', '_');
        analyticsClickEvent(text,'')
    }
    removeBlurModelPopup() {
        if (window.$('#registerModal').hasClass('show') === false) {
            window.$('#registerModal').modal('hide');
            window.$('.modal-backdrop').removeClass('show');
            window.$('body').removeClass('modal-open');
            window.$('.modal-backdrop').remove();
        }
    }

    onKeyPressData = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
        if (name === 'code') {
            if (value.length === 5) {
                event.preventDefault();
                return false;
            }
        }
    }

    onlyAcceptInputs = (e) => {
        if (!/[0-9]/.test(e.clipboardData.getData('Text'))) {
            e.preventDefault();
        } else if (/[a-zA-Z]/.test(e.clipboardData.getData('Text'))) {
            e.preventDefault();
        }
    }

    // To check the username and return the lastname from the userName
    getLastName(splitName) {
        let total_lastName = '';
        let last_name_array = splitName.slice(2, splitName.length)
        last_name_array.map((name) => {
            total_lastName += name
        })
        return total_lastName;
    }

    // To check the username and return the firstName, middleName and lastName
    handleUsername(userName) {
        let splitName = userName.trim().replace(/\s+/g, " ").split(' ');

        let user_object = {
            firstName: '',
            middleName: '',
            lastName: '',
            name_length: ''
        }
        user_object.name_length = splitName.length
        user_object.firstName = splitName[0].trim();
        if(user_object.name_length && user_object.name_length > 2){
            user_object.middleName = splitName[1].trim();
            user_object.lastName = this.getLastName(splitName);
        }
        else {
            if(splitName[1] == undefined){
                user_object.lastName = ''
            }
            else {
                user_object.lastName = splitName[1].trim()
            }
        }

        return user_object;
        
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let pathname = window.location.pathname;
        let screenName = pathname.split('/');
        let params = {
         page_title:screenName[1],
         user_click_event:'type_'+name.toLocaleLowerCase()
        }
    //   analyticsTypeEvent(name.toLocaleLowerCase(),params)
        this.setState({ disableRegistration: '' })
        this.setState({ disablePopupRegistration: '' })

        this.setState({ [name]: value });
        let errors = this.state.errors;
        this.setState({ commonError: '' });
        this.setState({ serverError: '' })
        if (name === 'code') {
            if (!/[0-9]/.test(value)) {
                event.preventDefault();
            }
        }
        switch (name) {
            case 'userName':
                let result_Username;
                result_Username = this.handleUsername(value)               
                if (value.trim() === "") {
                    errors.userName = `Full Name is required`;
                } else if (/[^a-zA-Z '-]/.test(value)) {
                    errors.userName = "Invalid characters";
                } else if (value.trim().length < 3) {
                    errors.userName = `Full Name must be at least three characters`
                } else if (result_Username.lastName === "") {
                    errors.userName = `Please enter your Last Name`;
                } else if(result_Username.firstName.length > 40){
                    errors.userName = `First name should be less than 40 characters`
                } else if(result_Username.middleName.length > 40){
                    errors.userName = `Middle name should be less than 40 characters`
                } else if(result_Username.lastName.length > 70) {
                    errors.userName = `Last name should be less than 70 characters`
                }
                else {
                    errors.userName = '';
                }
                break;
            case 'email':

                if (
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
                        value
                    )
                ) {
                    errors.email = '';
                } else if (value.trim() === "") {
                    errors.email = "Email is required";
                } else {
                    errors.email = "Please enter a valid email";
                }

                break;
            case 'password':
                errors.password = this.validateUserPassword(value);
                break;
            default:
                errors.password = '';
                errors.email = '';
                errors.userName = '';
        }
        this.setState({ errors, [name]: value })
        if (errors.email) {
            this.setState({ emailSuccess: false });
        }

        let commonState = this.state;
        let SpecialState = commonState.errors;
        if (SpecialState.email === "" && SpecialState.userName === "" && SpecialState.password === "") {
            this.setState({ isEnabled: false });
        } else {
            this.setState({ isEnabled: true });
        }

    }

    validateUserPassword(value) {
        let userPasswordError = '';
        if(value.trim() === "") {
            this.setState({isPasswordEmpty: true});            
        }
        else {
            this.setState({isPasswordEmpty: false});
        }

        if (value.trim().length < 8) {
            this.setState({isMinCharacter: false})
        }
        else {
            this.setState({isMinCharacter: true})
        }

        if (value.search(/[@$!%*?&]/) < 0) {
            this.setState({isSpecialCharacter: false})    
                
        }
        else {            
            this.setState({isSpecialCharacter: true})
        }

        if (value.search(/[a-z]/) < 0) {
            this.setState({isLowercase: false})
        }
        else {
            this.setState({isLowercase: true})
        }

        if (value.search(/[A-Z]/) < 0) {
            this.setState({isUppercase: false})
        }
        else {
            this.setState({isUppercase: true})
        }

        if (value.search(/\d/) < 0) {
            this.setState({isNumber: false})            
        }
        else {
            this.setState({isNumber: true})
        }
        
        if(value.indexOf(" ") >= 0 || value.at(0) === " " || value.indexOf('#') >= 0) {  
            userPasswordError = 'Only special characters like @$!%*?& are permitted';
            this.setState({isPasswordEmpty: false});
        }  
        
        const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        // Regular expression to test all password constraints with the input value
        const isPasswordValid = passwordRegExp.test(value);

        if (isPasswordValid && value.length >= 8) {
            this.setState({ isPasswordCheck: true }, this.changeRegisterBtnStatus);
        } else {
            this.setState({ isPasswordCheck: false }, this.changeRegisterBtnStatus);
        }
 
        return userPasswordError;
    }

    changeRegisterBtnStatus = () => {
        if (this.state.hasReadPrivacyPolicy && this.state.isPasswordCheck) {
            this.setState({ disableRegisterButton: false });
            console.log("enable btn register");
          } else {
            this.setState({ disableRegisterButton: true });
          }
    }

    async handleOnKeyUp(e) {

        const emailValue = $('#mail').val();
        if (/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(emailValue)) {
            // analyticsTypeEvent('user_email')
            const obj = { 'email': this.state.email }
            const result = await emailCheck(obj);
            if (result) {
                if (result.data.message === "User already exist" || result.data.message === "This is social media login. Click ok to reset the password") {
                    this.setState({
                        errors: { ...this.state.errors, email: 'User already exist' },
                        emailSuccess: false
                    });
                }


                if (result.data.message === "This account does not exist with humm") {
                    this.setState({
                        emailSuccess: true,
                        errors: { ...this.state.errors, email: '' }
                    });

                }


                let commonState = this.state;
                let SpecialState = commonState.errors;
                if (SpecialState.email === "" && SpecialState.userName === "" && SpecialState.password === "" && commonState.emailSuccess === true) {
                    this.setState({ isEnabled: false });
                } else {
                    this.setState({ isEnabled: true });
                }
            }

        }
    }

    redirectRegisterScreen = () => {
        window.$('#socialLoginModel').modal('hide');
    }


    initialValidation(userName, email, password) {

        let errors = this.state.errors
        if (userName.trim() === '') {
            errors.userName = 'Full Name is required';
            this.setState({ errors });
        }
        if (email.trim() === '') {
            errors.email = 'Email is required';
            this.setState({ errors });
        }
        if (password.trim() === '') {
            errors.password = hasPasswordEmpty(password);
            this.setState({ errors });
        }

    }


    handleSubmit = async (event) => {
        let text = event.target.defaultValue.replace(' ','_');
        analyticsClickEvent(text.toLocaleLowerCase(),'')
        setTimeout(() => {
            this.setState({ disableRegistration: 'disable-register' });
        }, 1000);
        this.setState({ isEnabled: true });

        event.preventDefault();
        const obj = {
            'username': this.state.userName,
            'email': this.state.email,
            'password': this.state.password,
            'provider': 1,
        }
        this.initialValidation(obj.username, obj.email, obj.password);

        if (this.state.errors.userName || this.state.errors.email || this.state.errors.password || !document.getElementById("accept-privacy-policy").checked) {
            return false
        }

        localStorage.setItem('UserName', this.state.userName);
        const result = await this.props.manualRegister(obj);

        if (result.status === 200 || result.status === 409) {
            if (result.data.data.session_id !== undefined && result.data.data.access_token !== undefined) {
                localStorage.setItem('token', result.data.data.access_token);
                localStorage.setItem('email', this.state.email);
                localStorage.setItem('sessionId', result.data.data.session_id);
            }
            window.$('#registerModal').modal('show');
        } else {
            this.setState({ serverError: result.data.message })
        }
    }

    inputChangeHandler(event) {
        analyticsClickEvent('register_password_view_icon','')
        this.setState({
            inputType: !this.state.inputType
        })
    }

    // get Application Banner image for homepage
    getApplicationBannerImage = async() => {
        if(localStorage.getItem('fromWhere') !='skip'){
            const applicationImage = await getApplicationImage();
            if (applicationImage && applicationImage.status === 201 && Array.isArray(applicationImage.data.image) && applicationImage.data.image.length !== 0 && applicationImage.data.image[1] != undefined) {
                    let appImg = applicationImage.data.image[1];
                    localStorage.setItem('applicationImage', appImg);                                   
            }
            else {
                localStorage.setItem('applicationImage', 'images/home-active-contract-bg.png');
            }
         }
    }


    redirectCodeScreen = async (e) => {
        analyticsClickEvent('register_verification_popup_ok','')
      
        this.setState({ codeError: '' })
        if (this.state.code === '') {
            this.setState({ codeError: 'Please enter the code' });
            return false;
        }

        if (this.state.code.trim().length !== 5) {
            this.setState({ codeError: 'Please enter five digits to verify the code' });
            return false;
       }

       this.setState({disablePopupRegistration:'disable-popup'});

       const obj={
           email:this.state.email,
           user_activation_code:this.state.code
       }
       this.setState({disableverifyokbtn:true})
       const result = await validateEmailCode(obj);
       if(result.data.statusCode===200){
        analyticsLoginSuccessEvent('sign_up','manual_register');
        this.setState({disableverifyokbtn:false})
        this.getApplicationBannerImage();
        localStorage.setItem('token',result.data.data.access_token);
        localStorage.setItem('email',this.state.email);
        localStorage.setItem('UserName',result.data.data.user_details.username);
        localStorage.setItem('sessionId',result.data.data.session_id);
        localStorage.setItem('csrfToken',result.data.data.csrf_token);
        window.$('#registerModal').modal('hide');
        this.setState({redirect: true});
       }else{
        this.setState({disableverifyokbtn:false})
        this.setState({codeError:'Invalid Code'}); 
       }
    }


    resendCode = async (e) => {
        let text = e.target.innerText.split(' ').join('_');
        analyticsClickEvent(text.toLocaleLowerCase(),'')
        const objs = {
            email: this.state.email
        }
        const results = await manualRegisterVerifyEmail(objs);

        if (results.data.statusCode === 200) {
            this.setState({ codeSuccess: 'Please check your email' })
        }
    }
    background = () => {
        return <div className="col-md-6 col-sm-12 col-xs-12 section-left p-0">
            <div className="leftLogin-img-sec">
                <img src="images/humm-login-bg.jpg" alt="Background" />
            </div>
        </div>;
    }

    changeToChecked = (e) => {
        analyticsClickEvent('privacy_policy')
        if (document.getElementById("accept-privacy-policy").checked === false) {
          window.open("https://shophumm.com/uk/privacy", "Popup", "toolbar=no, location=no,statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=50, left=500")

        }
    
        window.open("https://shophumm.com/uk/privacy", "Popup", "toolbar=no, location=no,statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=50,left=500")
    
      }
        changeMobileCheckbox = (event) => {
            console.log(event.target.checked, "checkbox value");
        if (event.target.checked === true) {
            analyticsClickEvent('accepted_privacy_policy')
            this.setState({ hasReadPrivacyPolicy: true}, this.changeRegisterBtnStatus)
        } else {
            analyticsClickEvent('notaccepted_privacy_policy')
            this.setState({ hasReadPrivacyPolicy: false}, this.changeRegisterBtnStatus)
        }

        }

        
        changeEmail = () => {
            this.setState({code: ''})
            this.setState({codeError: ''})
            this.setState({codeSuccess: ''})
            window.$("#registerModal").modal("hide");
        }

        changePasswordClass = (passwordState) => {
            if(passwordState)
                return '/images/completed-icon.svg'
            else
                return '/images/close-register.png'
        }
    render() {
        if (this.state.redirect) {
            return <Redirect push to="/application" />;
        }
        return (
            <div className="layout-fixed">
                <div className="wrapper registration scroll-sec-fix">
                    {this.background()}
                    <div className="col-md-6 col-sm-12 col-xs-12 section-right p-0">
                        <div className="section-form">
                            <div className="form-header">
                                <span className="section-logo">
                                    <img src="images/logo.svg" alt="logo" />
                                </span>
                                <span className="section-heading">
                                    <h4>Welcome to Humm! Now let's<br></br>get you started</h4>
                                </span>
                            </div>
                            <div className="form-body">
                                <form id="login-form" className="log-form" name="loginForm">
                                    <div className="error-user">
                                        <div className="form-group floating-label-group">
                                            <input type="text" className="form-control" autocomplete="nope" id="name" name="userName"
                                                value={this.state.userName} required
                                                onChange={this.handleChange} placeholder=" " onClick={(e) => analyticsClickEvent('full_name','')} />
                                                <label className="floating-label">Full Name</label>
                                        </div>
                                        {/* user name error message */}
                                        <p className="error-message">{this.state.userName === '' && this.state.commonError !== '' && this.state.errors.userName === '' &&
                                            <span style={{ color: 'Red' }}>{this.state.commonError}</span>
                                        }
                                            {this.state.errors.userName !== '' &&
                                                <span style={{ color: 'Red' }}>{this.state.errors.userName}</span>
                                            }</p>
                                    </div>


                                    <div className="error-user">
                                        <div className="form-group floating-label-group">
                                            <input type="email" className="form-control" id="mail" name="email"
                                                value={this.state.email}
                                                required onChange={(e) => this.handleChange(e)} onKeyUp={(e) => this.handleOnKeyUp(e)} placeholder=" " autocomplete="nope"  onClick={(_e) => analyticsClickEvent('register_email','')} />
                                            <label className="floating-label">Email</label>
                                        </div>
                                        {/* email error message */}
                                        <p className="error-message">{this.state.errors.email !== '' &&
                                            <span style={{ color: 'Red' }}>{this.state.errors.email}</span>
                                        }
                                        </p>
                                    </div>
                                    <div className="error-user psw-error">
                                        <div className="form-group floating-label-group">
                                            <input type={this.state.inputType ? "text" : "password"} className="form-control" id="pwd" name="password"
                                                value={this.state.password}
                                                required placeholder=" " autoComplete="new-password" onClick={(_e) => analyticsClickEvent('register_password','')} onChange={(e) => this.handleChange(e)} />
                                            <label className="floating-label">Password</label>
                                            <div className="input-group-append">
                                                {this.state.inputType && (<span className="view-icon" onClick={this.inputChangeHandler}> <img src="images/eye-icon-password.svg" alt="logo" /> </span>)}
                                                {!this.state.inputType && (<span className="view-icon" onClick={this.inputChangeHandler}> <img src="images/eye-icon-password.svg" alt="logo" /> </span>)}
                                            </div>
                                        </div>
                                        {/* password error message  */}
                                        <p className="error-message mb-1" style={{height: "0.5rem"}}>
                                            {this.state.errors.password !== '' &&
                                                <span style={{color: 'Red'}}>{this.state.errors.password}</span>
                                            }                                            
                                            {
                                            this.state.isPasswordEmpty &&
                                                <span style={{color: 'Red'}}>Password is required</span>
                                            }                                           
                                        </p>

                                        <div className="mt-3 mb-2">
                                            <div className="d-sm-flex">
                                                <p className="password-criteria flex-criterias mb-2" >
                                                    <span>
                                                        <img src={this.changePasswordClass(this.state.isUppercase)} alt="" 
                                                        className="password-circle" />
                                                    </span>
                                                    1 uppercase character
                                                </p>

                                                <p className="password-criteria flex-criterias mb-2" >
                                                    <span>
                                                        <img src={this.changePasswordClass(this.state.isLowercase)} alt="" 
                                                        className="password-circle" />
                                                    </span>
                                                    1 lowercase character  
                                                </p>
                                            </div>

                                            <div className="d-sm-flex">
                                                <p className="password-criteria flex-criterias mb-2 flex-shrink-0" >
                                                    <span>
                                                        <img src={this.changePasswordClass(this.state.isSpecialCharacter)} alt="" 
                                                        className="password-circle" />
                                                    </span>
                                                    1 special character
                                                </p>

                                                <p className="password-criteria flex-criterias mb-2">
                                                    <span>
                                                        <img src={this.changePasswordClass(this.state.isNumber)} alt="" 
                                                        className="password-circle" />
                                                    </span>
                                                    1 number 
                                                </p>
                                            </div>

                                            <p className="password-criteria flex-criterias mb-2" >
                                                    <span>
                                                        <img src={this.changePasswordClass(this.state.isMinCharacter)} alt="" 
                                                        className="password-circle" />
                                                    </span>
                                                8 minimum characters
                                            </p>
                                        </div>

                                      <div className="form-check form-group accept-privacy-policy">
                                        <input type="checkbox" value={this.state.hasReadPrivacyPolicy} onChange={(e) => { this.changeMobileCheckbox(e) }} name="accept-privacy-policy" className="form-check-input register-check-input" id="accept-privacy-policy" />
                                         <label className="form-check-label" htmlFor="accept-privacy-policy" style={{ display: 'inline' }} >I have read the</label>
                                         <span style={{ display: 'inline' }} >   <a style={{ WebkitTextFillColor: '#ff6200', display: 'inline', cursor: 'pointer' }} href onClick={(e) => { this.changeToChecked(e) }} > Privacy Policy </a>
                                         </span>
                                        </div>                                        
                                    </div>

                                    <div className="form-group mb-3">
                                        <input type="button" onClick={this.handleSubmit} className=
                                            {`${this.state.disableRegistration === 'disable-register' ? 'btn btn-warning form-control register disable-register' : 'btn btn-warning form-control register'}`} disabled={this.state.disableRegisterButton} id="submit" value="Register" />

                                    </div>
                                    <p className="m-0"><span style={{ color: 'Red' }}>{this.state.serverError}</span></p>
                                    <div className="form-group m-0">
                                        <p className="register-link">Already have an account? <Link to="/login" onClick={(e) => this.analyticseveeent(e)}>Login Here</Link></p>
                                    </div>
                                </form>
                                <div className="social-accounts">
                                    <div className="social-acc-content">
                                        <h6>Or register with ease via social media</h6>
                                    </div>
                                    <SocialMedia fromWhere={'/register'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="registerModal" tabIndex="-1" role="dialog" aria-labelledby="registerModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                                </div>

                                <button type="button" class="close" aria-label="Close" onClick={()=> this.changeEmail()}
                                    style={{position: "absolute", top: "0", right: "0"}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>

                                <h5 className="modal-title" id="registerModalLabel">Verification Email</h5>
                                <p>We sent you a code. Enter it below to verify - {this.state.email}</p>
                                <input className="form-control" type="number" id="code" name="code" placeholder="Code"
                                    value={this.state.code} onKeyPress={(event) => { this.onKeyPressData(event) }} onPaste={(e) => { this.onlyAcceptInputs(e) }}
                                    required onChange={(e) => this.handleChange(e)} />
                            </div>
                            <p className="text-center modal-error-msg">{this.state.codeError !== '' &&
                                <span style={{ color: 'Red' }}>{this.state.codeError}</span>
                            }</p>
                            <p>Didn't receive email?</p>
                            <p className="text-center link--block">
                                <a id="resend-code" className="font-weight-bold" onClick={(e) => this.resendCode(e)}>Resend email</a>
                                <a className="emailChange" onClick={()=> this.changeEmail()}>
                                    Change email address</a>
                            </p>
                            <p className="text-center modal-error-msg">{this.state.codeSuccess !== '' &&
                                <span>{this.state.codeSuccess}</span>
                            }</p>

                            <div className="modal-footer">
                                <button type="button" id='register-redirect-screen' className={`${this.state.disablePopupRegistration === 'disable-popup' ? 'btn btn-primary disable-popup' : 'btn btn-primary'}`} disabled={this.state.disableverifyokbtn} onClick={(e) => this.redirectCodeScreen(e)}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export const mapStateToProps = (state) => {
    return {
        manualRegisterStore: state.dataStore.manualRegisterStore
    };

};


export const mapDispatchToProps = (dispatch) => {
    return {
        manualRegister: (formData) => {
            return dispatch(manualRegister(formData));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Register);
