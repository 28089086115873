import React, { Component } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import Instructions from "./instuctions/instruction";
import parse from 'html-react-parser';
import { analyticsClickEvent } from "../../action/firebaseAnalytics/firebaseAnalytics";
import { getStatus } from "../../action/application/getStatus";
import Cookies from 'universal-cookie';

export class DocumentVerifyMobile extends Component {

    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.history = useHistory;
        this.state = {
            documentSteps: false,
            passportInstruction: false,
            drivinglicenseInstruction: false,
            selfieInstruction: false,
            documentType:'',
            instruction:true,
            instructionsSection:false,
            passportSection:false,
            drivinglicenseSection:false,
            isEnableDocumentsTabs:false,
            isSelectDocument:'',
            userLoginType:localStorage.getItem('userLoginType')
        };

        this.handleChange = this.handleChange.bind(this);
        this.callSessionStatus = this.callSessionStatus.bind(this);
        this.getSessionStatus = this.getSessionStatus.bind(this);
        this.checkIntervalID = '';
    }

    componentDidMount(){
      this.setState({documentSteps:true,instruction:false});

      this.callSessionStatus();
    }

    callSessionStatus() {
      console.log("get session data")       
      this.checkIntervalID = setInterval(this.getSessionStatus, 30000);
    }

    async getSessionStatus() {
      const sessionObj={
        "email":localStorage.getItem('email'),
        "application_id":localStorage.getItem('applicationId')
      }
      const sessionResult = await getStatus(sessionObj);
      const cookie = new Cookies();    
      if(sessionResult.data.statusCode !== undefined){
        if(sessionResult.data.statusCode === 400 && sessionResult.data.message === "Invalid Session") {
          // Remove cookie for Retailer Id once the user session is expired
          cookie.remove('retailerId', { path: '/' });

          this.props.history.push('/login');
          window.location.reload(true);
        }
      } 
    }

    componentWillUnmount(){
      clearInterval(this.checkIntervalID);
    }

    openinstruction = (documenttype) => {
      if(documenttype === 'passport'){
        analyticsClickEvent('passport','')
      }else if(documenttype === 'ID'){
        analyticsClickEvent('driving_licence','')
      }else if(documenttype === 'VETERAN_CARD'){
        analyticsClickEvent('hm_forces_card','')
      }
        //  analyticsClickEvent('documentType_in_'+documenttype)
      this.setState({documentSteps:false,instructionsSection:true,isEnableDocumentsTabs:true,isSelectDocument:documenttype});
      }

      updatechanges=(status)=>{
        console.log(status,'yesssss')
        if(status){
            console.log(this.props.documentVerify);
            this.setState({documentSteps:true,instruction:false})
         }
    }

      handleChange(value){
        console.log('statusValue',value)
        this.setState({isEnableDocumentsTabs:false,isSelectDocument:''})
        localStorage.setItem('applicationStatus','')
        if(value==='decliened'){
          localStorage.setItem('applicationStatus','decliened')
        }else if(value==='success'){
          localStorage.setItem('applicationStatus','success')
        }else if(value==='refer'){
          localStorage.setItem('applicationStatus','refer')
          this.props.history.push('/home')          
        }else{
          localStorage.setItem('applicationStatus','')
        }
        window.location.reload();
        
      }

      gotoSelectDocument(){
        if(this.state.passportSection === true){
             setTimeout(() => {
              this.setState({ instructionsSection: false,instruction:true});
          this.child.current.viewinstruction('passport');
        },2000);
              
        }else if(this.state.drivinglicenseSection === true){
          setTimeout(() => {
            this.setState({ instructionsSection: false,instruction:true});
          this.child.current.viewinstruction('DL_FRONT');
        },2000);
        }
        
  
      }



    render() {
      if(localStorage.getItem('applicationStatus')===''){
        return (
          <div class="step-final mobile-view-scan">
          {this.state.isEnableDocumentsTabs === false &&
          <div class="mit-container">
            <p class="mit-mobile-app">Finally, let’s upload your ID. Please select and upload one of the documents listed below.</p>
            <div class="mit-document-section">
              <div class="mit-inner-box">
                <Link onClick={() => this.openinstruction('passport')}>
                  <span class="inner-left">
                    <img src="images/passport.svg" alt="Card" />
                  </span>
                  <span class="inner-center">
                    <p>Passport</p>
                  </span>
                  <span class="inner-right">
                    <img src="images/circle-outline.png" alt="Success" />
                  </span>
                </Link>
              </div>
              <div class="mit-inner-box">
                <Link onClick={() => this.openinstruction('ID')}>
                  <span class="inner-left">
                    <img src="images/driving-license.svg" alt="Card" />
                  </span>
                  <span class="inner-center">
                    <p>Driving Licence</p>
                  </span>
                  <span class="inner-right">
                    <img src="images/circle-outline.png" alt="Success" />
                  </span>
                </Link>
              </div>
              <div class="mit-inner-box">
                <Link onClick={() => this.openinstruction('VETERAN_CARD')}>
                  <span class="inner-left">
                    <img src="images/hm-force-card1.svg" alt="Card" />
                  </span>
                  <span class="inner-center">
                    <p>HM Forces Card</p>
                  </span>
                  <span class="inner-right">
                    <img src="images/circle-outline.png" alt="Success" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
      }
      {/* {this.state.documentSteps === false && this.state.instructionsSection === true &&
    <div class="mit-container">
                   <div class="instructions-section">
                   <h4 class="application-qr-sub-title">INSTRUCTIONS</h4>
                   <div class="instruction-message">
                       <h6>Steps to capture the document correctly</h6>
                   </div>
                   <div class="suggetion-message">
                       <span class="icon-area">
                           <img src="images/progress-success.svg" alt="Success" />
                       </span>
                       <span class="message-area">
                           <p>Please place the document on a dark, plain background to ensure the best result
                           </p>
                       </span>
                   </div>
                   <div class="suggetion-message">
                       <span class="icon-area">
                           <img src="images/progress-success.svg" alt="Success" />
                       </span>
                       <span class="message-area">
                           <p>All four corners of the document must be visible in the frame</p>
                       </span>
                   </div>
                   <div class="suggetion-message">
                       <span class="icon-area">
                           <img src="images/progress-success.svg" alt="Success" />
                       </span>
                       <span class="message-area">
                           <p>Avoid any glare, shadows or dark spots on the ID document</p>
                       </span>
                   </div>
                   <div class="suggetion-message">
                       <span class="icon-area">
                           <img src="images/progress-success.svg" alt="Success" />
                       </span>
                       <span class="message-area">
                           <p>Hold the device steady while image is being captured</p>
                       </span>
                   </div>
                   <div class="bottom-button-area">
                       <button class="btn btn-warning"  onClick={() => this.gotoSelectDocument()}>Continue</button>
                   </div>
               </div>
               </div>
              } */}
      {this.state.isEnableDocumentsTabs === true &&
         <Instructions documentType = {this.state.isSelectDocument} ref={this.child} handleChnage= {this.handleChange} fromWhere={'handoff'}/>
      }  

      </div>
      )
      }else if(localStorage.getItem('applicationStatus')==='decliened'){
        return(
          <div class="appfrom-section">
                        <div class="mit-container">
                            <div class="complete-section">
                                <div class="logo-area">
                                    <img src="images/humm-logo.svg" alt="Humm" />
                                </div>
                                {localStorage.getItem('finalMessage')!=null && localStorage.getItem('finalMessage')!='' &&
                                <div class="complete-content">
                                {parse(localStorage.getItem('finalMessage'))}
                                </div>
                                }
                  </div>
              </div>
          </div>
        );
        
      }else if(localStorage.getItem('applicationStatus')==='success'){
        return(
          <div class="appfrom-section">
                        <div class="mit-container">
                            <div class="complete-section">
                                <div class="logo-area">
                                    <img src="images/humm-logo.svg" alt="Humm" />
                                </div>
                                {/* <div class="complete-content">
                                    <h4>Complete</h4>
                                    <p>Thank you, your document verification is in process. </p>
                                </div> */}
                                <div class="complete-content">
                                    <h4>Approved</h4>
                                    {localStorage.getItem('finalMessage')!=null && localStorage.getItem('finalMessage')!='' &&
                                    <p>{parse(localStorage.getItem('finalMessage'))}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
        )
      }else{
        return(
          <div class="appfrom-section">
                        <p></p>
                    </div>
        )
      }
        
      }

}

export default withRouter(DocumentVerifyMobile);