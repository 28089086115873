import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Header from '../header/header';
import branch from 'branch-sdk';
import Cookies from 'universal-cookie';
import { userLogs } from '../../action/logs/userLogs';

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            location: useLocation
        }

        this.initialLoad = this.initialLoad.bind(this);
    }

    initialLoad = () => {        
        branch.init(process.env.REACT_APP_BRANCH_KEY, async function(err, data) {
            const cookies = new Cookies();
            if(data.data_parsed.retailer_id != undefined && data.data_parsed.retailer_id !== '') {
                cookies.set('retailerId',data.data_parsed.retailer_id,{ path: '/' });
            }
            else{
                await userLogs('', data.data_parsed,'','Retailer ID not received from branch link', 404);
            }            
        });
        
        console.log("current header", this.props.location.search);
        // Get the search route values using URLSearchParams() method
        let searchParams = new URLSearchParams(this.props.location.search);
        let resultWebview = searchParams.get("isWebview");
        let resultMobileApps = searchParams.get("device");  
        if(resultWebview !== null && resultWebview !== undefined && resultWebview !== '' && resultWebview === 'true'){
            localStorage.setItem('webView', true);
        }
        if(resultMobileApps !== null && resultMobileApps !== undefined && resultMobileApps !== '' && resultMobileApps === 'mobile'){
            localStorage.setItem('mobile', true);
        }
    }

    acceptMethods(childDoAlert) {
        // Parent stores the method that the child passed
        this.childDoAlert = childDoAlert;
      }

    render() { 
        this.initialLoad();
        return ( 
            <React.Fragment>            
            {localStorage.getItem('webView') ? ' ' : <Header  shareMethods={this.acceptMethods.bind(this)}/> }
            {this.props.children}
            </React.Fragment>

         );
    }
}
 
export default Layout;