import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, NavLink } from 'react-router-dom';
import { withRouter } from "react-router"
import Notification from '../notification/notification';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { manualLogout } from '../../action/auth/logout';
import Cookies from 'universal-cookie';

export class Header extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            profilePicture: props.profilePicture,
            totalNotification: 0,
            isHaveNotification: 0,
            clicked: false,
            notifyFunction: this.handleNotificationFunctionalities.bind(this),
            locationValue: '',
            totalNotifications: true,
            isdropdownmenushow: false,
            showHeader:true
        }
        this.notificationCall = React.createRef();
    }

    componentDidMount() {
        
        if(window.location.origin===process.env.REACT_APP_APPLY_URL){
            this.setState({showHeader:false})
        }
    }

    handleClickOutside = (event) => {
        setTimeout(() => {
            this.setState({ clicked: false })
        }, 100);
    }

    handleNotificationFunctionalities = () => {
        if (localStorage.getItem('fromWhere') != 'skip') {
            if (this.notificationCall && this.notificationCall.current) {
                this.notificationCall.current.initialLoad();
            }
        }
    }

    totalNotificationCount = () => {
        console.log('test');
        //     setTimeout(() => {


        //     this.setState({ totalNotifications: false})
        // }, 500);
    }

    componentWillUnmount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.body.removeEventListener('click', this.opendropdown);
    }

    // openNotificationCountLabel = () => {
    //  setTimeout(() => {
    //   this.setState({ totalNotifications: true})
    // }, 500);
    // }
    myHandler = () => {
        alert('click');
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.location.pathname != prevState.locationValue) {
            return {
                locationValue: nextProps.location.pathname,
                model: prevState.notifyFunction(nextProps.model)
            }
        }
        if (prevState.profilePicture !== nextProps.profilePicture) {
            return {
                profilePicture: nextProps.profilePicture
            };
        }
        return null;
    }

    totalNotification = (data, isHaveNotif) => {
        this.setState({ totalNotification: data, isHaveNotification: isHaveNotif })
    }
    totalNotificationCount = () => {
        analyticsClickEvent('notifications','')
        // this.setState({ totalNotifications: false})
    }

    showNotificationDropdown = () => {
        console.log(this.state.clicked);
        setTimeout(() => {
            this.setState({ clicked: true, isdropdownmenushow: true })
            console.log(this.state.clicked + " - " + this.state.isdropdownmenushow);
        }, 0);
    }

    notCloseNotification = (e) => {
        // console.log(this.state.clicked);
        // setTimeout(() => {
        //     this.setState({clicked:true,isdropdownmenushow:true})
        //     console.log(this.state.clicked +" - "+ this.state.isdropdownmenushow);
        // }, 0);
        // e.preventDefault();
        // e.stopPropagation()
    }

    opendropdown = (event) => {
        this.setState({ clicked: false })
        analyticsClickEvent('notifications','')
        if (localStorage.getItem('fromWhere') != 'skip') {
            setTimeout(() => {
                this.setState({ clicked: true })
            }, 100);
        }
        event.preventDefault();
    }

    onBlurEvent = (event) => {
        console.log(event);
        if (localStorage.getItem('fromWhere') != 'skip') {



            setTimeout(() => {
                this.setState({ clicked: false, isdropdownmenushow: false })
            }, 500);
        }
    }

    headerClickEvent = (data) => {
        analyticsClickEvent(data,'')
    }

    handleAuth = async (event) => {    // 'login' clear and send to login page ''skip'' ''normal users'' 
        analyticsClickEvent('logout','')
        const cookie = new Cookies();
    
        let results = await manualLogout()
        if (results.status == 200) {
            localStorage.clear();
            this.props.unsetProfilePic(); window.location.reload(); this.props.unsetContractSummary('');
            
            // Remove cookie for Retailer Id once the user has logout of the application
            cookie.remove('retailerId', { path: '/' });
        }else{
            localStorage.clear();
            this.props.unsetProfilePic(); window.location.reload(); this.props.unsetContractSummary('');
            cookie.remove('retailerId', { path: '/' });
        }

    }

    analyticsevent = (e) => {
        analyticsClickEvent('settings_menu','')
    }


    render() {
        const { pathname } = this.props.location;
        return (
            <>
            <nav className="navbar navbar-expand-lg navbar navbar-light fixed-top bg-light">
                <div className=" container custom-container-sec">
                    {this.state.showHeader===true &&
                    <Link to="/home" className="navbar-brand">
                        <img src="images/logo.svg" alt="Humm" />
                    </Link>
                    }
                    {this.state.showHeader===false &&
                    <span className="navbar-brand">
                        <img src="images/logo.svg" alt="Humm" />
                        </span>
                    }

                    
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav mr-auto">
                            {this.state.showHeader===true &&
                                <li className="nav-item">
                                <NavLink to="/home" className="nav-link" activeClassName="active" onClick={(e) => analyticsClickEvent('home_menu','')} >Home</NavLink>
                              </li>
                            }
                              {localStorage.getItem('fromWhere') != 'skip' && this.state.showHeader===true &&
                              <li className="nav-item">
                                <NavLink to="/wallet" className="nav-link" onClick={(e) => { if (localStorage.getItem('fromWhere') === 'skip') { e.preventDefault(); }  analyticsClickEvent('wallet_menu','') }} activeClassName="active">Wallet</NavLink>
                              </li>
                              }
                              {this.state.showHeader===true &&
                                <li className="nav-item">
                                    <NavLink to='/shop' id='shop' onClick={() => { localStorage.setItem('showCategories', 'true'); analyticsClickEvent('shop_menu','')}} className="nav-link" activeClassName="active"
                                        isActive={() => ['/shop', '/favorite-partner', '/viewed-partner'].includes(pathname)} >
                                        Shop</NavLink>
                                </li>
                              }
                          
                            
                          {this.state.showHeader===true &&
                            <li className="nav-item">
                            <NavLink to="/application" className="nav-link" activeClassName="active" onClick={(e) => analyticsClickEvent('apply_menu','')}      >Apply Now</NavLink>
                            {/* <NavLink to="/application"  onClick={()=>{setTimeout(() => {window.location.reload() },1000)}} className="nav-link" activeClassName="active">Apply Now</NavLink> */}
                            </li>
                          }
                    {this.state.showHeader===false &&
                       <li className="nav-item">
                       <span to="/application" className="apply-now-direct" activeClassName="active" onClick={(e) => analyticsClickEvent('apply_menu','')}      >Apply Now</span>
                       {/* <NavLink to="/application"  onClick={()=>{setTimeout(() => {window.location.reload() },1000)}} className="nav-link" activeClassName="active">Apply Now</NavLink> */}
                       </li>
                    }

                        {this.state.showHeader===true &&
                            <li className="nav-item">
                            <NavLink to="/quote-calculator" className="nav-link" onClick={(_e) => { analyticsClickEvent('quote_calculator_menu','') }} activeClassName="active"
                            isActive={() => ['/quote-calculator', '/quote-calculator-results'].includes(pathname)}
                            >Quote</NavLink>
                            </li>
                            }                           
                          
                            
                        </ul>

                        {this.state.showHeader===true &&    
                        <div className="user-panel d-flex">
                            <div className={this.state.clicked ? "dropdown notification-btn show" : "dropdown notification-btn"} onBlur={(e) => { this.onBlurEvent(e) }}>
                                {localStorage.getItem('fromWhere') != 'skip' &&
                                <a className="btn btn-secondary" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.totalNotificationCount}>
                                    <img src="images/bell.svg" alt="notification" /><span className="badge badge-light text-center">{this.state.totalNotification}</span>
                                    <span className="sr-only">unread messages</span>
                                </a>
                                }
                                {localStorage.getItem('fromWhere') != 'skip' &&
                                    <div className={this.state.clicked ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuLink" id="dropdown-menu" onClick={(e) => { e.stopPropagation(); }} onBlur={(e) => {this.onBlurEvent(e)}}>
                                        {this.state.isHaveNotification == 0 &&
                                            <h6 className="section-main-title text-center">No notifications</h6>
                                        }
                                        {localStorage.getItem('token') !== null &&
                                            <Notification totalNotification={this.totalNotification} notificationLabelchange={this.openNotificationCountLabel} notCloseNotification={this.notCloseNotification} ref={this.notificationCall} />
                                        }
                                    </div>
                                }
                            </div>
                            {this.state.profilePicture !== '' && this.state.profilePicture !== null &&
                                <div className="image">
                                    <img src={this.state.profilePicture} className="img-circle elevation-2" alt="User avatar" />
                                </div>
                            }
                            {this.state.profilePicture === null &&
                                <div className="image">
                                    <div className="no-profile-image">
                                        {localStorage.getItem('UserName') !== '' && localStorage.getItem('UserName') !== null &&
                                            <span className="no-profile-image-letter">{localStorage.getItem('UserName').substring(0, 1).toUpperCase()}</span>
                                        }
                                    </div>
                                </div>
                            }



                            <div className="nav-item dropdown" >
                                <a className="nav-link pr-0" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={(e) => this.analyticsevent(e)}>
                                    <div className="info">
                                        {localStorage.getItem('UserName') !== '' && localStorage.getItem('UserName') !== null &&
                                            <span><span className="header-user-name">{localStorage.getItem('UserName')}</span>
                                                <img src="images/down-arrow.svg" alt="down arrow" className="down-arrow-show" />
                                                <img src="images/up-arrow.svg" alt="up arrow" className="up-arrow-show" />
                                            </span>
                                        }
                                        {localStorage.getItem('UserName') === null &&
                                            <span>
                                                <img src="images/down-arrow.svg" alt="down arrow" className="down-arrow-show" />
                                                <img src="images/up-arrow.svg" alt="up arrow" className="up-arrow-show" />
                                            </span>
                                        }
                                    </div>
                                </a>
                                <div className={ localStorage.getItem('fromWhere') !== 'skip' ? `dropdown-menu user-options-dropdown` : `dropdown-menu user-options-dropdown skip-for-now-option`} aria-labelledby="navbarDropdown">
                                    <ul>
                                    {localStorage.getItem('fromWhere') != 'skip' &&
                                        <li>

                                            <Link to="/my-account" onClick={(e) => { analyticsClickEvent('my_account',''); if (localStorage.getItem('fromWhere') === 'skip') { e.preventDefault(); } }}>   <span>
                                                <img src="images/account.svg" className="default" alt="my account" />
                                                <img src="images/accountActive.svg" className="active" alt="my account" />
                                            </span>

                                                My Account </Link>

                                        </li>
                                     }
                                     {localStorage.getItem('fromWhere') === 'skip' &&
                                        <li>

                                            <a href={process.env.REACT_APP_URL}>   <span>
                                                <img src="images/account.svg" className="default" alt="my account" />
                                                <img src="images/accountActive.svg" className="active" alt="my account" />
                                            </span>

                                                My Account </a>

                                        </li>
                                     }
                                     {localStorage.getItem('fromWhere') != 'skip' &&
                                        <li >
                                            <a href="javascript:void(0)" id="notification" onClick={(e) => { this.opendropdown(e); }} >
                                                <span>
                                                    <img src="images/notification.svg" className="default" alt="notification" />
                                                    <img src="images/notificationActive.svg" className="active" alt="notification" />
                                                </span>
                                                Notifications  <span className="badge badge-light text-center">{this.state.totalNotification}</span></a>
                                        </li>
                                    }
                                        <li>
                                            <a href="https://www.shophumm.com/uk/faqs/" target="_blank" rel="noreferrer" onClick={(e) => this.headerClickEvent('help_center')} >
                                                <span>
                                                    <img src="images/help.svg" className="default" alt="help" />
                                                    <img src="images/helpActive.svg" className="active" alt="help" />
                                                </span>
                                                Help Center/Contact </a>
                                        </li>
                                        <li>
                                            <a href="https://www.shophumm.com/uk/privacy-policy/" target="_blank" rel="noreferrer" onClick={(e) => this.headerClickEvent('privacy_policy')}>
                                                <span>
                                                    <img src="images/privacy.svg" className="default" alt="privacy" />
                                                    <img src="images/privacyActive.svg" className="active" alt="privacy" />
                                                </span>
                                                Privacy Policy </a>
                                        </li>
                                        <li>
                                            <a href="https://www.shophumm.com/uk/important-information" target="_blank" rel="noreferrer" onClick={(e) => this.headerClickEvent('terms_conditions')}>
                                                <span>
                                                    <img src="images/terms.svg" className="default" alt="terms" />
                                                    <img src="images/termsActive.svg" className="active" alt="terms" />
                                                </span>
                                                Terms and Conditions </a>
                                        </li>                                        
                                    </ul>
                                    {localStorage.getItem('fromWhere') !== 'skip' &&
                                        <span className="logout-link"><Link to="/login" id='logout-header' onClick={(e) => { this.handleAuth(e) }}>{'Log Out'}</Link></span>}

                                    {localStorage.getItem('fromWhere') == 'skip' &&
                                        <span className="logout-link"><Link to="/login" id='logout-header' onClick={() => {
                                            localStorage.clear();
                                            this.props.unsetProfilePic(); window.location.reload(); this.props.unsetContractSummary('');
                                     
                                            analyticsClickEvent('login','')
                                        }}>{'Log In'}</Link></span>}
                                </div>
                            </div>
                        </div>
                         }
                    </div>
                                   
                </div>
            </nav>

                </>

                
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profilePicture: state.dataStore.profilePicture
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        unsetProfilePic: () => {
            return dispatch({
                type: 'UNSET_PROFILE_PIC'
            });
        },
        unsetContractSummary: () => {
            return dispatch({
                type: 'UNSET_CONTRACT_SUMMARY'
            });
        }
    }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Header)

