import React from 'react';
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import { analyticsClickEvent } from '../../../action/firebaseAnalytics/firebaseAnalytics';

export class CheckBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { errors: {},stages:{},input: {},showValidation:this.props.showAppValidations  }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        this.handleValidations()
        let questionValues={};
        questionValues['field_api_name']=this.props.field_api_name;
        questionValues['priority']=this.props.priority;
        questionValues['stage']=this.props.stage;
        if(this.props.field_api_validations.length!=0){
          questionValues['validation']='yes';
        }else{
          questionValues['validation']='no';
        }
        this.props.questionSave(questionValues);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.showAppValidations !== this.state.showValidation) {
          if(this.state.showValidation!=='yes'){
           this.setState({ showValidation:nextProps.showAppValidations })
          }
          
        }
       }
        

    handleChange(event) {
      console.log(event);
      console.log(this.props.flxFieldApiName__c)
      const item = event.target.name;
       const isChecked = event.target.checked; 
      
       if(item === 'flx_DocumentAgree__c'){
        analyticsClickEvent('checkbox',this.props.field_api_name)
         if(isChecked === true){
          //  analyticsClickEvent(this.props.field_api_name+'_checked','')
         }else{
          //  analyticsClickEvent(this.props.field_api_name+'_unChecked','')
         }

       }
       
       if(item === 'flx_Agree__c'){
        analyticsClickEvent('checkbox',this.props.field_api_name)
        if(isChecked === true){
          //  analyticsClickEvent(this.props.field_api_name+'_checked','')
         }else{
          //  analyticsClickEvent(this.props.field_api_name+'_unChecked','')
         }
       }
        this.setState({showValidation:'yes'});
        let input = this.state.input;
        let inputValues={};
        input[event.target.name] = event.target.checked;
        this.setState({
          input
        });
        inputValues[event.target.name] = event.target.checked;
        this.props.saveApplicationDatas(inputValues);
        this.handleValidations()
      }

      handleValidations=()=>{
                let errors = this.state.errors;
                let stages={};
                errors[this.props.field_api_name] = '';
                stages[this.props.field_api_name] = ''; 
                stages['stage'] = this.props.stage; 
                this.props.appvalidations(stages);
                this.setState({
                  errors
                });
        if(this.state.input[this.props.field_api_name]===false || this.state.input[this.props.field_api_name]===undefined){
            this.props.field_api_validations.map((src)=>{
                errors[this.props.field_api_name] = src.flxError_Message__c;
                stages[this.props.field_api_name] = src.flxError_Message__c; 
                stages['stage'] = this.props.stage; 
                this.props.appvalidations(stages);
                this.setState({
                  errors
                });

                return false;

            })
           
        }

      }

      analyticsClickEvent = (e) => {
        let text = e.target.innerText.replace(' ','_');
        // analyticsClickEvent(text,'');
        analyticsClickEvent('privacy_policy','');
      }
    
    render() { 
      let disableValue=''
      if(this.props.editable===false && this.props.prepopulateStatus===true && this.props.prepopulateCondition===true){
        disableValue='nonEditable'
      }
        return (
            <div className="form-check form-group">
            <input type={this.props.field_Type} value={this.state.input[this.props.field_api_name]} onChange={this.handleChange} defaultChecked={this.props.field_checked} name={this.props.field_api_name} type="checkbox" className="form-check-input" id={this.props.field_api_name} />
            <label className={`form-check-label ${disableValue}`} htmlFor={this.props.field_api_name}><a onClick={(e) => {this.analyticsClickEvent(e)}}>{parse(this.props.field_label)}</a></label>
            <p className="error-message">{this.state.errors[this.props.field_api_name]!== '' && this.state.showValidation==='yes'&&
                                            <span class="app-error-message" style={{ color: 'Red' }}>{this.state.errors[this.props.field_api_name]}</span>
                                        }
                                        </p>
            </div>

          );
    }
}


export const mapStateToProps = (state) => {
    return {
        applicationValidation:state.dataStore.applicationValidation,
        showAppValidations:state.dataStore.showAppValidations,
        prepopulateStatus:state.dataStore.prepopulateStatus,
        questionValueSave:state.dataStore.questionValueSave,
        saveApplicationData:state.dataStore.saveApplicationData,
    };
   };
  
  
  export  const mapDispatchToProps = (dispatch) => {
    return {
        appvalidations:(validations)=>{
            return dispatch({
                type: 'APP_VALIDATIONS',
                value: validations
            });
        },
        questionSave:(data)=>{
          return dispatch({
            type: 'QUESTIONSAVE',
            value: data
        });
        },
        saveApplicationDatas:(data)=>{
          return dispatch({
            type: 'SAVEDATA',
            value: data
        });
        },
        
    }
   }
  
  export default connect(mapStateToProps,mapDispatchToProps)(CheckBox)