import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useLocation } from 'react-router-dom'


const PublicRoute: React.FC<{
    component: React.FC;
    path: string;
}> = (props) => {
        
    const condition = true;
    const location = useLocation();

    console.log(props);
    let applyBlock=[
        '/favorite-partner',
        '/wallet',
        '/wallet-contract-detail',
        '/viewed-partner',
        '/my-account',
        '/home',
        '/shop','/login','/register','/forgot-password','/forgot-password-code','/session-expired','/welcome'];

    if(window.location.origin==process.env.REACT_APP_APPLY_URL){
         if(applyBlock.includes(location.pathname)){
            return <Redirect to="/apply" />
         }
    }

    return condition ? (<Route path={props.path}  component={props.component} />) :
        (<Redirect to="/login" />);
};
export default PublicRoute;