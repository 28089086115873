import React from 'react'

export class TextArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 

            <textarea value={this.props.value} onChange={this.handleChange} />
         );
    }
}
 
export default TextArea;