
import React from 'react';
import Element from '../element'
import { connect } from 'react-redux';
import { analyticsChangeEvent, analyticsClickEvent } from '../../../action/firebaseAnalytics/firebaseAnalytics';

export class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      question: '',
      errors: {}, stages: {}, input: {}, showDev: {},
      fieldname: this.props.field_api_name,
      showValidation: this.props.showAppValidations,
      frequency_options: {}
    }
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    let questionValues={};
    questionValues['field_api_name']=this.props.field_api_name;
    questionValues['priority']=this.props.priority;
    questionValues['stage']=this.props.stage;
    questionValues['field_question'] = this.props.field_label;
    if(this.props.field_api_validations.length!=0){
      questionValues['validation']='yes';
    }else{
      questionValues['validation']='no';
    }
    this.props.questionSave(questionValues);
    this.handleValidations();
    if (this.props.priority === 3) {
      let showDev = this.state.showDev;
      showDev[this.props.field_api_name] = false;
      let stages = {};
      stages[this.props.field_api_name] = '';
      stages['stage'] = this.props.stage;
      this.props.appvalidations(stages);
      this.setState({
        showDev
      });
    } else {
      let showDev = this.state.showDev;
      showDev[this.props.field_api_name] = true;
      this.setState({
        showDev
      });
    }

    this.setState({frequency_options: this.props.getApplicationQuestions.data[0].serviceability_details})    
  }

  static getDerivedStateFromProps(nextProps, prevState) {
   
    
    if (nextProps.showAppValidations !== prevState.showValidation) {
      if (nextProps.showAppValidations !== '' || nextProps.stage === '2') {
        return {
          showValidation: nextProps.showAppValidations
        };
      }
    
    }


    if (nextProps.amountRequested.split(',').join('').length > 1) {
      if (nextProps.amountRequested.split(',').join('') > nextProps.threashold) {
        if (nextProps.priority === 3) {
           
            if (prevState.showDev[prevState.fieldname] === false) {
              let showDev = prevState.showDev;
              showDev[prevState.fieldname] = true;
                return {
                 showDev
                };
              }
            }
        if (nextProps.priority === 1) {
          if (prevState.showDev[prevState.fieldname] === true) {
            let showDev = prevState.showDev;
            showDev[prevState.fieldname] = false;
            return {
              showDev,
              value: ''
            };
          }
        }

      } else {
        if (nextProps.priority === 3 && nextProps.amountRequested !== '') {
          if (prevState.showDev[prevState.fieldname] === true) {
            let showDev = prevState.showDev;
            showDev[prevState.fieldname] = false;
            return {
              showDev,
              value: ''
            };
          }
          
        }
        if (nextProps.priority === 1 && prevState.fieldname === 'frequency') {
          let showDev = prevState.showDev;
          showDev[prevState.fieldname] = true;
            return {
              showDev,
              value: ''
            };
         
        }

      }
    }
  }

  handleSearch = (event) => {
    this.setState({ showValidation: 'yes' });
    let input = this.state.input;
    let value = event.target.value;
    let name = event.target.name;
    analyticsClickEvent('picklist',value);
    input[event.target.name] = event.target.value;
    this.props.saveApplicationDatas(input);
    this.setState({
      input
    });
    this.setState({ question: '' })
    this.props.field_options.map((src) => {
      if (src.flxActualValue__c === value) {
        this.setState({ question: src.Questions })
      }
    }
    )
    const saveApplicationData = Object.assign({}, ...this.props.saveApplicationData);
    console.log(saveApplicationData);
    let ValidationFields = ['occupation', 'time_in_occupation'];
       //Employment mortage value should not allowed to zero and rent Homeowner - mortgaged and Tenant/Boarding start
       if (this.props.field_api_name === 'Current_Residential_Status__c') {
        if(event.target.value==='Homeowner - mortgaged' ||  event.target.value==='Tenant/Boarding'){
          if(saveApplicationData['Fractional_Residential_Expense__c'] !== undefined && saveApplicationData['Fractional_Residential_Expense__c'].charAt(0)==0){
            let fractionalValues={};
            fractionalValues['Fractional_Residential_Expense__c']='';
            this.props.saveApplicationDatas(fractionalValues);
          }
        
        }else{
            let inputValuesExpenseC = {}
            inputValuesExpenseC['Fractional_Residential_Expense__c'] = '';
            this.props.saveApplicationDatas(inputValuesExpenseC)
          }
      }

    console.log(saveApplicationData);
    if (this.props.field_api_name === 'Employment_Stability__c') {
      if (event.target.value === 'Unemployed' || event.target.value === 'Retired' || event.target.value === 'Student' || event.target.value === '') {
        this.props.getEmploynmentstatus(false)
        ValidationFields.forEach((src) => {
          let stages = {}
          let inputValues = {}
          inputValues[src] = '';
          stages[src] = '';
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          this.props.saveApplicationDatas(inputValues);
        })
      } else {
        ValidationFields.forEach((src) => {
          let stages = {}
          let inputValues = {}
          if (saveApplicationData[src] === '') {
            stages[src] = 'This is Required';
            stages['stage'] = this.props.stage;
            this.props.appvalidations(stages);
          }
          inputValues = {}
        })
        this.props.getEmploynmentstatus(true)
      }
    }
    this.handleValidations();
  }

  handleValidations = () => {
    let errors = this.state.errors;
    let stages = {};
    errors[this.props.field_api_name] = '';
    stages[this.props.field_api_name] = '';
    stages['stage'] = this.props.stage;
    this.props.appvalidations(stages);
    this.setState({
      errors
    });

    if (this.state.input[this.props.field_api_name] === undefined || this.state.input[this.props.field_api_name] === '') {
      this.props.field_api_validations.map((src) => {
        errors[this.props.field_api_name] = src.flxError_Message__c;
        stages[this.props.field_api_name] = src.flxError_Message__c;
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors
        });

        return false;
      })
    }

    if (this.state.input['Current_Residential_Status__c'] !== '') {
      errors['Current_Residential_Status__c'] = '';
      stages['Current_Residential_Status__c'] = '';
      stages['stage'] = this.props.stage;
      this.props.appvalidations(stages);
      this.setState({
        errors
      });
      return false
    }

  }

  // Function to get the frequency and terms for the entered amount within range of mininum and maximum loan amounts
  selectedAmountInterest=()=>{
    let temporaryRate = this.state.frequency_options
    let finalArr = []
    let match = false

    temporaryRate.map((term)=>{
      let term_obj = {}
      let termArr = []
      temporaryRate.map((compareEachTerm)=>{
          if(term['min_loan_amount'] == compareEachTerm['min_loan_amount'] && term['frequency'] === compareEachTerm['frequency'] && this.props.amountRequested  >= Number(compareEachTerm['min_loan_amount']) && this.props.amountRequested  <= Number(compareEachTerm['max_loan_amount'])){
              termArr.push(compareEachTerm['term'])
          }
      })
      if(finalArr.length){
          finalArr.forEach((res)=>{
              if(res['min_loan_amount'] === term['min_loan_amount'] && term['frequency'] === res['frequency']){
                  match = true
              }
          })
      }
      term_obj.min_loan_amount = term['min_loan_amount']
      term_obj.max_loan_amount = term['max_loan_amount']
      term_obj.frequency = term['frequency']
      term_obj.term = termArr
      if(!match && termArr.length){
      finalArr.push(term_obj)
      }
      match = false
    });

    return finalArr
  }

  // Function to reset the terms when the frequency is not selected
  resetLoanTerms(){
    {this.props.saveApplicationData && 
      Object.entries(this.props.saveApplicationData).forEach(([_, value]) => {

        if(value.hasOwnProperty('frequency') && value['frequency'] === ''){
          value['Loan_Term_Requested__c'] = '';
        }        
      });
    }
  }

  //Function to display the options of frequency and terms in <option> tag for the entered amount
  SelectTermsDisplay=(isFrequencySelected)=>{
    let enteredAmount = this.selectedAmountInterest();
    let resultFrequency = '';

      if(this.props.saveApplicationData !== null && this.props.saveApplicationData != undefined){
        Object.entries(this.props.saveApplicationData).forEach(([_, value]) => {
         
          Object.entries(value).forEach(([field, valueOfField]) => {
              if(field === "frequency" && valueOfField !== null) {
                  resultFrequency = valueOfField;
              }
          })
        });
      }

    if(!isFrequencySelected){
      return enteredAmount.map((src) => {

        if(src.term.length && src.term && resultFrequency == src.frequency){
          return src.term.map((eachTerm)=>{
              if(this.props.amountRequested >= src.min_loan_amount && this.props.amountRequested <= src.max_loan_amount){
                return <option value={eachTerm}>{eachTerm.toString()}</option>
              }
              })
        } 
      })      
    }
    else {
      return enteredAmount.map((src) => {         
        if(this.props.amountRequested >= src.min_loan_amount && this.props.amountRequested <= src.max_loan_amount) {          
          return <option value={src.frequency}>{src.frequency}</option>          
        }

      })
    }
  }
  // Function to display the options for other fields based on priority and threashold
  SelectOptionsDisplay=()=>{
      
    return this.props.field_options.map((src) =>{
      if(this.props.amountRequested <=this.props.threashold){
        if(src.priority===1 || src.priority===2){          
          return <option value={src.flxActualValue__c}>{src.flxDisplayText__c}</option>
        }
      }

      if(this.props.amountRequested >this.props.threashold){
        if(src.priority===3 || src.priority===2){
        return <option value={src.flxActualValue__c}>{src.flxDisplayText__c}</option>
        }
      } 
    })
  }
  render() {
    const allDetails = Object.assign({}, ...this.props.applicationValidation);
    const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
    let disableValue = ''
    if (this.props.editable === false && this.props.prepopulateStatus === true && allDetailData[this.props.field_api_name] != '' && allDetailData[this.props.field_api_name] != null && this.props.prepopulateCondition === true) {
      console.log(allDetailData[this.props.field_api_name], "Select")
      disableValue = 'nonEditable'
    }
    if (this.state.showDev[this.props.field_api_name] === true) {
        if(this.props.field_api_name === 'frequency'){
          return (      
            <React.Fragment>
              <div className="dynamic-input">
                <div className="form-group">
                  <span>{this.props.field_label ? this.props.field_label : ''}</span>
                  
                  <select id={this.props.field_api_name} className={`form-control ${disableValue}`} name={this.props.field_api_name} value={allDetailData[this.props.field_api_name]} onChange={(e) => {this.handleSearch(e);this.props.fieldChange(this.props.field_api_name, e.target.value, this.props.field_type, e)}}>
                    <option value=''>Select</option>
                    {this.SelectTermsDisplay(true)}                    
                  </select>
                  <p className="error-message">{allDetails[this.props.field_api_name] !== '' && this.state.showValidation === 'yes' &&
                    <span className="app-error-message" style={{ color: 'Red' }}>{allDetails[this.props.field_api_name]}</span>
                  }
                  </p>
                </div>
              </div>
  
              <Element fieldItem={this.state.question} />
            </React.Fragment>
  
          );
        }
        else if(this.props.field_api_name === 'Loan_Term_Requested__c') {
          return (      
            <React.Fragment>
              <div className="dynamic-input">
                <div className="form-group">
                  <span>{this.props.field_label ? this.props.field_label : ''}</span>
                  
                  <select id={this.props.field_api_name} className={`form-control ${disableValue}`} name={this.props.field_api_name} value={allDetailData[this.props.field_api_name]} onChange={(e) => {this.handleSearch(e);this.props.fieldChange(this.props.field_api_name, e.target.value, this.props.field_type, e)}}>
                   { allDetailData['frequency']==='' ? 
                      <option value=''>Select</option> :
                      <>
                        <option value=''>Select</option>
                        {this.SelectTermsDisplay(false)}
                      </>
                    }                 
                  </select>
                  { /* Reset loan term when frequency option is not selected*/}                                 
                  {allDetailData['frequency']==='' && this.resetLoanTerms()}
                  <p className="error-message">{allDetails[this.props.field_api_name] !== '' && this.state.showValidation === 'yes' &&
                    <span className="app-error-message" style={{ color: 'Red' }}>{allDetails[this.props.field_api_name]}</span>
                  }
                  </p>
                </div>
              </div>
  
              <Element fieldItem={this.state.question} />
            </React.Fragment>
  
          );
        }
        else {
          return (      
            <React.Fragment>
              <div className="dynamic-input">
                <div className="form-group">
                  <span>{this.props.field_label ? this.props.field_label : ''}</span>
                  
                  <select id={this.props.field_api_name} className={`form-control ${disableValue}`} name={this.props.field_api_name} value={allDetailData[this.props.field_api_name]} onChange={(e) => {this.handleSearch(e);this.props.fieldChange(this.props.field_api_name, e.target.value, this.props.field_type, e)}}>
                    <option value=''>Select</option>
                    {this.SelectOptionsDisplay()}
                  </select>
                  <p className="error-message">{allDetails[this.props.field_api_name] !== '' && this.state.showValidation === 'yes' &&
                    <span className="app-error-message" style={{ color: 'Red' }}>{allDetails[this.props.field_api_name]}</span>
                  }
                  </p>
                </div>
              </div>
  
              <Element fieldItem={this.state.question} />
            </React.Fragment>
  
          );
        }
      } else {
      return (
        <React.Fragment>  </React.Fragment>
      );
    }

  }
}


export const mapStateToProps = (state) => {
  return {
    applicationValidation: state.dataStore.applicationValidation,
    showAppValidations: state.dataStore.showAppValidations,
    employmentStatus: state.dataStore.employmentStatus,
    amountRequested: state.dataStore.amountRequested,
    saveApplicationData: state.dataStore.saveApplicationData,
    threashold: state.dataStore.threashold,
    prepopulateStatus: state.dataStore.prepopulateStatus,
    questionValueSave:state.dataStore.questionValueSave,
    // Have used the getApplicationQuestions from data store to get the serviceability details
    getApplicationQuestions: state.dataStore.getApplicationQuestions     
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    appvalidations: (validations) => {
      return dispatch({
        type: 'APP_VALIDATIONS',
        value: validations
      });
    },
    getEmploynmentstatus: (validations) => {
      return dispatch({
        type: 'EMPLOYNMENTSTATUS',
        value: validations
      });
    },
    saveApplicationDatas: (data) => {
      return dispatch({
        type: 'SAVEDATA',
        value: data
      });
    },
    questionSave:(data)=>{
      return dispatch({
        type: 'QUESTIONSAVE',
        value: data
    });
    },

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Select)

