import { event } from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import { analyticsClickEvent, analyticsTypeEvent } from '../../../action/firebaseAnalytics/firebaseAnalytics';


export class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      req: {},
      errors: {
      },

      value: '',
      displayerrors: '',
      inputType: 'text',
      showValidation: this.props.showAppValidations,
      stages: this.props.stage,
      curvalue: '',
      showDevs: true,
      prepopulateUpdate: true,
      keyData: '',
      showDev: {

      },
      showInformation : '', 
      maxValue: '',
      fieldname: this.props.field_api_name,
      hideFieldList: []
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let questionValues = {};
    questionValues['field_api_name'] = this.props.field_api_name;
    questionValues['priority'] = this.props.priority;
    questionValues['stage'] = this.props.stage;
    questionValues['field_question'] = this.props.field_question;
    
    if (this.props.field_api_validations.length != 0) {
      questionValues['validation'] = 'yes';
    } else {
      questionValues['validation'] = 'no';
    }
    this.props.questionSave(questionValues);
   

    if (this.props.field_type === 'Number') {
      this.setState({ inputType: 'number' })
    }
    if (this.props.field_type === 'Email') {
      this.setState({ inputType: 'email' })
    }
    if (this.props.field_type === 'Currency' || this.props.field_type === 'Text' || this.props.field_api_name === 'sortcode_bankaccount') {
      this.setState({ inputType: 'text' })
    }
    let input = this.state.input;
    input[this.props.field_api_name] = '';
    this.setState({
      input
    });
    //this.handlingValidations();
    if (this.props.priority === 3) {
      let showDev = this.state.showDev;
      showDev[this.props.field_api_name] = false;
      let stages = {};
      stages[this.props.field_api_name] = '';
      stages['stage'] = this.props.stage;
      this.props.appvalidations(stages);
      this.setState({
        showDev
      });
    } else {
      let showDev = this.state.showDev;
      showDev[this.props.field_api_name] = true;
      this.setState({
        showDev
      });
    }
    if (this.props.field_api_name === 'genesis__Loan_Amount__c') {
      this.props.threasholdFunc(this.props.threshold);
    }
    // localStorage.setItem('flxPersonEmail__c', '')

    // localStorage.setItem('flxPeyrsonConfirmEmail__c', '')
  }
  onFocus(e) {
    if (e.target.name === "sortcode_bankaccount" || e.target.name === "occupation") {
      e.target.setAttribute('autoComplete', 'off');
    } else {
      e.target.setAttribute('autoComplete', 'nope');
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.value !== nextProps.field_value && nextProps.initialLoad === false) {
      return {
        value: nextProps.field_value
      };
    }

    if (nextProps.showAppValidations !== prevState.showValidation) {
      if (nextProps.showAppValidations !== '' || nextProps.stage === '2') {
        return {
          showValidation: nextProps.showAppValidations
        };
      }
    }

    if (nextProps.amountRequested.split(',').join('').length > 0 || nextProps.amountRequested === '') {
      if (nextProps.amountRequested.split(',').join('') > nextProps.threashold) {
        if (nextProps.priority === 3) {
          let showDev = prevState.showDev;
          showDev[prevState.fieldname] = true;
          const allDetails = Object.assign({}, ...nextProps.applicationValidation);
          const allDetailData = Object.assign({}, ...nextProps.saveApplicationData);
          //for Fractional_Residential_Expense__c validations
           //vinoth changes start
          if (allDetailData['Current_Residential_Status__c'] === 'Homeowner - no mortgage' || allDetailData['Current_Residential_Status__c'] === 'Living with parents/family') {
            if (allDetails['Fractional_Residential_Expense__c'] == 'Amount cannot be £0 if your Residential status is Homeowner - mortgaged or Tenant/Boarding' || allDetails['Fractional_Residential_Expense__c'] =='This is Required') {
              let stagesValues = {}
              stagesValues['Fractional_Residential_Expense__c'] = '';
              stagesValues['stage'] = nextProps.stage;
              nextProps.appvalidations(stagesValues);
              return {
                value: ''
              };
            }
          }
           //vinoth changes end
          if (allDetails[prevState.fieldname] === '' && allDetailData[prevState.fieldname] === '') {
            let stages = {}
            stages[prevState.fieldname] = 'This is Required';
            stages['stage'] = nextProps.stage;
            // nextProps.appvalidations(stages);
            let inputValues = {}
            inputValues[prevState.fieldname] = '';
            //nextProps.saveApplicationDatas(inputValues)
            return {
              showDev,
              value: ''
            };
          }
        }
        if (nextProps.priority === 1) {
          if (prevState.showDev[prevState.fieldname] === true) {
            let showDev = prevState.showDev;
            showDev[prevState.fieldname] = false;
            if (nextProps.stage === '1') {
              let stages = {}
              stages[prevState.fieldname] = '';
              stages['stage'] = nextProps.stage;
              //nextProps.appvalidations(stages);
            }
            let inputValues = {}
            inputValues[prevState.fieldname] = '';
            //nextProps.saveApplicationDatas(inputValues)
            return {
              showDev,
              value: ''
            };
          }
        }
        if (nextProps.priority === 1) {
          if (prevState.showDev[prevState.fieldname] === true) {
            let showDev = prevState.showDev;
            showDev[prevState.fieldname] = false;
            if (nextProps.stage === '1') {
              let stages = {}
              stages[prevState.fieldname] = '';
              stages['stage'] = nextProps.stage;
              //nextProps.appvalidations(stages);
            }
            let inputValues = {}
            inputValues[prevState.fieldname] = '';
            // nextProps.saveApplicationDatas(inputValues)
            return {
              showDev,
              value: ''
            };
          }
        }
      } else {
        if (nextProps.priority === 1 && nextProps.amountRequested !== '') {
      if (prevState.showDev[prevState.fieldname] === false) {
        let showDev = prevState.showDev;
        showDev[prevState.fieldname] = true;
        // if (nextProps.stage === '1') {
          const allDetails = Object.assign({}, ...nextProps.applicationValidation);
                const allDetailData = Object.assign({}, ...nextProps.saveApplicationData);
            
                // if (allDetails[prevState.fieldname] === '' && allDetailData[prevState.fieldname] === '') {
          let stages = {}
          stages[prevState.fieldname] = 'This is Require';
          stages['stage'] = nextProps.stage;
          //nextProps.appvalidations(stages);
        // }
        let inputValues = {}
        inputValues[prevState.fieldname] = '';
        //nextProps.saveApplicationDatas(inputValues)
        return {
          showDev,
          value: ''
        };
      }
    }
        if (nextProps.priority === 3) {

          if (prevState.showDev[prevState.fieldname] === true) {
            let showDev = prevState.showDev;
            showDev[prevState.fieldname] = false;
            if (nextProps.stage === '1') {
              let stages = {}
              stages[prevState.fieldname] = '';
              stages['stage'] = nextProps.stage;
              // nextProps.appvalidations(stages);
            }
            let inputValues = {}
            inputValues[prevState.fieldname] = '';
            //nextProps.saveApplicationDatas(inputValues)
            return {
              showDev,
              value: ''
            };
          }
        }

      }
    }
    if (nextProps.prepopulateCondition === true) {
      const allDetailDatas = Object.assign({}, ...nextProps.saveApplicationData);
      if (allDetailDatas[prevState.fieldname] && prevState.prepopulateUpdate === true) {

        if (allDetailDatas[prevState.fieldname] != prevState.value) {
          let preValue = allDetailDatas[prevState.fieldname];
          if (prevState.fieldname === 'sortcode_bankaccount') {
            preValue = allDetailDatas[prevState.fieldname].replace(/(\d{2})(\d{2})(\d{2})/, "$1-$2-$3")
          }

          return {
            value: preValue,
            prepopulateUpdate: false
          };
        }
      }

    }

    return null;
  }




removeFromHistory() {



  // if (window.$('#MonthlyExpModal').hasClass('show') === false) {
  //     window.$('#MonthlyExpModal').modal('hide');
  //     window.$('.modal-backdrop').removeClass('show');
  //     window.$('body').removeClass('modal-open');
  //     window.$('.modal-backdrop').remove();
  // }
  if (window.$('#MonthlyExpModal').hasClass('show') === false) {
    window.$('#MonthlyExpModal').modal('hide');
    window.$('.modal-backdrop').removeClass('show');
    window.$('body').removeClass('modal-open');
    window.$('.modal-backdrop').remove();
}
}
 

// removeFromHistory() {

//   if (window.$('#forgotModal').hasClass('show') === false) {
//       window.$('#forgotModal').modal('hide');
//       window.$('.modal-backdrop').removeClass('show');
//       window.$('body').removeClass('modal-open');
//       window.$('.modal-backdrop').remove();
//   }
// }
 



  handleChange(event) {

    let element = event.target;
    let name = event.target.name;
    let value = event.target.value.trim();
    // analyticsTypeEvent(name)
   this.setState({ showValidation: 'yes' });
    let key = this.state.keyData;
    let Currentdatas = document.getElementById(this.props.field_api_name).value;
    let caret = event.target.selectionStart;
    const allDetailDatas = Object.assign({}, ...this.props.saveApplicationData);
    if (this.props.field_type === 'Currency') {
      var currecyCurrentLength = Currentdatas.length;
    }


    if (this.props.field_api_name === 'genesis__bank_account_number__c' || this.props.field_api_name === 'flxNumber_of_Other_Financial_Dependents__c') {
      if (value.length != 0) {
        if (!Number(value) && value != 0) {
          return false;
        }
      }
    }
    

    if(this.props.field_api_name==='Fractional_Residential_Expense__c'){
      if(allDetailDatas['Current_Residential_Status__c']==='Homeowner - mortgaged' || allDetailDatas['Current_Residential_Status__c']==='Tenant/Boarding' ){
          if(value.charAt(0)==='0'){
          let stagesValues={}
          stagesValues['Fractional_Residential_Expense__c'] = 'Amount cannot be £0 if your Residential status is Homeowner - mortgaged or Tenant/Boarding';
          stagesValues['stage'] = this.props.stage;
          let errors = this.state.errors;
          errors['Fractional_Residential_Expense__c'] = 'Amount cannot be £0 if your Residential status is Homeowner - mortgaged or Tenant/Boarding';
          this.props.appvalidations(stagesValues);
          this.setState({
            errors
          });
          return false;
        }
    }}

    if(event.target.name === 'genesis__Loan_Amount__c'){
      
      
      if(allDetailDatas['frequency'] !== ''){
        let frequencyStagesValues={};
        let stages = {};
        frequencyStagesValues['frequency'] = '';
        stages['frequency'] = 'This is Required';
        stages['stage'] = this.props.stage;
        this.props.saveApplicationDatas(frequencyStagesValues);
   
        this.props.appvalidations(stages);
      }
      if(allDetailDatas['Loan_Term_Requested__c'] !== ''){
        let stages = {};
        let loanTermStagesValues={}
         loanTermStagesValues['Loan_Term_Requested__c'] = '';
         stages['Loan_Term_Requested__c'] = 'This is Required';
        stages['stage'] = this.props.stage;
        this.props.saveApplicationDatas(loanTermStagesValues);
        this.props.appvalidations(stages);
        
      }
    }

    if (this.props.field_type === 'Currency') {
      if (value.length !== 0) {
        if (event.target.name === 'genesis__Loan_Amount__c') {
        if(value.indexOf(".") >= 0){
          let decimal_pos = value.indexOf(".");
          let left_side = value.substring(0, decimal_pos);
          let right_side = value.substring(decimal_pos);
          let replaceLeftSide=left_side.replace(/\D/g, "").split(',').join('');
          right_side = right_side.substring(0, 3);
          if(right_side.length===2){
            right_side=right_side+'0';
          }

        if(right_side.length===1){
            right_side='.00';
        }
        value=replaceLeftSide+right_side;
        }else{
          value = value.replace(/\D/g, "").split(',').join('')+'.00';
        }

        }else{
          value = value.replace(/\D/g, "").split(',').join('');
        }
        
      }
    }


    if (this.props.field_api_name === 'sortcode_bankaccount') {
      if (value.length <= 8) {

        value = value.replace(/\D/g, "").split('-').join('');
        value = value.slice(0, 6)

      }
      if (value.length >= 8) {
        if (this.state.errors['sortcode_bankaccount'] == 'Must be 6 digit numeric value') {
          let errors = this.state.errors;
          let stages = {};
          errors['sortcode_bankaccount'] = '';
          stages['sortcode_bankaccount'] = '';
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          setTimeout(() => {
            this.setState({
              errors
            });
          }, 1000);
        }
        return false;
      }

    }


    if (this.props.field_api_name === 'flxPersonMobile__c') {
      if (value.length === 12) {
        event.preventDefault();
        return false;
      }
    }
    if (this.props.field_api_name === 'Postal_Code__c' || this.props.field_api_name === 'PreviousPostal_Code__c') {
      if (value.length >= 9) {
        event.preventDefault();
        return false;
      }
    }

    if (this.props.field_api_name === 'genesis__bank_account_number__c') {
      if (value.length === 9) {
        event.preventDefault();
        return false;
      }
    }



    if (event.target.name === 'flxPersonEmail__c') {

      localStorage.setItem('flxPersonEmail__c', value)
      if (localStorage.getItem('flxPersonConfirmEmail__c') !== null) {
        let stages = {};
        if (value === localStorage.getItem('flxPersonConfirmEmail__c')) {
          setTimeout(() => {
            stages['flxPersonConfirmEmail__c'] = '';
            this.props.email('');
            this.props.confirmemails('');
            stages['stage'] = this.props.stage;
            this.props.appvalidations(stages);
          }, 10);
        }
        if (value !== localStorage.getItem('flxPersonConfirmEmail__c') || this.props.prepopulateCondition === false) {
          let stages = {};
          setTimeout(() => {
            stages['flxPersonConfirmEmail__c'] = 'Email Addresses should match';
            this.props.email('Email Addresses should match');
            this.props.confirmemails('Email Addresses should match');
            stages['stage'] = this.props.stage;
            this.props.appvalidations(stages);
          }, 10);
          // return false
        }
        if (localStorage.getItem('flxPersonConfirmEmail__c') === '') {
          setTimeout(() => {
            stages['flxPersonConfirmEmail__c'] = 'This is Required';
            this.props.email('This is Required');
            this.props.confirmemails('This is Required');
            stages['stage'] = this.props.stage;
            this.props.appvalidations(stages);
          }, 10);
          // return false
        }
        if (value === '') {
          setTimeout(() => {
            stages['flxPersonEmail__c'] = 'This is Required';
            this.props.email('This is Required');
            this.props.confirmemails('This is Required');
            stages['stage'] = this.props.stage;
            this.props.appvalidations(stages);
          }, 10);
        }
      }
    }
    if (event.target.name === 'flxPersonConfirmEmail__c') {
      localStorage.setItem('flxPersonConfirmEmail__c', value);
      let stages = {};
      if (localStorage.getItem('flxPersonEmail__c') !== value && value !== '') {
        setTimeout(() => {
          stages['flxPersonConfirmEmail__c'] = 'Email Addresses should match';
          this.props.email('Email Addresses should match');
          this.props.confirmemails('Email Addresses should match');
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
        }, 10);
        // return false;
      } else if (localStorage.getItem('flxPersonEmail__c') === value && localStorage.getItem('flxPersonEmail__c') !== '') {

        setTimeout(() => {
          stages['flxPersonConfirmEmail__c'] = '';
          this.props.email('');
          this.props.confirmemails('');
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
        }, 10);

      }

      if (localStorage.getItem('flxPersonConfirmEmail__c') === '') {
        setTimeout(() => {
          stages['flxPersonConfirmEmail__c'] = 'This is Required';
          this.props.email('This is Required');
          this.props.confirmemails('This is Required');
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
        }, 10);
        // return false
      }
    }


    this.setState({ showValidation: 'yes' });
    let input = this.state.input;
    input[event.target.name] = value;
    if (event.target.name !== 'flxPersonConfirmEmail__c') {
      let inputValues = {}
      inputValues[event.target.name] = value;
      this.props.saveApplicationDatas(inputValues)
    }
    this.setState({
      input
    });

    let updatedCurrentLength='';

    if (value.indexOf(".") >= 0) {
      let decimal_pos = value.indexOf(".");
      let left_side = value.substring(0, decimal_pos);
      let right_side = value.substring(decimal_pos);
      let replaceLeftSide=left_side.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      right_side = right_side.substring(0, 3);
      let concatValue=replaceLeftSide+right_side;
      updatedCurrentLength=concatValue.length;
    }else{
      updatedCurrentLength = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",").length;
    }

    if (this.props.field_type === 'Currency') {
      if (caret != 0) {
        caret = updatedCurrentLength - currecyCurrentLength + caret;
      }
      window.requestAnimationFrame(() => { element.setSelectionRange(caret, caret) })
    }


    if (event.target.name === 'genesis__Loan_Amount__c') {
      this.props.getAmountRequested(value)
    }

    this.handlingValidations();
  }


  handlingValidations = () => {

    let breakCondition = false;
    let errors = this.state.errors;
    errors[this.props.field_api_name] = '';
    this.setState({
      errors
    });
    let stages = {};
    stages[this.props.field_api_name] = '';
    stages['stage'] = this.props.stage;
    this.props.appvalidations(stages);
    if (this.props.field_api_validations !== '') {
      this.props.field_api_validations.map((src) => {
        if (src.flxType__c === 'Required Field' && breakCondition === false) {
          if (this.state.input[this.props.field_api_name] === '') {
            errors[this.props.field_api_name] = 'This is Required';
            stages[this.props.field_api_name] = 'This is Required';
            stages['stage'] = this.props.stage;
            this.props.appvalidations(stages);
            this.setState({
              errors
            });

            breakCondition = true;

          }
        }
        if (src.flxType__c === 'Do not allow negative value') {
          if (this.state.value < 0 && breakCondition === false) {
            errors[this.props.field_api_name] = 'Please Enter positive Number';
            stages[this.props.field_api_name] = 'Please Enter positive Number';
            stages['stage'] = this.props.stage;
            this.props.appvalidations(stages);
            this.setState({
              errors
            });

            breakCondition = true;
          }
        }

        if (this.props.field_api_name === 'Total_Monthly_Expenses__c'  && this.state.input[this.props.field_api_name]<=0 && breakCondition === false) {
          errors[this.props.field_api_name] = 'Must be more than £0';
          stages[this.props.field_api_name] = 'Must be more than £0';
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          this.setState({
            errors
          });
          breakCondition = true;
        }

        if (this.props.field_api_name === 'Fractional_Living_Expense__c' && this.state.input[this.props.field_api_name]<=0 && breakCondition === false) {
              errors[this.props.field_api_name] = 'Must be more than £0';
              stages[this.props.field_api_name] = 'Must be more than £0';
              stages['stage'] = this.props.stage;
              this.props.appvalidations(stages);
              this.setState({
                errors
              });
              breakCondition = true;
        }

        if (src.flxType__c == "Popup View") { 
          if(this.props.field_api_name=='Fractional_Residential_Expense__c') {
            this.setState(
              {showInformation:src.flxError_Message__c
            })
          }
          else if (this.props.field_api_name== 'Fractional_Living_Expense__c'){
            this.setState(
              {showInformation:src.flxError_Message__c
            })
          }
          else if (this.props.field_api_name == 'monthly_expense_financial_commitments'){
            this.setState(
              {showInformation:src.flxError_Message__c
            })
            // 
          }
          else if (this.props.field_api_name == 'genesis__Loan_Amount__c'){
            this.setState(
              {showInformation:src.flxError_Message__c
            })
            // 
          }
          else if (this.props.field_api_name == 'Total_Monthly_Income__c' && src.flxMin_Value__c == process.env.REACT_APP_MINIMUM_MONTHLY_INCOME){
            this.setState(
              {
                showInformation:src.flxError_Message__c.replaceAll("\n","<br>"),
                maxValue: src.flxMax_Value__c
              })
            // 
          }else if(this.props.field_api_name == 'Total_Monthly_Expenses__c'){
            this.setState(
              {
                showInformation: src.flxError_Message__c
              })
          }

        }

          // if (this.state.value < 0 && breakCondition === false) {
          //   errors[this.props.field_api_name] = 'Please Enter positive Number';
          //   stages[this.props.field_api_name] = 'Please Enter positive Number';
          //   stages['stage'] = this.props.stage;
          //   this.props.appvalidations(stages);
          //   this.setState({
          //     errors
          //   });

          //   breakCondition = true;
          // }


        if (src.minmaxrequired === true && breakCondition === false) {
          if (this.state.inputType === 'email') {
            this.props.email('');
            this.props.confirmemails('');
            if (this.state.input[this.props.field_api_name].length < src.flxMin_Value__c || this.state.input[this.props.field_api_name].length > src.flxMax_Value__c && breakCondition === false) {

              errors[this.props.field_api_name] = src.flxError_Message__c;
              stages[this.props.field_api_name] = src.flxError_Message__c;
              stages['stage'] = this.props.stage;
              this.props.appvalidations(stages);
              this.setState({
                errors
              });
              breakCondition = true;
            }
            if (
              /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$[/^\S*$/])/i.test(
                this.state.input[this.props.field_api_name]
              )) {

              errors[this.props.field_api_name] = '';
              stages[this.props.field_api_name] = '';
              stages['stage'] = this.props.stage;
              this.props.appvalidations(stages);
              this.setState({
                errors
              });
            } else {

              errors[this.props.field_api_name] = 'Please enter a valid email';
              stages[this.props.field_api_name] = 'Please enter a valid email';
              stages['stage'] = this.props.stage;
              this.props.appvalidations(stages);
              this.setState({
                errors
              });
              breakCondition = true;
            }

            if (localStorage.getItem('flxPersonEmail__c') !== localStorage.getItem('flxPersonConfirmEmail__c') && breakCondition === false) {
              setTimeout(() => {
                // errors['flxPersonConfirmEmail__c']="test Email Addresses should match";
                // stages[this.props.field_api_name] = 'test Email Addresses should match';
                // stages['flxPersonConfirmEmail__c'] = 'Email Addresses should match';
                // this.props.email('Email Addresses should match');
                // this.props.confirmemails('Email Addresses should match');
                // stages['stage'] = this.props.stage;
                // this.props.appvalidations(stages);
                // breakCondition = true;
              }, 10);
            } else if (localStorage.getItem('flxPersonEmail__c') === localStorage.getItem('flxPersonConfirmEmail__c') && breakCondition === false) {
              setTimeout(() => {
                // stages['flxPersonConfirmEmail__c'] = '';
                // this.props.email('');
                // this.props.confirmemails('');
                // stages['stage'] = this.props.stage;
                // this.props.appvalidations(stages);
                // breakCondition = true;
              }, 10);
            } else {
              // stages['flxPersonConfirmEmail__c'] = '';
              // this.props.email('');
              // this.props.confirmemails('');
              // stages['stage'] = this.props.stage;
              // this.props.appvalidations(stages);
              // breakCondition = true;
            }


          }
          if (this.props.field_api_name === 'genesis__Loan_Amount__c' || this.props.field_api_name === 'Total_Monthly_Income__c') {
            var inputValue = this.state.input[this.props.field_api_name];
            if (src.flxType__c === 'Minimum Range') {
              if (src.flxMin_Value__c > inputValue) {
                errors[this.props.field_api_name] = src.flxError_Message__c;
                stages[this.props.field_api_name] = src.flxError_Message__c;
                stages['stage'] = this.props.stage;
                this.props.appvalidations(stages);
                this.setState({
                  errors
                });
                breakCondition = true;
              }
            }
            if (src.flxType__c === 'Maximum Range') {
              if (src.flxMax_Value__c < inputValue) {
                errors[this.props.field_api_name] = src.flxError_Message__c;
                stages[this.props.field_api_name] = src.flxError_Message__c;
                stages['stage'] = this.props.stage;
                this.props.appvalidations(stages);
                this.setState({
                  errors
                });
                breakCondition = true;
              }
            }
          }
          if (this.state.inputType === 'number' || this.props.field_type === 'Currency' || this.props.field_api_name === 'sortcode_bankaccount' && breakCondition === false) {
            if (this.props.field_api_name === 'flxPersonMobile__c') {
              this.props.getPhoneNumber('');
              if (this.state.value.length < src.flxMin_Value__c || this.state.value.length > src.flxMax_Value__c) {

                errors[this.props.field_api_name] = src.flxError_Message__c;
                stages[this.props.field_api_name] = src.flxError_Message__c;
                stages['stage'] = this.props.stage;
                this.props.appvalidations(stages);
                this.setState({
                  errors
                });
                breakCondition = true;
              }
              let mystring = this.state.value.substring(0, 2);
              if (mystring !== '07' && breakCondition === false) {

                errors[this.props.field_api_name] = 'This is not a valid UK Phone Number';
                stages[this.props.field_api_name] = 'This is not a valid UK Phone Number';
                stages['stage'] = this.props.stage;
                this.props.appvalidations(stages);
                this.setState({
                  errors
                });
                breakCondition = true;
              }
            } else {

              let inputValue = this.state.input[this.props.field_api_name].split(',').join('');
              if (src.flxType__c === 'Specify Length') {
                if (inputValue.length < src.flxMin_Value__c || inputValue.length > src.flxMax_Value__c) {

                  errors[this.props.field_api_name] = src.flxError_Message__c;
                  stages[this.props.field_api_name] = src.flxError_Message__c;
                  stages['stage'] = this.props.stage;
                  this.props.appvalidations(stages);
                  this.setState({
                    errors
                  });
                  breakCondition = true;
                }
              }
              if (src.flxType__c === 'Specify Range') {
                if (inputValue < src.flxMin_Value__c || inputValue > src.flxMax_Value__c) {
                  errors[this.props.field_api_name] = src.flxError_Message__c;
                  stages[this.props.field_api_name] = src.flxError_Message__c;
                  stages['stage'] = this.props.stage;
                  this.props.appvalidations(stages);
                  this.setState({
                    errors
                  });
                  breakCondition = true;
                }
              }
              if (this.state.input[this.props.field_api_name] == 'sortcode_bankaccount') {
                if (inputValue.length >= 8) {

                  errors[this.props.field_api_name] = '';
                  stages[this.props.field_api_name] = '';
                  stages['stage'] = this.props.stage;
                  this.props.appvalidations(stages);
                  this.setState({
                    errors
                  });
                  breakCondition = true;
                }
              }

            }

            

          }

          if (src.flxError_Message__c === 'Must be numeric value and six zeros or six nines not allowed') {
            if (inputValue === '000000' || inputValue === '999999') {
              errors[this.props.field_api_name] = src.flxError_Message__c;
              stages[this.props.field_api_name] = src.flxError_Message__c;
              stages['stage'] = this.props.stage;
              this.props.appvalidations(stages);
              this.setState({
                errors
              });
              breakCondition = true;
            }

          }
          if (src.flxError_Message__c === 'Must be numeric value and eight zeros or eight nines not allowed') {
            if (inputValue === '00000000' || inputValue === '99999999') {

              errors[this.props.field_api_name] = src.flxError_Message__c;
              stages[this.props.field_api_name] = src.flxError_Message__c;
              stages['stage'] = this.props.stage;
              this.props.appvalidations(stages);
              this.setState({
                errors
              });
              breakCondition = true;
            }

          }
          if (this.state.inputType === 'text' && breakCondition === false && this.props.field_type !== 'Currency') {
            // if(this.state.value.length<=src.flxMin_Value__c || this.state.value.length>=src.flxMax_Value__c){

            if (this.state.input[this.props.field_api_name].length < src.flxMin_Value__c) {

              errors[this.props.field_api_name] = 'This is Required';
              stages[this.props.field_api_name] = 'This is Required';
              stages['stage'] = this.props.stage;
              this.props.appvalidations(stages);
              this.setState({
                errors
              });
              breakCondition = true;
            } else if (this.state.input[this.props.field_api_name].length > src.flxMax_Value__c) {
              errors[this.props.field_api_name] = src.flxError_Message__c;
              stages[this.props.field_api_name] = src.flxError_Message__c;
              stages['stage'] = this.props.stage;
              this.props.appvalidations(stages);
              this.setState({
                errors
              });
              breakCondition = true;
            }
            if (this.props.field_api_name === 'flxFirst_Name__c' || this.props.field_api_name === 'flxLast_Name__c') {
              let inputValue = this.state.input[this.props.field_api_name];

              if (src.flxType__c === 'Specify Length') {
                if (inputValue.length < src.flxMin_Value__c || inputValue.length > src.flxMax_Value__c) {
                  errors[this.props.field_api_name] = src.flxError_Message__c;
                  stages[this.props.field_api_name] = src.flxError_Message__c;
                  stages['stage'] = this.props.stage;
                  this.props.appvalidations(stages);
                  this.setState({
                    errors
                  });
                  breakCondition = true;
                } else if (this.state.input[this.props.field_api_name].length < src.flxMin_Value__c) {
                  errors[this.props.field_api_name] = 'This is Required';
                  stages[this.props.field_api_name] = 'This is Required';
                  stages['stage'] = this.props.stage;
                  this.props.appvalidations(stages);
                  this.setState({
                    errors
                  });
                  breakCondition = true;
                }
              } else {

                errors[this.props.field_api_name] = src.flxError_Message__c;
                stages[this.props.field_api_name] = src.flxError_Message__c;
                stages['stage'] = this.props.stage;
                this.props.appvalidations(stages);
                this.setState({
                  errors
                });
                breakCondition = true;
              }
            }
          }

        } else if (src.minmaxrequired === false && breakCondition === false) {
          if (this.state.inputType === 'number' && breakCondition === false) {
            let inputValue = this.state.input[this.props.field_api_name].split(',').join('');

            if (src.flxType__c === 'Specify Length') {
              if (inputValue.length < src.flxMin_Value__c || inputValue.length > src.flxMax_Value__c) {

                errors[this.props.field_api_name] = src.flxError_Message__c;
                stages[this.props.field_api_name] = src.flxError_Message__c;
                stages['stage'] = this.props.stage;
                this.props.appvalidations(stages);
                this.setState({
                  errors
                });
                breakCondition = true;
              }
            }

            if (this.props.field_api_name === 'genesis__bank_account_number__c') {
              if (inputValue.length >= 9) {
                if (this.state.errors['genesis__bank_account_number__c'] == 'Must be 8 digit numeric value') {
                  errors[this.props.field_api_name] = src.flxError_Message__c;
                  stages[this.props.field_api_name] = src.flxError_Message__c;
                  stages['stage'] = this.props.stage;
                  this.props.appvalidations(stages);
                  this.setState({
                    errors
                  });
                  breakCondition = true;
                }
              }
            }

          } else if (this.state.inputType === 'text' && breakCondition === false) {
            let inputValue = this.state.input[this.props.field_api_name].split(',').join('');
            if (src.flxType__c === 'Specify Length') {
              if (inputValue.length < src.flxMin_Value__c || inputValue.length > src.flxMax_Value__c) {

                errors[this.props.field_api_name] = src.flxError_Message__c;
                stages[this.props.field_api_name] = src.flxError_Message__c;
                stages['stage'] = this.props.stage;
                this.props.appvalidations(stages);
                this.setState({
                  errors
                });
                breakCondition = true;
              }
            }
            if (this.props.field_api_name === 'sortcode_bankaccount') {
              if (inputValue.length >= 8) {
                if (this.state.errors['sortcode_bankaccount'] == 'Must be 6 digit numeric value') {
                  errors[this.props.field_api_name] = src.flxError_Message__c;
                  stages[this.props.field_api_name] = src.flxError_Message__c;
                  stages['stage'] = this.props.stage;
                  this.props.appvalidations(stages);
                  this.setState({
                    errors
                  });
                  breakCondition = true;
                }
              }
              if (src.flxType__c === 'Specify Length') {
                if (inputValue.length < src.flxMin_Value__c || inputValue.length > src.flxMax_Value__c) {

                  errors[this.props.field_api_name] = src.flxError_Message__c;
                  stages[this.props.field_api_name] = src.flxError_Message__c;
                  stages['stage'] = this.props.stage;
                  this.props.appvalidations(stages);
                  this.setState({
                    errors
                  });
                  breakCondition = true;
                }
              }

              // if (src.flxType__c === 'Specify Range') {
              //   if (inputValue < src.flxMin_Value__c || inputValue > src.flxMax_Value__c) {
              //     errors[this.props.field_api_name] = src.flxError_Message__c;
              //     stages[this.props.field_api_name] = src.flxError_Message__c;
              //     stages['stage'] = this.props.stage;
              //     this.props.appvalidations(stages);
              //     this.setState({
              //       errors
              //     });
              //     breakCondition = false;
              //   }
              // }

              // if (inputValue.length >= 8) {

              //   errors[this.props.field_api_name] = '';
              //   stages[this.props.field_api_name] = '';
              //   stages['stage'] = this.props.stage;
              //   this.props.appvalidations(stages);
              //   this.setState({
              //     errors
              //   });
              //   breakCondition = false;
              // }
            }

          }
        }

      })
    }
  }


  onKeyPressData = (event) => {
    event.persist();


    let invalidChars = [
      "e",
      "E",
      "+",
      ".",
      ",",
      "-"
    ];


    if (this.props.field_api_name === 'flxFirst_Name__c') {
      if (!/[a-zA-Z ]/i.test(event.key) || /[0-9]/.test(event.key) || /[!@#$%^&*()_+-=[\]{};:'"\\|,.<>/?]+/.test(event.key)) {

        event.preventDefault();
        return false;
      }
    }


    if (this.props.field_api_name === 'flxFirst_Name__c') {

      if (/[0-9]/.test(event.key)) {
        event.preventDefault();
        return false;
      }
      else if (/![a-zA-Z]/.test(event.key)) {
        event.preventDefault();
        return false;
      }
      else if (/[`!@#$%^&*()_+-=[\]{};:'"\\|,.<>/?]+/.test(event.key)) {
        event.preventDefault();
        return false;
      }

    }


    if (this.props.field_api_name === 'flxLast_Name__c') {


      if (/[0-9]/.test(event.key)) {
        event.preventDefault();
        return false;
      }
      else if (/![a-zA-Z]/.test(event.key)) {
        event.preventDefault();
        return false;
      }
      else if (/[`!@#$%^&*()_+=[\]{};:"\\|,.<>/?]+/.test(event.key)) {
        event.preventDefault();
        return false;
      }
      else if (/["]+/.test(event.key)) {
        event.preventDefault();
        return false;
      }
    }
    if (this.props.field_api_name === 'flxPersonEmail__c') {
      if (event.target.value.length > 50) {
        event.preventDefault();
      }
    }
    if (this.props.field_api_name === 'sortcode_bankaccount') {

      if (event.key === "-") {
        event.preventDefault();
        return false;
      }
    }
    if (this.props.field_api_name === 'flxPersonConfirmEmail__c') {
      if (event.target.value.length > 50) {
        event.preventDefault();
      }
    }

    if (this.props.field_api_name === 'genesis__bank_account_number__c') {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
        return false;
      }

      if (event.target.value.length > 7) {
        event.preventDefault();
        return false;
      }
      if (invalidChars.includes(event.key)) {
        event.preventDefault();
        return false;
      }
    }
   

    if (this.props.field_api_name === 'flxNumber_of_Other_Financial_Dependents__c') {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
        return false;
      }
      // if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      //   event.preventDefault();
      //   return false;
      // }
      if (event.target.value.length > 1) {
        event.preventDefault();
        return false;
      }

      if (invalidChars.includes(event.key)) {
        event.preventDefault();
      }
    }
    if (this.props.field_api_name === 'sortcode_bankaccount' || this.props.field_api_name === 'genesis__bank_account_number__c' || this.props.field_api_name === 'flxNumber_of_Other_Financial_Dependents__c') {
   

      if (event.key === "-") {
        event.preventDefault();

        return false;
      }
      // if (/(([0-9]+)(\.|,)([0-9]){2})/g.test(event.key)) {
      //   event.preventDefault();
      //   return false;
      // }

      // if (invalidChars.includes(event.key)) {
      //   event.preventDefault();
      // }
    }
  

    if (this.props.field_api_name === 'flxPersonMobile__c') {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }

    if(this.props.field_api_name === 'genesis__Loan_Amount__c'){
      if (!/([\d\.,]+)/g.test(event.key)) {
        event.preventDefault();
      }
    }

    if (this.props.field_type === 'Currency' && this.props.field_api_name != 'genesis__Loan_Amount__c') {
      if (/(([0-9]+)(\.|,)([0-9]){2})/g.test(event.key)) {
        event.preventDefault();
      }
      if (invalidChars.includes(event.key)) {
        event.preventDefault();
      }
    }
    
  }

  


  handlePaste = (event) => {
    if (this.props.field_api_name === 'flxPersonConfirmEmail__c') {
      event.preventDefault();
    }
  }
  clickbutton = () => {
    this.setState({ showDevs: false })
  }

  onlyAcceptInputs = (e) => {
    if (this.props.field_api_name === 'flxPersonConfirmEmail__c') {
      e.preventDefault();
    }
    if(this.props.field_api_name === 'Postal_Code__c' || this.props.field_api_name === 'PreviousPostal_Code__c'){
      if (!/^[a-zA-Z0-9\s]*$/.test(e.clipboardData.getData('Text'))){
        e.preventDefault();
      }
    }

    if (this.props.field_api_name === 'flxLast_Name__c') {

      if (!/[a-zA-Z ]/i.test(e.clipboardData.getData('Text')) || /[0-9]/.test(e.clipboardData.getData('Text')) || /[!@#$%^&*()_+\=\[\]{};:"\\|,.<>\/?]+/.test(e.clipboardData.getData('Text'))) {
        e.preventDefault();
      }
    }


    if (this.props.field_api_name === 'flxFirst_Name__c' || this.props.field_api_name === 'occupation') {

      if (!/[a-zA-Z ]/i.test(e.clipboardData.getData('Text')) || /[0-9]/.test(e.clipboardData.getData('Text')) || /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(e.clipboardData.getData('Text'))) {
        e.preventDefault();
      }
    } else if (this.props.field_api_name === 'sortcode_bankaccount' || this.props.field_api_name === 'genesis__bank_account_number__c' || this.props.field_api_name === 'flxNumber_of_Other_Financial_Dependents__c') {
      if (e.clipboardData.getData('Text').length <= 6) {
        return false
      }
      if (!/[0-9]/.test(e.clipboardData.getData('Text')) || /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(e.clipboardData.getData('Text'))) {
        e.preventDefault();
      }
    }
  }

  onKeyPressDown = (event) => {
    this.setState({ keyData: event.key });
  }

  render() {
    const allDetails = Object.assign({}, ...this.props.applicationValidation);
    const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
    let allowValue = this.state.showDev[this.props.field_api_name];
    if (this.props.employmentStatus === true && allowValue === true && this.props.field_api_name === 'occupation') {
      allowValue = true
    }
    if (this.props.employmentStatus === false && this.props.field_api_name === 'occupation') {
      allowValue = false;
    }
    let disableValue = false
    if (this.props.editable === false && this.props.prepopulateStatus === true && allDetailData[this.props.field_api_name] != '' && allDetailData[this.props.field_api_name] != null && this.props.prepopulateCondition === true) {
      disableValue = true
    }
    if(this.props.field_api_name==='Total_Expenditure__c'){
      disableValue = true
    }
    if(this.props.field_api_name=='Fractional_Residential_Expense__c'){
      if(allDetailData['Current_Residential_Status__c'] === 'Homeowner - no mortgage' || allDetailData['Current_Residential_Status__c'] === 'Living with parents/family'){
        disableValue = true
      }else{
        disableValue = false
      }
    }
    if (allowValue === true) {
      return (

        <React.Fragment>

        {this.props.field_question !== '' && this.props.field_api_name != "Total_Expenditure__c" &&
            <div className="dynamic-input">
              <div className="form-group">
                <span>{this.props.field_question}</span>

                <input type={this.state.inputType} name={this.props.field_api_name} value="" readOnly={true} style={{ display: "none", opacity: 0, position: "absolute" }} autoComplete="off" />

                {this.props.field_type === 'Currency' && allDetailData[this.props.field_api_name] !== '' &&
                  <span className="pound-span">£</span>
                }

                <input type={this.state.inputType} onWheel={(blurEvent) => { blurEvent.target.blur() }} lang="en" onPaste={(pasteEvent) => this.handlePaste(pasteEvent)} onKeyPress={(keyPressEvent) => { this.onKeyPressData(keyPressEvent) }} onKeyDown={(keyDownEvent) => { this.onKeyPressDown(keyDownEvent) }} className={`form-control  ${this.props.field_type === 'Currency' && allDetailData[this.props.field_api_name] !== undefined && allDetailData[this.props.field_api_name] !== '' ? "custom-cat-displays" : ""}`} id={this.props.field_api_name} name={this.props.field_api_name} onFocus={this.onFocus}
                  aria-describedby="email"

                  value={allDetailData[this.props.field_api_name] !== '' ? this.state.value : ''}
                  placeholder={this.props.field_placeholder}
                  onChange={(e) => { this.props.fieldChange(this.props.field_api_name, e.target.value, this.props.field_type, e, this.state.keyData); this.handleChange(e) }}
                  disabled = {disableValue}
                  onClick = {(e) => analyticsClickEvent(this.props.field_label,'')}
                  onBlur={(e) => {this.props.onBlurEvent(e,this.state.showInformation, this.state.maxValue)}}
                  onPaste={(e) => { this.onlyAcceptInputs(e) }} autoCorrect="off" autoCapitalize="none" spellCheck="false" />
                {/* user name error message */}
                {allDetails[this.props.field_api_name] !== '' && this.state.showValidation === 'yes' &&
                  <p className="error-message"><span class="app-error-message" style={{ color: 'Red' }}>{allDetails[this.props.field_api_name]}</span> </p>
                }

              </div>
            </div>

          }
          {this.props.field_question === '' &&  this.props.field_api_name != "Total_Expenditure__c" &&
            <div className="dynamic-input">
              <div class="form-group">
                <span></span>

                <input type={this.state.inputType} name={this.props.field_api_name} value="" readOnly={true} style={{ display: "none", opacity: 0, position: "absolute" }} autoComplete="off" />

                <input type={this.state.inputType} onWheel={(event) => { event.target.blur() }} className={`form-control  ${this.props.field_type === 'Currency' && this.state.value !== '' ? "custom-cat-displays" : ""}`} id="" onFocus={this.onFocus} name={this.props.field_api_name} aria-describedby="email" autoComplete="nope" autoCorrect="off" autoCapitalize="none" spellCheck="false"
                  value={this.state.value ? this.state.value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this.state.value} placeholder={this.props.field_placeholder} onChange={this.handleChange} onClick = {(e) => analyticsClickEvent(this.props.field_label,'')} onPaste={(e) => { this.onlyAcceptInputs(e) }} />
                <p className="error-message">{this.state.errors[this.props.field_api_name] !== '' && this.state.showValidation === 'yes' &&
                  <span class="app-error-message" style={{ color: 'Red' }}>{this.state.errors[this.props.field_api_name]}</span>
                }
                </p>
              </div>
            </div>
          }

        {this.props.field_api_name == "Total_Expenditure__c" && 
            <div class="dynamic-input">
              <div class="form-group">
                <span className="dynamic_label">Total of all Expenditure</span>
                <span className="expenditure_value">
                  {allDetailData[this.props.field_api_name] !== ""
                    ? this.state.value!=''?'£ '+this.state.value:''
                    : '£ 0'}
                </span>
              </div>
            </div>
          }


        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment></React.Fragment>
      )
    }

  }
}



export const mapStateToProps = (state) => {
  return {
    applicationValidation: state.dataStore.applicationValidation,
    showAppValidations: state.dataStore.showAppValidations,
    emails: state.dataStore.emails,
    confirmemail: state.dataStore.confirmemail,
    saveApplicationData: state.dataStore.saveApplicationData,
    phoneNumbers: state.dataStore.phoneNumbers,
    amountRequested: state.dataStore.amountRequested,
    employmentStatus: state.dataStore.employmentStatus,
    threashold:state.dataStore.threashold,
    prepopulateStatus:state.dataStore.prepopulateStatus,
    questionValueSave:state.dataStore.questionValueSave,

  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    appvalidations: (validations) => {
      return dispatch({
        type: 'APP_VALIDATIONS',
        value: validations
      });
    },
    showappValidation: (validations) => {
      return dispatch({
        type: 'SHOW_APP_VALIDATIONS',
        value: validations
      });
    },
    email: (validations) => {
      return dispatch({
        type: 'EMAIL',
        value: validations
      });
    },
    confirmemails: (validations) => {
      return dispatch({
        type: 'CONFIRMEMAIL',
        value: validations
      });
    },
    saveApplicationDatas: (data) => {
      return dispatch({
        type: 'SAVEDATA',
        value: data
      });
    },
    removeApplicationData: (data) => {
      return dispatch({
        type: 'REMOVEDATA',
        value: data
      });
    },
    getPhoneNumber: (validations) => {
      return dispatch({
        type: 'PHONENO',
        value: validations
      });
    },
    getAmountRequested: (validations) => {
      return dispatch({
        type: 'AMOUNTREQUESTED',
        value: validations
      });
    },
    threasholdFunc: (validations) => {
      return dispatch({
        type: 'THREADSHOLD',
        value: validations
      });
    },
    questionSave:(data)=>{
      return dispatch({
        type: 'QUESTIONSAVE',
        value: data
    });
    },



  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Input)
