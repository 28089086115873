import React from "react";
import firebase from 'firebase/app';
import 'firebase/auth';
import { twitterRequestToken } from "../../../action/auth/login";
import { socialLogin }  from "../../../action/auth/socialLogin";
import { Link,Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { Twitter } from "../login/twitter";
import { analyticsClickEvent, analyticsLoginSuccessEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import { getApplicationImage } from "../../../action/avatar/getAvatar";
import Cookies from "universal-cookie";

var firebaseConfig = {
    apiKey: process.env.REACT_APP_AUTHAPIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export class SocialMedia extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            email: '',
            password: '',
            disable: '',
            errors: {
                userName: '',
                email: '',
                password: '',
                serverError: '',
            },
            commonError: '',
            serverError: '',
            homePage: '',
            userExists: false,
            redirect : false,
            inputType:false,
            loginMessage:''
        };
        localStorage.setItem('contentFrom',this.props.fromWhere);
    }

    getApplicationBannerImg = async() => {
      if(localStorage.getItem('fromWhere') !='skip'){
          const applicationImg = await getApplicationImage();
          if (applicationImg && applicationImg.status === 201 && Array.isArray(applicationImg.data.image) && applicationImg.data.image.length !== 0 && applicationImg.data.image[1] != undefined) {
                  let appImg = applicationImg.data.image[1];
                  localStorage.setItem('applicationImage', appImg);                                   
          }
          else {
            localStorage.setItem('applicationImage', 'images/home-active-contract-bg.png');
          }
       }
    }

    async handleGoogleUserLogin() {

        const userGoogleDetails = await this.handleGoogleLogin();
         await this.checkLoginState(userGoogleDetails);
        if (userGoogleDetails) {
            localStorage.setItem('email', userGoogleDetails.email);
            if (userGoogleDetails.email !== null) {
                const obj = {
                    'username': userGoogleDetails.firstname+' '+userGoogleDetails.lastname,
                    'email': userGoogleDetails.email,
                    'uu_id': userGoogleDetails.user_id,
                    'provider': 2,
                }
                localStorage.setItem('UserName', userGoogleDetails.firstname);

                const googleResult = await this.props.socialLogin(obj);

                if(googleResult.data.message==='This email already using with Gmail account so must login with Gmail'
                  || googleResult.data.message==='This email already using with Facebook account so must login with Facebook' ||
                  googleResult.data.message==='This email already using with Twitter account so must login with Twitter'){
                    localStorage.clear();
                    this.setState({loginMessage:googleResult.data.message})
                    window.$('#exampleModal').modal('show');
                    return false;
                }
                if(googleResult.data.message==="This email already using with Humm account so must login with Humm"){
                    window.$('#exampleModal').modal('show');
                   this.setState({loginMessage: googleResult.data.message});
                 }
                // Set the state whether the user already exists or not
                this.setState({userExists: googleResult.data.data.user_exists});
                localStorage.setItem('token', googleResult.data.data.access_token);
                localStorage.setItem('sessionId',googleResult.data.data.session_id);
                localStorage.setItem('csrfToken',googleResult.data.data.csrf_token);

                if (googleResult.data.statusCode === 200 || googleResult.data.statusCode === 201) {
                  analyticsLoginSuccessEvent('login','social_media_login')
                  if(!this.state.userExists){
                    this.getApplicationBannerImg();
                  }
                    this.setState({redirect: true});
                }
            }
        }

    }

  

 
 async handleGoogleLogin() {
  analyticsClickEvent('google_login','')
    var provider = new firebase.auth.OAuthProvider('google.com');
    provider.addScope('profile');
    provider.addScope('email');
 
        
        return firebase.auth().signInWithPopup(provider).then(function (result) {
                var token = result.credential.accessToken;
                var user = result.user;
                let userEmail = result.user.email;
                if(!userEmail){
                    userEmail=result.additionalUserInfo.profile.email;
                }
                localStorage.setItem('profilePicture', user.photoURL);
                return   {
                    firstname: user.displayName,
                    lastname: result.additionalUserInfo.profile.last_name,
                    email:userEmail,
                    user_id: result.additionalUserInfo.profile.id,
                    access_token: token,
                    refresh_token: user.refreshToken,
                    errorEmail: ''
                }

              

            }).catch((error) => {
               
                var errorMessage = error.message;
                if (error.email) {
                    return {email: error.email,firstname: 'test'}
                   
                } else {
                    return {errorEmail: errorMessage}
                }

            });
    }


    async handleFbUserLogin() {
      analyticsClickEvent('facebook_login','')
        const userFbDetails = await this.handleFbLogin();
console.log(userFbDetails);
         await this.checkLoginState(userFbDetails);
        
        if (userFbDetails) {
           
            localStorage.setItem('email', userFbDetails.email);
            if (userFbDetails.email != null) {
                const obj = {
                    'username': userFbDetails.firstname+' '+userFbDetails.lastname,
                    'email': userFbDetails.email,
                    'uu_id': userFbDetails.user_id,
                    'provider': 3,
                }

                localStorage.setItem('UserName', userFbDetails.firstname);
                const result = await this.props.socialLogin(obj);
                if(result.data.message==='This email already using with Gmail account so must login with Gmail'
                  || result.data.message==='This email already using with Facebook account so must login with Facebook' ||
                  result.data.message==='This email already using with Twitter account so must login with Twitter'){
                    localStorage.clear();
                    this.setState({loginMessage:result.data.message})
                    window.$('#exampleModal').modal('show');
                    return false;
                }
                if(result.data.message==="This email already using with Humm account so must login with Humm"){
                  this.setState({loginMessage: result.data.message});
                    window.$('#exampleModal').modal('show');
                   return false;
                 }
                // Set the state whether the user already exists or not
                this.setState({userExists: result.data.data.user_exists});
                localStorage.setItem('token', result.data.data.access_token);
                localStorage.setItem('sessionId',result.data.data.session_id);
                localStorage.setItem('csrfToken',result.data.data.csrf_token);
                if (result.data.statusCode === 200 || result.data.statusCode === 201) {
                  analyticsLoginSuccessEvent('login','social_media_login')
                  if(!this.state.userExists){
                    this.getApplicationBannerImg();
                  }
                    this.setState({redirect: true});
                }
            }else{
              if(userFbDetails.errorEmail){
                return false;
              }else if(userFbDetails.email === undefined){
                  this.setState({loginMessage:'Please try again with an account which has email id registerd with it'});
                  window.$('#exampleModal').modal('show');
                  return false;
                 }
                 
            }
        }
    }





    //checkstate 

    async checkLoginState(response) {
        if (response.authResponse) {
          // User is signed-in Facebook.
          var unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
            unsubscribe();
            // Check if we are already signed-in Firebase with the correct user.
            if (!this.isUserEqual(response.authResponse, firebaseUser)) {
              // Build Firebase credential with the Facebook auth token.
              var credential = firebase.auth.FacebookAuthProvider.credential(
                  response.authResponse.accessToken);
              
              // Sign in with the credential from the Facebook user.
              firebase.auth().signInWithCredential(credential)
                .catch((error) => {
                  // Handle Errors here.
               
                  // ...
                });
            } else {
              // User is already signed-in Firebase with the correct user.
            }
          });
        } else {
          // User is signed-out of Facebook.
          firebase.auth().signOut();
        }
      }





      async isUserEqual(facebookAuthResponse, firebaseUser) {
        if (firebaseUser) {
          var providerData = firebaseUser.providerData;
          for (var i = 0; i < providerData.length; i++) {
            if (providerData[i].providerId === firebase.auth.FacebookAuthProvider.PROVIDER_ID &&
                providerData[i].uid === facebookAuthResponse.userID) {
              // We don't need to re-auth the Firebase connection.
              return true;
            }
          }
        }
        return false;
      }

    async handleFbLogin() {
        var provider = new firebase.auth.FacebookAuthProvider();
        provider.addScope('user_birthday');
        provider.addScope('email');
        provider.setCustomParameters({
            'display': 'popup'
          });
        
        return firebase.auth().signInWithPopup(provider).then(function (result) {
           
                var token = result.credential.accessToken;
                console.log(result);
                var user = result.user;
                console.log(user);
                let userEmail = result.user.email;
                if(!userEmail){
                    userEmail=result.additionalUserInfo.profile.email;
                }
                localStorage.setItem('profilePicture', user.photoURL);
                return   {
                    firstname: user.displayName,
                    lastname: result.additionalUserInfo.profile.last_name,
                    email:userEmail,
                    user_id: result.additionalUserInfo.profile.id,
                    access_token: token,
                    refresh_token: user.refreshToken,
                    errorEmail: ''
                }

              

            }).catch((error) => {
              console.log(error);
                var errorMessage = error.message;
                if (error.email) {
                    return {email: error.email}
                   
                } else {
                    return {errorEmail: errorMessage}
                }

            });
    }

    async handleTwitterUserLogin() {

        let obj = {
                "callback":window.location.href
                // "callback":"http://localhost:3000/login"
        }
        const userRequestToken = await twitterRequestToken(obj);
        window.location.replace(userRequestToken.data.redirect_url)
        // To login page for accessing twitterLogin (Setting to localstorage)
        }

    async authWithCredential(credential) {
        // [START auth_facebook_signin_credential]
        // Sign in with the credential from the Facebook user.
        firebase.auth().signInWithCredential(credential)
          .then((result) => {
            // Signed in       
            return result;

            
          })
          .catch((error) => {
            // Handle Errors here.
            // ...
          });

        }



    redirectCodeScreen=()=>{
        window.$('#exampleModal').modal('hide');
      }
    
    render() {
      const cookies = new Cookies();
        if (this.state.redirect) {
          if(this.state.userExists && cookies.get('retailerId') == undefined){
            return <Redirect push to='/welcome' />;
          }
          if(this.state.userExists && cookies.get('retailerId') != undefined){
            return <Redirect push to='/application' />;
          }
          if(!this.state.userExists){
            return <Redirect push to={'/application'} />;
          }
        }
        return (
          <div>
            <div className="social-links">
              {/* Twitter */}
              <span className="col-md-4 col-sm-3 p-0">
                <Link
                  to={this.props.fromWhere}
                  className="soQr--link" id="facebook"
                  onClick={() => this.handleFbUserLogin()}
                >
                  <img src="images/facebook.svg" alt="Facebook" />
                </Link>
              </span>
              <span className="col-md-4 col-sm-3 p-0">
                <Twitter fromWherePath={'/register'}  />
              </span>
              <span className="col-md-4 col-sm-3 p-0">
                <Link
                  to={this.props.fromWhere}
                  className="soQr--link" id="google"
                  onClick={() => this.handleGoogleUserLogin()}
                >
                  <img src="images/google.svg" alt="Google" />
                </Link>
              </span>
            </div>
            {this.state.loginMessage &&
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                       
                        <div className="modal-body">
                        <div class="logo">
                                    <img src="images/modal-logo.svg" alt="logo" class="img-fluid"/>
                                </div>
                                <h5 className="modal-title" id="exampleModalLabel">Social Login</h5>
                        {this.state.loginMessage &&
                         <p className="text-center mt-4">{this.state.loginMessage}</p>
                        }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={()=>this.redirectCodeScreen()} >Ok</button>
                        </div>
                        </div>
                    </div>
                </div>
    }
          </div>
                      
        );
    }

}

const mapStateToProps = (state) => {
    return {
        FireBaseStore: state.dataStore.FireBaseStore,
    };
    
};


const mapDispatchToProps = (dispatch) => {
    return {
        socialLogin: (formData) => {
            return dispatch(socialLogin(formData));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SocialMedia);