import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import { analyticsClickEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
export default class HomeBannerInactive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemValue: '',
        }
    }



    render() {

        return (
            <div>

                <Link to="/application" className="what-need-section inactive-dark" style={{
                    backgroundImage: `linear-gradient(45deg,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),url(${this.props.applicationImage})`
                }} onClick={(_e) =>analyticsClickEvent('begin_application','')}>

                    <div className="inner-content">
                        <h1 className="need-tittle">APPLY TODAY!</h1>
                        <p className="need-para">Here’s what you’ll need</p>
                        <div className="icon-need-item">
                            <div className="icon--items">
                                <div className="icon-monthly">
                                    <div className="icon-circle">

                                        <img src="images/monthly-icon.svg" className="img-fluid" alt="monthly Income" />

                                    </div>
                                    <div className="icon-para">
                                        <p>£1k+ monthly earnings</p>
                                    </div>
                                </div>
                                <div className="icon-monthly">
                                    <div className="icon-circle">
                                        <img src="images/18+-icon.svg" className="img-fluid" alt="Over18+" />
                                    </div>
                                    <div className="icon-para">
                                        <p>Aged 18+</p>
                                    </div>
                                </div>
                                <div className="icon-monthly">
                                    <div className="icon-circle">
                                        <img src="images/ID-icon.svg" className="img-fluid" alt="Id" />
                                    </div>
                                    <div className="icon-para">
                                        <p>Government <br></br>ID</p>
                                    </div>
                                </div>
                                <div className="icon-monthly">
                                    <div className="icon-circle">
                                        <img src="images/bank-statement-icon.svg" className="img-fluid" alt="Statement" />
                                    </div>
                                    <div className="icon-para">
                                        <p>Active UK bank <br></br>account with card</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-need-content mt-2">
                            <div className="begin-app-a"><h2>Once you’ve completed your application, we’ll assess it based on the details provided to see whether you’re eligible for humm credit. We’re a responsible lender, so we’ll need to perform a <span className="font-weight-bold">hard credit check</span> before we can agree to lend to you.</h2></div>
                            </div>
                        </div>
                    </Link>
            </div>
        );
    }
}