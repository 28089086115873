
import './App.css';
import Routes from './routes';
import React from 'react';

function App() {
  return (
    <div>
    <Routes />
    </div>
    
  );
}
export default App;