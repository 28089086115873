import React, { Component } from "react";
import { Link } from "react-router-dom";
import { analyticsClickEvent } from "../../action/firebaseAnalytics/firebaseAnalytics";
import Instructions from "./instuctions/instruction";

class DocumentVerify extends Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      documentSteps: false,
      passportInstruction: false,
      drivinglicenseInstruction: false,
      selfieInstruction: false,
      documentType: '',
      instruction: true,
      instructionsSection: false,
      passportSection: false,
      drivinglicenseSection: false,
      isEnableDocumentsTabs:false,
      isSelectDocument:'',
      userLoginType:localStorage.getItem('userLoginType')
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.refData) {
      this.setState({ documentSteps: true, instruction: false })
    }
  }


  openinstruction = (documenttype) => {
    if(documenttype === 'passport'){
      analyticsClickEvent('passport','')
    }else if(documenttype === 'ID'){
      analyticsClickEvent('driving_licence','')
    }else if(documenttype === 'VETERAN_CARD'){
      analyticsClickEvent('hm_forces_card','')
    }
      //  analyticsClickEvent('documentType_in_'+documenttype,'')
    this.setState({isEnableDocumentsTabs:true,isSelectDocument:documenttype})
    // this.setState({ documentSteps: false, instructionsSection: true });
    // if (documenttype === 'passport') {
    //   this.setState({ passportSection: true, drivinglicenseSection: false });
    // } else if (documenttype === 'DL_FRONT') {
    //   this.setState({ passportSection: false, drivinglicenseSection: true });
    // }
    // setTimeout(() => {
    //     this.setState({ documentSteps: false});
    //     this.setState({ instruction: true});
    //     this.child.current.viewinstruction(documenttype);
    // },2000);

  }

  updatechanges = (status) => {
    if (status) {
      this.setState({ documentSteps: true, instruction: false,instructionsSection:false })
    }
  }
  handleChange() {
    this.setState({isEnableDocumentsTabs:false,isSelectDocument:''})
    this.props.handleViewChange('4');
  }

  gotoSelectDocument() {
    if (this.state.passportSection === true) {
      setTimeout(() => {
        this.setState({ instructionsSection: false, instruction: true });
        this.child.current.viewinstruction('passport');
      }, 2000);

    } else if (this.state.drivinglicenseSection === true) {
      setTimeout(() => {
        this.setState({ instructionsSection: false, instruction: true });
        this.child.current.viewinstruction('DL_FRONT');
      }, 2000);
    }


  }



  render() {
    return (
      <div class="step-final">
        {this.state.isEnableDocumentsTabs === false &&
          <div class="mit-container">
            <p class="mit-mobile-app">Finally, let’s upload your ID. Please select and upload one of the documents listed below.</p>
            <div class="mit-document-section">
              <div class="mit-inner-box">
                <Link onClick={() => this.openinstruction('passport')}>
                  <span class="inner-left">
                    <img src="images/passport.svg" alt="Card" />
                  </span>
                  <span class="inner-center">
                    <p>Passport</p>
                  </span>
                  <span class="inner-right">
                    <img src="images/circle-outline.png" alt="Success" />
                  </span>
                </Link>
              </div>
              <div class="mit-inner-box">
                <Link onClick={() => this.openinstruction('ID')}>
                  <span class="inner-left">
                    <img src="images/driving-license.svg" alt="Card" />
                  </span>
                  <span class="inner-center">
                    <p>Driving Licence</p>
                  </span>
                  <span class="inner-right">
                    <img src="images/circle-outline.png" alt="Success" />
                  </span>
                </Link>
              </div>
              <div class="mit-inner-box">
                <Link onClick={() => this.openinstruction('VETERAN_CARD')}>
                  <span class="inner-left">
                    <img src="images/driving-license.svg" alt="Card" />
                  </span>
                  <span class="inner-center">
                    <p>HM Forces Card</p>
                  </span>
                  <span class="inner-right">
                    <img src="images/circle-outline.png" alt="Success" />
                  </span>
                </Link>
              </div>
            </div>

          </div>
        }
        {/* {this.state.documentSteps === false && this.state.instructionsSection === true &&
          <div class="mit-container">
            <div class="instructions-section">
            <h4 class="application-qr-sub-title">INSTRUCTIONS</h4>
              <div class="instruction-message">
                <h6>Steps to capture the document correctly</h6>
              </div>
              <div class="suggetion-message">
                <span class="icon-area">
                  <img src="images/progress-success.svg" alt="Success" />
                </span>
                <span class="message-area">
                  <p>Please place the document on a dark, plain background to ensure the best result
                  </p>
                </span>
              </div>
              <div class="suggetion-message">
                <span class="icon-area">
                  <img src="images/progress-success.svg" alt="Success" />
                </span>
                <span class="message-area">
                  <p>All four corners of the document must be visible in the frame</p>
                </span>
              </div>
              <div class="suggetion-message">
                <span class="icon-area">
                  <img src="images/progress-success.svg" alt="Success" />
                </span>
                <span class="message-area">
                  <p>Avoid any glare, shadows or dark spots on the ID document</p>
                </span>
              </div>
              <div class="suggetion-message">
                <span class="icon-area">
                  <img src="images/progress-success.svg" alt="Success" />
                </span>
                <span class="message-area">
                  <p>Hold the device steady while image is being captured</p>
                </span>
              </div>
              <div class="bottom-button-area">
                <button class="btn btn-warning" onClick={() => this.gotoSelectDocument()}>Continue</button>
              </div>
            </div>
          </div>
        } */}
        {this.state.isEnableDocumentsTabs === true &&
          <Instructions documentType = {this.state.isSelectDocument} ref={this.child} handleChnage={this.handleChange} fromWhere={'application'}/>
        }
        
      </div>


    )
  }

}

export default DocumentVerify;