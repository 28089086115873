import React from "react";
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import range from 'lodash/range';
import moment, { invalid } from "moment";
import { analyticsChangeEvent, analyticsClickEvent, analyticsTypeEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";

export class InputDatePicker extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      startDate: '',
      dateType: '',
      time_in_occupation: "",
      changeTexts: false,
      errors: {
      },
      input: {

      },
      stages: {

      }, showValidation: this.props.showAppValidations, startYear: 1922, fieldname: this.props.field_api_name, showDev: {}
    }
  }

  setStartDate = async(date) => {
    this.setState({ changeTexts: true })
    let saveDate = '';
    let dateMovedin = '';
    this.setState({ showValidation: 'yes' });
    let errors = this.state.errors;
    let stages = {};

   
    if (date === null) {
      date = '';
    } else {
      saveDate = format(date, this.state.dateType)
      dateMovedin = format(date, this.state.dateType)
    }
    if (this.props.field_api_name === 'date_moved_in_current_address') {
      const allDetails = Object.assign({}, ...this.props.applicationValidation);
      if (allDetails['flxBirthDate__c'] !== '' && allDetails['flxBirthDate__c']!=undefined) {
        errors['date_moved_in_current_address'] = 'Please select the correct Date of Birth';
        this.setState({
          errors
        });
        stages["date_moved_in_current_address"] = 'Please select the correct Date of Birth';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        return false;
      }

      if(this.props.saveDates==='' || this.props.saveDates===undefined){
        errors['date_moved_in_current_address'] = 'Please select the Date of Birth';
        this.setState({
          errors
        });
        stages["date_moved_in_current_address"] = 'Please select the Date of Birth';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        return false;
      }




      const dates = moment(this.props.saveDates, 'dd/MM/yyyy');
      const month = dates.format('MMM-YYYY');
      const movedDate = moment(saveDate, 'MM/yyyy');
      const movedmonth = movedDate.format('MMM-YYYY');
       const isEarlierThanDob = this.dateConversion(month,movedmonth);
      if (isEarlierThanDob==='true') {
        errors['date_moved_in_current_address'] = 'Date must not be earlier than your Date of Birth';
        this.setState({
          errors
        });
        stages["date_moved_in_current_address"] = 'Date must not be earlier than your Date of Birth';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        date = '';
      }
      if (month > movedmonth) {
        this.props.saveDateMovedIn(dateMovedin)
      }

      this.props.saveDateMovedIn(dateMovedin);
      let input = this.state.input;
      let inputValues = {}
      input[this.props.field_api_name] = date;
      inputValues[this.props.field_api_name] = dateMovedin
      if(stages.date_moved_in_current_address==undefined){
        this.HandlingValidation()
      }else{
        return false;
      }
    }

    if (this.props.field_api_name === 'time_in_occupation') {
      const bDates = moment(this.props.saveDates, 'dd/MM/yyyy');
      const month = bDates.format('MMM-YYYY');
      const dates = moment(saveDate, 'MM/yyyy');
      const tio = dates.format('MMM-YYYY');
      let tioerrors = this.state.errors;
      let tiostages = {};
      const isEarlierThanDob = this.dateConversion(month,tio);
      if (isEarlierThanDob==='true') {
        let tinput = this.state.input;
        tioerrors['time_in_occupation'] = 'Date must not be earlier than your Date of Birth';
        this.setState({
          tioerrors
        });
        tiostages['time_in_occupation'] = 'Date must not be earlier than your Date of Birth';
        tiostages['stage'] = this.props.stage;
        this.props.appvalidations(tiostages);
        let tinputValues = {};
        tinput[this.props.field_api_name] = date;
        tinputValues[this.props.field_api_name] = saveDate;
        this.props.saveApplicationDatas(tinputValues)
        this.setState({
          tinput
        });
        return false;
      }else {
        let tinput = this.state.input;
        tioerrors['time_in_occupation'] = '';
        this.setState({
          tioerrors
        });
        tiostages['time_in_occupation'] = '';
        tiostages['stage'] = this.props.stage;
        this.props.appvalidations(tiostages);
        let tinputValues = {};
        tinput[this.props.field_api_name] = date;
        tinputValues[this.props.field_api_name] = saveDate;
        this.props.saveApplicationDatas(tinputValues)
        this.setState({
          tinput
        });
        return false;
      }
      
    }

    if (this.props.field_api_name === 'genesis__bank_hours__c') {
      const bDates = moment(this.props.saveDates, 'dd/MM/yyyy');
      const month = bDates.format('MMM-YYYY');
      const dates = moment(saveDate, 'MM/yyyy');
      const tio = dates.format('MMM-YYYY');
      let gerrors = this.state.errors;
      let gstages = {};
    const isEarlierThanDob = this.dateConversion(month,tio);
    if (isEarlierThanDob==='true') {
        gerrors['genesis__bank_hours__c'] = 'Date must not be earlier than your Date of Birth';
        this.setState({
          gerrors
        });
        gstages['genesis__bank_hours__c'] = 'Date must not be earlier than your Date of Birth';
        gstages['stage'] = this.props.stage;
        this.props.appvalidations(gstages);
        let ginputValues = {}
        let ginput = this.state.input;
        ginput[this.props.field_api_name] = date;
        ginputValues[this.props.field_api_name] = saveDate;
        this.props.saveApplicationDatas(ginputValues)
        this.setState({
          ginput
        });
        return false;
      } else {
         let ginput = this.state.input;
      
        gerrors['genesis__bank_hours__c'] = '';
        this.setState({
          gerrors
        });
        gstages['genesis__bank_hours__c'] = '';
        gstages['stage'] = this.props.stage;
        this.props.appvalidations(gstages);
        let ginputValues = {};
        ginput[this.props.field_api_name] = date;
        ginputValues[this.props.field_api_name] = saveDate;
        this.props.saveApplicationDatas(ginputValues)
        this.setState({
          ginput
        });
        return false
      }

    }

    let input = this.state.input;
    let inputValues = {}
    input[this.props.field_api_name] = date;
    inputValues[this.props.field_api_name] = saveDate;
    this.props.saveApplicationDatas(inputValues)
    this.setState({
      input
    });
    if (this.props.field_api_name === 'flxBirthDate__c') {
      if (date === null || date === " " || date === undefined || !date || date === invalid) {
        date = '';
        let errors = this.state.errors;
        let stages = {};
        errors['flxBirthDate__c'] = 'This is Required';
        stages["date_moved_in_current_address"] = 'This is Required';
        this.setState({
          errors
        });
        stages["flxBirthDate__c"] = 'This is Required';
        stages["date_moved_in_current_address"] = 'This is Required';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.props.saveDate(saveDate)
        // return false;
      }

      const dates = moment(saveDate, 'dd/MM/yyyy');
      const month = dates.format('YYYY');
      const movedDate = moment(this.props.saveDatesMovedIn, 'MM/yyyy');
      const movedyear = movedDate.format('YYYY');
      if (month > movedyear && month !== "Invalid date") {
        let errors = this.state.errors;
        let stages = {};
        errors['date_moved_in_current_address'] = 'Date must not be earlier than your Date of Birth';
        this.setState({
          errors
        });
        stages["date_moved_in_current_address"] = 'Date must not be earlier than your Date of Birth';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);

        this.props.saveDate(saveDate)
        // return false;
      }
      // 155 126
      else if (month < movedyear && month !== "Invalid date") {
        let errors = this.state.errors;
        let stages = {};
        errors['date_moved_in_current_address'] = '';
        this.setState({
          errors
        });
        stages["date_moved_in_current_address"] = '';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);

        this.props.saveDate(saveDate)
      }
      // 140 // 83 
      else if (month === "Invalid date" || month == undefined) {
        let errors = this.state.errors;
        let stages = {};
        errors['flxBirthDate__c'] = 'This is Required';

        this.setState({
          errors
        });
        stages["flxBirthDate__c"] = 'This is Required';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);

        this.props.saveDate(saveDate)
      }
      this.props.saveDate(saveDate)
    }
    this.setState({ startDate: date });
    this.HandlingValidation();
  }


  componentDidMount() {
    let questionValues={};
    questionValues['field_api_name']=this.props.field_api_name;
    questionValues['priority']=this.props.priority;
    questionValues['stage']=this.props.stage;
    questionValues['field_question'] = this.props.field_question;
    if(this.props.field_api_validations.length!=0){
      questionValues['validation']='yes';
    }else{
      questionValues['validation']='no';
    }
    this.props.questionSave(questionValues);
    
    if (this.props.field_placeholder === 'DD/MM/YYYY') {
      this.setState({ dateType: 'dd/MM/yyyy' })
    }
    if (this.props.field_placeholder === 'MM/YYYY') {
      this.setState({ dateType: 'MM/yyyy' })
    }
    //  this.HandlingValidation();
    this.props.saveDate('');
    this.props.saveDateMovedIn('');
    if (this.props.priority === 3) {
      let showDev = this.state.showDev;
      showDev[this.props.field_api_name] = false;
      let stages = {};
      stages[this.props.field_api_name] = '';
      stages['stage'] = this.props.stage;
      this.props.appvalidations(stages);
      this.setState({
        showDev
      });
    } else {
      let showDev = this.state.showDev;
      showDev[this.props.field_api_name] = true;
      this.setState({
        showDev
      });
    }
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.prepopulateStatus === true && this.state.changeTexts === false) {
      const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
      let prepopulateInput = this.state.input;
      if (prepopulateInput[this.props.field_api_name] !== allDetailData[this.props.field_api_name] && allDetailData[this.props.field_api_name] !== undefined && allDetailData[this.props.field_api_name] !== null) {
        let monthYearDate = allDetailData[this.props.field_api_name];
        if (allDetailData[this.props.field_api_name].replace('/', '').length === 6) {
          let monthDate = allDetailData[this.props.field_api_name].split('/');
          monthYearDate = monthDate[0] + '/' + allDetailData[this.props.field_api_name];
        }
        if (allDetailData[this.props.field_api_name].replace('/', '').length === 9) {
          let dobDate = allDetailData[this.props.field_api_name].split('/');
          monthYearDate = dobDate[1] + '/' + dobDate[0] + '/' + dobDate[2];
        }
        let prepopulateDateValue = new Date(monthYearDate);
        // const dates = moment(this.props.saveDates,'dd/MM/yyyy');
        prepopulateInput[this.props.field_api_name] = prepopulateDateValue;
        this.setState({
          prepopulateInput
        });
      }
    }

    if (nextProps.showAppValidations !== this.state.showValidation) {
      if (nextProps.showAppValidations !== '' || nextProps.stage === '2') {
        this.setState({ showValidation: nextProps.showAppValidations })
      }
    }
    if (nextProps.saveDates !== this.state.startYear && nextProps.saveDates !== '') {

      let year = nextProps.saveDates.substring(nextProps.saveDates.lastIndexOf("/") + 1)
      this.setState({ startYear: year })
    }
    if (nextProps.saveDates === '' && this.state.input['date_moved_in_current_address'] !== '') {

      let input = this.state.input;
      input['date_moved_in_current_address'] = '';
      let inputValues = {}
      inputValues['date_moved_in_current_address'] = '';
      this.props.saveApplicationDatas(inputValues)
      this.setState({
        input
      });
    }
    // const bDates = moment(this.props.saveDates, 'dd/MM/yyyy');
    // const month = bDates.format('MMM-YYYY');


    // const dates = moment(this.state.input['time_in_occupation'], 'dd/MM/yyyy');
    // const years_in_occupation = dates.format('YYYY');
    // const tio = dates.format('MMM-YYYY');
    // var d1 = new Date(month);
    // var d2 = new Date(tio);
    // if (years_in_occupation < 1900 && this.state.input['time_in_occupation']) {
    //   let input = this.state.input
    //   input['time_in_occupation'] = '';
    //   let inputValues = {}
    //   inputValues['time_in_occupation'] = '';
    //   this.props.saveApplicationDatas(inputValues)
    //   this.setState({
    //     input
    //   });
    // }

    // if (this.state.input['genesis__bank_hours__c'] !== '' && this.state.input['genesis__bank_hours__c'] !== undefined) {
    //   const dateswithbank = moment(this.state.input['genesis__bank_hours__c'], 'dd/MM/yyyy');

    //   if (dateswithbank) {
    //     const timewithbank = dateswithbank.format('YYYY');
    //     const dwbmy = dateswithbank.format('MMM-YYYY');
    //     var dwb = new Date(dwbmy);
    //     if (timewithbank < 1900 && this.state.input['genesis__bank_hours__c'] && timewithbank !== 'Invalid date') {
    //       let input = this.state.input
    //       input['genesis__bank_hours__c'] = '';
    //       let inputValues = {}
    //       inputValues['genesis__bank_hours__c'] = '';
    //       this.props.saveApplicationDatas(inputValues)
    //       this.setState({
    //         input
    //       });
    //     }
    //   }
    // }


    if (nextProps.amountRequested.split(',').join('').length > 1) {
      if (nextProps.amountRequested.split(',').join('') > nextProps.threashold) {
        if (nextProps.priority === 3) {
          let showDev = this.state.showDev;
          showDev[this.state.fieldname] = true;
          this.setState({
            showDev
          });

        }
      } else {

        if (nextProps.priority === 3 && nextProps.amountRequested !== '') {
          let showDev = this.state.showDev;
          showDev[this.state.fieldname] = false;
          this.setState({
            showDev
          });
          let input = this.state.input;
          input[this.state.fieldname] = '';
          this.setState({
            input
          });
        }

      }
    }

  }



  HandlingValidation = () => {
    let stages = {};
    stages[this.props.field_api_name] = '';
    stages['stage'] = this.props.stage;
    let errors = this.state.errors;
    errors[this.props.field_api_name] = '';
    this.setState({
      errors
    });
    this.props.appvalidations(stages);
    setTimeout(() => {
      this.props.field_api_validations.map((src) => {
        if (this.state.startDate === '' || this.state.startDate === undefined || this.state.dateMovedin == "") {
          errors[this.props.field_api_name] = 'This is Required';
          this.setState({
            errors
          });
          stages[this.props.field_api_name] = 'This is Required';
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          return false;
        }

        if (this.state.startDate === undefined) {
          errors[this.props.field_api_name] = 'This is Required';
          this.setState({
            errors
          });
          stages[this.props.field_api_name] = 'This is Required';
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          return false;
        }
        if (src.flxType__c === 'Age Range') {
          let today = new Date();
          let birthDate = new Date(this.state.startDate);
          let age = today.getFullYear() - birthDate.getFullYear();
          let m = today.getMonth() - birthDate.getMonth();
          let d=today.getDate() - birthDate.getDate();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          let dobDateCheck=true;
          if(m==0 && d==0 && age==100){
            dobDateCheck=false;
          }
          
          if (age < 18) {
            errors[this.props.field_api_name] = src.flxError_Message__c;
            this.setState({
              errors
            });
            stages[this.props.field_api_name] = src.flxError_Message__c;
            stages['stage'] = this.props.stage;
            this.props.appvalidations(stages);
            return false;
          }
          if (age >= 100 && dobDateCheck===true) {
            errors[this.props.field_api_name] = '';
            this.setState({
              errors
            });
            stages[this.props.field_api_name] = 'Age should be less than 100 year old';
            stages['stage'] = this.props.stage;
            this.props.appvalidations(stages);
            return false;
          }
        }

      });
    }, 200);
  }


  dateConversion=(dob,compareDate)=>{
    let dateComparisonResult='false';
    let dobMonthYearConversion= moment(dob,"MMM-YYYY");
    let compareDateMonthYearConversion= moment(compareDate,"MMM-YYYY");
    
    if(dobMonthYearConversion>compareDateMonthYearConversion){
      dateComparisonResult='true';
    }
    return dateComparisonResult;
  }

  render() {

    const allDetails = Object.assign({}, ...this.props.applicationValidation);
    const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
    let startYear = '';
    if (this.props.field_api_name === 'date_moved_in_current_address' || this.props.field_api_name === 'time_in_occupation' || this.props.field_api_name ===  'genesis__bank_hours__c') {
      startYear = this.state.startYear;
    } else {
      startYear = 1900;
    }
    let allowValue = this.state.showDev[this.props.field_api_name];
    if (this.props.employmentStatus === true && allowValue === true && this.props.field_api_name === 'time_in_occupation') {
      allowValue = true
    }
    if (this.props.employmentStatus === false && this.props.field_api_name === 'time_in_occupation') {
      allowValue = false;
    }
    let disableValue = ''
    if (this.props.editable === false && this.props.prepopulateStatus === true && allDetailData[this.props.field_api_name] != '' && allDetailData[this.props.field_api_name] != null && this.props.prepopulateCondition === true) {
      disableValue = 'nonEditable'
    }

    if (allowValue === true) {
      return (
        <div className="dynamic-input">
          <div class="form-group">
            <span>{this.props.field_question}</span>
            {this.state.dateType === 'dd/MM/yyyy' &&
              <DatePicker selected={allDetailData[this.props.field_api_name] != '' ? this.state.input[this.props.field_api_name] : ''} dateFormat="dd/MM/yyyy" className={`form-control ${disableValue}`} autoComplete="new-password"
                placeholderText={this.props.field_placeholder} onChange={(date) => { this.setStartDate(date); this.props.fieldChange(this.props.field_api_name, date) }}
                showMonthDropdown
                showYearDropdown
                maxDate={new Date()}
                strictParsing
                dropdownMode="select"
                onInputClick ={(e) => analyticsClickEvent(this.props.field_label,'')}
              />
            }
            {this.state.dateType != 'dd/MM/yyyy' &&
              <DatePicker selected={allDetailData[this.props.field_api_name] != '' ? this.state.input[this.props.field_api_name] : ''} className={`form-control ${disableValue}`} autoComplete="new-password"
                placeholderText={this.props.field_placeholder}
                dateFormat={this.state.dateType}
                onInputClick ={(e) => analyticsClickEvent(this.props.field_label,'')}
                showMonthYearPicker
                maxDate={new Date()}
                showFourColumnMonthYearPicker
                strictParsing
                renderCustomHeader={({
                  date,
                  changeYear,
                }) => (
                  <div>
                    <div class="react-datepicker__header react-datepicker-year-header">{date.getFullYear()}</div>
                    <div class="react-datepicker__month-dropdown-container react-datepicker__month-dropdown-container--select">
                      <select id="inputState" className="react-datepicker__year-select" name="datePicker" value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                        {range(startYear, new Date().getFullYear()+1).map((y) =>
                          <option value={y}>{y}</option>
                        )}
                      </select>
                    </div>
                  </div>
                )}
                onChange={(date) => { this.setStartDate(date); this.props.fieldChange(this.props.field_api_name, date) }}

              />
            }
            <p className="error-message">{allDetails[this.props.field_api_name] !== '' && this.state.showValidation === 'yes' &&
              <span class="app-error-message" style={{ color: 'Red' }}>{allDetails[this.props.field_api_name]}</span>
            }
            </p>
          </div>
        </div>
      );
    } else {
      return (<React.Fragment>  </React.Fragment>);
    }

  }

}

export const mapStateToProps = (state) => {
  return {
    applicationValidation: state.dataStore.applicationValidation,
    showAppValidations: state.dataStore.showAppValidations,
    saveApplicationData: state.dataStore.saveApplicationData,
    saveDates: state.dataStore.saveDates,
    saveDatesMovedIn: state.dataStore.saveDatesMovedIn,
    amountRequested: state.dataStore.amountRequested,
    employmentStatus: state.dataStore.employmentStatus,
    threashold: state.dataStore.threashold,
    prepopulateStatus: state.dataStore.prepopulateStatus,
    questionValueSave:state.dataStore.questionValueSave,
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    appvalidations: (validations) => {
      return dispatch({
        type: 'APP_VALIDATIONS',
        value: validations
      });
    },
    showappValidation: (validations) => {
      return dispatch({
        type: 'SHOW_APP_VALIDATIONS',
        value: validations
      });
    },
    saveApplicationDatas: (data) => {
      return dispatch({
        type: 'SAVEDATA',
        value: data
      });
    },
    saveDate: (data) => {
      return dispatch({
        type: 'SAVEDATE',
        value: data
      });
    },
    saveDateMovedIn: (data) => {
      return dispatch({
        type: 'SAVEDATEMOVEDIN',
        value: data
      });
    },
    questionSave:(data)=>{
      return dispatch({
        type: 'QUESTIONSAVE',
        value: data
    });
    },

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputDatePicker)