import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

const headers = {
    'session_id': localStorage.getItem('sessionId'),
  }
export const questions = () => {
  
    let url = config.Humm_Dev_Link+'application/questions'

    return async (dispatch) => {
        let resp = [];
        await axios.get(url,{headers:sessionID()})
            .then(response => {
                dispatch({
                    type: 'APPLICATION_QUESTIONS',
                    value: response.data
                });
                resp = response;
                return resp;
            })
            .catch(async error => {
                await userLogs(url,'',error);
                if(error.response){
        handlingSession(error.response.status);
        }
                resp = error.response;
                return resp;
            })

            return resp;
    }
};


