import { createReducer } from '../store.js';
const propic = localStorage.getItem('profilePicture');

export default createReducer(
	{
		assetList: null,
        FavouritePartners: null,
		ViewPartners:null,
		FireBaseStore:null,
		loginStore:null,
		manualRegisterStore:null,
        profilePicture:propic,
        viewedPartnerStore:null,
		viewedPartnerSearchStore:null,
		favouritePartnerStore:null,
		favouritePartnerSearchStore:null,
		allpartnerListStore:null,
		allpartnerListSearchStore:null,
		allCategoryListStore: null,
        allCategoryListSearchStore: null,
		allIndustriesListStore:null,
		getApplicationQuestions:null,
		movenext:null,
		applicationValidation:[],
		showAppValidations:'',
		emails:'',
		confirmemail:'',
		saveApplicationData:'',
		phoneNumbers:'',
		saveDates:'',
		saveDatesMovedIn:'',
		amountRequested:'',
		employmentStatus:'',
		contractSummary:'',
		threashold:'',
		prepopulateStatus:false,
		questionValueSave:[],
		notificationDatas:[],
		storePartnerImage:[],
		showCurrentAddress:''
	},
	{
		'ASSET_LIST': (state, action) => {
			return Object.assign({}, state, {
				assetList: action.value
			});
		},
        'FAV_PARTNERS': (state, action) => {
			return Object.assign({}, state, {
				FavouritePartners: action.value
			});
		},
		'VIEW_PARTNERS': (state, action) => {
			return Object.assign({}, state, {
				ViewPartners: action.value
			});
		},
		'FIRE_BASE': (state, action) => {
			return Object.assign({}, state, {
				FireBaseStore: action.value
			});
		},
		'LOGIN_STORE': (state, action) => {
			return Object.assign({}, state, {
				loginStore: action.value
			});
		},
		'REGISTER_STORE': (state, action) => {
			return Object.assign({}, state, {
				manualRegisterStore: action.value
			});
		},
    	'VIEWED_PARTNER': (state, action) => {
			return Object.assign({}, state, {
				viewedPartnerStore: action.value
			});
		},
		'VIEWED_SEARCH_PARTNER': (state, action) => {
			return Object.assign({}, state, {
				viewedPartnerSearchStore: action.value
			});
		},
		'FAVOURITE_SEARCH_PARTNER': (state, action) => {
			return Object.assign({}, state, {
				favouritePartnerSearchStore: action.value
			});
		},
		'FAVOURITE_PARTNER': (state, action) => {
			return Object.assign({}, state, {
				favouritePartnerStore: action.value
			});
		},
		'PARTNER_LIST': (state, action) => {
			return Object.assign({}, state, {
				allpartnerListStore: action.value
			});
		},
		'PARTNER_SEARCH_LIST': (state, action) => {
			return Object.assign({}, state, {
				allpartnerListSearchStore: action.value
			});
		},
		'CATEGORY_LIST': (state, action) => {
			return Object.assign({}, state, {
				allCategoryListStore: action.value
			});
		},
		'CATEGORY_SEARCH_LIST': (state, action) => {
			return Object.assign({}, state, {
				allCategoryListSearchStore: action.value
			});
      },
		'SET_PROFILE_PIC': (state, action) => {
			localStorage.setItem('profilePicture',action.value)
			return Object.assign({}, state, {
				profilePicture: action.value
			});
		},
		'UNSET_PROFILE_PIC': (state, action) => {
			localStorage.removeItem('profilePicture');
			return Object.assign({}, state, {
				profilePicture: null
			});
		},
		'INDUSTRIES_LIST': (state, action) => {
			return Object.assign({}, state, {
				allIndustriesListStore: action.value
			});
		},
		'APPLICATION_QUESTIONS': (state, action) => {
			return Object.assign({}, state, {
				getApplicationQuestions: action.value
			});
		},
		'NEXT_MOVE': (state,action) => {
			return Object.assign({}, state,{
				
				movenext: action
			});
		},
		'APP_VALIDATIONS': (state,action) => {
			if(state.applicationValidation!==''){
				state.applicationValidation.map((src,key)=>{
					if(Object.keys(action.value)['0']===Object.keys(src)['0']){
					   state.applicationValidation.splice(key, 1);
					}
				})
			}
			return { 
				...state,
				applicationValidation: [...state.applicationValidation, action.value]
			}
		},
		'APP_VALIDATIONS_Remove': (state,action) => {
			return Object.assign({}, state,{
				applicationValidation: action.value
			});
		},
		'SHOW_APP_VALIDATIONS': (state,action) => {
			return Object.assign({}, state,{
				showAppValidations: action.value
			});
		},
		'HIDE_APP_VALIDATIONS': (state,action) => {
			return Object.assign({}, state,{
				showAppValidations: action.value
			});
		},
		'EMAIL': (state,action) => {
			return Object.assign({}, state,{
				emails: action.value
			});
		},
		'CONFIRMEMAIL': (state,action) => {
			return Object.assign({}, state,{
				confirmemail: action.value
			});
		},
		'SAVEDATA': (state,action) => {
		 if(state.saveApplicationData!==''){
			state.saveApplicationData.map((src,key)=>{
				if(Object.keys(action.value)['0']===Object.keys(src)['0']){
				   state.saveApplicationData.splice(key, 1);
				}
			})
			}
		   return { 
			   ...state,
			   saveApplicationData: [...state.saveApplicationData,action.value]
		   }
	   },
	   'REMOVEDATA': (state,action) => {
		   return Object.assign({}, state,{
			saveApplicationData: action.value
		   });
	  	},
		'PREPOPOLATE': (state,action) => {
			return {
				...state,
				saveApplicationData: [...state.saveApplicationData,action.value]
			}
		 },
		'PHONENO': (state,action) => {
			return Object.assign({}, state,{
				phoneNumbers: action.value
			});
		},
		'SAVEDATE': (state,action) => {
			return Object.assign({}, state,{
				saveDates: action.value
			});
		},
		'SAVEDATEMOVEDIN': (state,action) => {
			return Object.assign({}, state,{
				saveDatesMovedIn: action.value
			});
		},
		'NOTIFICATIONDATA': (state,action) => {
			return Object.assign({}, state,{
				notificationDatas: action.value
			});
		},
		'AMOUNTREQUESTED': (state,action) => {
			return Object.assign({}, state,{
				amountRequested: action.value
			});
		},
		'EMPLOYNMENTSTATUS': (state,action) => {
			return Object.assign({}, state,{
				employmentStatus: action.value
			});
		},
		'SET_CONTRACT_SUMMARY':(state,action) => {
			localStorage.setItem('contractSummary',JSON.stringify(action.value));
			return Object.assign({}, state,{
				contractSummary: action.value
			});
		},
		'UNSET_CONTRACT_SUMMARY':(state,action) => {
			localStorage.removeItem('contractSummary');
			return Object.assign({}, state,{
				contractSummary: ''
			});
		},
		'THREADSHOLD': (state,action) => {
			return Object.assign({}, state,{
				threashold: action.value
			});
		},
		'PREPOPULATEE': (state,action) => {
			return Object.assign({}, state,{
				prepopulateStatus: action.value
			});
		},
		'QUESTIONSAVE':(state,action) => {
			if(state.questionValueSave!==''){
				state.questionValueSave.map((src,key)=>{
					if(action.value['field_api_name']===src['field_api_name']){
					   state.questionValueSave.splice(key, 1);
					}
				})
				}
			return {
				...state,
				questionValueSave: [...state.questionValueSave,action.value]
			}
		},
		'REMOVEQUESTIONSAVE': (state,action) => {
			return Object.assign({}, state,{
				questionValueSave: action.value
			});
		},
		'STOREPARTNERIMAGE' :(state,action)=>{
			if(state.storePartnerImage!==''){
				state.storePartnerImage.map((src,key)=>{
					if(action.value['image_url']===src['image_url'] && action.value['access_token']===src['access_token']){
					   state.storePartnerImage.splice(key, 1);
					}  
					})
				}
			return {
				...state,
				storePartnerImage: [...state.storePartnerImage,action.value]
			}
		},
		'SHOWADDRESS': (state,action) => {
			return Object.assign({}, state,{
				showCurrentAddress: action.value
			});
		},
		
	}
);
